.pt_protect {
	.ui-widget-header .ui-button .ui-button-icon-primary.ui-icon-closethick {
		margin-top: 0;
	}
	&.video #dialog-container.dialog-contentgit a {
		margin-top: -13px!important;
	}
	#protection {
		.protect-btn {
			font-size: .9375rem;
			font-family: neuzeit-grotesk,sans-serif;
			font-weight: 700;
			height: 3.75rem;
			margin-right: 15px;
		}
		.input-protect,
		.lbl-protect {
			font-size: 1rem;
			line-height: 3.25rem;
			min-height: 3.25rem;
			border-radius: 0;
			border-color: #9d9d9d;
			color: #6e6e6e;
			font-family: neuzeit-grotesk,sans-serif;
		}
		.lbl-protect {
			color: $black;
		}
	}
	.ui-dialog-content {
		background-color: $white;
		padding: 30px;
	}
	.h2 {
		font-weight: 700;
		font-size: 1.5rem;
		font-family: neuzeit-grotesk,sans-serif;
	}
	.ui-dialog-titlebar-close {
		position: absolute !important;
		min-width: 0;
		top: 46px;
		background: url(../images/interface/black-close-btn.png) no-repeat;
		border: none;
		opacity: .5;
		&:hover {
			background-color: initial;
			border-color: initial;
			opacity: 1;
		}
	}
}
.ui-widget-overlay {
    background: url(../images/interface/ui-overlay-shadow.png) repeat scroll 50% 50% #000;
    opacity: 0.95;
}

.ui-dialog.signup-sms-dialog {
    font-family: $neuzeit;
    background-color: #f6f6f6;
    @include respond(large){
        width: 626px !important;
    }
    .ui-widget-header {
        background-color: #f6f6f6;
    }
    .ui-dialog-content {
        margin-top: -30px;
        text-align: center;
        max-width: 626px;
        box-sizing: border-box;
        padding-left: 34px !important;
        padding-right: 34px !important;
        @media screen and (max-width: 879px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
    .signup-sms-modal {
        #errorPlaceHolder {
            color: #b2595c;
        }
    }
    .signup-sms-modal-submitted-content {
        &:after {
            content: '';
            display: block;
            width: 592px;
            height: 1px;
            @media screen and (max-width: 879px) {
                width: 270px;
            }
        }
    }
    .signup-sms-modal-submitted-content,
    .signup-sms-modal-heading {
        .signup-sms-modal-header {
            font-size: 24px;
            line-height: 1;
            font-weight: 800;
            @include respond(largeUp) {
                font-size: 32px;
            }
        }
        .signup-sms-modal-subheader {
            font-size: 18px;
            line-height: 27px;
            @include respond(largeUp) {
                margin-top: 28px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    button {
        font-family: $neuzeit;
        text-transform: none;
        &.signup-sms-modal-submit {
            position: relative;
            min-width: 80px;
            height:44px;
            @include respond(large){
                height: 60px;
            }
            @include respond(small){
                height: 60px;
            }
            @include respond(tablet){
                height: 60px;
            }
            &::after {
                background-image: url("../images/chevron-right.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 26px;
                height: 26px;
                content: '';
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover {
                &::after {
                    filter: invert(1);
                }
            }
        }
    }
    .input-custom-label {
        text-align: left;
        font-weight: 400;
    }
    .input-custom {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .field-wrapper {
            text-align: left;
        }
        span.error {
            & + span.error {
                display: none;
            }
        }
        input {
            height: 44px;
            @include respond(large){
                height: 60px;
            }
            @include respond(small){
                height: 60px;
            }
            @include respond(tablet){
                height: 60px;
            }
        }
    }
    .checkbox-custom {
        position: relative;

        input[type=checkbox]+label>.styled-checkbox {
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 15px;
            background: $white;
            border: 1px solid $black;
            vertical-align: top;
            min-width: 18px;
            &::after,
            &::before {
                display: none;
            }
        }
        input[type=checkbox] {
            z-index: 2;
            width: 100%;
            height: 100%;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            &.error {
                & + label {
                    & > .styled-checkbox {
                        border: 1px solid #b2595c;
                    }
                    & > .signup-lable-text {
                        color: #b2595c;
                    }
                }
                &:checked {
                    & + label {
                        & > .styled-checkbox {
                            border: 1px solid $black;
                        }
                        & > .signup-lable-text {
                            color: $black;
                        }
                    }
                }
            }
        }
        input[type=checkbox]:checked+label>.styled-checkbox {
            background-color: $black;
            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 3px;
                background: #fff;
                display: block;
            }
            &::before {
                width: 8px;
                bottom: 5px;
                left: 1px;
                transform: rotate(45deg);
            }
            &::after {
                width: 14px;
                right: 0;
                bottom: 7px;
                transform: rotate(-45deg);
            }
        }
    }
    .lbl-check {
        padding-left: 0;
        max-width: 100%;
        display: flex;
        position: relative;
        z-index: 3;
        pointer-events: none;
        .signup-lable-text {
            font-weight: 400;
            text-align: left;
            line-height: 1.25;
            color: $black;
            a {
                pointer-events: all;
            }
            p {
                margin: 0;
                & + p {
                    margin-top: 12px;
                }
            }
            a {
                color: $black;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
