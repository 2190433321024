.pdp-expand.pdp-explore {
    &.expanded {
        overflow: unset;
    }
    > .tlt-asset-grid {
        margin-bottom: 30px;
    }
}

.pdp-secondaryContent {
    .content-2 h3,
    .content-2 h2,
    .content-container .content h2,
    .content-container .content h3 {
        font-family: $neuzeit;
        text-transform: none;
        color: $black;
    }

    .content-2 h3 {
        font-weight: normal;
    }

    .content-2 h3 {
        font-size: rem(24px);
        font-family: $neuzeit;
        font-weight: bold;
        padding: 25px 0 5px;
        top: auto;
    }

    .explore-1x {
        margin-top: 30px;
        margin-bottom: 40px;

        @include respond(largeUp) {
            margin-bottom: 40px;
        }
    }

    .explore-tlt {
        margin-top: 0;
        margin-bottom: 20px;
        padding: 0;
        font-size: 24px;
        font-weight: 900;
        line-height: 28.8px;

        @include respond(largeUp) {
            margin-bottom: 40px;
            line-height: 27px;
        }
    }

    .explore-sub-tlt {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 900;
        line-height: 21.6px;

        @include respond(largeUp) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .content-asset p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        @include respond(largeUp) {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .pdp-piu-wrap {
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);

        @include respond(largeUp) {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h3.tlt-asset-grid {
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

            @include respond(largeUp) {
                font-size: 36px;
                line-height: 43.2px;
            }

            &::after {
                display: none;
            }
        }
    }
}

.explore-flex {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @include respond(largeUp) {
        gap: 50px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.explore__video {
    aspect-ratio: 16/9;

    @include respond(small) {
        margin-left: -20px;
        margin-right: -20px;
    }

    @include respond(largeUp) {
        flex: 0 0 69%;
        max-width: 827px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

.explore__video-description {
    @include respond(largeUp) {
        text-align: left;
    }

    @include respond(1200px) {
        margin-top: 100px;
    }
}

.explore-slider {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 40px;

    @include respond(largeUp) {
        margin-bottom: 60px;
    }

    .slick-prev,
    .slick-next {
        width: 68px;
        height: 68px;
        opacity: 1;
        top: 270px;
        transform: translateX(-50%);
        background: url('../images/carouselBlackArrowRight.svg') no-repeat center;
        background-size: 10px;

        @include respond(largeUp) {
            top: 110px;
        }

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-prev {
        left: -20px;
        transform: rotate(180deg);

        @include respond(largeUp) {
            left: 10px;
            transform: translateX(-100%) rotate(180deg);
        }
    }

    .slick-next {
        right: -20px;
        transform: none;

        @include respond(largeUp) {
            right: 10px;
            transform: translateX(100%);
        }
    }
}

.explore__slide {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;

    .explore__slide__image {
        margin-bottom: 20px;

        @include respond(largeUp) {
            margin-bottom: 30px;
        }
    }

    .explore-item {
        padding: 0;

        @include respond(small) {
            padding: 0 20px;
        }
    }
}

.explore-overlay {
    position: relative;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    .explore-banner {
        position: absolute;
        display: flex;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        max-width: 1200px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        box-sizing: border-box;

        @include respond(large) {
            max-width: 880px;
        }

        @media screen and (min-width: 1320px) {
            padding-left: 0;
            padding-right: 0;
            max-width: 1300px;
        }
    }

    .explore-banner-copy {
        text-align: left;

        @include respond(largeUp) {
            margin-top: 40px;
        }
    }

    .explore-banner__title {
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        color: $white;
        margin-bottom: 10px;

        @include respond(largeUp) {
            font-size: 42px;
            line-height: 42px;
            margin-bottom: 20px;
        }
    }

    .button.white-new {
        border-color: $white;
        font-size: 15px;
        font-weight: 700;
        line-height: 42px;
    }

    .explore-banner__link {
        font-size: 23px;
        line-height: 27.8px;
        font-weight: 700;
        text-decoration: underline;
        color: $white;

        &:hover {
            text-decoration: none;
        }
    }
}

.guided-shopping-content {
    @include respond(small) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .sec4_update {
        padding: 60px 0;

        @include respond(small) {
            padding: 30px 0 40px;
        }
    }

    .inner_sec4 {
        position: relative;
        padding: 31px;
        max-width: 616px;
        margin: 0 auto;
        text-align: center;
        box-shadow: 0 2px 11px -6px #888;

        @include respond(small) {
            padding: 31px 10px 20px;
        }

        img {
            position: absolute;
            top: -17px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 40px;
            width: 100%;
        }
        h4 {
            font-size: 16px;
            max-width: 330px;
            margin: 0 auto 20px;
            font-family: neuzeit-grotesk,sans-serif;
            font-weight: normal;
            line-height: 1.5;
            text-transform: none;
            letter-spacing: 0;

            @include respond(small) {
                max-width: 220px;
            }
        }
        a {
            color: #000;
            font-family: neuzeit-grotesk,sans-serif;
            font-size: 16px;
            padding: 0 40px;
            background-image: url("/MEDP/btn_right_arrow.png?$staticlink$");
            background-repeat: no-repeat;
            background-position: center right 10px;
            text-transform: none;
            letter-spacing: 0;
            font-weight: 600;
            transition: 0.5s;

            @include respond(small) {
                padding: 0 40px 0 0;
            }

            &:hover {
                background-position: center right 0;
            }
        }
    }
}