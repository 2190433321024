.bonus-product-list {
    .promotion {
        padding: rem(20px) 0;
    }
    .bonus-product-item {
        padding-top: rem(20px);
        margin-bottom: rem(20px);
        .product-col-1 {
            display: inline-block;
            width: 40% !important;
        }
        .product-col-2 {
            min-width: 0;
            padding: 0;
            width: 60% !important;
            .product-number {
                display: none;
            }
            .input-text {
                border-radius: 0;
                padding: 0 1em;
                width: 86%;
                background-position-x: calc(100% - 7px);
                &.error {
                    background-color: transparent;
                }
            }
            .product-variations {
                margin: rem(14px) 0 0;
                .swatches {
                    li.selected-value {
                        left: auto;
                        width: 78%;
                    }
                }
                .custom-select-field {
                    margin-top: rem(7px);
                }
            }
        }
        &:not(.bonus-master) {
            ul.color,
            ul.custom-select {
                li:not(.selected) {
                    display: none;
                }
                li.selected-value {
                    display: inline;
                }
            }
            .no-selection .select-header {
                display: none;
            }   
        }
    }
    .custom-select-field {
        margin-bottom: rem(16px);
        .container {
            margin: 0;
            width: 100%;
            .select-size,
            .select-sizeW,
            .select-sizeJ,
            .select-sizeJG,
            .select-width,
            .select-length,
            .select-hand,
            .select-waist,
            .select-gloveHand {
                background: none;
                border: none;
                margin: 0;
                padding: 0;
                width: 100%;
                .content {
                    display: block !important;
                }
                li {
                    @include font-size(13px);
                    @include line-height(32px);
                    border-radius: 0;
                    letter-spacing: 0;
                    margin-top: rem(5px);
                    padding: rem(2px) rem(10px);
                    border-color: $gray-light;
                    &.selected {
                        background: $black;
                        color: $white;
                        font-weight: normal;
                    }
                    &.inactive {
                        background: $white;
                        color: $gray-light2;
                        font-weight: bold;
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .product-add-to-cart {
        .inventory {
            width: rem(66px);
            label {
                @include font-size(13px);
                @include line-height(13px);
                color: $grey-label;
                float: none;
                margin: 0 0 rem(2px);
            }
            input {
                background: none;
                border: 1px solid black;
                border-radius: 0;
                font-weight: bold;
                margin: 0;
                width: rem(50px);
            }
        }
    }
    .select-bonus-item,
    .no-stock {
        background: $black !important;
        border: none;
        color: $white !important;
        font-family: $neuzeit;
        margin: 0 !important;
        height: rem(42px);
        width: calc(100% - 66px);
        margin-top: 15px !important;
        .btn-text-enabled,
        .btn-text-disabled {
            display: none;
        }
        &[disabled],
        &.disabled {
            opacity: 0.2925;
            .btn-text-disabled {
                display: inline;
            }
        }
        &.enabled {
            .btn-text-enabled {
                display: inline;
            }
        }
    }
    .select-bonus-item {
        margin: 15px 0 0 0 !important;
        height: rem(42px);
    }
    .bonus-product-list-footer {
        .add-to-cart-bonus {
            font-size: 20px;
            height: 42px;
        }
    }
    .quantity-error {
        bottom: rem(-30px);
        right: 0;
        left: auto;
        padding: 0 rem(15px);
        @include respond(extra-small) {
            position: relative;
            bottom: rem(-13px);
            display: inline-block;
            width: 200%;
        }
    }
}

.bonus-product-list .bonus-variant .custom-select-field .container .select-size li.selected {
    pointer-events: none;
}

.ui-dialog.bonus-dialog {
    .ui-dialog-title {
        @include font-size(18px);
        font-family: $neuzeit;
        letter-spacing: 0;
        margin-left: 0 !important;
        white-space: normal;
    }
    .ui-dialog-titlebar-close {
        @include respond(largeUp) {
            right: -8px !important;
        }
    }
    &.select-bonus-dialog {
        .ui-dialog-buttonpane button {
            font-size: 20px;
            font-weight: 200;
        }
    }
    .bonus-dialog-close {
        text-align: center;
        a {
            text-transform: uppercase;
            @include font-size(14px);
            color: $black;
            text-decoration: underline;
        }
    }
}

#mini-cart {
    .bonus-discount-container {
        display: none;
    }
}
