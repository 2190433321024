
// Help Menu

#wrapper.pt_checkout .top-banner {
	.menu-utility-user {
		background-color: transparent;
		float: right;
		position: absolute;
			right: 0;

		li {
			float: left;
			padding-top: 0;
		}

		li#mini-cart {
			a.mini-cart-link {
				padding-left: 0;
				padding-top: 5px;
			}
			.minicart-quantity {
				margin-left: 0;
				margin-top:-5px;
			}
		}
	}
}

.menu-help {
	font-size: 14px;
	letter-spacing: normal;
	list-style: none;
	margin: 0;
	padding: 0;
	float: right;
	overflow: hidden;
	line-height: 30px;
	text-transform: uppercase;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	height: 30px;
	@include breakpoint( $small-breakpoint ) {
		display: block;
	}
	.help-menu-trigger {
		border-left: 1px solid $white;
		padding: 0;
		position: relative;
		font-weight: bold;
		font-family: $font-neuzeit;
		@include breakpoint( $small-breakpoint ) {
			padding: 17px 0;
			margin-right: 10px;
		}

		&::after {
			content: "X";
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			opacity: 0;
			color: $white;
			font-weight: 700;
			line-height: 30px;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.active {

		.help-menu-trigger {
			span {
				opacity: 0;
			}
			&::after {
				opacity: 1;
			}
		}

		.help-links {
			display: block;
			width: 50vw;
			background: $black;
			text-align: right;
		}

	}

	.help-links {
		display: none;
		a,
		h3 {
			display: inline-block;
			font-family: $font-neuzeit;
			font-size: 14px;
			font-weight: bold;
			margin: 0;
			padding: 0 10px;
			vertical-align: top;
		}
	}

	.help-panel {
		float: left;
	}

	.content-asset {
		a, h3 {
			color: $white;
			text-transform: uppercase;
			span {
				padding:5px 15px;
			}
		}

		a {
			&.help-menu-trigger {
				span {
					padding: 5px 36px;
				}
			}
		}
	}
}

.UsableNetAssistive {
	font-family: $font-neuzeit;
	font-weight: 300;
	@include respond(largeUp) {
		padding-right: 36px !important;
	}
	@include respond(small) {
		font-size: 12px;
		color: $black;
		text-transform: uppercase;
	}
}

.menu-utility-user {
	width: 41px;
	height: 100%;
	margin: 0;
    padding: 0;
	position: relative;
	background: $white;

	li {
		list-style: none;

		&#liveChat{
			@include respond(large){
				display: block;
			}
			@include respond(small){
				display: none;
			}
		}

		&.user-info {
			display: block;
			width: 40px;
			height: 100%;
			position: relative;
			@include respond(small){
				position:inherit;
			}
				&.is-active .user-panel {
					opacity: 1;
					display: block;
					@include respond(small){
						left: 0;
						height: 82%;
						position: fixed;
						top: 74px;
						overflow-y: auto;
						z-index: 21;
					}
				}

				& > a {
					background-image: url('../images/icons/icon-account-new.svg');
					background-position: 50%;
					background-repeat: no-repeat;
					background-color: $white;
					display: block;
					font-size: 18px;
					padding: 0;
					text-transform: uppercase;
					width: 39px;
 					height: 100%;
					position: relative;

					&.loggedin {
						&::after {
							background-color: $lima;
							border-radius: 50%;
							content: "";
							display: block;
							height: 9px;
							position: absolute;
							top: 40px;
							left: 25px;
							width: 9px;
							@include respond(small){
								top: 32px;
							}
						}
					}

					span {
						display: none;
                    }
				}
		}

		// User Info Dropdown
		.user-panel {
			background-color: #f8f8f8;
			border-top: 5px solid #4d738a;
			box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
			opacity: 0;
			position: absolute;
			top: 48px;
			right: -48px;
			transition: all .3s ease;
			width: 100vw;
			display:none;
			font-family: neuzeit-grotesk,sans-serif,serif;
			.is-active & {
				opacity: 1;
			}

			@include respond(largeUp) {
				max-width: 375px;
				position: absolute;
				top: 100%;
				right: 0;
				left: auto;
				transform: none;
				z-index: 20;
			}

			&__top {
				padding: 20px 25px;

				h3 {
					color: #000;
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-weight: bold;
					font-size: 24px;
					letter-spacing: -0.12px;
					margin: 0;
					text-transform: capitalize;
				}
			}

			&__greeting {
				font-family: neuzeit-grotesk,sans-serif,serif;
				color: $grey-label;
				font-size: 14px;
				letter-spacing: -0.28px;
			}

			.user-links a {
				font-family: neuzeit-grotesk,sans-serif,serif;
				background-color: $gray-pale;
				border-top: 1px solid $gray-line;
				color: $black;
				font-weight: bold;
				display: block;
				font-size: 14px;
				letter-spacing: 0.35px;
				padding: 18px 20px 16px;
				position: relative;
				text-transform: uppercase;
				text-indent: 53px;
				background-repeat: no-repeat;
				background-position: 30px center;

				&:hover {
					background-color: $gray-cirlce;
				}

				&::after {
					content: "";
					position: absolute;
					width: 16px;
					height: 16px;
					top: 50%;
					right: 24px;
					border-top: 2px solid $blue-medium2;
					border-right: 2px solid $blue-medium2;
					transform: translateY(-50%) rotate(45deg);
					transform-origin: 50% 50%;
					transition: transform .5s;
				}

				&.user-insider {
					background-image: url('../images/icons/icon-insider.png');
				}

				&.user-preferences {
					background-image: url('../images/icons/icon-preferences.png');
				}

				&.user-orders {
					background-image: url('../images/icons/icon-orders.png');
				}

				&.user-profile {
					background-image: url('../images/icons/icon-my_info.png');
				}

				&.user-wishlist {
					background-image: url('../images/icons/icon-wishlist.png');
				}

                &.user-myfittings {
                    background-image: url('../images/icons/icon-myfittings.svg');
                }

                &.user-logout {
					text-indent: 10px;
					&::after {
						border: none;
					}
				}
			}

			.account-no-toggle-register a {
				background-color: #000;
				border-radius: 0;
				color: #FFF;
				font-family: neuzeit-grotesk,sans-serif,serif;
				font-size: 15px;
				font-weight: bold;
				width: 100%;
				height: 60px;
				line-height: 60px;
				text-align: center;
				display: block;
				margin-top: 15px;
				&:hover {
					text-decoration: none;
				}
			}

			// Non Logged-in User
			.account-toggle-wrapper {
				padding: 30px;

				+ .account-toggle-wrapper {
					border-top: 1px solid $gray-mediumer;
				}

				&.active {
					.account-toggle-content {
						display: block;
					}
				}

				input:not([type=checkbox]) {
					border: 1px solid $gray-border;
					border-radius: 0;
					@include font-small;
					font-size: 16px;
					letter-spacing: -0.32px;
					font-weight: 400;
					padding: 4px 18px;
					text-transform: none;

					+ input,
					+ span:empty + input,
					+ button {
						margin-top: 15px;
					}
				}

				label,
				.password-link {
					font-size: 14px;
					font-weight: normal;
					text-transform: capitalize;
					padding: 28px 0;
					background: none;
					letter-spacing: normal;

					a {
						text-decoration: underline;
						color: $black;
						font-weight: bold;
						padding-left: 9px;

						&.privacy-policy {
							font-weight: normal;
							padding-left: 0;
						}
					}

					span {
						text-transform: none;
						&.signup-lable-text {
							display: inline-block;
							width: calc(100% - 35px);
						}
					}
				}

				.password-link {
					font-size: 12px;
					padding: 13px 0;
				}

				button {
					background-color: #000;
					border-radius: 0;
					color: #FFF;
					font-family: neuzeit-grotesk,sans-serif,serif;
					font-size: 15px;
					font-weight: bold;
					width: 100%;
					height:60px;
				}

				input[type=checkbox]+label>.styled-checkbox {
					position: relative;
					display: inline-block;
					width: 18px;
					height: 18px;
					margin-right: 15px;
					float: left;
					background: $white;
					border: 1px solid $black;
					vertical-align: top;

					&::after,
					&::before {
						display: none;
					}
				}

				input[type=checkbox] {
					display: none;
				}

				input[type=checkbox]:checked+label>.styled-checkbox {
					background-color: $black;

					&::before,
					&::after {
						content: "";
						position: absolute;
						height: 3px;
						background: #fff;
						display: block;
					}

					&::before {
						width: 8px;
						bottom: 5px;
						transform: rotate(45deg);
					}

					&::after {
						width: 14px;
						right: -1px;
						bottom: 7px;
						transform: rotate(-45deg);
					}
				}
				&.active {
					.account-toggle-trigger {
						&::after {
							content: "-";
						}
					}
				}
				.account-toggle-trigger {
					color: #000;
					font-weight: bold;
					font-size: 24px;
					line-height: 36px;
					position: relative;
					display: block;
					text-transform: capitalize;

					&::after {
						font-size: 30px;
						content: "+";
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}
				}

				.account-toggle-content {
					display: none;
					padding-top: 30px;
					span.error, div.error, div.error-message {
						font-family: $font-small;
					}
				}
                #regiserStep2 {
                    padding-top: 10px;
                }
                .sms-channel-section.header-register-section {
                    max-width: 526px;
                    .registration-sms-channel-header {
                        text-transform: none;
                        .registration-sms-channel-heading {
                            color: #000;
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 36px;
                        }
                        .registration-sms-channel-subheading {
                            color: #000;
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                    .form-row {
                        display: inline-block;
                        width: 100%;
                        &::after {
                            display: none;
                        }
                        &.smschannelphone {
                            margin-bottom: 0;
                            margin-top: 20px;
                            label {
                                padding: 0;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                        &.checkbox-custom {
                            margin-bottom: 0;
                            margin-top: 8px;
                            label {
                                width: 100%;
                                padding-left: 0;
                                line-height: 16px;
                                font-weight: 400;
                                text-transform: none;
                                display: flex;
                                align-items: flex-start;
                                margin: 0;
                                padding: 0;
                                &::after {
                                    display: none;
                                }
                            }
                            input[type=checkbox]+label {
                                background: none;
                            }
                            input[type=checkbox]+label>.styled-checkbox {
                                position: relative;
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                margin-right: 8px;
                                background: $white;
                                border: 1px solid $black;
                                vertical-align: top;
                                min-width: 14px;
                                border-radius: 2px;
                                box-sizing: content-box;
                                &::after,
                                &::before {
                                    display: none;
                                }
                            }
                            input[type=checkbox] {
                                display: none;
                            }
                            input[type=checkbox]:checked+label {
                                background: none;
                            }
                            input[type=checkbox]:checked+label>.styled-checkbox {
                                background-color: $black;
                                &::before,
                                &::after {
                                    content: "";
                                    position: absolute;
                                    height: 3px;
                                    background: #fff;
                                    display: block;
                                }
                                &::before {
                                    width: 6px;
                                    height: 2px;
                                    bottom: 5px;
                                    left: 1px;
                                    transform: rotate(45deg);
                                }
                                &::after {
                                    width: 10px;
                                    height: 2px;
                                    right: 0;
                                    bottom: 7px;
                                    transform: rotate(-45deg);
                                }
                            }
                            & ~ .checkbox-custom {
                                margin-top: 8px;
                            }
                        }
                        &.registration-sms-channel-notification {
                            color: #000000;
                            font-weight: 400;
                            margin-top: 4px;
                            text-transform: none;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin: 0;
                                width: 100%;
                                & + p {
                                    margin-top: 12px;
                                }
                            }
                            a {
                                color: #000000;
                                font-size: 11px;
                                line-height: 16px;
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    .sms-channel-additional-options {
                        width: 100%;
                        margin-top: 0;
                        h2, h3, h4, h5, p, a {
                            color: $black;
                            font-family: $neuzeit;
                            text-transform: none;
                            letter-spacing: normal;
                        }
                        h2,
                        h3,
                        h4 {
                            font-weight: 700;
                        }
                        h2 {
                            font-size: 24px;
                            margin-bottom: 15px;
                        }
                        h3 {
                            font-size: 20px;
                            padding: 10px 0;
                        }
                        p,
                        h4 {
                            font-size: 16px;
                            width: 100%;
                        }
                        & > h4 {
                            margin-top: 0;
                            margin-bottom: 8px;
                        }
                        input[type="checkbox"] {
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            width: initial;
                            height: initial;
                            overflow: hidden;
                            z-index: 2;
                            min-height: initial;
                            margin: 0;
                        }
                        input[type="radio"] {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            right: 0;
                            bottom: 0;
                            margin: 0;
                            min-height: initial;
                            z-index: 2;
                        }
                        input[type="radio"] + label {
                            background: url('../images/radio_unchecked_pref.svg') left 1px no-repeat;
                            background-size: 16px 16px;
                            span {
                                font-weight: 400;
                            }
                        }
                        input[type="radio"]:checked + label {
                            background:url('../images/radio_checked_pref.svg') left 1px no-repeat;
                            background-size: 16px 16px;
                        }
                        .radio-row {
                            label {
                                padding-left: 29px;
                                color: $black;
                                font-size: 11px;
                                line-height: 18px;
                                margin: 0;
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                        .check-row {
                            #FIInterests {
                                display: flex;
                                flex-wrap: wrap;
                            }
                            .check-btn {
                                opacity: 0;
                            }
                            .option-item {
                                display: inline-block;
                                margin: 0 4px 8px 0;
                            }
                            label {
                                display: inline-block;
                                margin: 0;
                                position: relative;
                                padding: 0;
                            }
                            input.check-btn + span {
                                padding: 4px 8px;
                                color: $gray-dark;
                                border: solid 1px $gray-light;
                                border-radius: 2px;
                                background-color: $white;
                                margin: 0;
                                max-width: 72px;
                                line-height: 1;
                                min-height: 34px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-size: 11px;
                            }
                            input.check-btn:checked + span {
                                color: $white;
                                border-color: #a2c3b8;
                                background-color: $green-light;
                            }
                        }
                        .form-section {
                            &.style-options {
                                display: flex;
                                justify-content: flex-start;
                                margin-bottom: 13px;
                                .radio-row {
                                    width: initial;
                                    padding-bottom: 0;
                                    position: relative;
                                    margin-right: 32px;
                                }
                            }
                            &.birthday-options {
                                label {
                                    font-size: 16px;
                                    width: 100%;
                                    font-weight: 700;
                                    color: #000;
                                    text-transform: none;
                                    letter-spacing: normal;
                                    margin-bottom: 11px;
                                    margin-top: 15px;
                                    padding: 0;
                                }
                                .birthday-fields {
                                    display: flex;
                                    .form-row {
                                        margin-right: 10px;
                                        max-width: 68px;
                                        margin-top: 0;
                                        margin-bottom: 0;
                                        .field-wrapper {
                                            margin-bottom: 0;
                                        }
                                        label {
                                            display: none;
                                        }
                                        select {
                                            padding: 0 28px 0 8px;
                                            background-position: right 7px center;
                                            background-color: white;
                                            background-size: 15px 9px;
                                            font-size: 12px;
                                            height: 32px;
                                            background-image: url("../images/interface/icon-select-arrow-black.svg");
                                        }
                                    }
                                }
                            }
                            &.category-options {
                                margin-bottom: 16px;
                                h4 {
                                    margin: 16px 0 10px;
                                }
                            }
                        }
                    }
                }
			}
			.user-panel-wrapper {
				max-height: calc(100vh - 86px);
				overflow: auto;
			}
		}

		div.content-asset {
			max-height: 28px;
			@include respond('small') {
				max-height: initial;
			}
		}
    }
}

@media only screen and (max-width: $standard-breakpoint) {
	.menu-utility-user {
		.user-info {
			a.user-account {
				span {
					display: none;
				}
			}
		}
	}
}

@media only screen and (max-width: $small-breakpoint) {
	.menu-utility-user {
		width:40px;
	}
	#mini-cart {
		width:40px;
		.mini-cart-total {
			width:40px;
		}
	}
}

@include respond(large) {
	#mini-cart {
		width:40px !important;
		.mini-cart-total {
			width:40px !important;
		}
	}
}
