@import "nav";
@import "menu_utility";
//@import "country_selector";


// Header Banner - Promo and Help Menu

.search-open {
	.logo-container {
		@include respond(largeUp) {
			padding-right: 0;
		}
	}

	.menu-category--footjoy.level-1 {
		> li > a {
			padding: 5px 14px 0 14px;
		}
	}

	@include respond(largeUp) {
		#mobileSearchShowBtn {
			display: none;
		}
	}
}

.header {
	@include respond(small) {
		position: relative;
		z-index: 53;
	}
}

.header-banner {
	align-items: center;
	background-color: $black;
	color: $white;
	display: flex;
	justify-content: space-between;
	line-height: 30px;
	height: 30px;
	overflow: hidden;
	z-index: 52;
	position: fixed;
	width: 100vw;
	top: 74px;
	box-sizing:border-box;
	font-family: $font-neuzeit;

	@include respond(largeUp) {
		line-height: 30px;
		height: 30px;
		top:0;
	}

	// Header Promo
	.header-slider,
	.html-slot-container {
		height: 100%;
		text-align: left;

		.header-promotion {
			font-size: 14px;
			line-height: 30px;
			position: absolute;
			top: 100%;
			font-family: $font-neuzeit;
			width: 100%;
			font-weight: bold !important;
			text-align: center;
			color: $white !important;

			@include respond(largeUp) {
				line-height: 30px;
			}

			a {
				color: $white;
			}
		}
	}

	.html-slot-container {
		.header-promotion {
			top: 0;
		}
	}
}

#wrapper .header-banner {
	@include respond(small) {
		width: 100% !important;
		padding-left: 0 !important;
	}
}

#wrapper .mobile-access {
	display: none;
	@include respond(small) {
		z-index: 97 !important;
		width: 100% !important;
		height: 23px !important;
		text-align: center;
		line-height: 23px;
		position: fixed;
		background: $gray-pale;
		top: 0;
		display: block;
		padding-right: 0;
	}
}

.top-banner {
	border-bottom: 2px solid #e6e6e6;
	position: relative;

	.primary-logo {
		padding: 0;
		margin: 18px auto;
		z-index: 1;
		float: left;
	}
}

// Header Main - Logo, Search, My Account, Mini-cart

.header-main {
	box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
	position: fixed;
	z-index: 51;
	width: 100%;
	background-color: white;
	top: 30px;
	height: 75px;

	@include respond(small){
		text-align: left;
		height: 50px;
		top: 23px;
	}

	&__inner {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		background-color: #fff;
		position:relative;
		box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
		@include respond(small){
			height:50px;
			z-index:2;
		}
	}

	.home & {
		//hide the border on the homepage
		border: 0;
	}
	* {
		box-sizing: border-box;
	}
}


.logo-container {
	display: flex;
	padding-right: 84px;

	@include respond(largeUp){
		padding-left: 76px;
		padding-right: 97px;
	}

	@media only screen and (min-width: 880px) and (max-width: 1260px) {
		padding-left: 20px;
		padding-right: 0;
	}
}

.primary-logo {

	a {
		@include respond(small){
			display: block;
			padding: 10px;
			background-image: none;
			background-repeat: no-repeat;
		}

		@include respond(large){ //tablet view
			padding: 0;
			background-image: none;
		}

		img {
			display: block;
			width: 46px;
			height: 46px;
			@include respond(small){
				height:36px;
				width: 36px;
			}
		}
	}
}

// Search/Account/Cart Container

.search-account-cart-container {
	display: flex;
	height: 100%;
	padding-right: 12px;
	@include respond(largeUp) {
		padding-right: 58px;
	}

	@media only screen and (min-width: 880px) and (max-width: 1260px) {
		padding-right: 34px;
	}
}

#mobileSearchShowBtn {
	background-color: $white;
	background-image: url('../images/icons/icon-search.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	height: 50px;
	width: 40px;
	@include respond(largeUp) {
		height: 75px;

	}
}

.header-search {
	color: $dusty-grey;
	background-color: $gray-light-bk;
	display: none;
	padding: 0;
	transition: width .5s;
	position: absolute;
	top: 50px;
	left: 0;

	@include respond(largeUp) {
		position: relative;
		top: 0;
		left: 0;
		margin: 15px 0;
	}

	fieldset {
		display: block;
	}

	input[type="text"] {
		background-color: $gray-light-bk;
		border: none;
		border-radius: 0;
		color: $gray-warm2;
		font-family: $font-neuzeit;
		letter-spacing: normal;
		font-size: 20px;
		height: 100%;
		line-height: 1;
		opacity: 1;
		padding: 8px 13px 7px;
		min-height: 40px;
		font-weight: 400;


		@include respond(largeUp) {
			padding: 12px 50px 10px 20px;
		}

		.open {
			opacity: 1;
		}

		&::-ms-clear {
		    display: none;
		}
		&::placeholder {
			color: $dusty-grey;
			font-family: $font-neuzeit;
			text-transform: capitalize;
		}
	}

	// Search Button
	button[type="submit"] {
		background-image: url('../images/icons/icon-search.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		border: none;
		height: 40px;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 50%;
		right: 145px;
		z-index: 8;
		transform: translateY(-50%);
		width: 39px;
		transition: none;
		@include respond(small){
			display: none;
		}

		@include respond(largeUp) {
			right: 1px;
		}


		&:hover {
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: 50%;
			background-image: url('../images/icons/icon-search.svg');
		}
	}

	span#closeSearch{
		display: none;
		width: 16px;
		height: 16px;
		position: relative;
		float: right;
		right: 17px;
		margin: -28px 0 0 0;
		cursor: pointer;

		@include respond(small){
			display: block;
		}
		&:before,&:after{
			content:'';
			position:absolute;
			width:18px;
			height:2px;
			background-color: $black;
			border-radius:2px;
			top: 7px;
		}

		&:before{
			-webkit-transform:rotate(45deg);
			-moz-transform:rotate(45deg);
			transform:rotate(45deg);
			left:-2px;
		}
		&:after{
			-webkit-transform:rotate(-45deg);
			-moz-transform:rotate(-45deg);
			transform:rotate(-45deg);
			right:0px;
		}
	}
}

//--------------------------------------------------------------
// Sitewide Header and Nav Styles
//--------------------------------------------------------------


body.mobile-menu-active {
	overflow: hidden;
}

.header-spacer {
	height: $nav-height-mobile;

	@include breakpoint( $small-breakpoint ) {
		display: none;
	}
}

.desktop-menu-container{
	display: flex;
	flex-direction: row;
}

.desktop-menu-promos--margin{

	@include breakpoint( $small-breakpoint ) {
		margin-left: 455px !important;
	}

	@include breakpoint( $standard-breakpoint ) {
		margin-left: 440px !important;
	}
}

.overflow {
	overflow: hidden;
}

#needle_headerNT a {
	display:none!important; /* live chat appears and breaks "myaccount" button. */
}

.skip-nav {
    position: absolute;
    left: -10000px;
}

#wrapper #main {
	margin-top: rem(106px) !important;
	@include respond(small) {
		margin-top: rem(120px) !important;
	}
}
#wrapper.home #main {
	margin-top: rem(106px) !important;
	@include respond(small) {
		margin-top: rem(104px) !important;
	}
}


#wrapper.pt_product-search-result #main {
	margin-top: rem(140px) !important;
	@include respond(small) {
		margin-top: rem(126px) !important;
	}
}

.header-banner-checkout {
	position: relative;
	top: 0;
	padding-right: 5px;
	@include respond(largeUp) {
		padding-right: 0;
	}

	.header-banner-checkout-inner {
		width: 100%;
		margin: 0 auto;
		vertical-align: top;
		max-width: $standard-breakpoint;

		a {
			font-family: $font-neuzeit;
			font-size: 14px;
			color: $white;
			text-transform: uppercase;
		}
	}
}
/*END Header Styles */



