#wrapper {
	&.menu-active {
	    > div {
	        left: auto;
	        @include respond(small) {
	        	&.header-banner {
	        		position: fixed;
	        	}
	        }
	    }
		& > .mobile-access {
			@include respond(small) {
	        	position: fixed;
				right: 0;
				left: auto;
	        }
		}

	    #navigation {
	        left: 0;
	    }
	    .header-main {
	    	position:fixed;
	    }
	}
	&.home {
		#main {
			@include respond(small) {
				margin-top: 74px;
			}
		    margin-top: 86px;
		}
	}
	#main {
		#parcellab-track-and-trace {
			padding: 30px;
			font-family: neuzeit-grotesk,sans-serif,serif !important;
			text-transform: initial;
			h1, h2, h3, h4, h5, .ui-dialog .ui-dialog-title  {
				letter-spacing: .05em;
			}
			h2, .ui-dialog .ui-dialog-title {
				text-transform: initial;
			}
			div.pl-tab {
				border: 1px solid #000;
			}
			div.pl-tab.pl-active {
				background-color: #000 !important;
				color: #fff !important;
			}
			#pl-reroute-link {
				display: none;
			}
		}
	}
	#parcellab-track-and-trace-ui-wrapper {
		font-family: neuzeit-grotesk,sans-serif,serif !important;
	}
	&.pt_checkout {
		margin-top:0;
		#main {
			margin-top: 2rem;
		}
	}
	#main {
		@include respond(small) {
			margin-top: 90px;
		}
		margin-top: 74px;

		&.page-content {
			@include respond(small) {
				margin-top: 74px;
			}
		}
	}
	&.pt_customer-service.pt_email-sub {
		.newsletter-header {
			@include respond(small) {
				margin-top: 90px;
			}
			margin-top: 7rem;
		}
	}
}

.primary-content {
	@include respond(largeUp) {
		width: calc(100% - 240px);
	}
	.hide-refinements & {
		width: 100%;
	}
}

#main {
	#pl-on-rendered-wrapper {
		h1, h2, h3, h4, h5 {
			font-family: neuzeit-grotesk,sans-serif,serif !important;
		}
	}
}


.desktop {
	display: block;
	@include respond(small) {
		display: none;
	}
}

.mobile {
	display: none;
	@include respond(small) {
		display: block;
	}
}
