.pt_product-search-result {
    .product-tile {
        &:hover {
            .pr-clp-raing,
            .product-swatches,
            .product-category {
                @include respond(largeUp) {
                    display: block;
                    position: absolute;
                }
            }
            @include respond(largeUp) {
                .colors-available,
                .product-promo,
                .plp-text-badge {
                    display: none;
                }
            }
        }

        .pr-clp-raing {
            display: none;
        }
        .product-swatches {
            @include respond(largeUp) {
                display: none;
            }
        }
        .product-image {
            .product-badge {
                display: block;
                position: absolute;
                top: 8px;
                left: 0;
                padding: 10px 15px;
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
                color: $black;
                letter-spacing: normal;
                background-color: $white;
                user-select: none;
                @include respond(largeUp) {
                    top: 0;
                    left: 10px;
                    padding: 15px 10px;
                    transform: rotate(180deg);
                    direction: rtl;
                    -ms-writing-mode: bt-rl;
                    writing-mode: vertical-rl;
                }
            }
            .thumb-link {
                display: block;
                background-color: $gray-lighter;
                font-size: 0;
            }
        }

        .product-tile-details {
            position: relative;
            margin-top: 8px;
            padding-left: 8px;
            text-align: left;
            @include respond(largeUp) {
                padding-left: 0;
                min-height: 150px;
            }
        }

        .product-name {
            margin-bottom: 4px;
            @include respond(largeUp) {
                display: table;
                table-layout: fixed;
                width: 73%;
            }
            @media only screen and (min-width: 1200px) {
                width: 77%;
            }
            @media only screen and (min-width: 1440px) {
                width: 81%;
            }
            @media only screen and (min-width: 1600px) {
                width: 84%;
            }
            a {
                display: block;
                font-size: 14px;
                letter-spacing: 0;
                font-size: 16px;
                @include respond(largeUp) {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    height: 20px !important;
                }
            }
        }
        .product-category {
            @include respond(largeUp) {
                padding-right: 40px;
            }
        }
        .product-category,
        .members-only-login,
        .colors-available {
            margin-bottom: 5px;
            font-size: 14px;
            color: $gray-warm2;
        }

        .colors-available {
            display: none;
            @include respond(largeUp) {
                display: block;
            }
            &.myjoy-colors {
                @include respond(small) {
                    display: block;
                }
            }
        }
        .lnk-morecolors {
            color: $gray-warm2;
        }
        .product-pricing {
            display: flex;
            font-family: $neuzeit;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0;
            color: $black;
            @include respond(small) {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
            @include respond(largeUp) {
                flex-direction: column-reverse;
                position: absolute;
                top: 0;
                right: 12px;
            }
            span {
                @include respond(small) {
                    margin-right: 8px;
                }
                @include respond(largeUp) {
                    display: block;
                }
            }
            .product-standard-price {
                text-decoration: line-through;
            }
            .product-sales-price {
                color: $black;
            }
        }
        .pr-clp-raing,
        .members-only-login {
            @include respond(largeUp) {
                position: absolute;
                top: 23px;
                background-color: $white;
                width: 100%;
            }
        }
        .pr-clp-raing {
            z-index: 49;
            width: 72%;
            .pr-category-snippet {
                margin: 0;
            }
        }

        .product-swatches {
            ul {
                text-align: left;
                li {
                    display: block;
                    float: left;
                    margin: 0 2px 0 0;
                    padding: 0;
                    width: 20px;
                    &.open-close {
                        margin-left: 3px;
                        line-height: 20px;
                        width: auto;
                        @include respond(largeUp) {
                            margin-left: 8px;
                            line-height: 36px;
                        }
                        @include respond(large) {
                            margin-left: 3px;
                            line-height: 20px;
                        }
                        @include respond(extraLarge) {
                            margin-left: 3px;
                            line-height: 30px;
                        }
                    }
                    @include respond(largeUp) {
                        margin: 0 5px 0 0;
                        width: 36px;
                    }
                    @include respond(large) {
                        margin: 0 2px 0 0;
                        width: calc((100% / 7) - 1px);
                    }
                    @include respond(extraLarge) {
                        margin: 0 2px 0 0;
                        width: calc((100% / 7) - 1px);
                    }
                }
            }
            .swatch {
                display: block;
                width: 100%;
                height: auto;
                text-align: left;
                background-color: $gray-lighter;
                border-radius: 0;
                border-width: 1px;
                &.selected {
                    border-width: 1px;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 0;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 0;
                }
            }
            &.myjoy-colors {
                @include respond(small) {
                    display: none;
                }
            }
        }
    }
}

#featured-carousel {
    .product-tile {
        &:hover {
            .pr-clp-raing,
            .product-swatches,
            .product-category {
                @include respond(largeUp) {
                    display: block;
                }
            }
            @include respond(largeUp) {
                .colors-available {
                    display: none;
                }
            }
        }
        .product-swatches {
            @include respond(largeUp) {
                display: none;
            }
            ul {
                text-align: center;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                margin: 4px 0 5px;
                li {
                    display: block;
                    float: left;
                    margin: 0 2px 0 0;
                    padding: 0;
                    width: 20px;
                    &.open-close {
                        margin-left: 3px;
                        line-height: 20px;
                        width: auto;
                        @include respond(largeUp) {
                            margin-left: 8px;
                            line-height: 36px;
                        }
                        @include respond(large) {
                            margin-left: 3px;
                            line-height: 20px;
                        }
                        @include respond(extraLarge) {
                            margin-left: 3px;
                            line-height: 30px;
                        }
                    }
                    @include respond(largeUp) {
                        margin: 0 5px 0 0;
                        width: 36px;
                    }
                    @include respond(large) {
                        margin: 0 2px 0 0;
                        width: calc((100% / 7) - 1px);
                    }
                    @include respond(extraLarge) {
                        margin: 0 2px 0 0;
                        width: calc((100% / 7) - 1px);
                    }
                }
            }
            .swatch {
                display: block;
                width: 100%;
                height: auto;
                text-align: left;
                background-color: $gray-lighter;
                border-radius: 0;
                border-width: 1px;
                &.selected {
                    border-width: 1px;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 0;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 0;
                }
            }
        }
        .colors-available {
            display: none;
            @include respond(largeUp) {
                display: block;
                margin-top: 27px;
            }
        }
    }
}

.product-tile {
    @extend %clearfix;
    color: $gray-warm;
    position: relative;
    text-align: center;
    font-family: $titillium;
    @include font-size(16px);
    padding: 0;
    box-sizing: border-box;

    @include respond(largeUp) {
        &.qv-visible {
            #quickviewbutton,
            button.quickview {
                display: flex;
            }

            .product-tile-details {
                top: 40px;
            }

            .pr-clp-raing {
                top: 25px;
            }
        }
    }

    img {
        max-width: 100%;
        &.product-badge-plp {
            position: absolute;
            width: 30%;
            right: 0;
            z-index: 1;

            &[src*="trending"],
            &[src*="thermoseries"] {
                @include respond(small) {
                    width: 60%;
                }
            }

            &[src*="members-only"] {
                height: 21px;
                width: 16px;
                right: 15px;
                top: 11px;
                @include respond(largeUp) {
                    right: 21px;
                    top: 20px;
                }
            }
        }
    }

    .members-only-login {
        display: none;
    }

    .plp-text-badge {
        @include respond("small") {
            display: none;
        }
        .plp-badge-value {
            margin-top: 0;
            color: $blue-light;
            font-weight: 700;
            line-height: 1;
            font-size: 14px;
            @include respond(largeUp) {
                margin-top: 7px;
                display: inline-block;
            }
        }
        &.master-badge {
            @include respond("small") {
                display: inline;
            }
        }
    }

    &.members-only:not(.unlocked) {
        .product-image img {
            @include respond(largeUp) {
                opacity: 0.5;
            }
        }
        .members-only-login {
            color: $blue-light;
            display: block;
            font-weight: 300;
            margin: 9px 0 4px;
            @include respond(largeUp) {
                margin: 0;
                z-index: 1;
            }
            a {
                color: $blue-light;
                font-weight: 500;
                text-decoration: underline;
            }
        }
        .product-category,
        .pr-clp-raing {
            display: none;
        }
        &:hover {
            .product-image img {
                opacity: 1;
            }
            .product-swatches {
                @include respond(largeUp) {
                    display: block;
                }
            }
            .product-category,
            .pr-clp-raing,
            .colors-available {
                @include respond(largeUp) {
                    display: none;
                }
            }
        }
    }
    @include respond("small") {
        @include font-size(14px);
    }
    .product-image {
        height: auto;
        position: relative;
        min-height: 200px;
        @include respond("large") {
            min-height: 145px;
        }
        @include respond("small") {
            min-height: 132px;
        }

        .customize-link {
            display: inline-block;
            position: absolute;
            top: 18px;
            right: 25px;
            height: 34px;
            width: 83px;
            @include respond("large") {
                right: 10px;
                width: 50%;
            }
            @include respond("small") {
                height: auto;
                width: 50%;
                right: 10px;
            }
        }
        .lazyload {
            opacity: 0;
            transform: scale(0.8);
        }
        .lazyloaded {
            opacity: 1;
            transform: scale(1);
            transition: all 700ms;
        }
    }
    .product-name {
        color: $black;
        font-weight: bold;
        line-height: 1.25rem;
        margin-bottom: 4px;
        a {
            color: $black;
            font-size: 19.2px;
            letter-spacing: 1.5px;
        }
    }
    .product-category {
        @include font-small;
        font-size: 12.8px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .product-pricing {
        @include font-small;
        font-weight: bold;
        font-size: 16px;
        margin: 0 0 6px 0;
        line-height: 22px;
        @include respond("small") {
            @include font-size(12px);
        }
        @include respond(largeUp) {
            margin: 0 0 10px 0;
        }
        .product-standard-price {
            text-decoration: line-through;
        }
        .product-sales-price {
            color: $red;
        }
        &.default-master-badge {
            margin: 0 0 4px 0;
            @include respond(largeUp) {
                margin: 0 0 10px 0;
            }
        }
    }
    .product-swatches-all {
        cursor: pointer;
        @include font-size(10px);
    }
    .product-swatches {
        ul {
            display: block;
            list-style: none outside none;
            margin: 0;
            overflow: hidden;
            padding: 0;
            text-align: center;

            @include respond(largeUp) {
                margin-top: 33px;
            }
        }
        li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 4px;
            &.hide {
                display: none;

                &.show-in-desktop {
                    @include respond(standard) {
                        display: inline-block;
                    }
                }

                &.show-in-mobile {
                    @include respond(small) {
                        display: list-item;
                    }
                }
            }
            @include respond("large") {
                margin: 0 3px 4px;
                &.hide-mobile {
                    display: none;
                }
                &:last-child {
                    &.hide-mobile {
                        display: block;
                    }
                }
            }
            @include respond("small") {
                margin: 0 2px 4px;
                &.hide-mobile {
                    display: none;
                }
                &:last-child {
                    &.hide-mobile {
                        display: block;
                    }
                }
            }
        }
        .open-close {
            a {
                font-size: 10px;
                color: $gray-dark;
                line-height: 18px;
                text-transform: uppercase;
            }
            span.closetxt {
                display: none;
            }
        }
        &.expanded {
            position: absolute;
            z-index: 1;
            background-color: $white;
            box-shadow: 0 10px 10px 0 $white;
            li.hide,
            li.hide-mobile {
                display: inline-block;
            }
            span.moretxt {
                display: none;
            }
            span.closetxt {
                display: inline-block;
            }
        }
        .swatch {
            display: table-cell;
            vertical-align: middle;
            box-sizing: border-box;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            border: 2px solid transparent;
            position: relative;
            @include respond(large) {
                height: 32px;
                width: 32px;
            }
            &.members-only.locked {
                @include respond(small) {
                    img {
                        opacity: 0.5;
                    }
                    &::after {
                        background: url("../images/icons/swatch-locked.svg")
                            no-repeat center;
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                    }
                }
            }
            img {
                border-radius: 50%;
                box-sizing: border-box;
                height: 30px;
                width: 30px;
                margin: 0 auto;
                vertical-align: middle;
                @include respond(large) {
                    height: 28px;
                    width: 28px;
                }
            }
            &.selected {
                border: 2px solid #000;

                img {
                    height: 30px;
                    width: 30px;
                    @include respond(large) {
                        height: 28px;
                        width: 28px;
                    }
                }
            }
        }
        .selected img,
        img:hover {
            border-color: $black;
        }
    }
    .product-promo {
        color: $red;
        @include font-size(12px);
        margin: 0;
        @include respond(largeUp) {
            @include font-size(14px);
            margin-top: 5px;
        }
    }
    .product-compare {
        float: left;
        line-height: 11px;
        label {
            font-weight: 400;
            line-height: 1.25em;
            width: auto;
        }
        input {
            float: left;
            margin: 0;
        }
    }
    .product-review {
        margin-bottom: 1em;
        .rating-title {
            display: inline-block;
            margin-right: 1em;
        }
        .rating {
            display: inline-block;
        }
    }
    //PowerReview styles
    #reviewstarratings,
    .reviewstarratings {
        .prSnippetNumberOfRatingsText {
            @include font-size(12px);
            position: relative;
            top: -3px;
        }
    }
}

.capture-product-id {
    left: -999em;
    position: absolute;
}

//just in case, allow swatch dropdowns within a slick carousel break the height, so they are visible.
li.slick-slide .product-tile {
    .product-swatches.expanded {
        position: static;
    }
}
