 footer {
 	a {
		&.mobile-footer-policy {
			display: inline-block;
			padding-top: 25px;
			@include respond(largeUp){
				display: none;
			}
		}
	}
	.footer-user-link {
		@include respond(standard){
			min-width:78px;
			margin-left:50px;
		}
		+ .footer-user-link {
			margin-left: 40px;
			min-width: 200px;
		}
	}
 }