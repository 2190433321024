.pt_product-search-result .primary-content{
	.did-you-mean{
		display: none;
	}
	h1.content-header {
		padding-left: 1.25rem;
	}
}
/*
* Primary search refinement styles are in secondary_region_elements.scss
*/
.pt_product-search-result {
	&#wrapper{
		#main{
			@include respond('large') {
				padding: 0 15px;
				box-sizing: border-box;
			}
			#primary{
				.search-result-options{
					float: left;
					width: auto;
					&.bottom-pagination{
						float: right;
					}
					@include respond('small') {
						width: 100%;
						padding: 0 15px;
						box-sizing: border-box;

						.sort-by{
							float: right;
							width: 50%;
						}
					}
					@include respond('large') {
						margin-left: 172px;
					}
				}
				.pagination{
					float: right;
					line-height: 17px;
					margin: 10px 0;
					@include respond('small') {
						float: left;
						width: 100%;
						padding: 0 15px;
						box-sizing: border-box;

					}
					.first-last a{
						width: 14px;
						height: 17px;
					}
				}
			}
		}
	}
}
.pt_product-search-result .slot-grid-header {
	margin-bottom: 0.8333rem;
	@include respond('small') {
		margin: 0 1.25rem 0.8333rem;
	}
}
.category-main-banner img {
	max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
	color: $red;
	font-style: italic;
}

.no-hits-footer {
	margin: 1.667rem 0;
	padding: 1.667rem 0;
}

.search-result-bookmarks {
	margin: 1.25rem;
	.first {
		border-right: 0.08333rem solid $gray-warm;
		padding-right: 0.4167rem;
	}
}

.search-result-options {
	margin: 0 0 0.6667rem 0;
	overflow: hidden;
	width: 100%;
	&.bottom-pagination {
		margin-bottom: 5rem;
		@include respond('small') {
			margin: 0;
		}
		@include respond('large') {
			margin: 0;
		}
	}
	#grid-sort-header{
		height: 36px;
	}
	@include respond('small') {
		box-sizing: border-box;
		margin: 0;
		padding: 0 1.25rem;
	}
}

.sort-by,
.items-per-page {
	float: left;
	margin: 0 0.8333rem 0 0;
	padding: 0;
	label {
		display: none;
	}
	select {
		text-transform: uppercase !important;
		height: 36px;
		line-height: 32px;
	}
}
.sort-by{
	.sort-Go-Button{
		display: none;
	}
	@include respond('small') {
		float: right;
		margin: 0;
		height: 2.917rem;
		min-width: 11.67rem;
		max-width: 50%;
	}
}
.items-per-page {
	@include respond('small') {
		display: none;
	}
}

.pagination {
	color: $gray-warm;
	display: block;
	@include font-size(11px);
	float: right;
	line-height: 3em;
	@include respond('not-standard') {
		width: 100%;
	}
	@include respond('small') {
		padding: 1rem 0;
	}
	.results-hits {
		color: $gray-warm;
		float: left;
		letter-spacing: normal;
		margin-right: 1.25rem;
		@include respond('small') {
			margin: 0;
			text-align:center;
			width: calc(100% - 30px);
		}
	}
	ul {
		float: right;
		margin: 0;
		padding: 0;
		@include respond('small') {
			float: none;
		}
	}
	li {
		display: block;
		float: left;
		list-style: none;
		padding: 0 0.7em;
		a {
			color: $gray-warm;
			@include font-size(11px);
		}
		&.current-page {
			font-weight: 700;
			@include font-size(11px);
			color: $gray-dark;
		}
	}
	.first-last {
		padding: 0;
		a {
			display: inline-block;
			margin-top: -1px;
			width: 1.25rem;
			height: 1.417rem;
			vertical-align: middle;
			&.page-first {
				background: url('../images/interface/arrow-double.png') no-repeat;
				-webkit-transform: rotate(180deg); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
				-ms-transform: rotate(180deg); /* IE 9 */
				transform: rotate(180deg);
			}
			&.page-previous {
				background: url('../images/interface/arrow-single.png') no-repeat;
				-webkit-transform: rotate(180deg); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
				-ms-transform: rotate(180deg); /* IE 9 */
				transform: rotate(180deg);
			}
			&.page-last {
				background: url('../images/interface/arrow-double.png') no-repeat;
			}
			&.page-next {
				background: url('../images/interface/arrow-single.png') no-repeat;
			}
		}
	}
	.mobile-pagination {
		display:none;
		@include respond('small') {
			display: block;
		}
	}
	@include respond('small') {
		.desktop-pagination {
			display:none;
		}
	}
}
.search-result-content {
	clear: both;
	border-bottom: 2px solid $gray-light;
	margin-bottom: 1em;
	//overflow-x: hidden;
	max-width: 970px;
/* 	@include respond('large') {
		max-width: 538px;
	} */
}

.search-result-items {
	border-top: 2px solid $gray-light;
	margin: 0;
	padding: 0;
	position: relative;
	left: 0px;
	width: 970px;
	@include respond('large') {
		left: 0;
		width: 100%;
	}
	li {
		list-style: none outside none;
	}
	.grid-tile {
		list-style: none outside none;
		margin: 0 0 10px 0;
		position: relative;
		box-sizing: border-box;

		.product-image{
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		@include respond('large') {
			padding: 0 0 0 1.167rem;
		}
		@include respond('small') {
            flex: 0 0 calc(50% - 8px);
			padding: 0;
			&:nth-child(odd) {
				margin-right: 16px;
			}
		}
		&.banner-grid{
			overflow: hidden;
			.curated-box{

				height: 100%;
			}
			&.horizontal{
				width: 50%;
				@include respond('large') {
					width: 50%;
				}
				@include respond('small') {
					width: 50%;
					display: none;
				}
				img {
					max-width: 99%;
				}
			}
			&.vertical{
				width: 25%;
				position: relative;
				overflow: visible;
				@include respond('large') {
					width: 25%;
				}
				@include respond('small') {
					width: 50%;
				}
				.curated-box{
					position: absolute;
					left: 3.167rem;
					top: 0;
					overflow: hidden;
					z-index: 100;
					margin-top: 2.5rem;
					img {
						max-width: 100%;
						box-sizing: border-box;
					}
					@include respond('small') {
						left: 0.8333rem;
					}
					@include respond('large') {
						left: 1.167rem;
					}

				}
			}
		}
		&.banner-grid-hide{
			display: none;
			&.three{
				display: block;
				@include respond('large') {
					display: block;
				}
				@include respond('small') {
					display: none;
				}
			}
			&.two{
				@include respond('large') {
					display: none;
				}
				@include respond('small') {
					display: none;
				}
			}
		}
	}
	.invisible {
		display: none;
	}
	//Curated Grid Promo Slot Custom Styles
	.standard-curated {
		max-width: 25%;
		overflow:hidden;
		@include respond('not-standard') {
			display: none;
		}
	}
	.wide-curated {
		width: 50%;
		max-width: 50%;
		overflow: hidden;
		@include respond('not-standard') {
			display: none;
		}
	}
	.tall-curated {
		max-width: 25%;
		overflow: hidden;
		~ .tile-8.positioned {
			position: absolute;
			top: 676px;
			left: 0;
		}
		@include respond('not-standard') {
			display: none;
			~ .tile-8.positioned{
				position: initial;
				top: initial;
				left: initial;
			}
		}
	}

	&.hide-curated-in-tablet-mobile {
		// hide all below 1200
		@include respond(not-standard) {
			.banner-grid, .banner-grid-hide {
				display: none;

				&.three, &.two {
					display: none;
				}
			}
		}
	}
}

.search-promo {
	padding: 0;
	@include respond('large') {
    	clear: both;
		text-align: center;
	}
}

#results-content {
	padding-top: 2.5rem;
}

.folder-content-list {
	margin: 0;
	overflow: hidden;
	padding: 0 1.667rem;
	li {
		float: left;
		list-style: none outside none;
		margin: 0;
		padding: 2% 2% 2% 0;
		width: 48%;
	}
	.content-title {
		display: block;
	}
}

.no-results {
	padding: 1.667rem;
}

.toggle-grid {
	display: block;
	float: left;
	margin-right: 0.5em;
	i {
		cursor: pointer;
		margin-right: 0.3em;
	}
	[data-option="column"] {
		color: $red;
	}
	&.wide {
		[data-option="wide"] {
			color: $red;
		}
		[data-option="column"] {
			color: $gray-dark;
		}
	}
}

.wide-tiles {
	.search-result-items .grid-tile {
		width: 91%;
	}
	.product-tile {
		.product-swatches {
			bottom: auto;
			left: auto;
			position: relative;
			right: auto;
			margin-top: 0.4167rem;
			li {
				float: left;
			}
		}
	}
}
@include respond('small') {
	.pt_product-search-result{
		#main{
			.slot-grid-header{
				margin-top: 10px;
			}
		}
		#primary{
			.search-result-options{
				float: right;
				padding: 0 1.25rem 0 0;
   				width: 50%;
				.sort-by{
					width: 100%;
					min-width: auto;
					max-width: none;
					#grid-sort-header{
						width: 140px;
					}
				}
			}
		}
	}
}
#wrapper.pt_myjoys{
	#main{
		#primary{
			min-height: 500px;
		}
	}
}
