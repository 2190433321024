.edit-product-modal {
    opacity: 0;

    @include respond(small) {
        padding: 0 !important;
        top: 0 !important;
        width: 100% !important;
        bottom: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: none;
    }

    .ui-dialog-titlebar {
        height: 57px;
        display: flex;
        align-items: center;
        border: 1px solid #D0D0D0;

        @include respond(largeUp) {
            height: 0;
            margin: 0;
            padding: 0;
        }

        &.sticky {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            z-index: 98;
        }

        .ui-dialog-title {
            &::before {
                background: url("../images/icons/quick_shop.png") no-repeat center center;
                background-size: 100%;
                content: "";
                display: block;
                height: 42px;
                width: 49px;
            }

            align-items: center;
            display: flex;
            font-family: "neuzeit-grotesk";
            @include font-size(23px);
            font-style: normal;
            font-weight: 400;
            gap: 10px;
            justify-content: center;
            letter-spacing: normal;
            line-height: normal;
            margin: auto !important;
            text-align: center;
            text-transform: capitalize;

            @include respond(largeUp) {
                margin: 0 !important;
                height: 0;
            }
        }

        button.ui-button {
            display: block;
            font-family: neuzeit-grotesk, sans-serif;
            margin: 0;
            opacity: 1;
            right: 15px !important;
            top: 20px;
            z-index: 98;

            @include respond(small) {
                top: 50%;
                transform: translateY(-50%);
            }

            span {
                padding: 10px;
                height: 20px;
                width: 20px;
            }
        }
    }

    .pdp-main {
        .product-add-to-cart {
            .cart-buttons-container {
                @include respond(small) {
                    flex-wrap: nowrap;
                }
            }

            button.dw-apple-pay-button  {
                width: 100% !important;
                margin: 0 !important;

                @include respond(small) {
                    padding: 0 10px!important;
                }
            }

            #add-to-cart {
                @include respond(small) {
                    padding: 0 10px;
                }
            }

            .add-to-cart {
                width: 100%;
            }

            .low-inventory-msg {
                @include respond(small) {
                    position: absolute;
                }
            }
        }
    }
}

.ui-widget-overlay {
    background: rgba(118, 118, 118, 0.44);
}

#QuickViewDialog {
    padding: 0 !important;
    box-sizing: border-box;
    float: none;
    min-width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 97;

    #pdpMain {
        top: 0;
        margin: 0;
        width: 100%;

        @include respond(small) {
            margin: 69px 0 0;
        }

        &.customizable-product-pdp {
            .product-variations .attribute .label,
            .custom-select-field .select-header,
            .product-add-to-cart .inventory-container .inventory label {
                color: $grey-label;
                font-family: $neuzeit;
                font-size: rem(14px);
                padding: 0;
            }
        }

        .product-add-to-cart {
            @include respond(largeUp) {
                padding: 0 40px;
            }

            .low-inventory-msg {
                @include font-size(12px);
    
                @include respond(standard) {
                    @include font-size(14px);
                }
            }
        }

        .product-name {
            @include respond(small) {
                @include font-size(24px);
                margin: 10px 0;
                max-width: 59%;
            }
        }

        .product-main-info {
            margin-bottom: 0;

            @include respond(largeUp) {
                display: flex;
            }
        }

        .product-variations .attribute .value,
        .product-options .attribute .value {
            width: 100%;
        }

        .product-col-1 {
            float: none;
            margin: 0;
            padding: 0;
            width: 60%;

            &.product-image-container {
                position: relative;

                @include respond(small) {
                    order: 1;
                }

                .pdp-badges-container {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    position: absolute;
                    top: 32px;
                    right: 0;
                    z-index: 1;
                    @include respond(small) {
                        top: 30px;
                        width: 100%;
                        flex-direction: row-reverse;
                        align-items: end;
                        justify-content: space-between;
                    }
                    .pdp-badge-image {
                        margin-right: 32px;
                        @include respond(small) {
                            margin-right: 8px;
                            position: absolute;
                            top: -24px;
                        }
    
                        img {
                            width: rem(60px);
                            position: static;
                            &[src*="members-only"] {
                                display: None;
                            }
    
                            &.product-badge--trending,
                            &.product-badge--thermoseries {
                                width: rem(120px);
                            }
                        }
                    }
                    &.pdp-badges-kr {
                        @include respond(small) {
                            top: 0;
                            .pdp-badge-image {
                                top: 0;
                            }
                        }
                    }
                    .pdp-badge-text {
                        @include respond(largeUp) {
                            height: max-content;
                            position: relative;
                            right: 0;
                        }
                    }
                }
    
                .product-badge {
                    font-weight: bold;
                    padding: rem(8px) rem(13px);
                    text-transform: uppercase;
                    @include respond(largeUp) {
                        transform: rotate(180deg);
                        -ms-writing-mode: bt-rl;
                        writing-mode: vertical-rl;
                    }
                    @include respond(small) {
                        left: rem(10px);
                        position: absolute;
                        right: auto;
                        writing-mode: horizontal-tb;
                    }
                }

                .product-badge-pdp {
                    position: absolute;
                    right: 13px;
                    width: 24%;
                    z-index: 1;
                }

                .primary-image {
                    &:hover {
                        @include respond(largeUp) {
                            cursor: default;
                        }
                    }
                }

                .product-primary-image {
                    border-bottom: 1px solid #d1d0d0;

                    @include respond(small) {
                        img {
                            max-width: 650px;
                            width: 90%;
                            padding: 0;
                        }
                    }

                    @include respond(standard) {
                        min-height: 544px;
                    }
                }

                .regular.slider {
                    margin: 0;

                    @include respond(small) {
                        height: auto;
                    }
                }

                .slick-prev,
                .slick-next {
                    background-color: transparent;
                    border: none;
                }
            }

            .product-top-data {
                padding: 14px 34px 20px;
                position: relative;

                @include respond(small) {
                    .space {
                        height: 40px;
                    }
                }
            }
        }

        .product-col-2 {
            border-left: 1px solid #d1d0d0;
            float: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            width: 40%;

            @include respond(small) {
                order: 2;
            }

            .promotion {
                padding: 0;
                width: 100%;
            }

            #product-content {
                min-width: 100%;
                margin-top: 50px;

                @include respond(small) {
                    margin-top: 0;

                    .product-top-data,
                    .view-full-details,
                    .product-variations,
                    .product-add-to-cart {
                        padding: 0 30px;
                        margin: 0;
                        max-width: 100%;
                        min-width: auto;
                    }
                }
            }

            .product-variations {
                padding: 0 40px;

                > ul {
                    float: none;
                    width: auto;
                }

                li.variant-dropdown {
                    margin: 0;
                    padding: 0;
                }

                .hide-variations {
                    .custom-select-field {
                        display: none;
                    }
                }

                .custom-select-field {
                    margin: 10px 0 0;

                    @include respond(small) {
                        .container:nth-child(odd) {
                            margin-right: 0;
                        }

                        .custom-select {
                            width: 100%;
                        }
                    }
                }

                .my-designs-line {
                    &.overflow {
                        overflow: visible;
                    }

                    &.visually-hidden {
                        margin: 0;
                        padding: 0;
                        overflow: hidden;
                    }

                    .selected-value {
                        font-weight: normal;
                        @include font-size(13px);
                        margin-top: 0px;

                        @include respond(small) {
                            max-width: 55%;
                            left: auto;
                        }
                    }
                }

                ul.swatches.must-select {
                    float: none;
                    width: 100%;
                    margin: 0;
                    max-width: 365px;
                    padding: 0;

                    &.slick-slider {
                        &:not(.customizable-swatches) {
                            max-width: 304px;
    
                            @media only screen and (max-width: 425px) {
                                max-width: 242px;
                            }
                        }
    
                        @media only screen and (max-width: 425px) {
                            max-width: 242px;
                        }
                    }

                    .swatchanchor {
                        display: block;
                        margin: 0;
                        padding: 0;
                    }

                    li.selectable {
                        box-sizing: border-box;
                        height: auto;
                        padding: 0;
                        width: 58px !important;

                        a {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    &.slick-slider {
                        .slick-arrow {
                            top: calc(50% + 8px);
                            background: url("../images/carouselBlackArrowLeft.svg")
                                no-repeat center;
                            background-size: 10px 16px;
                            width: 45px;
                            height: 45px;
                            @include respond(largeUp) {
                                border: 1px solid $gray-outline;
                            }
                            &.slick-prev {
                                transform: translateY(-50%);
                                left: -40px;
                            }
                            &.slick-next {
                                transform: translateY(-50%) rotate(180deg);
                                right: -50px;
                            }
                            &.slick-disabled {
                                opacity: 0;
                            }
                        }
                        .slick-prev,
                        .slick-next {
                            background-color: transparent;
                            border: none;
                        }
                    }
                }
            }

            .custom-select-field {
                .select-size li {
                    @include respond(large) {
                        width: 54px;
                    }
                }
            }

            form.pdpForm {
                float: none;

                select {
                    margin-top: 8px;

                    @include respond(small) {
                        margin-top: 0px;
                    }
                }

                #add-to-cart {
                    @include respond(small) {
                        height: 3.625rem;
                        white-space: nowrap;
                    }
                }

                .notify-me-link-wrapper {
                    padding: 0 2rem 0 0;
                }

                .customizer-different-size-button {
                    padding: 10px 40px 0;

                    @include respond(small) {
                        padding: 0 35px;
                    }
                }
            }
        }

        #reviewssnippet {
            @include respond(large) {
                height: auto !important;
            }

            .pr-snippet-write-review-link {
                display: none;
            }

            .pr-snippet {
                display: flex;
                gap: 5px;
                align-items: end;

                .pr-snippet-stars {
                    margin: 0;
                }

                .pr-snippet-review-count {
                    border: none;
                    padding: 0;
                    margin: 0;
                    font-family: neuzeit-grotesk, sans-serif, serif;
                    font-size: 14px !important;
                    font-weight: bold;

                    &::hover {
                        text-decoration: none;
                    }
                }

                .pr-star-v4 {
                    width: 18px;
                    height: 18px;
                    padding: 0;
                }
            }
        }

        .size-chart-lnk {
            right: 0;
        }

        .spacerbar {
            width: 100%;
        }

        .product-price-details-link {
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .product-price {
                float: none;
                margin: 0;
            }

            .view-full-details {
                float: none;
                font-family: neuzeit-grotesk, sans-serif, serif;
                @include font-size(16px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
                text-transform: capitalize;
                width: auto;

                @include respond(small) {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                }
            }
        }

        .product-promo-messaging {
            background: #ececec;
            display: block;
            margin: 23px auto 10px;
            padding: 12px;
            text-align: center;

            @include respond(small) {
                margin: 40px 0 0;
                padding: 0 0 12px 0;
                order: 3;
            }

            &:not(.customizable) {
                @include respond(largeUp) {
                    margin: 23px;
                }
    
            }

            &.customizable {
                padding: 12px;
            }

            &.promos-quickview {
                .pdp-promo-container {
                    margin: 0;
                    padding: 0;

                    h3 {
                        font-family: neuzeit-grotesk, sans-serif;
                        @include font-size(16px);
                        margin: 1rem 0 0.5rem;
                        letter-spacing: normal;
                    }
                }

                .pdp-addtocart-message * {
                    font-size: 14px !important;
                    color: #C80000 !important;
                    margin: 0 0 10px 0 !important;
                }

                .promotion-callout {
                    color: #333333 !important;
                    font-size: 14px !important;

                    a {
                        color: #333333 !important;
                        font-weight: 700 !important;
                        text-decoration: underline !important;
                    }

                    span {
                        display: block;
                        margin: 0 0 12px;
                        padding: 0;
                        width: 100%;
                        color: #000;
                    }
                }
            }

            .promotion {
                padding: 0;
                margin: 0;
                display: block;
                border: 0;
                overflow: visible;

                .promotion-title, .promotion-callout i {
                    display: none;
                }
            }
        }
    }
}

.quickview-loading {
    background: url("../images/loading.gif") no-repeat center center
        rgba(118, 118, 118, 0.44);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.wrapper-fixed {
    position: fixed !important;
}

#quickviewbutton,
button.quickview {
    border-width: 1px;
    display: none;
    @include font-size(16px);
    font-family: $neuzeit;
    font-weight: 100;
    align-items: center;
    height: 34px;
    justify-content: center;
    margin: 5px 0 0 0;
    position: absolute;
    gap: 10px;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;

    &::before {
        background: url("../images/icons/quick_shop.png") no-repeat
            center center;
        background-size: 100%;
        content: "";
        height: 31px;
        width: 31px;
    }

    &:hover {
        background-color: white;
        color: black;
    }

    @include respond(small) {
        display: flex;
        margin: 5px auto 0;
        padding: 0;
        position: static;
        width: 95%;
    }
}

.mobile-qv {
    top: 0;
    bottom:0;
    overscroll-behavior: none;
    position:fixed;
    width: 100%;
}
