.tabbed-recommendations {
    .tabbed-layout-heading {
        h2 {
            font-size: 24px;
            text-align: center;
            letter-spacing: 0;
            margin: 0 30px 20px;
            font-family: neuzeit-grotesk,sans-serif,serif;
            font-weight: 700;
            text-transform: uppercase;
            color: $black;
        }
    }

    .fj-recommended-products {
        h2.fj-rp-slider-title {
            display: none;
        }
    }

    .tabs-toggle {
        display: none;
        &.active {
            display: block;
        }
    }

    .product-listing-1x4 {
        padding: 0 50px;
    }

    .pdp-recommendations .product-listing-1x4 .search-result-items .slick-arrow {
        background-color: transparent !important;
        box-shadow: none;
        top: calc(100% - 20px) !important; // needed to override

        @media screen and (min-width: 767px) {
            top: 40% !important; // needed to override
        }

        &.slick-next {
            right: -40px;
        }

        &.slick-previous {
            left: -40px;
        }
    }
}
