#wrapper {
    &.pt_account {
        #main {
            padding: 0 16px;
            #primary {
                .main-box {
                    ul.account-options {
                        li a span {
                            float: right;
                            margin-right: 8px;
                            text-transform: capitalize;
                            font-weight: 500;
                            text-decoration: underline;
                            font-size: 14px;
                            &:hover {
                                @include respond(largeUp) {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                span.log-out {
                    @include respond(small) {
                        display: block;
                    }
                }
                > h1 {
                    font-size: rem(40px);
                    font-family: $neuzeit;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $black;
                    letter-spacing: normal;
                    margin-bottom: 15px;
                    @include respond(small) {
                        border: none;
                        padding-bottom: 2px;
                    }
                }
                .golfer-name {
                    @include respond(small) {
                        padding: 2px 0 10px;
                        font-size: 28px;
                        letter-spacing: normal;
                        border-bottom: none;
                    }
                }
                #PasswordResetForm {
                    @include respond(largeUp) {
                        max-width: 305px;
                    }
                    button {
                        font-size: 14px;
                        line-height: 39px;
                        height: 43px;
                    }
                }
                &.account-content {
                    p {
                        width: 100%;
                    }
                }
            }
        }
		&.pt_account_login_register {
			#primary {
				#RegistrationForm {
					.form-row.error {
						.form-caption.error-message {
							margin-left: 0;
						}
						.error-message {
							a {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
    }
}

h4.content-call {
    font-family: $neuzeit;
    font-weight: 700;
    color: $black;
    font-size: 20px;
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;
    width: 95%;
    padding-bottom: 10px;
}

#wrapper.pt_account {
    #primary {
        #RegistrationForm.registered-form {
            > div {
                .profile-col-title {
                    padding-top: 0;
                    margin-top: 26px;
                    margin-bottom: 18px;
                    font-size: 22px;
                    border: none;
                }
            }

            .form-col {
                .form-col-level2 {
                    float: left;
                    width: calc(50% - 25px);
                    &:nth-child(odd) {
                        margin-right: 50px;
                    }
                    &:nth-child(even) {
                        label {
                            width: calc(100% + 50px);
                            opacity: 0;
                        }
                    }
                    label {
                        width: calc(200% + 50px);
                        max-height: 18px;
                        @include respond(largeUp) {
                            max-height: 34px;
                        }
                    }
                }
            }

            .form-row {
                position: relative;

                .field-wrapper {
                    width: 100%;
                    padding-left: 0;
                }
                label {
                    width: 100%;
                    margin-left: 0;
                }
                label[for='dwfrm_profile_customer_lastname'] {
                    display: none;
                }
                input[type="checkbox"] {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    left: 0;
                    top: 0;
                }
                .form-caption {
                    display: none;
                }
                &.form-row-button {
                    border-top: none;
                    margin-top: 0;
                }
            }
            input,
            select {
                font-family: $neuzeit;
                font-size: 15px;
                letter-spacing: normal;
                border-radius: 0;
                height: 42px;
                background-color: transparent;
                border-width: 1px;
            }
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="number"] {
                @include placeholder-color($gray-light3);
                padding: 10px 12px;
            }
            select {
                padding: 0 32px 0 12px;
                background: url("../images/interface/icon-select-arrow-black.svg") no-repeat right 12px center;
                background-size: 18px 11px;
            }
            .side-form button {
                height: auto;
                width: auto;
                font-size: 14px;
                font-family: $neuzeit;
                text-decoration: underline;
                color: $black;
                &:hover {
                    color: $black;
                }
            }
            .side-form {
                position: relative;
                .form-row {
                    &.form-row-button {
                        margin-left: 0;
                    }
                }
                .form-caption {
                    display: none;
                }
                .show-password {
                    position: absolute;
                    top: 40px;
                    left: 268px;
                    width: 24px;
                    height: 24px;
                    background: url('../images/icons/password-show.svg') no-repeat;
                    cursor: pointer;
                    @include respond(largeUp) {
                        top: 50px;
                    }
                    @include respond(extra-small) {
                        left: auto;
                        right: 28%;
                    }
                    &.eye-show {
                        background: url('../images/icons/password-show_on.svg') no-repeat;
                    }
                }
            }
            .profile-info-container {
                padding: 0 6px;
                margin-bottom: 0;
                @include respond(largeUp) {
                    padding: 0;
                }
                .my-newsletter {
                    margin-top: 26px;
                    margin-bottom: 16px;
                    @include respond(largeUp) {
                        margin-bottom: 0;
                    }
                    input[type="checkbox"] + label {
                        background:url("../images/checkbox_unchecked.svg") left center no-repeat;
                        background-size: 28px 28px;
                    }
                    input[type="checkbox"]:checked + label {
                        background:url("../images/checkbox_checked.svg") left center no-repeat;
                        background-size: 28px 28px;
                    }
                    label {
                        display: block;
                        padding-left: 60px;
                        font-size: 14px;
                        color: $black;
                        line-height: normal;
                        letter-spacing: normal;
                        a {
                            font-size: 14px;
                            display: block;
                        }
                    }
                }
            }
            .my-information {
                > .form-row {
                    width: 142px;
                }
                .birthday-fields {
                    .form-row {
                        width: 28%;
                        margin-right: 10px;
                    }
                }
            }
            .my-game-container {
                padding: 16px 6px 0;
                margin-bottom: 64px;
                border-top: 1px solid $gray-light3;
                @include respond(largeUp) {
                    padding: 0;
                    border: none;
                }
                .form-row {
                    .field-wrapper {
                        margin-bottom: 0;
                    }
                    label {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: normal;
                        text-transform: none;
                    }
                }
                .shoe-finder-cta {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    @include respond(largeUp) {
                        margin-top: 16px;
                        margin-bottom: 0;
                    }
                    .shoe-finder-text {
                        float: left;
                        width: calc(100% - 96px);
                        span {
                            font-size: 14px;
                            color: $gray-warm;
                            letter-spacing: normal;
                            &.accent-text {
                                display: block;
                                margin-bottom: 16px;
                                font-size: 16px;
                                color: $black;
                            }
                        }
                    }
                    .go-shoe-finder {
                        float: right;
                        width: 80px;
                        margin-top: 24px;
                    }
                }
            }
        }

        .shoe-finder {
            p {
                width: 100%;
                + a {
                    font-size: 13px;
                }
            }
            a {
                font-weight: bold;
            }
            .content-asset {
                padding-bottom: 12px;
                @include respond(largeUp) {
                    padding: 0;
                }
            }
            .sf-header {
                font-size: 12px;
            }
            .sf-top-half {
                display: flex;
            }
            .sfproduct-image {
                display: inline-block;
                margin-right: 22px;
                max-width: 90px;
            }
            .sfproduct-info {
                display: inline-block;
                display: flex;
                flex-direction: column;
                vertical-align: top;
                max-width: calc(100% - 120px);
                font-size: 14px;
            }
            .sfproduct-name {
                padding-bottom: 4px;
                a {
                    text-decoration: none;
                }
            }
            .sfproduct-price {
                margin: auto 0 8px;
            }
            .sf-bottom-half {
                padding-top: 36px;
            }
        }

        .main-box {
            @include respond(largeUp) {
                width: 74%;
                margin-right: 5%;
            }
            .golfer-profile-section {
				.sfproduct-links {
				    p, a {
				        font-size: 13px;
				        padding: 0;
				    }
				    p {
				        margin: 0 0 -10px 0;
				    }
				}
                .account-option-title {
                    font-family: $neuzeit;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: .05em;
                    @include respond(largeUp) {
                        font-size: 14px;
                    }
                }
                .golfer-name {
                    padding-top: 10px;
                }
                .golfer-profile-col {
                    max-width: 370px;
                    @include respond(largeUp) {
                        float: left;
                    }
                    &.gp-col-1 {
                        @include respond(largeUp) {
                            width: 45%;
                        }
                    }
                    &.gp-col-2 {
                        box-sizing: border-box;
                        @include respond(largeUp) {
                            width: 55%;
                            margin-top: 12px;
                            margin-bottom: -20px;
                            padding-left: 55px;
                        }
                    }
                }

                .edit-profile-wrapper {
                    width: 100%;
                    margin-top: 38px;
                    @include respond(largeUp) {
                        text-align: right;
                        margin-top: 0;
                        margin-bottom: 34px;
                    }
                }
                .button-edit-profile {
                    position: absolute;
                    height: 43px;
                    bottom: 44px;
                    font-size: 18px;
                    letter-spacing: normal;
                    text-decoration: none;
                    line-height: 41px;
                    padding: 0 20px;
                    @include respond(largeUp) {
                        position: static;
                        margin-right: 32px;
                        &:hover {
                            color: $white;
                        }
                    }
                }
                .golfer-data {
                    .profile-data-item {
                        float: left;
                        margin: 0 12px 24px 0;
                        width: calc(50% - 12px);
                        @include respond(largeUp) {
                            margin-bottom: 28px;
                        }
                        span {
                            font-size: 14px;
                            &.label {
                                color: $gray-warm;
                                font-size: 12px;
                            }
                            &.value {
                                color: $black;
                            }
                        }
                        &.two-row {
                            &:first-child {
                                clear: left;
                            }
                            span {
                                display: block;
                                &.label {
                                    margin-bottom: 10px;
                                }
                                &.value {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
        p {
            color: $black;
        }
    }
}
#wrapper.pt_wish-list {
	#main {
		#primary {
			form:not(:last-child) {
				table.item-list {
					tr:last-child {
						border-bottom: 1px solid $gray-light;
					}
				}
			}
		}
	}
}
#wrapper.pt_account.ja_JP .golfer-profile-wrapper .golfer-profile-hdr .progress-links .account-tooltip {
    height: auto;
}
@import "account_nav";
@import "myprofile";
@import "login_register";
@import "preferences";
@import "account_dashboard";
@import "wishlist";
@import "order_history";
@import "customer_service_nav";
