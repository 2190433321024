.p-w-r {
    .pr-snippet-stars-png {
        .pr-snippet-rating-decimal {
            display: none;
        }
    }
    .pr-snippet-stars-reco-inline {
        &.pr-snippet-compact {
            .pr-snippet-read-and-write {
                a {
                    color: $black;
                    font-family: $neuzeit;
                    font-size: rem(16px) !important;
                    font-weight: bold;
                    padding-right: 0;
                    text-transform: inherit;
                    &.pr-snippet-review-count {
                        border-right: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.product-tabs {
    ul {
        font-family: $neuzeit;
        display: flex;
    }
    li {
        flex-grow: 1;
        text-align: center;
        width: 50%;
        padding: rem(14px) 0;
        &:first-of-type {
            border-bottom: solid rem(4px) #000;
        }
        a {
            color: $black;
            @include font-size(22px);
            width: 100%;
            font-weight: bold;
            @include respond(largeUp) {
                @include font-size(28px);
            }
        }
        &::marker {
            color: $white;
        }
    }
    &.ui-tabs {
        .ui-tabs-nav,
        .ui-tabs-panel {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .ui-tabs-nav {
        li {
            border-bottom-color: $gray-outline;
            border-width: 0 0 1px 0;
            margin: 1px 0 -34px 0;
            a {
                color: $gray-warm2;
                font-weight: bold;
            }
            &.ui-state-active {
                border-bottom-color: $black;
                border-width: 0 0 3px 0;
                margin-bottom: -34px;
            }
        }
        .ui-tabs-anchor {
            padding: rem(22px) 0 rem(12px);
            @include respond(largeUp) {
                padding: rem(12px) 0 rem(18px);
            }
        }
    }
}

.reviews-container {
    border-bottom: none;
    .p-w-r {
        font-family: $neuzeit;
        .pr-review-snapshot {
            margin-top: rem(40px);
            &.pr-snapshot-desktop {
                display: flex;
                .pr-review-snapshot-header,
                .pr-review-snapshot-block-container,
                .pr-review-snapshot-histogram-msq-container {
                    font-family: $neuzeit;
                    display: block;
                    flex-grow: 1;
                    margin: 0 rem(28px);
                    width: 33%;
                    border-top: 0;
                }
            }
            .pr-review-snapshot-block-headline {
                font-family: $neuzeit;
                @include font-size(22px);
                color: $gray-medium;
                padding: 0 0 rem(32px);
                font-weight: bold;
                letter-spacing: 0;
                @include respond(small) {
                    @include font-size(20px);
                    text-align: center;
                    padding: rem(22px) 0;
                }
            }
            .pr-review-snapshot-snippets {
                .pr-review-snapshot-block-headline {
                    text-align: center;
                }
                .pr-overall-rating {
                    @include font-size(38px);
                    font-weight: bold;
                    line-height: normal;
                    text-align: center;
                    margin-bottom: rem(10px);
                }
                .pr-snippet-stars-reco-inline {
                    .pr-snippet-stars-reco-stars {
                        .pr-snippet-stars-container {
                            margin-bottom: 0;
                            .pr-snippet-stars {
                                .pr-star-v4 {
                                    height: rem(32px);
                                    width: rem(32px);
                                }
                            }
                            .pr-snippet-rating-decimal {
                                display: none;
                            }
                        }
                        .pr-snippet-read-and-write {
                            .pr-snippet-review-count {
                                color: $black;
                                font-family: $neuzeit;
                                margin: rem(4px) 0 rem(28px);
                            }
                            .pr-snippet-write-review-link {
                                font-family: $neuzeit;
                                @include line-height(36px);
                                font-size: 15px !important;
                                text-decoration: none;
                                margin: 0;
                                height: rem(40px);
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .pr-slider-root {
                &.pr-text-above-root {
                    max-width: 315px;
                }
                .pr-min-text,
                .pr-max-text {
                    font-size: 16px;
                    color: #333;

                }
                .pr-slider .pr-slider-line {
                    border-top: none;
                }
                .pr-slider .pr-slider-node-group .pr-slider-node {
                    background-color: transparent;
                    border-radius: inherit;
                    height: 16px;
                    width: 60px;
                    border: none;
                    &.pr-active {
                        background-color: $black;
                    }
                }
            }
            &.pr-review-enhanced {
                .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram {
                    width: 33%;
                    height: auto;
                    padding: 0;
                    border-right: none;
                    .pr-histogram-msq-headline {
                        padding: 10px 0;
                        font-size: 16px;
                        text-transform: none;
                        color: $black;
                        letter-spacing: inherit;
                    }
                    .pr-histogram-msq-slider {
                        padding: 10px 0;
                    }
                }
                .pr-review-snapshot-block-container .pr-review-snapshot-block {
                    padding: 0;
                }
                .pr-review-snapshot-header {
                    .pr-review-snapshot-header-intro {
                        display: none;
                    }
                    .pr-review-snapshot-snippets .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars .pr-snippet {
                        display: inline-block;                        
                        .pr-snippet-read-and-write {
                            display: inline-block;
                            margin-left: 0;
                            .pr-snippet-review-count {
                                font-size: 13px !important;
                                color: $black;
                                font-style: normal;
                            }
                        }                     
                    } 
                }
                &.pr-snapshot-mobile {
                    .pr-review-snapshot-header .pr-review-snapshot-snippets .pr-snippet-stars-reco-inline {
                        display: block;
                    }
                    .pr-review-snapshot-histogram-msq-container .pr-review-snapshot-msq-histogram .pr-histogram-msq-slider {
                        max-width: none;
                    }
                    #msq-histogram-fit {
                        margin-top: 20px;
                    }
                } 
            } 
            &.pr-review-enhanced .pr-review-snapshot-block-container,
            .pr-review-snapshot-block-container {
                .pr-review-snapshot-block {
                    border: none;
                    margin: 0;
                    width: 100%;
                }
                .pr-review-snapshot-histogram {
                    .pr-review-snapshot-block-headline {
                        @include font-size(22px);
                        @include line-height(24px);
                        color: $gray-medium;
                        background: none;
                        padding: 0 0 rem(32px);
                        letter-spacing: 0;
                        @include respond(small) {
                            @include font-size(20px);
                            text-align: center;
                            padding: rem(22px) 0;
                        }
                    }
                    .pr-histogram-label {
                        padding-left: 0;
                        text-align: left;
                    }
                }
                .pr-ratings-histogram {
                    padding: 0;
                    margin: 0;
                    .pr-histogram-stars {
                        height: rem(16px);
                        .pr-histogram-label {
                            font-size: rem(13px);
                        }
                    }
                    .pr-ratings-histogram-bar {
                        background-color: $grey-inactive;
                        border-radius: 0;
                        margin: rem(8px) 0;
                        height: rem(16px);
                    }
                    .pr-ratings-histogram-barValue {
                        border-radius: 0;
                        height: rem(16px);
                    }
                    .pr-histogram-count {
                        text-align: center;
                        font-size: 16px !important;
                        font-weight: normal;
                    }
                    .pr-histogram-cross {
                        display: none;
                    }
                }
                .pr-review-snapshot-block-pros {
                    display: none;
                }
            }
            .pr-snippet-reco-to-friend {
                padding: rem(22px) rem(15px) 0;
                max-width: none;
                @include respond(small) {
                    padding: rem(22px) 0 rem(10px);
                    text-align: center;
                }
                .pr-checkbox-icon {
                    display: none !important;
                }
                > div {
                    margin: 1px 9px 0 0;
                    vertical-align: middle;
                    @include respond(small) {
                        margin-right: 5px;
                    }
                }
                span {
                    color: $black;
                    vertical-align: middle;
                    margin: 0;
                }
                .pr-reco span {
                    @include font-size(30px);
                    font-weight: bold;
                }
                .pr-reco-to-friend-message {
                    font-family: $neuzeit;
                    @include font-size(16px);
                    @include line-height(18px);
                    max-width: rem(220px);
                }
            }
            .pr-review-snapshot-msq-histogram {
                float: none;
                padding: 0;
				width: 100% !important;
				.pr-histogram-msqs {
					.pr-ratings-histogram-bar {
						height: 18px;
						.pr-ratings-histogram-barValue {
							height: 18px;
						}
					}
				}
                .pr-histogram-msq-headline {
                    margin: 0;
                    color: #333;
                    font-weight: 700;
                    margin-bottom: 10px;
                    font-size: 13.712px;
                    @include respond(small) {
                        text-align: center;
                    }
                }
                .pr-histogram-list {
                    li {
                        .pr-histogram-label,
                        .pr-ratings-histogram-bar {
                            display: block;
                        }
                        .pr-histogram-label {
                            padding: rem(15px) 0 rem(5px);
                        }
                    }
                }
                .pr-ratings-histogram {
                    margin: 0;
                    .pr-ratings-histogram-bar {
                        background-color: $grey-inactive;
                        border-radius: 0;
                    }
                    .pr-ratings-histogram-barValue {
                        border-radius: 0;
                    }
                    .pr-ratings-histogram-percent {
                        display: none;
                    }
                }
            }
            .pr-review-snapshot-msq-container {
                &.pr-review-snapshot-subratings {
                    display: none;
                }
            }
        }
        .pr-slider-node-group {
            background-color: #f0f0f0;
            border-radius: 0;
            height: 18px;
            width: 100%;
            box-shadow: none;
            float: none;
        }
        .pr-review-display {
            font-family: $neuzeit;
            span {
                color: $black;
            }
            .pr-rd-no-reviews {
                background: $white;
                padding: 0;
                .pr-snippet-stars-container {
                    margin-top: rem(40px);
                }
                .pr-snippet-write-review-link {
                    vertical-align: top;
                }
            }
            .pr-write-cta {
                margin: rem(4px) 0;
                span {
                    @include font-size(16px);
                }
            }
            .pr-write-copy {
                margin-bottom: rem(32px);
                span {
                    @include font-size(13px);
                }
            }
            .pr-review-filter-info-bar:not(:empty) {
                display: inline-block;
                border-top: 1px solid $gray-outline;
                margin: 0 auto;
                padding: rem(14px) 0 rem(6px);
                width: 100%;
                h1 {
                    display: inline-block;
                    margin-top: rem(4px);
                    width: auto;
                }
            }
            h2.pr-rd-review-headline {
                @include font-size(28px);
                margin: rem(8px) 0 0;
                width: 100%;
                @include respond(small) {
                    @include font-size(23px);
                }
            }
            .pr-rd-review-product {
                display: none;
            }
            p.pr-rd-description-text {
                @include font-size(15px);
                @include line-height(24px);
                color: $black;
                position: relative;
                @include respond(largeUp) {
                    max-width: 72%;
                }
                a {
                    color: $black;
                    font-weight: bold;
                    text-decoration: underline;
                }
                &.truncated {
                    max-height: rem(144px);
                    overflow: hidden;
                    &::after {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background: $white;
                        content: '';
                        display: block;
                        height: rem(24px);
                        width: rem(132px);
                    }
                }
                .read-full-review {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                }
            }
            .pr-helpful-btn {
                border: none;
                background: none !important;
                margin: 0;
                span {
                    color: $black;
                }
                .pr-thumbs-icon {
                    font-weight: bold;
                    text-decoration: underline;
                    padding-right: rem(8px);
                }
            }
            .pr-rd-flag-review-btn {
                color: $black;
                font-weight: bold;
                margin-right: 0 !important;
                text-transform: none;
            }
        }
    }
    > .clear {
        display: block;
    }
}

#reviews {
    .p-w-r {
        .pr-review-snapshot {
            .pr-review-snapshot-block-container {
                .pr-ratings-histogram {
                    .pr-histogram-stars {
                        pointer-events: none;
                        &:hover {
                            background: none;
                            .pr-ratings-histogram-bar {
                                background-color: $grey-inactive;
                            }
                        }
                        [data-tooltip] {
                            &::before,
                            &::after {
                                display: none;
                            }
                        }
                        .pr-histogram-label {
                            font-size: rem(16px) !important;
                            font-family: $neuzeit;
                        }
                    }
                }
            }
        }
        .rev-carousel-wrapper {
            @include respond(largeUp) {
                max-width: rem(1200px);
                background-color: transparent;
                margin: auto;
            }

            @include respond(1320px) {
                max-width: 1300px;
            }
        }
        .pr-review-display {
            @include respond(largeUp) {
                @include full-bleed;
                background: $grey-inactive;
            }
            .pr-review-filter-info-bar {
                display: block;
                margin: 0 auto;
                max-width: rem(1200px);
            }
            .pr-rd-main-header {
                flex: 0 1 100%;
                padding: 0;
                margin-bottom: rem(62px);
                background: $white;
                border-bottom: none;
                @include respond(small) {
                    margin-bottom: 0;
                    padding: 0 rem(20px);
                }
                h1 {
                    @include font-size(22px);
                    margin: 0;
                    @include respond(small) {
                        @include font-size(20px);
                        letter-spacing: 0;
                    }
                }
            }
            .pr-review-filter-info-bar {
                margin-top: rem(14px);
                h1 {
                    margin: rem(4px) rem(20px) 0 0;
                }
                .pr-filter-btn {
                    padding: rem(8px) rem(12px);
                    pointer-events: none;
                }
                .pr-filter-btn__icon {
                    display: none;
                }
            }
            .pr-rd-main-header-with-filters {
                .pr-rd-review-header-contents {
                    border-top: 1px solid $gray-outline;
                    float: none;
                    padding: rem(20px) 0 rem(4px);
                    margin: rem(38px) auto 0;
                    max-width: rem(1200px);
                    text-align: left;
                    @include respond(small) {
                        margin-top: 0;
                        padding: rem(20px) rem(8px) rem(8px);
                    }
                }
                .pr-rd-search-container,
                .pr-rd-review-header-sorts {
                    display: none;
                }
            }
            .view-all-reviews {
                float: right;
                color: $black;
                font-weight: bold;
                text-decoration: underline;
                @include respond(small) {
                    margin-top: rem(2px);
                }
            }
            .reviews-carousel {
                @include respond(small) {
                    background: $grey-inactive;
                    padding: rem(30px) rem(28px);
                }
                .pr-review {
                    margin: 0 rem(15px);
                    max-width: rem(380px);
                }
            }
            .slick-track {
                display: flex;
            }
            .slick-disabled {
                display: none !important;
            }
            .slick-arrow {
                background: none;
                @include respond(small) {
                    background: $white !important;
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
                    opacity: 1;
                    height: rem(40px);
                    width: rem(38px);
                }
                &::before {
                    border: solid $black;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    content: '';
                    position: absolute;
                    height: rem(24px);
                    width: rem(24px);
                    @include respond(small) {
                        top: rem(13px);
                        height: rem(12px);
                        width: rem(12px);
                    }
                }
            }
            .slick-prev {
                left: rem(-24px);
                @include respond(small) {
                    left: 4px;
                }
                &::before {
                    transform: rotate(135deg);
                    left: 0;
                    @include respond(small) {
                        left: 15px;
                    }
                }
            }
            .slick-next {
                right:rem(-24px);
                @include respond(small) {
                    right: 4px;
                }
                &::before {
                    transform: rotate(-45deg);
                    right: 0;
                    @include respond(small) {
                        right: 15px;
                    }
                }
            }
            .pr-review {
                display: flex;
                flex-direction: column;
                background: $white;
                padding: rem(36px) rem(46px);
                position: relative;
                height: inherit;
                @include respond(small) {
                    padding: rem(84px) rem(20px) rem(12px);
                }
            }
            .write-review {
                background: none;
                border: 1px solid $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                .pr-write-cta {
                    margin: 0;
                    span {
                        @include font-size(22px);
                    }
                }
                .pr-write-copy {
                    margin: rem(12px) 0 rem(28px);
                    span {
                        @include font-size(15px);
                    }
                }
                a {
                    @include buttonstyle;
                    margin: 0 auto;
                    text-decoration: none;
                }
                @include respond(small) {
                    &:empty {
                        display: none;
                    }
                }
            }
            .pr-rd-content-block {
                width: 100%;
                position: static;
            }
            .pr-rd-header {
                @include respond(largeUp) {
                    margin: rem(62px) 0 rem(28px);
                }
            }
            .pr-rd-star-rating {
                .pr-snippet-rating-decimal {
                    @include font-size(16px);
                    @include line-height(18px);
                    color: $black;
                    background: none;
                }
            }
            .pr-rd-description {
                .pr-rd-side-content-block {
                    position: absolute;
                    right: initial;
                    top: 0;
                    padding: rem(36px) 0 0;
                    width: calc(100% - 92px);
                    @include respond(small) {
                        padding-top: rem(26px);
                        width: calc(100% - 40px);
                    }
                    .pr-rd-reviewer-details {
                        margin: 0;
                    }
                    .pr-rd-author-submission-date {
                        float: right;
                    }
                    .pr-rd-author-nickname {
                        font-weight: bold;
                    }
                    .pr-rd-details {
                        margin: 0;
                        .pr-rd-bold {
                            display: none;
                        }
                    }
                    .pr-rd-badging-text {
                        padding: 0;
                    }
                    .pr-rd-author-location,
                    .pr-badging-icon,
                    .pr-rd-review-disclosure {
                        display: none;
                    }
                }
            }
            h2.pr-rd-review-headline {
                @include line-height(30px);
                @include respond(small) {
                    @include line-height(26px);
                }
            }
            .pr-rd-footer {
                margin: auto 0 0;
                padding: 0;
            }
            .pr-rd-helpful-action-group {
                flex-wrap: wrap;
                .pr-rd-helpful-action-legend {
                    flex: 1 0 100%;

                }
                .pr-rd-helpful-action-btn-group {
                    margin: 0;
                }
                .pr-helpful-btn {
                    padding-left: 0;
                    padding-right: 22px;
                }
                .pr-rd-flag-review-container {
                    display: none;
                }
            }
            .pr-rd-main-footer {
                flex: 0 1 100%;
                margin: 0 auto;
                max-width: rem(1200px);
                @include respond(largeUp) {
                    padding: rem(32px) 0 rem(30px);
                }
                span {
                    color: $black;
                }
                .pr-rd-content-block {
                    display: none;
                }
                .pr-rd-to-top {
                    display: none;
                }
            }
            &.pr-rd-display-desktop,
            &.pr-rd-display-tablet {
                .reviews-carousel {
                    width: 100%;
                    @include respond(largeUp) {
                        max-width: rem(1200px);
                        margin: auto;
                    }
                }
            }
        }
    }
}

.product-info > .reviews-modal {
    display: none;
}

.reviews-dialog {
    font-family: $neuzeit;
    padding: 0 !important;
    width: 96% !important;
    left: 2% !important;
    overflow-y: scroll;
    height: 94.5% !important;
    position: fixed !important;
    margin: 1% 0;
    @include respond(small) {
        left: 2% !important;
    } 
    .ui-dialog-titlebar-close {
        opacity: 1;
        margin: 0;
        right: 3% !important;
        top: 5%;
        position: fixed !important;
        @include respond(small) {
            width: rem(16px);
        }
        @include respond(largeUp) {
            width: 24px;
            height: 24px;
            padding: 0;
            right: 2% !important;
            top: rem(41px);            
            .ui-button-icon-primary {
                display: block;
                height: 24px;
                margin-left: 0 !important;
                width: 24px;
                right: 0;
                left: auto !important;
                position: absolute;
                background-position: center;
            }
        }
        &:hover {
            background: none;
        }
        &.fixed {
            @include respond(small) {
                position: fixed !important;
                right: auto;
                margin: 0;
                width: 96%;
                left: 2%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 54px;
                background: white;
                top: 0;
                margin-right: 0 !important;
                transform: translateX(0);
                .ui-button-icon-primary {
                    display: block;
                    height: 54px;
                    margin-left: 0 !important;
                    width: 54px;
                    z-index: 1;
                    left: 50%!important;
                    background-position: center;
                    transform: translateX(-50%);
                }
            }
            @include respond(largeUp) {
                position: fixed !important;
                right: 0;
                width: 96%;
                left: 2%;
                pointer-events: none;
                margin: 0 !important;
                box-sizing: border-box;
                .ui-button-icon-primary {
                    right: 27px;
                    pointer-events: auto;
                }
            }
        }
    }
    .ui-widget-header {
        text-transform: none;
    }
    .reviews-modal {
        box-sizing: border-box;
        padding: 0 4% !important;
        width: 100% !important;
        @include respond(small) {
            border-top: 1px solid $gray-outline;
            margin-top: rem(20px);
            padding: 0 rem(14px) !important;
        }
    }
}

.pr-review-display .pr-star-v4-100-filled,
.p-w-r .pr-review-display .pr-star-v4 {
    &:hover {
        width: rem(12px);
        height: rem(12px);
    }
}

#modal-reviews {
    .p-w-r {
        .pr-review-display {
            .pr-rd-main-header {
                border-top: 1px solid $gray-outline;
                border-bottom: 1px solid $gray-outline;
                margin: rem(38px) auto 0;
                padding: 0;
                h1 {
                    @include font-size(22px);
                    @include respond(small) {
                        display: none;
                    }
                }
                .pr-rd-review-header-contents {
                    .pr-rd-search-container {
                        .pr-rd-search-reviews-input input {
                            @include font-size(16px);
                            background: none;
                            border-radius: 0;
                            border-right: 1px solid $gray-outline;
							font-family: $neuzeit;
							padding: 0 44px 0 16px;
                            @include respond(largeUp) {
                                border-left: 1px solid $gray-outline;
                            }
                            &::placeholder {
                                color: $black;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                .pr-rd-review-header-sorts {
                    padding: rem(12px) 0;
                }
                @include respond(small) {
                    .pr-rd-review-header-contents,
                    .pr-rd-review-header-sorts {
                        display: inline-block;
                        vertical-align: middle;
                        width: 50%;
                    }
                }
            }
            .pr-rd-sort-group {
                font-family: $neuzeit;
                padding: 0;
                label {
                    @include font-size(16px);
                    color: $black;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                select {
                    @include font-size(16px);
                    font-family: $neuzeit;
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    cursor: pointer;
                    padding: 0 rem(26px) 0 rem(20px);
                }
            }
            .pr-review {
                border-bottom: 1px solid $gray-outline;
                margin-top: rem(30px);
                padding-bottom: rem(26px);
                position: relative;
                @include respond(largeUp) {
                    margin-top: rem(67px);
                    padding-bottom: rem(37px);
                }
            }
            .pr-accordion-btn {
                display: none;
            }
            .pr-accordion-content {
                margin: 0 0 rem(4px);
                max-height: none;
            }
            .pr-rd-def-list {
                dt,
                dd {
                    @include font-size(13px);
                    min-width: 0;
                    padding-right: rem(8px);
                }
            }
            &.pr-rd-display-desktop,
            &.pr-rd-display-tablet {
                .pr-rd-content-block {
                    width: 60%;
                }
                .pr-rd-main-footer .pr-rd-content-block {
                    width: 100%;
                }
            }
            .pr-rd-content-block {
                margin: 0;
            }
            .pr-rd-star-rating {
                float: none;
                .pr-snippet-rating-decimal {
                    @include font-size(16px);
                    @include line-height(17px);
                    color: $black;
                    background: none;
                    font-weight: bold;
                }
            }
            h2.pr-rd-review-headline {
                letter-spacing: 0;
                margin-bottom: rem(26px);
                max-width: rem(420px);
            }
            .pr-badging-icon {
                display: none;
            }
            .pr-rd-helpful-action {
                width: 100%;
                .pr-rd-helpful-text {
                    @include font-size(15px);
                    font-weight: normal;
                    @include respond(small) {
                        padding-bottom: rem(20px);
                    }
                }
            }
            .pr-rd-helpful-action-btn-group,
            .pr-rd-flag-review-container {
                @include respond(small) {
                    display: inline-block;
                    width: 50%;
                }
            }
            .pr-helpful-btn {
                @include respond(small) {
                    padding: 0;
                }
            }
            .pr-rd-flag-review-container {
                @include respond(largeUp) {
                    margin-left: auto;
                    padding-right: rem(346px);
                }
                @include respond(small) {
                    text-align: right;
                }
                a {
                    @include font-size(15px);
                    font-weight: bold;
                }
            }
            .pr-rd-description {
                position: static;
                margin-bottom: rem(22px);
                .pr-rd-side-content-block {
                    background: $grey-inactive;
                    padding: rem(32px);
                    right: 0;
                    top: 0;
                    @include respond(largeUp) {
                        width: rem(256px);
                        top: -67px;
                    }
                    .pr-rd-content-block {
                        width: 100%;
                    }
                    .pr-snippet-stars {
                        background: none;
                        .pr-rating-stars > div {
                            height: rem(16px);
                            width: rem(16px);
                        }
                    }
                    .pr-snippet-rating-decimal {
                        display: none;
                    }
                }
            }
            .pr-rd-reviewer-details {
                margin-bottom: 0;
                .pr-rd-bold {
                    display: none;
                }
                .pr-rd-author-submission-date {
                    position: absolute;
                    right: rem(356px);
                    width: fit-content;
                    @include respond(small) {
                        right: 0;
                        top: 0;
                    }
                }
                .pr-rd-author-nickname {
                    font-weight: bold;
                }
                .pr-rd-author-location {
                    display: none;
                }
            }
            .pr-rd-badging-text {
                padding: 0;
            }
            .pr-rd-review-disclosure {
                color: $black;
                font-style: italic;
                margin-bottom: rem(6px);
            }
            .pr-rd-footer {
                padding: 0;
            }
        }
    }
    .product-tabs .ui-tabs-nav li {
        border-bottom-color: $white;
    }
}

.answers-container {
    .p-w-r {
        .pr-qa-display {
            font-family: $neuzeit;
            margin-top: rem(32px);
            @include respond(small) {
                display: flex;
                flex-direction: column;
            }
            span {
                @include font-size(15px);
                color: $black;
            }
            .pr-qa-write-title {
                margin: rem(4px) 0;
                span {
                    @include font-size(16px);
                }
            }
            .pr-qa-write-copy {
                margin-bottom: rem(32px);
                span {
                    @include font-size(13px);
                }
            }
            .pr-qa-display-ask-question {
                @include respond(small) {
                    margin: rem(28px) 0 0;
                    order: -1;
                }
                .pr-btn-ask-question {
                    .pr-qa-display-text {
                        color: $white;
                    }
                }
            }
            .pr-waqwaa {
                max-width: none;
            }
            .pr-question-form,
            .pr-answer-form {
                position: relative;
                padding-top: rem(20px);
                .pr-header-required {
                    position: absolute;
                    top: rem(22px);
                    right: 0;
                }
                .form-group {
					position: relative;
					&#authorEmail {
						display: flex;
						flex-direction: column;
					}
                    .pr-helper-text {
                        font-style: italic;
                        position: relative;
						top: auto;
						max-width: calc(100% - 22px);
						order: 2;
						margin-top: rem(13px);
                    }
                }
                .authorEmail-form-group,
                .authorName-form-group {
                    @include respond(largeUp) {
                        padding-right: rem(20px);
                    }
                }
                .authorEmail-form-group,
                .authorName-form-group,
                .authorLocation-form-group {
                    @include respond(largeUp) {
                        display: inline-block;
                        margin-bottom: rem(62px);
                        width: 33%;
                        .form-group {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .pr-waq-legal-text {
                display: inline-block;
                max-width: rem(400px);
                a {
                    color: $black;
                    font-weight: bold;
                }
            }
            .pr-question-form {
                .pr-submit {
                    float: right;
                }
            }
            .pr-textarea,
            .pr-textinput {
                border: 1px solid $black;
                border-radius: 0;
            }
            .pr-submit {
                .pr-btn {
                    background: $black;
                    border: none;
                    &:hover,
                    &:active,
                    &:focus {
                        background: $black;
                        border: none;
                        color: $white;
                        outline: none;
                    }
                }
            }
            .pr-qa-display-headline {
				margin: 0;
                .pr-qa-display-ask-question {
					margin: rem(40px) 0;
					button.pr-qa-display-btn {
						font-family: $neuzeit;
						font-size: rem(15px);
					}
                }
                .pr-qa-display-search {
                    margin: rem(38px) auto 0;
					padding: rem(8px);
					border-top: 1px solid $gray-outline;
                    @include respond(small) {
                        display: inline-block;
                        padding: rem(10px) 0;
						width: 49%;
                    }
                    label {
                        display: none;
                    }
                    .pr-qa-display-searchbar {
                        @include respond(small) {
                            max-width: none;
                        }
                        input {
                            @include font-size(16px);
                            background: none;
                            border-radius: 0;
                            border-right: 1px solid $gray-outline;
                            font-family: $neuzeit;
                            @include respond(largeUp) {
                                border-left: 1px solid $gray-outline;
                            }
                            &::placeholder {
                                color: $black;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                .pr-qa-display-sort {
                    border-top: 1px solid $gray-outline;
                    margin: rem(38px) auto 0;
					padding: 0;
					border-bottom: none;
                    @include respond(small) {
                        display: inline-block;
                        vertical-align: top;
						width: 51%;
                    }
                    .pr-qa-sort-headline {
                        @include font-size(16px);
                        margin: rem(19px) 0 rem(14px);
                        text-transform: uppercase;
                        @include respond(small) {
                            display: none;
                        }
                    }
                }
                .pr-qa-sort-group {
                    margin-top: rem(8px);
                    label {
                        @include font-size(16px);
                        color: $black;
                        display: inline-block;
                        font-weight: bold;
                        text-transform: uppercase;
                        @include respond(small) {
                            display: none;
                        }
                    }
                    select {
                        @include font-size(16px);
                        font-family: $neuzeit;
                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                        color: $black;
                        cursor: pointer;
                        display: inline-block;
                        padding: 0 rem(40px) 0 rem(20px);
                        @include respond(small) {
                            font-weight: bold;
                            padding-right: rem(26px);
                            width: rem(165px);
                        }
                    }
                }
            }
            .pr-qa-display-question {
				font-family: $neuzeit;
				border-top: 1px solid $gray-outline;
				padding-top: rem(8px);
                .pr-qa-display-questionlist {
                    .pr-qa-display-item {
                        margin: rem(32px) 0 0;
                        .pr-qa-display-item-icon {
                            @include font-size(22px);
                            @include line-height(28px);
                            display: inline-block;
                            background: none;
                            border: none;
                            color: $black;
                            font-weight: bold;
                            padding: 0;
                            position: initial;
                            vertical-align: top;
                            width: auto;
                            &::after {
                                content: ":";
                                margin-right: rem(8px);
                            }
                        }
                        .pr-qa-display-item-text {
                            @include font-size(22px);
                            @include line-height(28px);
                            display: inline;
                        }
                    }
                    .pr-qa-display-item-details {
                        .pr-subscript {
                            @include font-size(12px);
                            color: $black;
                            font-weight: bold;
                            @include respond(small) {
                                margin-bottom: rem(33px);
                            }
                            &::after {
                                background: $black;
                                border: 1px solid $black;
                                border-radius: 50%;
                                content: "";
                                display: inline-block;
                                margin: 0 0 2px 7px;
                                height: 4px;
                                width: 4px;
                            }
                        }
                        time {
                            color: $black;
                        }
                    }
                    .pr-qa-display-answer {
                        padding: 0;
                        @include respond(small) {
                            margin-top: rem(34px);
                        }
                        .pr-qa-display-answer-by-container {
                            .pr-qa-display-answer-details {
                                .pr-qa-display-answer-icon {
                                    display: none;
                                }
                                .pr-qa-display-answer-by {
                                    font-weight: bold;
                                }
                                .pr-qa-display-answer-text {
                                    margin: rem(16px) 0;
                                }
                            }
                        }
                    }
                    .pr-qa-helpful-cta {
                        vertical-align: middle;
                        @include respond(small) {
                            display: block;
                        }
                    }
                    .pr-helpful-btn {
                        margin: 0;
                    }
                    .pr-helpful-yes {
                        padding-right: rem(8px);
                        @include respond(small) {
                            padding-left: 0;
                        }
                    }
                    .pr-thumbs-icon {
                        font-weight: bold;
                        text-decoration: underline;
                        padding-right: rem(8px);
                    }
                    .pr-qa-display-answer-link-container {
                        float: none;
                        margin: 0;
                        padding: 0;
                        .pr-btn-answer {
                            border: 1px solid $black;
                            margin: rem(18px) 0 rem(36px);
                            padding: rem(10px) rem(15px);
                            @include respond(small) {
                                margin-bottom: rem(8px);
                                padding: rem(12px) rem(15px);
                            }
                        }
                        .pr-cross-icon {
                            display: none;
                        }
                        .pr-qa-display-text {
                            @include font-size(14px);
                            font-weight: bold;
                        }
                    }
                }
            }
            .pr-qa-display-add-answer {
                float: none;
                margin: rem(26px) 0;
                padding: 0;
            }
            .pr-answer-form {
                .authorName-form-group,
                .authorLocation-form-group {
                    @include respond(largeUp) {
                        margin-bottom: rem(32px);
                        width: 50%;
                    }
                }
            }
            &.pr-qa-display-desktop {
                padding: 0;
                .pr-qa-display-question {
                    .pr-qa-display-questionlist {
                        padding-left: 33px;
                    }
                }
            }
        }
    }
}

#pwrwritediv {
    margin: 0 16px;

    .p-w-r {

        > h1 {
            margin-bottom: 30px;
            font-family: $din-condensed;
            text-align: center;
            letter-spacing: normal;
            text-transform: uppercase;
        }

        p {
            color: $black;
        }

        .pr-star-v4 {
            @include respond(largeUp) {
                margin-right: 9px;
            }
            &.pr-star-v4-0-filled {
                width: 30px;
                height: 30px;
                background-image: url("../images/interface/pwr-star-0-filled.svg");
                background-size: 30px 30px;
                @include respond(iphone) {
                    width: 26px;
                    height: 26px;
                    background-size: 26px 26px;
                }
            }
            &.pr-star-v4-100-filled {
                width: 30px;
                height: 30px;
                background-image: url("../images/interface/pwr-star-100-filled.svg");
                background-size: 30px 30px;
                @include respond(iphone) {
                    width: 26px;
                    height: 26px;
                    background-size: 26px 26px;
                }
            }
        }

        .pr-ryp-list {
            @include respond(largeUp) {
                max-width: 1045px;
                margin: 0 auto;
            }

            .ryp-purchase {
                position: relative;
                width: 100%;
                min-height: 132px;
                padding: 8px 0 2px;
                margin-bottom: 16px;
                font-family: $neuzeit !important;
                @include respond(largeUp) {
                    min-height: 180px;
                    padding: 28px 0 0;
                    margin-bottom: 21px;
                }

                > .col-sm-3 {
                    position: absolute;
                    width: 116px;
                    padding: 0;
                    top: 7px;
                    left: 10px;
                    @include respond(iphone) {
                        top: 3px;
                        left: 0;
                    }
                    @include respond(largeUp) {
                        padding: 0;
                        top: 20px;
                        left: 20px;
                    }
                }

                > .col-sm-9,
                .ryp-stars {
                    width: calc(100% - 141px);
                    margin-left: 141px;
                    @include respond(iphone) {
                        width: calc(100% - 110px);
                        margin-left: 110px;
                    }
                    @include respond(largeUp) {
                        width: calc(100% - 160px);
                        margin-left: 160px;
                    }
                }

                .ryp-first-q {
                    @include respond(small) {
                        padding: 0 8px 0 15px;
                    }
                    @include respond(iphone) {
                        padding-left: 8px !important;
                    }
                }

                .ryp-stars {
                    + .col-sm-12 {
                        @include respond(largeUp) {
                            padding: 0 32px;
                            margin-top: 16px;
                        }
                    }
                }

                .ryp-product-img {
                    margin: 8px;
                    width: calc(100% - 16px);
                    max-width: none;
                }

                .ryp-product-title {
                    margin-bottom: 4px;
                    font-family: $din2014narrow;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    letter-spacing: normal;
                    text-align: left;
                }

                .ryp-order-text {
                    margin-bottom: 9px;
                    font-size: 16px;
                    letter-spacing: normal;
                    text-align: left;
                    color: $gray-mediumer;

                    .ryp-purchased-date {
                        font-size: 16px;
                    }

                    .pr-checkbox-icon {
                        display: none;
                    }

                }

                .form-group {
                    margin-bottom: 20px;
                    @include respond(largeUp) {
                        margin-bottom: 22px;
                    }

                    label {
                        margin: 10px 0 12px;

                        &.pr-file-input-label {
                            display: none;
                        }

                        span {
                            font-size: 20px;
                            font-weight: normal;
                            letter-spacing: normal;
                            &.pr-required-indicator {
                                color: $blue-light;
                            }
                        }
                    }

                    .pr-control-label {
                        + div {
                            text-align: left;
                        }
                    }

                    .pr-file-input-btn-group {
                        @include respond(small) {
                            flex-direction: row;
                        }
                        @include respond(largeUp) {
                            margin-top: -16px;
                        }

                        .pr-btn-fileinput {
                            position: relative;
                            display: inline-block;
                            width: auto !important;
                            margin: 12px 10px 8px 0;
                            padding: 15px 28px 15px 50px;
                            border: none;
                            border-radius: 0;
                            color: $blue-light;
                            font-family: $din-condensed;
                            font-weight: normal;
                            font-size: 18px;
                            text-transform: uppercase;
                            &.pr-file-input-btn {
                                @include respond(small) {
                                    max-width: 48px;
                                    padding: 15px 24px;
                                    margin-left: 0;
                                    margin-right: 10px;
                                    overflow: hidden;
                                    color: transparent;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                            @include respond(largeUp) {
                                margin-left: 0;
                            }
                            svg {
                                position: absolute;
                                top: 12px;
                                left: 12px;
                            }
                        }
                    }

                }

                .ryp-footer {
                    margin: 0 -15px -2px;
                    padding: 24px 22px;
                    border-radius: 0 0 4px 4px;
                    border-top: none;
                    @include respond(largeUp) {
                        margin: 0 -32px;
                        padding: 24px 32px;
                    }

                    .pr-submit {
                        p {
                            &.pr-subscript {
                                margin-bottom: 28px;
                                font-size: 16px;
                                line-height: normal;
                                letter-spacing: normal;
                            }
                        }
                        a {
                            font-size: 16px;
                            line-height: normal;
                            color: $blue-light;
                            text-decoration: underline;
                            &:hover {
                                @include respond(largeUp) {
                                    text-decoration: none;
                                }
                            }
                        }
                        .pr-btn-review {
                            width: auto !important;
                            padding: 15px 28px;
                            font-family: $din-condensed;
                            font-weight: normal;
                            font-size: 18px;
                            text-transform: uppercase;
                            background-color: $blue-medium;
                            border-bottom: none;
                            border-radius: 0;
                            &:hover {
                                color: $white;
                                background-color: $blue-dark;
                            }
                        }
                    }
                }

            }

        }

        textarea {
            font-size: 16px;
            letter-spacing: normal;
            &.form-control {
                height: 197px !important;
                padding: 20px !important;
                border-radius: 0;
                box-shadow: none;
                font-size: 16px;
            }
        }

        input[type="text"] {
            font-size: 16px;
            letter-spacing: normal;
            &.form-control {
                height: 48px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                border-radius: 0;
                box-shadow: none;
                font-size: 16px;
            }
        }

    }
}

.rev-carousel-wrapper,
#reviews .p-w-r {
    position: relative;
}

html.pr-open {
    overflow-y: hidden;
}

@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        /* Safari only */ 
        .p-w-r .pr-media-carousel--light .button__next {
            @include respond(largeUp) {
                right: 39px !important;
            }
        }
        /* Safari only */ 
        .p-w-r .pr-media-carousel--light .button__prev {
            @include respond(largeUp) {
                left: 39px !important;
            }
        }
    }
}
