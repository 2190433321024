.main__wrapper {
    @include respond(largeUp) {
        display: flex;
    }
}

#wrapper.pt_customer-service {
    #main {
        margin-bottom: 0;

		@include respond(small) {
			margin-top: 104px !important;
		}

        @include respond(largeUp) {
            position: relative;
            margin-top: 106px !important;
            padding-left: 0;
            padding-right: 0;
        }

        #secondary.nav {
            width: 100%;
            padding: 0;

            @include respond(largeUp) {
                width: 25%;
                max-width: 417px;
                box-sizing: border-box;
                padding-top: 83px;
                background: #DDDDDD;
                padding-left: 20px;
                padding-right: 20px;
                margin: 0;
            }

            @include respond(standard) {
                padding-right: 60px;
                width: 30%;
            }
        }

        #primary {
            @include respond(largeUp) {
                width: 75% !important;
                max-width: 1000px;
                box-sizing: border-box;
                padding-top: 86px;
                padding-left: 77px;
                padding-right: 20px;
                margin: 0;
                background: $white;
            }

            @include respond(large) {
                padding-left: 40px;
                padding-right: 20px;
            }
        }
    }

    .contactus-form {
        float: none;
    }
}

#wrapper #main #secondary nav .secondary-navigation {
    .customer-service-side-nav {
        @include respond(small) {
            margin-top: 20px;
            margin-left: 34px;
        }
    }

    .customer-service-mobile-drawer {
        display: block;
    }

    h3 {
        display: block;
        margin-bottom: 0;
        color: $black;
        font-family: $neuzeit;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: -0.09px;
    }
    ul {
        margin-left: 20px;
        margin-bottom: 40px;

        li {
            display: list-item;
            float: none;
            list-style: disc !important;
            border: none;

            a {
                display: block;
                padding: 0;
                margin: 0;
                float: none;
                color: $black;
                font-family: $neuzeit !important;
                font-size: 18px;
                font-weight: 400 !important;
                line-height: 47px;
                letter-spacing: -0.09px;
                text-decoration: underline;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.customer-service-mobile-drawer .header {
    @include respond(small) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 34px;
        background: #D9D9D9;
        color: $black;
        font-family: $neuzeit;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.03rem;
        border-radius: 0;
		z-index: 2;
    }

    @include respond(largeUp) {
        display: none;
    }

    &.open {
        &::after {
            @include respond(small) {
                transform: none;
            }
        }
    }

    &::after {
        @include respond(small) {
            content: '';
            width: 29px;
            height: 21px;
            background: url('../images/icons/customer-service-menu-icon.svg') no-repeat center;
        }
    }
}

.service-help {
    margin-top: 100px;
    text-align: center;
    color: $black;

    .service-help__title {
        margin-bottom: 44px;
        color: $black;
        font-family: $neuzeit;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.54px;
        text-transform: none;
    }

    .service-help__description {
        margin: 0;
        font-family: $neuzeit;
        font-size: 22px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.33px;
     }

    .service-help__contacts {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        margin-bottom: 40px;

        @include respond(small) {
            gap: 60px;
            flex-direction: column;
        }

        @include respond(largeUp) {
            justify-content: space-between;
        }
    }

    .service-help__item {
        flex: 1;
    }

    .service-help__item__image {
        margin-bottom: 13px;

        @include respond(largeUp) {
            height: 93px;
        }
    }

    .service-help__item__title {
        margin: 0;
        font-family: $neuzeit;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: -0.27px;
    }

    .service-help__item__description {
        margin: 0;
        font-family: $neuzeit;
        font-size: 18px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.27px;
    }

    .service-help__item__title--space {
        margin-bottom: 10px;

        @include respond(largeUp) {
            margin-bottom: 20px;
        }
    }

    .service-help__item__link {
        font-family: $neuzeit;
        color: $black;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.27px;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.faq-page {
    margin-top: -16px;

    @include respond(largeUp) {
        margin-top: -87px;
    }

    .faq-page__banner {
        position: relative;
        margin-bottom: 43px;

        @include respond(small) {
            margin-left: -4vw;
            width: calc(100vw);
        }

        @include respond(largeUp) {
            margin-left: -77px;
            width: calc(100vw - 417px);
        }

        @include respond(large) {
            margin-left: -40px;
            width: calc(100vw - 25%);
        }
    }

    .faq-page__banner__img {
        width: 100%;
        height: auto;
    }

    .faq-page__banner__logo {
        position: absolute;
        top: 40%;
        left: 50%;
        display: flex;
        align-items: end;
        gap: 20px;
        transform: translateX(-50%);

        @include respond(largeUp) {
            left: 47px;
            bottom: 20%;
            top: initial;
            transform: none;
        }
    }

    .faq-page__banner__logo__text {
        margin: 0;
        padding: 0;
        font-family: $neuzeit;
        color: $black;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.54px;
        text-wrap: nowrap;
    }

    .faq-page__links__wrapper {
        border-bottom: 1px solid $black;
        padding-bottom: 73px;
        margin-bottom: 52px;

        @include respond(small) {
            margin-left: -4vw;
            width: calc(100vw);

        }

        @include respond(largeUp) {
            margin-left: -77px;
            width: calc(100vw - 417px);
        }

        @include respond(large) {
            margin-left: -40px;
            width: calc(100vw - 35%);
        }
    }

    .faq-page__links__inner {
        @include respond(small) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include respond(largeUp) {
            padding-left: 77px;
            max-width: 1000px;
        }

        @include respond(large) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 40px;
        }
    }

    .faq-page__links__title {
        margin-bottom: 36px;
        text-align: center;
        font-family: $neuzeit;
        color: $black;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.54px;
    }

    .faq-page__links__list {
        list-style: none;
        margin: 0;
        padding: 0;

        @include respond(small) {
            display: flex;
            flex-direction: column;
            gap: 47px;
        }

        @include respond(largeUp) {
            display: flex;
            justify-content: space-around;
        }

        @include respond(large) {
            display: flex;
            flex-direction: column;
            gap: 47px;
        }
    }

    .faq-page__links__list__link {
        color: $black;
        font-family: $neuzeit;
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.33px;
        text-decoration-line: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .accordion__wrapper {
        margin-top: 20px;
    }

    .accordion__item__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        padding: 20px 15px;
        color: $black;
        font-family: $neuzeit;
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.33px;
        text-transform: uppercase;
        border: none;
        border-bottom: 1px solid;

        @include respond(largeUp) {
            padding: 20px 50px;
        }

        &:hover {
            background-color: #DDDDDD;
        }

        &::after {
            content: '–';
            display: block;
            color: $black;
            font-size: 34px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.51px;
        }

        &.collapsed {
            &::after {
                content: '+';
                font-weight: 700;
            }
        }
    }

    .accordion__item__container {
        overflow: hidden;
        padding: 15px 15px;
        max-height: 10000px;
        transition: all 0.1s ease-in-out;

        @include respond(largeUp) {
            padding: 15px 50px;
        }

        &.collapsed {
            padding-top: 0;
            padding-bottom: 0;
            max-height: 0;
        }

        p {
            color: $black;
            font-family: $neuzeit;
            font-size: 18px;
            line-height: normal;
            letter-spacing: -0.27px;

            strong {
                font-weight: 700;
            }
        }

        strong {
            font-weight: 700;
        }

        a {
            color: $black;
            font-family: $neuzeit;
            font-size: 18px;
            line-height: normal;
            letter-spacing: -0.27px;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

#wrapper.pt_customer-service #primary h1.faq-page__title {
    color: $black;
    font-family: $neuzeit;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.54px;
}
