#search-suggestions {
	border-top: 5px solid #25495f;
	display: none;
	position: absolute;
	top: 100%;
	bottom: auto;
	left: auto;
	overflow-y: auto;
	width: 100%;
	right: 0;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
	background: #f8f8f8;
	z-index: 6;
	max-height: 95vh;
	@include respond(suggestbreak) {
		width: 800px
	}

	@include respond(largeUp) {
		top: 61px;
	}
}
.search-suggestion-wrapper {
	@extend %clearfix;
	background-color: $white;
	/*box-shadow: 0 2px 5px 0 $gray-light;*/
	border: none !important;
	width: 100%;
	font-family: $neuzeit;

	@include respond(largeUp) {
		&.full {
			width: 100%;
		}
	}

	.hitgroup {
		color: #9b9b9b;
		margin: 0;
		text-transform: capitalize;
		.header {
			margin: 0 0 .5rem 0;
		}
		.hit {
			display: inline-block;
			color: #9b9b9b;
			line-height: 30px;
			font-family: 'neuzeit-grotesk', sans-serif;
			font-weight: 400;
			position: relative;
			font-size: 11px;
			&:hover {
				text-decoration: none;
				&:after {
					display: block;
				}
			}
			&:after {
				content: "";
				position: absolute;
				display: none;
				height: 1px;
				right: 0;
				bottom: 0;
				left: 0;
				background: #000;
			}
		}
		.parent-category {
			color: $gray-warm;
		}
	}

	.product-name {
		line-height: 13px;
		color: $black;
		font-weight: 700;
		font-size: .75rem;
	}
}

.phrase-suggestions,
.product-suggestions {
	padding: 25px;
}

.phrase-suggestions {
	font-family: 'neuzeit-grotesk' ,sans-serif;
	background: #f2f2f2;
	color: #9b9b9b;
	@include respond(largeUp) {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 25%;
	}
}

.product-suggestions {
	background: #f8f8f8;
	@include respond(largeUp) {
		margin-right: 25%;
	}
}

.product-suggestion {
	@extend %clearfix;
	float: none;
	width: auto;
	display: flex;
	@include respond(largeUp) {
		float: left;
		width: 50%;
		padding: 25px 10px 0 0;
	}
	&:hover,
	&:focus {
	}
	.product-image {
		float: left;
		text-align: center;
		width: 100px;
		min-width: 100px;
		margin-right: 25px;
		img {
			max-width: 100%;
		}
	}
	.product-details {
		-ms-flex-item-align: center;
		align-self: center;
		.product-name {
			font-size: 14px;
			/*font-size: 1.4rem;*/
			padding-bottom: 5px;
			letter-spacing: 1px;
			font-weight: normal;
		}
		.product-category {
			font-size: 11px;
			/*font-size: 1.1rem;*/
			font-family: 'neuzeit-grotesk', sans-serif;
			color: #4a4a4a;
			letter-spacing: .3px;
			text-transform: capitalize;
		}
		.product-price {
			color: $black;
		}
	}
	.product-link {
		display: flex;
	}
}

.search-phrase {
	color: #9b9b9b;
	padding: 0 0 25px 0;
	letter-spacing: .3px;
	text-transform: capitalize;
	font-size: 11px;
	.completed {
		font-style: italic;
		.original {
			text-decoration: underline;
			font-weight: bold;
		}
	}
	.corrected {
		font-weight: bold;
	}
	a {
		color: $gray-warm;
	}
}

.suggestion-title {
	font-size: 24px;
	/*font-size: 2.4rem;*/
	letter-spacing: 1px;
}

.suggestions-wrapper {
	display: table;
	width: 100%;
}

.highlighted-phrase {
	color: #333333;
}

.phrase-suggestions {
	.selected {
		width: 100%;
		background: $white;
		text-decoration: underline;
	}
}

.product-suggestions {
	.header-suggestion {
		&.selected {
			background: $black;
			color: $white;
		}
	}
}
