#klarna-placement {
    *::part(osm-container) {
        position: relative;
        background: none;
        border: none;
        text-align: left;
        padding: 0 0 0 20px;
        color: $black;
        font-size: 12px;
        line-height: normal;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            background: url('../images/icons/icon-checkmark-dark.svg') no-repeat center;
            left: 0;
            top: 5px;

            @include respond(500px, 900px) {
                top: 6px;
            }
        }
    }

    *::part(osm-legal),
    *::part(osm-message) {
        color: $black;
        font-size: 12px;
    }
}
