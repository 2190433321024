///////////////////////
/// Styles for Shopping Cart page
///////////////////////

#wrapper.pt_cart{
	#main{
		#primary{
			.error-form {
				padding-left: 0;
				padding-right: 0;
				margin: 0;
				box-sizing: border-box;
				font-family: $neuzeit;
				font-size: 16px;
				letter-spacing: .025rem;
				color: $red;
				@include respond(largeUp) {
					margin: .5em 0;
				}
			}
			.bonus-item-promo {
				.bonus-item-details {
					margin-top: 20px;
				}
				.bonus-products {
					a {
						height: 44px;
						font-size: 20px;
						line-height: 44px;
					}
				}
			}
			#cart-items-form {
				#cart-table {
					tr {
						td {
							&.item-image {
								img {
									width: 126px;
									margin-right: 0;
									@include respond(largeUp) {
										width: 142px;
										margin-right: 48px;
									}
								}
							}
							&.item-details {
								.product-list-item {
									font-size: 16px;
									font-weight: normal;
									letter-spacing: normal;
									.name {
										a {
											font-weight: 900;
										}
									}
									.sku {
										span {
											font-size: 14px;
										}
									}
									.gc-to,
									.gc-from,
									.gc-message {
										@include respond(small) {
											margin: 8px 0;
										}
									}
									.gc-to {
										&.gc-inline {
											@include respond(small) {
												margin-bottom: 0;
											}
											@include respond(largeUp) {
												display: inline-block;
												margin-right: 48px;
											}
											+ .gc-to-email {
												display: inline-block;
												@include respond(small) {
													margin: 0;
												}
												.label {
													display: none;
												}
											}
										}
									}
									.label,
									.value {
										font-size: 16px;
										font-weight: normal;
										text-transform: none;
										color: $gray-dark;
									}

								}
								.item-edit-details {
									@include respond(largeUp) {
										padding: 20px 0 16px;
									}
								}
							}
							&.item-price {
								.price-promotion {
									display: inline-flex;
									> span:first-child {
										&:first-child {
											display: inline-block;
											width: auto;
										}
										display: inline-block;
										width: auto;
									}
									.price-sales {
										display: inline-block;
										padding: 9px 0 0 9px;
										width: auto;
										@include respond(small) {
											padding: 0 0 0 9px;
										}
									}
								}
							}
						}
					}
					.item-image {
						@include respond(largeUp) {
							width: 194px;
						}
					}
					.item-details {
						@include respond(largeUp) {
							width: calc(61% - 194px);
						}
					}
					.item-quantity {
						@include respond(largeUp) {
							width: 21%;
						}
					}
					.item-price,
					.item-total {
						@include respond(largeUp) {
							width: 11.5%;
						}
					}

				}
			}
			.name-container {
				.cart-actions-top {
					button {
						&.go-checkout {
							font-size:20px;
						}
						&.dw-apple-pay-button {
							border-radius:0!important;
						}
					}
					a {
						&.cart-action-paypal {
							&.button {
								span {
									font-size:20px;
                                    color: $black;
								}
							}
						}
					}
				}
			}
			.cart-actions {
				&.cart-actions-top {
					.go-checkout {
						@include respond(large) {
							width: 220px;
						}
					}
					.cart-action-paypal {
						@include respond(large) {
							width: 220px;
						}
						img {
							@include respond(large) {
								height: 100%;
							}
						}
					}
				}
				.button {
					font-weight: 400;
				}
				a {
					&.cart-action-paypal {
						span {
							display: inline-block;
							font-size:20px;
							font-weight: 400;
							line-height: 40px;
							vertical-align: top;
						}
						img {
							display: inline-block;
							height: 100%;
							vertical-align: top;
						}
					}
				}
			}

            .footer-bottom {
                .banner-security {
                    float: left;
                    margin: 25px 0 57px;
                    width: 100%;
                    text-align: center;
                    @include respond(small) {
                        margin-bottom: 33px;
                    }
                    @include respond(largeUp) {
                        float: none;
                        display: flex;
                        justify-content: space-between;
                        max-width: 634px;
                        box-sizing: border-box;
                        margin-right: auto;
                        margin-left: auto;
                        padding-right: 2px;
                    }

                    .data-content-asset {
                        & > span {
                            font-weight: bold;
                            text-transform: uppercase;
                            color: $gray-dark;
                            width: 17%;
                            display: inline-block;
                            vertical-align: middle;
                            padding-left: 30px;
                            line-height: 120%;
                            text-align: left;
                            background: url('../images/security-icons.gif') no-repeat left 2px;

                            &:not(.secure):not(.returns):not(.phone) {
                                display: none !important;
                            }

                            @include respond(small) {
                                width: 30%;
                                padding: 30px 1.5% 0;
                                float: left;
                                font-size: 11px;
                                text-align: center;
                            }
                            @include respond(largeUp) {
                                width: initial;
                            }

                            &.secure {
                                @include respond(small) {
                                    background: url('../images/secure-tr-icon.gif') no-repeat center top;
                                }
                            }

                            &.returns {
                                background-position: left center;
                                line-height: 30px;
                                @include respond(small) {
                                    background: url('../images/return-center-icon.gif') no-repeat center top;
                                    line-height: 120%;
                                }
                            }

                            &.phone {
                                background-position: left -55px;

                                span {
                                    font-weight: normal;
                                    color: $gray-warm;
                                    text-transform: none;
                                }

                                @include respond(small) {
                                    line-height: 120%;
                                    background-position: center -57px;
                                    span {
                                        padding: 0;
                                        width: 100%;
                                        float: none;
                                        font-weight: normal;
                                        color: $gray-warm;
                                    }
                                }
                            }
                        }
                    }

                }
            }
		}
	}
}

.pt_cart_main {
    .cart-actions {
        #express-container {
            margin: 10px auto 0;
            max-width: 750px;
            @include respond(standard) {
                margin: 0;
                max-width: initial;
            }
        }
    }
}

.cart-empty {
    font-family: $font-neuzeit;
    h1,
    a,
    button {
        font-family: $font-neuzeit;
    }
}
