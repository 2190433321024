.menu-category--footjoy.level-1 {
	> li > a {
		text-transform: uppercase;
		font-family: $neuzeit;
		font-size: 20px;
		font-weight: bold;
		padding: 5px 25px 0px 25px;
		color: $black;
		letter-spacing: normal;

		@include respond(small) {
			font-family: $neuzeit;
			font-weight: 700;
			font-size: 22px;
			letter-spacing: 0.05em;
			padding: 21px 25px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			text-transform: none;
		}

		@media screen and (min-width: $tablet-landscape + 1) {
			line-height: 65px;
			font-size: 20px;
		}

		@media screen and (min-width: $small-breakpoint + 1) and (max-width: $tablet-landscape) {
			font-size: 14px;
			line-height: 65px;
			padding: 5px 22px 0px 22px;
		}

		&::before {
			@include respond(largeUp) {
				display: none;
			}
		}
	}

	.nav-mobile-only {
		margin-top: 20px;
	}

	.home-button {
		@include respond(small) {
			background: $white;
			border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}
	}

	li.active > a.active {
		@include respond(small) {
			display: none;
		}
	}

	.level-2 {
		.innerWidth {
			padding: 0;
			max-width: none;

			@include respond(largeUp){
				max-height: calc(100vh - 90px);
				padding-left: 15px;
				padding-right: 0;
			}

			@include respond(standard){
				margin-left: 0;
				margin-right: 0;
			}

			@include respond(xlUp){
				margin-right: calc(50% - 50vw);
			}

			@media (min-width: 1570px) {
				margin-left: calc((100vw - 1570px) / 2);
			}
		}
	}
}

nav[role="navigation"] {
	@extend .clearfix;
	color: $black;
	margin: 0;
	width: 100%;
	height: 100vh;
	top: 52px; //due to being relative from the top black banner
	float: none;

	@include respond(small){
    	left: -100%;
		position: fixed;
		top: 73px;
    	height: 100vh;
    	overflow-y: auto;
    	background: $white;
    	padding-bottom: 20px;
		display: block;
		z-index: 99;
		.menu-active & {
			display: block;
		}
	}

	@include respond(largeUp) {
		color: $black;
		float: left;
		left: initial;
		top: initial;
		position: initial;
		height: auto;
		width: auto;
		border: 0;
		min-width:475px;
		overflow:hidden;
		margin-left: auto;
		margin-right: auto;
	}
	.primary-logo {
		margin: 0;
		padding: 15px 20px;
		height: 66px;
		background: $white;
		width: 100%;
		float: left;
		clear: both;
		&.mobile-only {
			@include respond(largeUp) {
				display: none;
			}
		}
	}
	.menu-category{
		@include respond(small){
			z-index: 100;
			clear: both;
		}
		@include respond(large){
			z-index: 100;
			clear: both;
		}
		&.level-1 {
			.myjoys-nav-item {
				display: flex;
				align-items: center;
				height: 67px;

				@include respond(largeUp) {
					height: 75px;
				}

				.myjoys-nav-img {
					height: auto;
					max-width: 100%;
					width: 70px;
					position: relative;
					top: 4px;
					@include respond(largeUp) {
						width: 80px;
						top: 6px;
					}
					& ~ .myjoys-nav-title {
						display: none;
					}
				}
			}
		}
		.level-2{
			padding-top:0px;
			z-index: -1;
		}
	}
}

// Mobile Menu Toggle
.menu-toggle, .mobile-hamburger-toggle {
    border-right: 1px solid $gray-light;
	display: block;
    cursor: pointer;
    width: 48px;
	height: 48px;
	position: relative;
	margin: 0;
	transform: rotate(0deg);
	transition: $quickSpeed;
    outline: none;
    top: 0;

	@include respond(largeUp) {
		display: none;
	}

	span {
		background: $black;
		height: 2px;
		position: absolute;
		left: 10px;
		transition: all 0.3s;
		transform-origin: 0 50%;

		&:nth-child(1) {
            top: 15px;
            width: 28px;
		}
		&:nth-child(2) {
            top: 23px;
            width: 20px;
		}
		&:nth-child(3) {
            top: 31px;
            width: 28px;
		}
	}

	&.open, &.active {
		span {
			left: 14px;

			&:nth-child(1) {
				top: 14px;
				transform: rotate( 45deg );
			}

			&:nth-child(2) {
				width: 0;
				top: 24px;
			}

			&:nth-child(3) {
				top: 34px;
				transform: rotate( -45deg );
			}
		}
	}
}

.menu-category, .playersSlot ul {
	@extend %clearfix;
	list-style: none;
	margin: 0;
	padding: 0;
    text-transform: uppercase;

	@include respond(largeUp) {
		margin: 0;
		width: 100%;
    }

	@include respond(large){
		padding-right: 0;
		display:none;
	}
	@include respond(small){
		.home-button {
			display:none;
			position: relative;
			padding: 11px 20px;
			margin: 0;
			cursor:pointer;

			&.hideme {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.home-button__btn {
				border: none;
				background: none;
				color: #808080;
				font-family: $neuzeit;
				text-transform: none;
				letter-spacing: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: normal;
				padding: 0;

				&::before {
					content: '<'
				}
			}

			.home-button__category-name {
				display: flex;
				align-items: center;
				font-family: $neuzeit;
				font-weight: 700;
				font-size: 24px;
				line-height: 29px;
				color: $dark;

				img {
					max-width: 100px;
				}

				.myjoys-nav-title {
					display: none;
				}
			}

			.home-button__view-all {
				display: inline-block;
				width: auto;
				padding: 10px 16px;
				border: 1px solid;
				border-radius: 2px;
				font-family: $neuzeit;
				color: $dark;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;

				&:hover {
					background: $dark;
					color: $white;
				}

				&::after {
					display: none;
				}
			}
		}
	}
	li {
		list-style: none;

		@include respond(small){
			background: $white;
			display:block;

			&.active {
				display:block;
			}
			&.hideme {
				display:none;
			}
		}
		@include respond(largeUp) {
			border: none;
			float: left;
			border: 0;
			letter-spacing:1.64px;
			height: 75px;
			&:last-child{
				border: 0;
			}
			&:hover > a {
				background-color: transparent;
			}
		}

	}
	a {
		color: $dark;
		font-family: $din-condensed;
		font-weight: 700;
		font-size: 16px;
    	padding: 0.9rem 0;
		display: block;
		width: 100%;
	}
    &.custom-menu {
        &.players-community-menu {
            .level-2 {
                .innerWidth {
                    @include respond(large){
                        padding: 0 45px;
                    }
                    @include respond(standard){
                        padding: 0 75px;
                    }
                    .megamenu-col {
                        @extend %clearfix;
                        max-width: 48%;
                        &.megamenu-col-left {
                            float: left;
                        }
                        &.megamenu-col-right {
                            float: right;
                        }
                        h1 {
                            font-family: $titillium;
                            font-weight: 700;
                            letter-spacing: 0;
                            margin-bottom: 4px;
                        }
                        .menu-item-image {
                            max-width: 100%;
                            width: 425px;
                            img {
                                max-width: 100%;
                            }
                        }
                        .level-3 {
                            margin-bottom: 15px;
                            li {
                                a {
                                    font-size: 16px;
                                    margin: 0;
                                    padding: 2px 0 8px ;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

// Level 1's
.level-1 > li {

	&:first-child {
		border-top: none;
	}
	&:hover{
		> a{
			//border-color: $black;
		}
	}
	&.open {
		 a{
		 	@include respond(largeUp) {
				border-color: $black;
			}
		}
	}

	&.active{
		i.menu-item-toggle-nav{
			@include respond(small) {
				//open
				transform: rotate(45deg);
			}
		}
	}

	a {
		line-height: 2rem;
		@include respond(small) {
			line-height:24px;
			letter-spacing:normal;
		}
		transition: background-color $quickSpeed, color $quickSpeed;
		&:hover {
			background-color: transparent;
			&.special-1857 {
				border-bottom:5px solid $black;
			}
		}
		@include respond(largeUp) {
			&:hover {
				background-color: transparent;
			}
		}
		img {
			&.help-icon {
				margin-top:-5px;
				margin-right:5px;
				vertical-align:middle;
			}
		}
		&.special-1857 {
			font-size:25px;
			letter-spacing:normal;
			img {

				@include respond(largeUp) {
					padding-bottom: 7px;
				}
			}
		}
	}
	> a {
		display: block;
		padding: 18px 20px 16px;
		position: relative;

		@include respond(small) {
			letter-spacing: 0.05em;
			padding: 21px 25px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}

		@include respond(largeUp) {
			border-top: none;
			float: none;
			padding: 5px 2vw 0px;
			margin: 0 1rem;
			width: auto;
			box-sizing: border-box;
			border-bottom: 5px solid transparent;
			font-size: 18px;
			line-height: 52px;
			font-weight: 400;
			text-transform: uppercase;

			&.has-sub-menu {
				width: auto;
			}

			&:hover {
				background-color: transparent;
				color: $black;
				border-color: $black;
				border-top: none;
			}
		}

		&:hover {
			background-color: $gray-cirlce;

			@include respond(largeUp) {
				background-color: transparent;
			}
		}

		&::after {
			content: "";
			position: absolute;
			width: 8px;
			height: 8px;
			top: 50%;
			right: 20px;
			border-top: 4px solid $dark;
			border-right: 4px solid $dark;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: 50% 50%;
			transition: transform .5s;

			@include respond(largeUp) {
				display: none;
			}
		}

		&.has-sub-menu {
			//width: calc(100% - 3.3rem);
			//padding: 0em 20px 0 20px;
			//padding: 1.5rem 1rem 1.5rem 1rem;
			margin: 0;
			@include respond(small) {
				//width: 100%;
				//padding-top: 0;
				//padding-bottom: 0;
				//height: 53px;
				//line-height: 53px;
				//background: $white;
			}
			@include respond(large) {
				//padding: 0 0.63rem 0 0.63rem;
			}
		}

	}
}

.level-2 {
	clear: left;
	display: none;
	z-index: 1;

	@include respond(largeUp){
		box-shadow: 0 2px 6px 0 rgba(0,0,0,.5);
	}

	.level-1 > .active & {
		display: block;
		@include respond(largeUp){
			//if somethings open in mobile and then the browser gets wide...
			display: none; //ignore the .active class possibly set by javascript
		}
	}

	ul {
		@extend .clearfix;
		padding: 0;
		background-color: $white-smoke;
		border-top: 1px solid $white-smoke;
		@include respond(small){
			background: none;
		}
		> li {
			border: 0;
			&:last-child{
				border: 0;
			}
			h3{
				font-size: 14px;
			}
			@include respond(small){
				a {
					padding-left:30px;
					border-top: 2px solid $gray-darker;
					position:relative;
					&::after {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						top: 50%;
						right: 20px;
						border-top: 2px solid #68a1b8  ;
						border-right: 2px solid #68a1b8  ;
						transform: translateY(-50%) rotate(45deg);
						transform-origin: 50% 50%;
						transition: transform .5s;
					}
				}
			}
		}
	}
	.subNavBanners{
		@include respond(largeUp) {
			display: flex;
			flex: 0 0 calc(100% - 230px);
			width: calc(100% - 230px);
		}

		img{
			display: block;
		}

		.fly-out-banner {
			margin:10px;
			max-width:160px
		}
	}

	@include respond(largeUp) {
		width: 100%;
		position: absolute;
		left: 0px;
		top: 60px;
		background-color: $white;
		box-shadow: 0 3px 3px 2px rgba($gray-dark, 0.3);
		color: $black;
		padding: 0;
		z-index: 2;
		clear: none;

		.innerWidth{
			padding:50px 20px;
			@include respond(standard){
				max-width: 1400px;
			}
			@include respond(largeUp){
				max-height: 400px;
			}
		}

		ul {
			float: left;
			padding-left: 0;
			background-color: transparent;
			border-top: 0;
			margin-right: 2.5rem;

			> li {
				float: left;
				clear: left;
				min-width: 12rem;
				border: 0;
				background-color: transparent;

				&.bottomBorder{
					padding-bottom: 1em;
					border-bottom: 1px solid $gray-light;
				}

				> a {
					padding: 1rem 0 0 0;
					text-transform: uppercase;
					background-color: transparent;

					&.withArrow{
						font-size: 14px;

						&:after{
							content: '>>';
						}
					}
				}
			}
			&#cat-nav-shoes, &#cat-nav-apparel {
				h3 {
					margin-bottom: 0;
				}
				.cat-nav-link {
					padding-top: .75rem;
				}
			}
			&#cat-nav-gloves, &#cat-nav-gear, &#cat-nav-socks {
				h3 {
					margin-bottom: .5rem;
				}
			}
		}
		.mobileSubnav{
			display: none;
		}
	}
}


/*custom my joys level-2 styles */
#cat-nav-myjoys {
	margin-right: 0;
	li {
		float: left;
		min-width: initial;
		width: 100%;
		h3 {
			margin-bottom: 1rem;
		}
		ul {
			li {
				clear: none;
				float: left;
				min-width: initial;
				width: auto;
			}
			&.level-3 li a {
				padding: 0 1rem 5px;
				&:first-of-type {
					padding-right: 1.25rem;
					padding-left: 0;
				}
			}
		}
		button {
			padding: 0 2em;
		}
		&.collegiate-links {
			padding-bottom: .5em;
			ul {
				margin: 1rem 0 0 0;
				li {
					min-width: 74.4px;
					@include respond(large){
						min-width: 65px;
					}
					a {
						color: $gray-warm;
						font-size: .75rem;
						font-weight: normal;
						height: 50px;
						&:hover {
							color: $gray-dark;
						}
					}
					#collegiate1 {
						background: url('../images/myjoysnav/alabama-grayscale.png') no-repeat;
						&:hover {
							background: url('../images/myjoysnav/alabama-color.png') no-repeat;
						}
					}
					#collegiate2 {
						background: url('../images/myjoysnav/auburntigers-grayscale.png') no-repeat 5px 0px;
						&:hover {
							background: url('../images/myjoysnav/auburntigers-color.png') no-repeat 5px 0px;
						}
					}
					#collegiate3 {
						background: url('../images/myjoysnav/bostoneagles-grayscale.png') no-repeat 10px 0px;
						&:hover {
							background: url('../images/myjoysnav/bostoneagles-color.png') no-repeat 10px 0px;
						}
					}
					#collegiate4 {
						background: url('../images/myjoysnav/calberkeleygoldenbears-grayscale.png') no-repeat 15px 0px;
						&:hover {
							background: url('../images/myjoysnav/calberkeleygoldenbears-color.png') no-repeat 15px 0px;
						}
					}
					&:last-of-type a {
						padding-right: 0;
						text-align: right;
					}
				}
			}
		}
		&.mlb-links {
			padding-bottom: .5em;
			ul {
				margin: 1rem 0 0 0;
				li {
					min-width: 74.4px;
					@include respond(large){
						min-width: 65px;
					}
					a {
						color: $gray-warm;
						font-size: 12px;
						font-weight: normal;
						height: 50px;
						&:hover {
							color: $gray-dark;
						}
					}
					#mlb1 {
						background: url('../images/myjoysnav/bostonredsox-grayscale.png') no-repeat;
						&:hover {
							background: url('../images/myjoysnav/bostonredsox-color.png') no-repeat;
						}
					}
					#mlb2 {
						background: url('../images/myjoysnav/chicagocubs-grayscale.png') no-repeat 5px 0px;
						&:hover {
							background: url('../images/myjoysnav/chicagocubs-color.png') no-repeat 5px 0px;
						}
					}
					#mlb3 {
						background: url('../images/myjoysnav/chicagowhitesox-grayscale.png') no-repeat 10px 0px;
						&:hover {
							background: url('../images/myjoysnav/chicagowhitesox-color.png') no-repeat 10px 0px;
						}
					}
					#mlb4 {
						background: url('../images/myjoysnav/cincinnatireds-grayscale.png') no-repeat 15px 8px;
						&:hover {
							background: url('../images/myjoysnav/cincinnatireds-color.png') no-repeat 15px 8px;
						}
					}
					&:last-of-type a {
						padding-right: 0;
						text-align: right;
					}
				}
			}
		}
		&.foldsofhonor-links {
			ul {
				margin-right: 0;
			}
			#vet1 {
				display: inline-block;
				margin: 1rem 1rem 0 0;
				min-width: 50px;
				height: 50px;
				background: url('../images/myjoysnav/foldsofhonor-grayscale.png') no-repeat;
				&:hover {
					background: url('../images/myjoysnav/foldsofhonor-color.png') no-repeat;
				}
			}
			.folds-of-honor-text {
				color: #c8c8c8;
				float: left;
				line-height: 1.2em;
				margin: 4px 0 0 5px;
				text-transform: none;
				width: 250px;
			}
		}
	}
}
ul.level-3 {
	float: none;
	color: $gray-warm; //#999
	padding-left: 0;
	@include respond(small){
		display: none; //no show in mobile menu!(?)
	}

	.level-2 .active & {
		display: block;
	}
}

.nav-mobile-only {
	@include respond(largeUp){
		display: none;
		&.home-button {
			display: none!important;
		}
	}

	a {
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 21px 25px;
		font-family: $neuzeit;
		font-weight: 300;
		font-size: 16px;
		text-transform: none;
	}
}

.nav-mobile-first {
	display: none;
	a {
		font-weight: normal;
		height: 30px !important;
		border: none;
	}
	@include respond(small){
		display: block;
	}
}
.playersSlot{
	margin-top: 1rem;
	float: left;
	box-sizing: border-box;
	padding-left: 1rem;
	margin-left: 23px;
    &::before {
        content: '';
        display: block;
        width: 2px;
        background: $gray-light;
        height: 26px;
        position: absolute;
        top: 27px;
    }
	ul{
		margin: 0;
		padding: 0;
		li a{
			padding: 1rem 20px 3rem 10px;
			margin: 0 0 0 32px;
		}
	}
	@include respond(large){
		padding-left: 0;
		margin-left: 0;
		ul li a{
            padding: 1rem 5px 3rem 0;
            margin: 0 0.3rem 0 1rem;
		}
	}
	@include respond(small){
		display: none;
	}
}

/* Updated Navigation Styles 10/2018 */

.desktop-menu-link-groups {
	overflow: hidden;
	float:left;
	width:40%;
	.link-group {
		float: left;
		padding-right: 25px;
		margin-bottom: 35px;
		max-width:185px;
		&:first-child {
			margin-bottom: 40px;
		}

		* {
			display: block;
			/*line-height: 25px;*/
			line-height: 1;
			max-width:185px;
		}
		a {
			&:hover {
				text-decoration:underline;
			}
		}
		p {
			&.link-text {
				padding-top:14.4px;
			}
		}
		.link-group-head {
			font-size: 24px;
			padding: 5px 0;
			padding-top:0;
		}

		.link-group-link {
			font-size: 16px;
			letter-spacing: 1.2px;
			line-height:25px;
			padding:0;

			&:hover {
				text-decoration: underline;
			}
		}

		.link-group-divider {
			width: 80px;
			margin: 8px 0 15px 0;
			border-top: solid 2px rgba( $black, 0.3 );
		}

		.link-group-spacer {
			height: 25px;
			@include respond(large) {
				height: 27px;
			}
		}
	}
}

.desktop-menu-promos {
	display: flex;
	float: left;
	overflow: hidden;
	max-width:60%;
	@include breakpoint( $standard-breakpoint ) {
		max-width: 60%
	}

	.desktop-menu-promo {
		display: block;
		float: left;
		max-width: 250px;

		&:hover > .desktop-menu-promo-title {
			text-decoration: underline;
		}
		&:hover > .desktop-menu-promo-text {
			text-decoration: none;
		}

		& + .desktop-menu-promo {
			/*margin-left: 25px;*/
		}

		img {
			width: 100%;
		}

		.desktop-menu-promo-title {
			font-size: 20px;
			padding: 5px 0;
			letter-spacing:normal;
			line-height:normal;
		}

		.desktop-menu-promo-text {
			font-size: 15px;
			color: $gray-strong;
			font-family: $font-small;
			text-transform: none;
			letter-spacing:normal;
			line-height:normal;
			font-weight:100;
			text-decoration:none;
		}
	}
}
.desktop-menu-promo {
	display: block;
	float: left;
	max-width: 275px;

	&:hover > .desktop-menu-promo-title {
		text-decoration: underline;
	}
	&:hover > .desktop-menu-promo-text {
		text-decoration: none;
	}
	& + .desktop-menu-promo {
		margin-left: 25px;
	}

	img {
		width: 100%;
	}

	.desktop-menu-promo-title {
		font-size: 20px;

		padding: 5px 0;
	}

	.desktop-menu-promo-text {
		font-size: 16px;
		color: $gray-strong;
		font-family: $font-small;
		text-transform: initial;
		line-height:1;
	}
}

#wrapper {
	&.menu-active {
		#navigation {
			height:90%;
		}
	}
}
.special-1857 {
	&.desktop-only {
		display:block;
	}
	@include respond(small){
		&.desktop-only {
			display:none;
		}
	}
}

.desktop-menu-1857 {
	overflow: hidden;
	text-align: center;
	float:left;
	width:40%;
	@include breakpoint( $standard-breakpoint ) {
		max-width: 40%
	}
	a {
		font-size: 28px;
		display: inline-block;
		padding-bottom: 2px;
		text-align: center;
		border-bottom: none;
		img {
			width: 80px;
			display: block;
			padding-bottom: 50px;
			margin: 0 auto;
		}
	}
}


@include respond(large) {
	#wrapper {
		header {
			&.header {
				.header-main {
					#navigation {
						ul {
							&.menu-category {

								&.level-1 {
									display:block!important;
									background-color:#fff;
									li {
										a {
											white-space:nowrap;
											overflow:visible;
											@include respond(largeUp){
												height: inherit;
											}
											&.desktop-menu-promo {
												white-space:normal;
											}
											&.special-1857 {
												line-height:64px;
												@include respond(largeUp){
													position: relative;
													line-height: 1;
													margin-top: 20px;
													font-size: 22px;
													padding:0;
													height: 40px;
												}
											}
										}
										.level-2 {
											.link-group {
												width:initial;
												padding:0;
												margin-right:5px;
											}
											.desktop-menu-link-groups {
												width:40%;
												padding-left: 0px;
												@include respond(large){
													width:47%;
													&.desktop-menu-1857 {
														max-width: 45%;
													}
												}
												.link-group {
													min-width:100px;
													@include respond(large){
														&:first-child {
															padding-left: 20px;
														}
													}												}
											}
											a {
												&.link-group-head {
													font-size:24px;
													padding-bottom:15px;
												}
												&.link-group-link {
													font-size:16px;
													line-height:25px;
												}
												.desktop-menu-promo-text {
													font-size:16px;
													line-height:16px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.level-2__category {
	flex: 1 1 100%;
	display: flex;
}

.level-2__left {
	@include respond(largeUp){
		flex: 0 0 230px;
		padding-right: 20px;
		padding-top: 50px;
		max-height: calc(100vh - 145px);
		overflow-y: auto;
	}
}

.level-2__promo__mobile {
	margin-bottom: 30px;
}

.level-2__subcategories {
	.subcategories__col + .subcategories__col {
		@include respond(largeUp){
			margin-top: 35px;
		}
	}

    .data-content-asset + .data-content-asset {
        @include respond(largeUp) {
            margin-top: 35px;
			display: flex;
        }
    }
}

.level-2__subcategories--with-border {
	@include respond(largeUp){
		border-right: 1px solid #C4C4C4;
	}
}

.megamenu-content {
	@include respond(largeUp){
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.subcategories__list__wrapper {
	@include respond(small) {
		overflow: hidden;
		height: auto;
		max-height: 2000px;
		transition: max-height, padding 0.2s;
		padding-bottom: 20px;
	}

	&.collapsed {
		@include respond(small) {
			max-height: 0;
			padding-bottom: 0;
		}
	}

	+ .subcategories__name {
		@include respond(largeUp){
			margin-top: 26px;
		}
	}
}

.subcategories__col {
	flex: 1;

	.subcategories:not(.subcategories--no-border) {
		@include respond(small) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}
	}

	.subcategories + .subcategories {
		@include respond(largeUp){
			margin-top: 35px;
		}
	}
}

#wrapper header.header .header-main #navigation {
	.level-2 {
		font-family: $neuzeit;
		letter-spacing: normal;
	}

	ul.menu-category.level-1 li {
		&.menu-category__account {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 20px;
			border-top: 1px solid rgba(0, 0, 0, 0.15);
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);

			@include respond(tablet) {
				justify-content: center;
				gap: 40px;
			}

			@include respond(largeUp) {
				display: none;
			}

			.menu-category__account__title {
				margin: 0;
				padding: 0;
				color: #808080;
				font-family: $neuzeit;
				font-size: 16px;
				font-weight: 400;
				line-height: 36px;
				text-transform: none;
			}

			.menu-category__account__links {
				font-family: $neuzeit;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				text-transform: none;
				color: $dark;
			}

			.menu-category__account__links__link {
				display: inline-block;
				background: none;
				padding: 0;
				width: auto;
				height: auto;
				border: none;
				font-family: $neuzeit;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 0.05em;
				text-transform: none;
				text-decoration: underline;
				color: $dark;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&.subcategories__list__item {
			float: none;
			clear: none;
			height: auto;
			min-width: 0;
			margin: 4px 0;
			padding: 0;
			border: none;

			@include respond(largeUp){
				margin: 0;
			}

			> .subcategories__sub-name {
				@include respond(small){
					margin-top: 30px;
				}
			}

			.subcategories__list__item__link {
				display: flex;
				align-items: center;
				gap: 16px;
				letter-spacing: normal;
				min-width: 0;
				padding: 0 25px;
				font-size: 16px;
				font-weight: 400;
				line-height: 30px;
				color: $dark;
				font-family: $neuzeit !important;
				text-transform: none;
				border: none;

				@include respond(largeUp){
					padding: 0;
				}

				&::after {
					@include respond(small){
						display: none;
					}
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.subcategories__name__link {
			font-weight: 700;
			font-size: 22px;
			line-height: 30px;
			padding: 0;
			margin: 0;
			font-family: $neuzeit;
			text-transform: none;

			@include respond(small) {
				display: block;
				width: 100%;
				height: 100%;
				padding: 0;
				font-size: 22px;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.level-2__category__view-all {
			flex: 1 1 100%;
			margin-bottom: 35px;
			padding-left: 55px;

			@include respond(small){
				display: none;
			}

			@include respond(xlUp){
				padding-left: 75px;
			}

			.level-2__category__view-all__link {
				display: inline-block;
				margin: 0;
				padding: 10px 16px;
				width: auto;
				font-family: $neuzeit;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				color: $dark;
				border: 1px solid;
				border-radius: 2px;

				&:hover {
					color: $white;
					background: $dark;
					border-color: $dark;
				}
			}
		}

		.subcategory__promotion {
			text-align: center;
			margin: 0;
			text-transform: none;
			font-family: $neuzeit;
			font-weight: 700;
			font-size: 16px;
			line-height: 36px;
			color: $dark;

			.subcategory__promotion__link {
				display: inline;
				padding: 0;
				font-family: $neuzeit;
				font-size: 16px;
				font-weight: 400;
				line-height: normal;
				text-transform: none;
				text-decoration: underline;

				@include respond(small){
					display: block;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

		.subcategories__list__item__link--mobile-only {
			@include respond(largeUp){
				display: none;
			}
		}

		.subcategories__name {
			position: relative;
			margin: 0 0 10px;
			padding: 0;
			font-family: $neuzeit;
			font-weight: 700;
			font-size: 22px;
			line-height: 30px;
			letter-spacing: 0.0125em;
			text-transform: none;

			@include respond(small) {
				margin-bottom: 0;
				padding: 19px 35px 19px 25px;
			}
		}

		.subcategories__sub-name {
			font-family: $neuzeit;
			font-weight: 700;
			font-size: 16px;
			line-height: 30px;
			letter-spacing: normal;
			padding: 4px 25px;
			margin: 0;
			text-transform: none;

			@include respond(largeUp){
				padding: 0;
			}
		}

		.level-2__category-name {
			font-family: $neuzeit;
			font-size: 48px;
			font-weight: 700;
			line-height: 58px;
			letter-spacing: 0.0125em;
			padding: 0;
			margin: 0 0 28px;

			@include respond(small){
				display: none;
			}

			img {
				max-width: 150px;
			}

			.myjoys-nav-title {
				display: none;
			}
		}

		.subcategory-banner__badge {
			position: absolute;
			left: 40px;
			top: 38px;
			padding: 10px 16px;
			background-color: $black;
			color: $white;
			font-size: 11px;
			text-transform: uppercase;
		}

		.subcategory-banner__details {
			padding: 25px;

			@include respond(largeUp) {
				position: absolute;
				left: 0;
				bottom: 0;
				padding: 40px;
			}

			.subcategory-banner__product-name {
				margin-bottom: 10px;
			}

			.subcategory-banner__product-name__link {
				display: inline;
				margin: 0;
				padding: 0;
				font-family: $neuzeit;
				font-size: 24px;
				line-height: 30px;
				font-weight: 700;
				text-transform: none;

				&:hover {
					text-decoration: underline;
				}
			}

			.subcategory-banner__description {
				font-size: 16px;
				line-height: 19px;
				text-transform: none;
				margin: 10px 0 30px;
			}

			.subcategory-banner__cta {
				display: inline-block;
				margin: 0;
				padding: 10px 16px;
				width: auto;
				border: 1px solid;
				border-radius: 2px;
				font-family: $neuzeit;
				font-size: 14px;
				font-weight: 700;
				line-height: 17px;
				text-transform: uppercase;

				&:hover {
					background: $dark;
					color: $white;
					border-color: $dark;
				}
			}
		}

		.level-2__promotion {
			padding: 20px 0;
			border-top: 1px solid #C4C4C4;

			@include respond(largeUp){
				margin-left: -20px;
			}

			@include respond(xlUp){
				padding: 13px 0;
			}

			@media (min-width: 1560px) {
				margin-left: calc((100vw - 1530px) / -2);
			}
		}

		.subcategories__name__toggle-btn {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: none;
			border: none;
			padding: 0 30px 0 25px;
			margin: 0;

			@include respond(largeUp){
				display: none !important;
			}

			&::after {
				content: "—";
				display: block;
				position: absolute;
				left: initial;
				right: 20px;
				top: 50%;
				background: none;
				transform: translateY(-50%);
				font-size: 30px;
			}

			&.collapsed {
				&::after {
					content: "+";
				}
			}
		}

		.subcategory-banner__collection {
			&.slick-initialized {
				margin-bottom: 0;

				@include respond(largeUp){
					height: 100%;
				}

				.slick-track,
				.slick-list {
					@include respond(largeUp){
						height: 100%;
					}
				}
			}

			.slick-arrow:not(.fj-rp-slider-arrow) {
				width: 60px;
				height: 60px;
				background: $white;
				border-radius: 3px;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border: none;
				opacity: 1;

				@include respond(small) {
					top: 35%;
				}

				&:hover {
					background: $white !important;
				}

				&::after {
					content: '';
					position: absolute;
					display: block;
					left: calc(50% + 4px);
					top: 50%;
					width: 14px;
					height: 14px;
					border-top: 4px solid $dark;
					border-left: 4px solid $dark;
					transform: translateX(-50%) translateY(-50%) rotate(-45deg);
				}
			}

			.slick-arrow:not(.fj-rp-slider-arrow).slick-prev {
				display: none !important;

				&.active {
					display: block !important;
				}
			}

			.slick-arrow:not(.fj-rp-slider-arrow).slick-next {
				left: initial;
				right: 20px;

				&.hidden,
				&.slick-disabled {
					display: none !important;
				}

				&::after {
					left: calc(50% - 3px);
					transform: translateX(-50%) translateY(-50%) rotate(135deg);
				}
			}
		}

		.header__insider {
			margin-top: 20px;
			margin-bottom: 20px;
			padding-top: 20px;

			@include respond(small){
				margin-top: 40px;
				margin-bottom: 40px;
				padding-top: 40px;
				padding-left: 25px;
				padding-right: 25px;
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}
		}

		.header__insider__logo {
			margin-bottom: 10px;
		}

		.header__insider__title {
			margin: 0 0 16px;
			text-transform: none;
			font-size: 24px;
			font-weight: 700;
			line-height: 29px;
			color: $dark;
		}

		.header__insider__description {
			margin: 0 0 16px;
			text-transform: none;
			font-size: 16px;
			font-weight: 400;
			line-height: 19px;
			color: $dark;
		}

		.header__insider__buttons {
			display: flex;
			gap: 10px;
		}

		.header__insider__buttons__button {
			padding: 10px 16px;
			height: auto;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			font-family: $neuzeit;
			letter-spacing: 0.0125em;
			color: $dark;
			background: $white;
			border: 1px solid;
			border-radius: 3px;
			text-decoration: none;

			&:hover {
				color: $white;
				background: $dark;
				border-color: $dark;
			}
		}

		.header__insider__buttons__button--sign-up {
			color: $white;
			background: $dark;
			border-color: $dark;

			&:hover {
				color: $dark;
				background: $white;
			}
		}

		.level-2 ul.subcategories__list {
			float: none;
			margin: 0;
			padding: 0;
			border: none;

			+ .subcategories__sub-name {
				margin-top: 35px;

				@include respond(largeUp){
					margin-top: 26px;
				}
			}
		}
	}
}

.subcategories__wrapper {
	display: none;

	@include respond(largeUp){
		display: block;
		padding-top: 55px;
		flex: 1;
	}
}

.subcategories__cols__wrapper {
	@include respond(largeUp){
		flex: auto;
		display: flex;
		flex-wrap: wrap;
		align-content: start;
		padding-left: 55px;
	}

	@include respond(xlUp){
		padding-left: 75px;
	}
}

.subcategories__cols__wrapper--with-border {
	@include respond(largeUp){
		border-left: 1px solid #C4C4C4;
	}
}

.subcategory-banner__collection:not(.slick-initialized) {
	.subcategory-banner {
		margin-right: 0;
	}
}

.subcategory-banner {
	margin-right: 16px;

	@include respond(small) {
		margin-top: 40px;
		max-width: 267px;
	}

	@include respond(largeUp) {
		max-width: none;
		margin-right: 24px;
		max-height: calc(100vh - 145px);
	}

	@include respond(xlUp) {
		margin-right: 16px;
	}

	&:last-child {
		margin-right: 0;
	}

	&.display-desktop-only {
		display: none;

		@include respond(standard){
			display: block;
		}
	}
}

.subcategory-banner__collection {
	display: flex;
	gap: 15px;
	justify-content: end;

	@include respond(largeUp){
		margin-top: 0;
		margin-left: auto;
	}

	&.slick-initialized {
		overflow: hidden;
	}
}

.subcategory-banner__wrapper {
	position: relative;

	@include respond(largeUp){
		height: 100%;
	}
}

.subcategory-banner__wrapper .subcategory-banner__image {
	@include respond(largeUp){
		height: 100%;
	}

	img {
		width: 100%;
		height: auto;

		@include respond(largeUp){
			width: auto;
			height: 100%;
		}
	}
}

.data-content-asset {
	display: contents;

    &.slick-slide {

        .js-subcategory-banner {

            float: left;
            height: 100%;
            min-height: 1px;

            &.subcategory-banner {
                margin-right: 16px;

                @include respond(small) {
                    margin-top: 40px;
                    max-width: 267px;
                }

                @include respond(largeUp) {
                    max-width: none;
                    margin-right: 24px;
                    max-height: calc(100vh - 145px);
                }

                @include respond(xlUp) {
                    margin-right: 16px;
                }

                &.display-desktop-only {
                    display: none;

                    @include respond(standard) {
                        display: block;
                    }
                }
            }
        }
    }
}

.js-subcategory-banner__collection {
    .data-content-asset {
        &:last-child {
            .js-subcategory-banner {
                margin-right: 0;
            }
        }
    }
}
