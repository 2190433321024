///////////////////////
/// Styles for Shopping Cart page
///////////////////////
.fj_account {
	#wrapper.pt_account #main #primary {
		.golfer-preferences {   
			width: 100%;
			margin-top: 26px;
			@include respond(largeUp) {
				width: 420px;
			}         
			h2, h3, h4, h5, p, a {
				color: $black;
				font-family: $neuzeit;
				text-transform: none;
				letter-spacing: normal;
			}
			h2,
			h3,
			h4 {
				font-weight: 700;
			}
			h2 {
				font-size: 24px;
				margin-bottom: 15px;
			}
			h3 {
				font-size: 20px;
				padding: 10px 0;
			}
			p,
			h4 {
				font-size: 16px;
				width: 100%;
			}
			.form-text {
				font-size: 14px;
				font-weight: 700;
			}
			.select-all {
				margin: -25px 0 25px;
			}
			.p-extra {
				margin-bottom: 30px;
			}
			.form-section,
			h4 {
				margin-bottom: 30px;
			}
			.section-climnate {
				margin-bottom: 55px;
			}
			button {
				margin-bottom: rem(40px);
			}
			input[type="radio"],
			input[type="checkbox"] {
				position: absolute;
				left: -9999px;
				top: auto;
				width: 1px;
				height: 1px;
				overflow: hidden;
			}
			input[type="radio"] + label {
				background: url('../images/radio_unchecked_pref.svg') left 0 no-repeat;
				background-size: 20px 20px;
				span {
					font-weight: 400;
				}
			}
			input[type="radio"]:checked + label {
				background:url('../images/radio_checked_pref.svg') left 0 no-repeat;
				background-size: 20px 20px;
			}
			.sizing-section {
				h4 {
					margin-bottom: 20px;
					&.pref-hdr {
						margin-bottom: 35px;
					}
				}
				.size-header {
					display: flex;
					.size-guidelnk {
						text-align: right;
						min-width: rem(120px);
						font-size: rem(16px);
						font-weight: 400;
					}
				}
				.sizing-prefs-women {
					display: none;
				}
				&.prefs-sizes-women {					
					.sizing-prefs-men {
						display: none;
					}					
					.sizing-prefs-women {
						display: block;
					}
				}
				&.prefs-sizes-men {
					.sizing-prefs-women {
						display: none;
					}					
					.sizing-prefs-men {
						display: block;
					}
				}
			}
			.radio-row {
				width: 100%;
				padding-bottom: 13px;
				label {
					padding-left: rem(29px);
					color: $black;
					font-size: rem(14px);
					line-height: rem(21px);
				}
			}
			.check-row {
				.check-btn {
					display: none;
				}
				label {
					display: inline-block;
					margin: 0 4px 8px 0;
				}
				input.check-btn + span {
					display: block;
					padding: 12px 18px;
					color: $gray-dark;
					border: solid 1px $gray-light;
					border-radius: 2px;	
					background-color: $white;				
				}
				input.check-btn:checked + span {
					color: $white;
					border-color: #a2c3b8;	
					background-color: $green-light;
				}
                &.check-row-kr {
                    display: grid; 
                    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); 
                    grid-column-gap: 4px;
                    label {
                        display: block;
                        text-align: center;
                    }
                }
			}
			.select-row {
				display: flex;
				select {
					padding: 0 32px 0 12px;
					background: url("../images/interface/icon-select-arrow-black.svg") no-repeat right 12px center;
					background-size: 18px 11px;
					font-family: $neuzeit;
					font-size: 15px;
					letter-spacing: normal;
					border-radius: 0;
					height: 42px;
					background-color: transparent;
					border-width: 1px;
				}
			}
			.two-up {
				.twoup-lable {
					width: 100%;
					&:first-child {
						margin-right: 20px;
					}
				}
				select {
					&:first-child {
						margin-right: 20px;
					}
				}
			}
			.select-space {
				margin-right: 20px;
			}
			.three-up {
				flex-basis: 0;
				select {
					flex: 1;
				}
				.grow-select {
					flex: 2;
				}
			}
			.shoe-hdr {
				margin-top: 50px;
			}
			.shoe-recommendation {
				margin: 30px 0 40px;
				display: flex;				
				.reco-info {
					position: relative;
					margin-left: 20px;					
					width: 100%;
					.reco-date {
						position: absolute;
						top: 0;
						right: 0;
						color: $gray-dark;
						font-size: 12px;
					}
					.reco-productname {
						text-decoration: none;
					}
					.reco-tag {
						margin: 30px 0 8px;
					}
					a {
						font-weight: 700;
					}
				}
				.reco-info,
				.reco-info a {
					font-size: 16px;
				}
			}
			.optin-section {
				margin-bottom: 50px;
				input[type="checkbox"] + label {
					background:url("../images/icons/optcheckbox.svg") left center no-repeat;
					background-size: 23px 23px;
				}
				input[type="checkbox"]:checked + label {
					background:url("../images/icons/optcheckbox_checked.svg") left center no-repeat;
					background-size: 23px 23px;
				}
				h4 {
					margin: 30px 0;
				}
				label.optin-lbl {
					display: block;
					padding-left: 33px;
					font-size: 16px;
					font-weight: 400;
				}
			}
			.disabledbutton {
                pointer-events: none;
                opacity: 0.4;
            }
		}
	}
	#wrapper.pt_account #main #secondary {
		.tabs-prefs-ul {
			li {
				img {
					display: none;
				}
				&.tab-on {
					img {
						display: block;
					}
				}
			}
		}
	}
}