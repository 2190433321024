///////////////////////
/// Styles for EMEA Billing page
///////////////////////

#wrapper.pt_checkout #main #primary .checkout-billing .payment-methods .payment-method-tab {
    &[data-method="Adyen"],
    &[data-method="Adyen_PayPal_ECS"] {
        display: none;
    }
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-methods .payment-method-tab[data-method="OPEN_INVOICE"] .label-text {
    display: block;
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset.address.open {
	& ~.fieldset.coupon-code,
	& ~ .payment-methods,
	& ~ .form-row.form-row-button {
		display: none !important;
	}
}

#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-billing {
				.payment-methods {
					.payment-method-tab[data-method="scheme"] {
						.label-text {
							@include respond(small) {
								display: inline-block;
							}
						}
					}

					.payment-method-tab[data-method="klarna"],
					.payment-method-tab[data-method="klarna_paynow"],
					.payment-method-tab[data-method="klarna_account"] {
						display: none !important;
					}
					
					.payment-method[data-method="scheme"] {
						.adyen-checkout__card-input {
							display: inline-block;
							width: 100%;
							.adyen-checkout__card__form {
								display: flex;
								flex-direction: column;
							}
							.adyen-checkout__label {
								width: 100%;
								display: flex;
								flex-wrap: wrap;
								@include respond(small) {
									flex-direction: column;
								}
							}
							.adyen-checkout__label__text {
								color: #000;
								display: inline-block;
								font-size: 12px;
								font-weight: bold;
								line-height: 18px;
								text-align: left;
								text-transform: uppercase;
								width: 20%;
    							font-family: din-condensed, sans-serif;
								padding-right: 5px;
								margin-top: 5px;
								text-overflow: initial;
								white-space: normal;
								@include respond(standard) {
									width: 18%;
								}
								@include respond(small) {
									width: 100%;
								}
								&::after {
									content: ' *';
									display: inline;
								}
							}
							.adyen-checkout__input-wrapper {
								width: 80%;
								position: relative;
								@include respond(standard) {
									width: 82%;
								}
								@include respond(small) {
									width: 100%;
								}
							}
							.adyen-checkout__error-text {
								border: none;
								color: #b2595c !important;
								font-family: neuzeit-grotesk, sans-serif, serif;
								font-size: 13.2px;
								font-style: normal;
								font-weight: 300;
								font-weight: 400 !important;
								padding-left: 20%;
								padding-right: 5px;
								text-transform: none !important;
								width: 100%;
    							margin-top: 1px;
								@include respond(standard) {
									padding-left: 18%;
								}
								@include respond(small) {
									padding-left: 0;
								}
							}
							.adyen-checkout__card__brands {
								order: 1;
								gap: 12px;
								.adyen-checkout__card__brands__brand-wrapper {
									height: 26px;
									width: 42px;
									img {
										height: 26px;
										width: 42px;
									}
								}
							}
							.adyen-checkout__field--cardNumber {
								order: 2;
							}
							.adyen-checkout__card__exp-cvc {
								order: 3;
								display: flex;
								flex-direction: column;
							}
							.adyen-checkout__input {
								background-color: #f7f7f7;
								border-radius: 0;
								border: 2px solid #e6e6e6;
								max-width: 340px;
								height: 34px;
								padding-left: 20px;
								@include respond(small) {
									max-width: 100%;
								}
								&.adyen-checkout__input--invalid,
								&.adyen-checkout__input--error {
									border-color: #b2595c;
								}
							}
							.adyen-checkout__field {
								width: 100%;
								&.adyen-checkout__field--50 {
									width: 100%;
									& + .adyen-checkout__field--50 {
										margin-top: 16px;
									}
								}
							}
							.adyen-checkout__field__cvc {
								margin-left: 0;
							}
							.adyen-checkout__card__cvc__hint__wrapper {
								position: absolute;
								left: initial;
								right: 0;
								top: 0;
								width: 39px;
								display: flex;
								justify-content: flex-end;
								margin: 0;
								padding-right: 12px;
							}
							.adyen-checkout__card__holderName {
								input {
									& ~ span.error {
										display: none !important;
									}
								}
							}
							.adyen-checkout-input__inline-validation {
								position: absolute;
								left: initial;
								right: 0;
								top: 17px;
								width: 28px;
								display: flex;
								justify-content: flex-end;
								margin: 0;
								padding-right: 12px;
							}
						}
						button.adyen-checkout__button {
							@include respond(small) {
								margin-top: 20px;
								width: 100%;
							}
							&.adyen-checkout__button--pay {
								&:hover {
									.adyen-checkout__button__icon {
										filter: invert(1);
									}
								}
							}
						}
					}
					
					.payment-method[data-method="klarna_all"] {
						.klarna-label {
							cursor: pointer;
							padding: 0 0 10px 40px;
							position: relative;

							&::before {
								content: " ";
								border: 1px #999 solid;
								border-radius: 50%;
								height: 13px;
								left: 10px;
								position: absolute;
								top: 10px;
								width: 13px;
							}

							&::after {
								background-color: #000;
								border-radius: 50%;
								content: " ";
								height: 7px;
								left: 14px;
								position: absolute;
								top: 14px;
								opacity: 0;
								width: 7px;
							}

							&.active {
								&::after {
									opacity: 1;
								}
							}

							img {
								float: left;
								margin: 0 15px 0 0;
							}

							h5,
							p {
								font-family: neuzeit-grotesk, sans-serif, serif;
								font-size: 14px;
								margin: 0;
							}

							h5 {
								font-weight: 500;
							}
						}
						
						.klarna-payments_more {
							font-size: 14px;
							padding-left: 30px;
							
							&::before {
								border: 2px #000 solid;
								border-radius: 50%;
								content: "i";
								display: inline-block;
								font-size: 20px;
								line-height: 15px;
								margin-right: 5px;
								height: 18px;
								text-align: center;
								width: 18px;
							}

							a {
								color: #000;
								text-decoration: underline;

								&:hover {
									text-decoration: none;
								}
							}
						}

						.klarna-payments_button {
							.klarna-checkout__button--pay {
								&[disabled],
								&.disabled {
									&:hover {
										background: $black;
										color: $white;
									}
								}
							}
							
							.adyen-checkout__button__icon {
								height: 32px;
								margin: 0 0 0 12px;
							}
						}
					}
					
					.payment-method[data-method="klarna_account"],
					.payment-method[data-method="klarna_paynow"],
					.payment-method[data-method="klarna"] {
						display: none !important;
					}
				}
			}
		}
	}
}

#wrapper.pt_order #main .order-confirmation-details table.order-shipment-table tr th.header-divider.header-divider,
#wrapper.pt_order-confirmation #main .order-confirmation-details table.order-shipment-table tr th.header-divider.header-divider {
	@include respond(largeUp) {
		background: $gray-white;
	}
}

#wrapper.pt_order #main .order-confirmation-details table.order-shipment-table tr td.order-shipment-details, #wrapper.pt_order-confirmation #main .order-confirmation-details table.order-shipment-table tr td.order-shipment-details.order-shipment-details {
	@include respond(largeUp) {
		padding-top: 30px;
		padding-left: 0;
	}
}

#wrapper.pt_order #main .order-confirmation-details table.order-shipment-table tr td.order-shipment-details .order-shipment-address, #wrapper.pt_order-confirmation #main .order-confirmation-details table.order-shipment-table tr td.order-shipment-details .order-shipment-address.order-shipment-address {
	@include respond(largeUp) {
		margin-top: 0;
	}
}

#wrapper.pt_order #main .order-confirmation-details table.order-shipment-table tr th:last-child, #wrapper.pt_order-confirmation #main .order-confirmation-details table.order-shipment-table.order-shipment-table tr th:last-child {
	@include respond(largeUp) {
		text-align: left;
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing {
	.customet-tax-section {
		margin-top: 33px;
	}
}
#wrapper.pt_checkout #main #primary .order-summary-footer {
	.submit-order {
		.customet-tax-section {
			text-align: left;
			margin-bottom: 14px;
			h2 {
				font-weight: 700;
				font-style: normal;
			}
			.form-row {
				label {
					margin-top: 5px;
				}
			}
		}
	}
}

.asset__klarna {
	margin-bottom: 20px;
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-method.payment-method-expanded:not([data-method="CREDIT_CARD"]) {
	padding: 24px 16px 16px;

	@include respond(largeUp) {
		padding: 40px;
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent {
	display: flex;

	> .form-row label {
		@include respond(300px, 1181px) {
			margin: 0;
			padding-left: 0;
			padding-top: 20px;
			background-position: top center;
			text-align: center;
		}
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .fieldset > .payment-method-options.form-indent.emea-options > .form-row.form-row {
	flex: 1;
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 0;
	border-left: none;

	@include respond(300px, 1181px) {
		height: 55px;
	}

	@include respond(880px, 1199px) {
		padding: 10px 5px;
		height: 55px;
	}

	&:first-child {
		border-left: 1px solid #999;
	}

	label {
		width: 100%;
	}

	.label-text {
		float: none;
	}
}

#wrapper.pt_checkout #main #primary .checkout-billing .payment-methods .payment-method-tab img {
	max-width: 50px;
    height: auto;

	@include respond(300px, 1181px) {
		height: auto;
		max-width: 50px;
	}
}
