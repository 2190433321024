/////////////////////////
// Checkout Shipping Step
/////////////////////////

#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-progress-indicator {
				div {
					@include respond(small) {
						width: 33%;
					}
				}
			}
			.header_prompt {
				@include font-small;
				strong {
					color: $error-red;
					font-weight: normal;
				}
			}
			.corrections-wrap {
				@include font-small;
				@include respond(small) {
					margin-top: 30px;
				}
				max-width: 650px;
				.right_details {
					@include respond(largeUp) {
						float: left;
						width: 385px;
					}
					.prompt {
						@include font-regular;
						font-size: 16px;
						font-weight: bold;
						text-transform: uppercase;
						@include respond(small) {
							margin-bottom: 6px;
						}
						@include respond(largeUp) {
							padding-bottom: 20px;
							padding-right: 10px;
							text-align: right;
						}
						strong {
							font-weight: normal;
						}
					}
					.oldAddress {
						padding-right: 5px;
					}
					.form-row {
						@include respond(small) {
							margin: .5rem 0;
						}
						@include respond(largeUp) {
							height: 1.4rem;
							margin: 0;
							padding-bottom: 3px;
						}
						label {
							@include font-regular;
							font-size: 16px;
							width: 50%;
							@include respond(largeUp) {
								line-height: 14px;
								margin: 3px 0 0 0;
								width: 39%;
							}
						}
						.field-wrapper {
							float: right;
							margin-bottom: 0;
							width: auto;
							input[type="text"],
							input[type="tel"],
							select {
								height: 16px;
								min-height: 1.8em;
								padding: 0 6px;
								text-align: right;
								width: 100%;
							}
							span {
								margin: 3px 0 0 0;
								text-align: right;
							}
						}
					}
				}
				.corrections {
					@include respond(small) {
						padding-top: 35px;
					}
					@include respond(largeUp) {
						float: right;
						max-width: 252px;
					}
					.prompt {
						@include font-regular;
						font-weight: bold;
						font-size: 16px;
						text-transform: uppercase;
						@include respond(small) {
							margin-bottom: 6px;
						}
						@include respond(largeUp) {
							padding-bottom: 20px;
							padding-right: 6px;
							text-align: right;
						}
						strong {
							font-weight: normal;
						}
					}
					.form-row {
						@include respond(small) {
							margin: .5rem 0;
						}
						@include respond(largeUp) {
							height: 1.4rem;
							margin: 0;
							padding-bottom: 3px;
						}
						&.spacer-row {
							@include respond(largeUp) {
								height: 24px;
							}
						}
						label {
							@include font-regular;
							font-size: 16px;
							width: 50%;
							@include respond(largeUp) {
								display: none;
								width: 30%;
							}
						}
						.field-wrapper {
							float: right;
							width: auto;
							line-height: 14px;
							margin: 3px 0 0 0;
							padding: 0 5px;
							text-align: right;
							&.mismatch {
								background-color: #d0fbbb;
							}
							span {
								@include respond(largeUp) {
									text-align: right;
								}
							}
						}
					}
					button.submit-button {
						background-color: $black;
						color: $white;
						font-size: 20px;
						height: auto;
						line-height: inherit;
						padding: 10px 20px;
						@include respond(largeUp) {
							line-height: 40px;
							padding: 0 29px;
						}
						&:hover {
							background-color: $white;
							border: 2px solid $black;
							color: $black;
						}
					}
				}
				.form-row.edit-button {
					clear: both;
					color: #999;
					font-weight: normal;
					text-align: right;
					text-transform: capitalize;
					&.mobile {
						display: inline-block;
						margin: 0 0 0 10px;
						@include respond(largeUp) {
							display: none;
						}
					}
					&.desktop {
						@include respond(small) {
							display: none;
						}
					}
					#havs-edit-address {
						color: #999;
						font-size: 12px;
						font-weight: normal;
					}
				}
				.submit-button {
					font-size: 20px;
					height: auto;
					padding: 10px 20px;
					line-height: inherit;
					@include respond(largeUp) {
						float: right;
						font-size: 20px;
						height: 44px;
						line-height: 40px;
						margin-top: 30px;
						min-width: 140px;
						padding: 0 29px;
						width: auto;
					}
					&.addField {
						@include respond(largeUp) {
							margin-right: 10px;
						}
					}
				}
			}
			.no-results {
				button.submit-button {
					background-color: $black;
					color: $white;
					font-size: 20px;
					&:hover {
						background-color: $white;
						border: 2px solid $black;
						color: $black;
					}
				}
			}
			.checkout-shipping {
				.form-row {
					.form-field-tooltip {
						@media screen and (min-width: 880px) {
							left: 400px;
						}
						@media screen and (min-width: 1200px) {
							left: 350px;
						}
					}
				}
                .form-row--dwfrm_singleshipping_shippingAddress_addressFields_confirmshipping {
                    .error {
                        display: block !important;
                        padding: 5px 0 0
                    }
                    .error + label {
                        position: absolute;
                        top: -13px;
                    }
                }
			}

			//class ends with "addressFields_postal"
			.form-row[class$="addressFields_postal"] {
				.melisa-data-button {
					position: absolute;
					height: auto;
					line-height: 33.5px;
					top: 0;
					left: calc(350px + 18%);

					@media screen and (max-width: 879px) {
						position: static;
						width: 100%;
						height: 44px;
						line-height: 40px;
						margin-top: 10px;
					}
				}
			}
		}
	}
}
