.fj_account {
    #wrapper {
        &.pt_wish-list,
        &.pt_account,
        &.pt_order {
            .golfer-profile-wrapper {
                font-family: $neuzeit;
                width: 100%;
                padding-bottom: 2%;
				padding-top: 48px;
                @include respond(small) {
                    margin-top: 135px;
                }
                &.hide-acctMenu {
                    display: none;
                }
                .profile-tabs,
                .golfer-profile-hdr {
                    margin: auto 15px;
                    @include respond(largeUp) {
                        width: 90%;
                        margin: auto 5%;
                    }
                }
                .golfer-profile-hdr {
                    display: flex;
                    align-items: center;
                    @include respond(largeUp) {
                        margin-top: rem(130px);
                    }
                    .profile-icon {
                        min-width: rem(68px);
                        min-height: rem(68px);
                        background: url('../images/icons/icon-account.svg') no-repeat 8px -4px;
                        background-size: 83% 83%;
                        border-radius: 50%;
                        background-color: $gray-lighter;
                        margin: 0 15px auto 0;
                        @include respond(largeUp) {
                            width: rem(80px);
                            height: rem(80px);
                            margin-right: 25px;
                            background-position:  10px -4px;
                        }
                    }
                    .profile-name {
                        @include respond(largeUp) {
                            display: flex;
                            align-items: center;
                        }
                        img {
                            @include respond(small) {
                                height: 20px;
                            }
                        }
                    }
                    h1 {
                        font-size: rem(24px);
                        font-family: $neuzeit;
                        margin: 5px 0;
                        font-weight: 700;
                        text-transform: none;
                        padding: 0;
                        color: $black;
                        letter-spacing: normal;
                        line-height: rem(31px);
                        @include respond(largeUp) {
                            font-size: rem(36px);
                            margin: 0;
                            padding-right: rem(20px);
                        }
                    }
                    .progress-container {
                        margin: 6px 0 8px;
                        color: $black;
                        span {
                            display: block;
                            &.progres-bar-complete {
                                margin-bottom: 6px;
                                font-size: 14px;
                                font-weight: bold;
                                text-transform: uppercase;
                                letter-spacing: .075rem;
                            }
                        }
                        .progress-bg {
                            position: relative;
                            width: rem(200px);
                            height: rem(9px);
                            background: $gray-lighter;
                            @include respond(small) {
                                margin: 10px 0;
                                width: rem(177px);
                            }
                            .progress-bar {
                                position: absolute;
                                height: 100%;
                                width: 39%;
                                background-color: $green-light;
                            }
                        }
                    }
                    .progress-links {
                        .progres-bar-text,
                        .progres-tab-link a {
                            margin-right: 10px;
                            font-size: 12px;
                            letter-spacing: normal;
                        }
                        .progres-bar-text {
                            @include respond(small) {
                                display: block;
                                margin-bottom: 4px;
                            }
                        }
                        a {
                            font-weight: 700;
                            color: $black;
                            text-decoration: underline;
                        }
                    }
                }
                .profile-tabs {
                    display: flex;
                    align-items: center;
                    margin-top: 31px;
                    @include respond(small) {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                    .profile-tab {
                        display: flex;
                        align-items: center;
                        border: 1px solid $gray-light3;
                        flex-grow: 1;
                        justify-content: center;
                        margin-right: 20px;
                        height: 50px;
                        border-radius: 3px;
                        &:last-child {
                            margin-right: 0;
                        }
                        img {
                            margin-right: 16px;
                        }
                        span {
                            font-size: rem(14px);
                            font-family: $neuzeit;
                            color: $black;
                            margin-left: 5%;
                            @include respond(large) {
                                margin-left: 20%;
                            }
                        }
                        @include respond(small) {
                            flex: 0 0 calc(50% - 20px);
                            margin: 8px 9px;
                            &.tab-1 {
                                flex: 0 0 calc(100% - 20px);
                            }
                        }
                        &.tabmenu-landing {
                            background: url("../images/icons/icon-insider.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                        }
                        &.tabmenu-preferences {
                            background: url("../images/icons/icon-preferences.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 9% center;
                                span {
                                    margin-left: 18%;
                                }
                            }

                        }
                        &.tabmenu-orders {
                            background: url("../images/icons/icon-orders.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 16% center;
                            }
                        }
                        &.tabmenu-profile {
                            background: url("../images/icons/icon-my_info.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 15% center;
                            }
                        }
                        &.tabmenu-wishlist {
                            background: url("../images/icons/icon-wishlist.svg") no-repeat 21% center;
                            background-size: 24px 24px;
                            @include respond(extra-small) {
                                background-position: 14% center;
                            }
                        }
						&.tabmenu-myfittings {
							text-transform: uppercase;
							background: url("../images/icons/icon-myfittings.svg") no-repeat 21% center;
							background-size: 24px 24px;
							@include respond(extra-small) {
								background-position: 14% center;
							}
						}
                        &:hover {
                            background-color: $gray-lighter !important;
                        }
                    }
                }
                span.log-out {
                    position: absolute;
                    right: 17px;
                    top: -17px;
                    a {
                        color: $black;
                        text-decoration: underline;
                    }
                }
            }
			&.pt_account {
				&.tab-orderstatus {
					.golfer-profile-wrapper {
						display: none;
						& ~ #main {
							margin-top: 6.125rem;
						}
					}
				}
			}
			&.pt_account_kr,
			&.pt_order_kr,
			&.pt_wish-list_kr {
				.golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences {
					@include respond(extra-small) {
						background-position: 16% center;
						span {
							margin-left: 16%;
						}
					}
				}
			}
        }
		&.pt_account {
			&.tab-orderstatus {
				.golfer-profile-wrapper {
					display: none;
					& ~ #main {
						margin-top: 6.125rem;
					}
				}
			}
		}
		&.pt_order {
			&.tab-orderhistory.order-unregistered {
				.golfer-profile-wrapper {
					display: none;
					& ~ #main {
						margin-top: 0;
					}
				}
			}
		}
        //TODO : replace with SVG fill color if available
        &.tab-wishlist .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-wishlist {
            background-image: url("../images/icons/icon-wishlist_on.svg");
        }
        &.tab-accountlanding .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-landing {
            background-image: url("../images/icons/icon-insider_on.svg");
        }
        &.tab-preferences .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences {
            background-image: url("../images/icons/icon-preferences_on.svg");
        }
        &.tab-orderhistory .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-orders {
            background-image: url("../images/icons/icon-orders_on.svg");
        }
        &.tab-myinfo .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-profile {
            background-image: url("../images/icons/icon-my_info_on.svg");
        }
		&.tab-myfittings .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-myfittings {
			background-image: url("../images/icons/icon-my_fittings_on.svg");
		}

        &.tab-wishlist .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-wishlist,
        &.tab-orderhistory .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-orders,
        &.tab-myinfo .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-profile,
        &.tab-preferences .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-preferences,
        &.tab-accountlanding .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-landing,
		&.tab-myfittings .golfer-profile-wrapper .profile-tabs .profile-tab.tabmenu-myfittings {
            background-color: $gray-lighter;
        }
        &.pt_account {
            &.pt_account_login_register {
                #main {
                    max-width: 1108px;
                    @include respond(largeUp) {
                        width: 100%;
                    }
                }
            }
            &.tab-preferences {
                #main {
                    #primary {
                        .golfer-preferences {
                            .form-section.optin-section {
                                input[type="checkbox"] {
                                    & + label {
                                        background-position: -3px 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #main {
                margin: 0 auto;
                width: 96%;
                @include respond(largeUp) {
                    width: 90%;
                }
                h1, h2, .ui-dialog .ui-dialog-title, h3, h4, h5, .content-asset .account-asset p, .content-asset .account-asset a,
                .account-asset h3, .account-asset p, .account-asset .msg-us, .content-asset .account-asset {
                    font-family: $neuzeit;
                    color: $black;
                    letter-spacing: normal;
                    text-transform: none;
                    line-height: normal;
                }
                .content-asset .account-asset,
                #secondary .account-asset p,
                #secondary .content-asset .account-asset,
                .content-asset .account-asset h3,
                .content-asset .account-asset p {
                    font-size: 14px;
                }
                .content-asset .account-asset p {
                    margin: 0;
                    padding: 0;
                }
                .content-asset .account-asset h3 {
                    padding: 0;
                    margin-bottom: 3px;
                }
                .msg-us {
                    font-weight: 700;
                    text-decoration: underline;
                    font-size: 14px;
                }
                #secondary {
                    padding-top: 48px;
                    display: none;
                    @include respond(largeUp) {
                        display: block;
                        width: 270px;
                        margin-right: 58px;
                    }
                    .content-asset a {
                        color: $black;
                    }
                }

                #primary {
                    padding-top: 33px;
					.successful-password-change {
						float: left;
						width: 100%;
						margin-bottom: 16px;
					}
                    &.account-content {
                        width: auto;
                        float: left;
                        padding-left: 0;
                        margin-bottom: 25px;
                        @media only screen and (min-width: 880px) and (max-width: 1680px) {
                            width: 55%;
                        }
                        @include respond(largeUp) {
                            max-width: 60%;
                        }
                    }
                    ul.address-list li,
                    ul.address-list li div {
                        font-size: 16px;
                        color: $black;
                        border-bottom: none;
                        .mini-address-title {
                            font-style: normal;
                            margin-bottom: 3px;
                        }
                        .mini-address-location {
                            div {
                                margin: 2px 0;
                                &.city-state {
                                    span {
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        @include respond(extra-small) {
                            font-size: 14px;
                        }
                    }
                    ul.payment-list li,
                    ul.address-list li {
                        width: 42%;
                        border-bottom: none;
                        @media only screen and (max-width: 1199px) {
                            &:nth-child(odd) {
                                padding-right: 16%;
                            }
                        }
                        @include respond(standard) {
                            width: 22%;
                            padding-right: 3%;
                            min-height: 270px;
                        }
                        a {
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 600;
                            white-space: nowrap;
                            &.address-make-default,
                            &.payment-make-default {
                                border: 1px solid $gray-light3;
                                text-transform: none;
                                text-decoration: none;
                                border-radius: 3px;
                                font-size: 16px;
                                font-weight: bold;
                                padding: 10px;
                                display: inline-block;
                                margin: 6px 0 11px;
                                @include respond(extra-small) {
                                    font-size: 14px;
                                }
                            }
                            &.address-edit {
                                margin-left: 0;
                            }
                        }
                        &.default {
                            width: 42%;
                            float: left;
                            @include respond(standard) {
                                width: 22%;
                            }
                        }
                        .is-default {
                            background-color: $green-light;
                            color: $white;
                            border-radius: 3px;
                            font-size: 16px;
                            font-weight: bold;
                            padding: 10px;
                            display: inline-block;
                            margin: 8px 0 11px;
                            white-space: nowrap;
                            @include respond(extra-small) {
                                font-size: 14px;
                            }
                        }
                    }
                    .default-payments {
                        margin-top: 15px;
                    }
                    #payments {
                        clear: both;
                    }
                    .payment-list {
                        li,
                        .button-text {
                            font-family: $neuzeit;
                            color: $black;
                            letter-spacing: normal;
                        }
                        .button-text {
                            font-size: 14px;
                            font-weight: 600;
                            text-decoration: underline;
                            text-transform: uppercase;
                        }
                        li {
                            font-size: 16px;
                        }
                        .card-tlt {
                            font-weight: 700;
                            padding-bottom: 5px;
                            text-transform: uppercase;
                        }
                    }
                    .registered-form {
                        h1, h2, .ui-dialog .ui-dialog-title, h3, h4, h5, p {
                            font-family: $neuzeit;
                        }
                        h1 {
                            display: inline-block;
                            font-size: rem(24px);
                            text-transform: none;
                            letter-spacing: normal;
                            border-bottom: none;
                        }
                        .section-header-note {
                            display: block;
                            text-transform: uppercase;
                            @include respond(largeUp) {
                                display: inline-block;
                                margin-left: 45px;
                                vertical-align: middle;
                                padding-bottom: 4px;
                            }
                        }
                        .my-information {
                            max-width: rem(335px);
                        }
                        .my-information label {
                            float: none;
                            text-align: left;
                            width: auto;
                        }
                        .side-form {
                            .form-row {
                                margin-right: 6px;
                                width: rem(327px);
                                &.form-row-button {
                                    position: absolute;
                                    left: 304px;
                                    top: 12px;
                                    width: auto;
                                    height: auto;
                                    @include respond(largeUp) {
                                        top: 40px;
                                    }
                                }
                            }
                            @include respond(extra-small) {
                                display: flex;
                                .form-row {
                                    min-width: 75%;
                                    width: auto;
                                    &.form-row-button {
                                        position: relative;
                                        left: auto;
                                        min-width: auto;
                                    }
                                }
                            }
                        }
                        input[type="text"],
                        input[type="password"],
                        input[type="email"],
                        input[type="number"] {
                            max-width: rem(309px);
                        }
                        .error {
                            display: block;
                            margin-top: 4px;
                        }
                    }
                    .form-row label,
                    .my-information label {
                        text-transform: none;
                        font-size: rem(16px);
                        font-weight: 700;
                        width: 100%;
                    }
                    .sms-channel-section {
                        max-width: 526px;
                        .registration-sms-channel-header {
                            .registration-sms-channel-heading {
                                color: #000;
                                font-weight: bold;
                                font-size: 24px;
                                line-height: 36px;
                            }
                            .registration-sms-channel-subheading {
                                color: #000;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                        .form-row {
                            display: inline-block;
                            width: 100%;
                            &::after {
                                display: none;
                            }
                            &.smschannelphone {
                                margin-bottom: 0;
                                label {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                            &.checkbox-custom {
                                margin-bottom: 0;
                                margin-top: 8px;
                                label {
                                    width: 100%;
                                    padding-left: 0;
                                    line-height: 16px;
                                    font-weight: 400;
                                    text-transform: none;
                                    display: flex;
                                    align-items: flex-start;
                                    margin: 0;
                                    &::after {
                                        display: none;
                                    }
                                }
                                input[type=checkbox]+label {
                                    background: none;
                                }
                                input[type=checkbox]+label>.styled-checkbox {
                                    position: relative;
                                    display: inline-block;
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 8px;
                                    background: $white;
                                    border: 1px solid $black;
                                    vertical-align: top;
                                    min-width: 14px;
                                    border-radius: 2px;
                                    box-sizing: content-box;
                                    &::after,
                                    &::before {
                                        display: none;
                                    }
                                }
                                input[type=checkbox] {
                                    display: none;
                                }
                                input[type=checkbox]:checked+label {
                                    background: none;
                                }
                                input[type=checkbox]:checked+label>.styled-checkbox {
                                    background-color: $black;
                                    &::before,
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        height: 3px;
                                        background: #fff;
                                        display: block;
                                    }
                                    &::before {
                                        width: 6px;
                                        height: 2px;
                                        bottom: 5px;
                                        left: 1px;
                                        transform: rotate(45deg);
                                    }
                                    &::after {
                                        width: 10px;
                                        height: 2px;
                                        right: 0;
                                        bottom: 7px;
                                        transform: rotate(-45deg);
                                    }
                                }
                                & ~ .checkbox-custom {
                                    margin-top: 8px;
                                }
                            }
                            &.registration-sms-channel-notification {
                                color: #000000;
                                font-weight: 400;
                                margin-top: 4px;
                                p {
                                    font-size: 11px;
                                    line-height: 16px;
                                    margin: 0;
                                    width: 100%;
                                    & + p {
                                        margin-top: 12px;
                                    }
                                }
                                a {
                                    color: #000000;
                                    font-size: 11px;
                                    line-height: 16px;
                                    text-decoration: underline;
                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        &.account-preferences-page {
                            .form-row {
                                &.checkbox-custom {
                                    input[type=checkbox] {
                                        & + label {
                                            & > .styled-checkbox {
                                                margin-right: 16px;
                                                border: 2px solid #000;
                                                min-width: 13px;
                                                border-radius: 3px;
                                                width: 13px;
                                                height: 13px;
                                            }
                                        }
                                        &:checked {
                                            & + label {
                                                & > .styled-checkbox {
                                                    &::before {
                                                        width: 7px;
                                                        bottom: 4px;
                                                        left: 0px;
                                                    }
                                                    &::after {
                                                        width: 10px;
                                                        right: -1px;
                                                        bottom: 6px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.login-register-landing {
                            .form-row {
                                &.checkbox-custom {
                                    position: relative;
                                    input[type=checkbox] {
                                        z-index: 2;
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        & + label {
                                            & > .styled-checkbox  {
                                                border: 2px solid #000;
                                                width: 12px;
                                                height: 12px;
                                                min-width: 12px;
                                                border-radius: 3px;
                                                margin-right: 14px;
                                            }
                                        }
                                        &:checked {
                                            & + label {
                                                & > .styled-checkbox  {
                                                    &::before {
                                                        width: 6px;
                                                        bottom: 3px;
                                                        left: 0px;
                                                    }
                                                    &::after {
                                                        width: 10px;
                                                        right: -1px;
                                                        bottom: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .sms-channel-additional-options {
                                width: 100%;
                                margin-top: 0;
                                h2, h3, h4, h5, p, a {
                                    color: $black;
                                    font-family: $neuzeit;
                                    text-transform: none;
                                    letter-spacing: normal;
                                }
                                h2,
                                h3,
                                h4 {
                                    font-weight: 700;
                                }
                                h2 {
                                    font-size: 24px;
                                    margin-bottom: 15px;
                                }
                                h3 {
                                    font-size: 20px;
                                    padding: 10px 0;
                                }
                                p,
                                h4 {
                                    font-size: 16px;
                                    width: 100%;
                                }
                                & > h4 {
                                    margin-top: 0;
                                    margin-bottom: 8px;
                                }
                                input[type="checkbox"] {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    width: initial;
                                    height: initial;
                                    overflow: hidden;
                                    z-index: 2;
                                    min-height: initial;
                                    margin: 0;
                                }
                                input[type="radio"] {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                    right: 0;
                                    bottom: 0;
                                    margin: 0;
                                    min-height: initial;
                                    z-index: 2;
                                }
                                input[type="radio"] + label {
                                    background: url('../images/radio_unchecked_pref.svg') left 1px no-repeat;
                                    background-size: 16px 16px;
                                    span {
                                        font-weight: 400;
                                    }
                                }
                                input[type="radio"]:checked + label {
                                    background:url('../images/radio_checked_pref.svg') left 1px no-repeat;
                                    background-size: 16px 16px;
                                }
                                .radio-row {
                                    label {
                                        padding-left: 29px;
                                        color: $black;
                                        font-size: 11px;
                                        line-height: 18px;
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 400;
                                    }
                                }
                                .check-row {
                                    #FIInterests {
                                        display: flex;
                                        flex-wrap: wrap;
                                    }
                                    .check-btn {
                                        opacity: 0;
                                    }
                                    .option-item {
                                        display: inline-block;
                                        margin: 0 5px 5px 0;
                                        max-width: 75px;
                                        width: 100%;
                                        @include respond(largeUp) {
                                            margin: 0 10px 10px 0;
                                            max-width: 106px;
                                        }
                                    }
                                    label {
                                        display: inline-block;
                                        margin: 0;
                                        position: relative;
                                        width: 100%;
                                    }
                                    input.check-btn + span {
                                        padding: 4px 6px;
                                        color: $gray-dark;
                                        border: solid 1px $gray-light;
                                        border-radius: 2px;
                                        background-color: $white;
                                        margin: 0;
                                        width: 100%;
                                        line-height: 1;
                                        min-height: 44px;
                                        box-sizing: border-box;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-align: center;
                                        font-size: 14px;
                                        font-weight: 400;
                                        @include respond(largeUp) {
                                            min-height: 46px;
                                        }
                                    }
                                    input.check-btn:checked + span {
                                        color: $white;
                                        border-color: #a2c3b8;
                                        background-color: $green-light;
                                    }
                                }
                                .form-section {
                                    &.style-options {
                                        display: flex;
                                        justify-content: flex-start;
                                        margin-bottom: 13px;
                                        .radio-row {
                                            width: initial;
                                            padding-bottom: 0;
                                            position: relative;
                                            margin-right: 32px;
                                        }
                                    }
                                    &.birthday-options {
                                        label {
                                            font-size: 16px;
                                            width: 100%;
                                            font-weight: 700;
                                            color: #000;
                                            text-transform: none;
                                            letter-spacing: normal;
                                            margin-bottom: 11px;
                                            margin-top: 15px;
                                            padding: 0;
                                            @include respond(largeUp) {
                                                margin-bottom: 19px;
                                                margin-top: 21px;
                                            }
                                        }
                                        .birthday-fields {
                                            display: flex;
                                            .form-row {
                                                margin-right: 10px;
                                                max-width: 84px;
                                                margin-top: 0;
                                                margin-bottom: 0;
                                                @include respond(largeUp) {
                                                    max-width: 90px;
                                                }
                                                .field-wrapper {
                                                    margin-bottom: 0;
                                                }
                                                label {
                                                    display: none;
                                                }
                                                select {
                                                    padding: 0 28px 0 8px;
                                                    background-position: right 7px center;
                                                    background-color: white;
                                                    background-size: 15px 9px;
                                                    font-size: 14px;
                                                    height: 38px;
                                                    background-image: url("../images/interface/icon-select-arrow-black.svg");
                                                    @include respond(largeUp) {
                                                        height: 40px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.category-options {
                                        margin-bottom: 16px;
                                        h4 {
                                            margin: 16px 0 10px;
                                            @include respond(largeUp) {
                                                margin: 22px 0 10px;
                                            }
                                        }
                                    }
                                }
                            }
                            & ~ button {
                                width: initial;
                                height: 44px;
                                text-transform: none;
                                font-weight: 500;
                                padding-left: 15px;
                                padding-right: 15px;
                                font-size: 14px;
                                line-height: 1;
                                @include respond(largeUp) {
                                    height: 50px;
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                &.password-main {
                    margin-top: 60px;
                }
            }
        }
		&.pt_account.pt_account_kr {
			#main {
				#primary {
					&.account-content {
						@include respond(standard) {
							width: 65%;
							max-width: 65%;
						}
						@media screen and (min-width: 1300px) {
							width: 55%;
							max-width: 60%;
						}
					}
					ul.address-list {
						display: flex;
						justify-content: flex-start;
						align-items: stretch;
						flex-wrap: wrap;
						li {
							width: 50%;
							padding-right: 0;
							@media screen and (min-width: 375px) {
								min-width: 160px;
							}
							@include respond(standard) {
                                width: 25%;
								padding-right: 0;
								min-height: initial;
                            }
							&.default {
								width: 50%;
								@include respond(standard) {
									width: 25%;
								}
							}
							.address-list-item {
								width: 100%;
								padding-right: 16px;
								box-sizing: border-box;
							}
						}
					}
				}
			}
			& ~ .ui-dialog #dialog-container #CreditCardForm {
				.form-two-cols {
					width: 100%;
					display: flex;
					padding-left: 0;
					box-sizing: border-box;
					@include respond(largeUp) {
						width: 68%;
						padding-left: 2%;
					}
					.month {
						margin-left: 0;
						margin-right: 0;
						padding-right: 2%;
						box-sizing: border-box;
						width: 50%;
						select {
							width: 100%;
						}
					}
					.year {
						margin-left: 0;
						margin-right: 0;
						padding-left: 2%;
						box-sizing: border-box;
						width: 50%;
						select {
							width: 100%;
						}
					}
				}
			}
		}
		&.pt_order.pt_order_kr {
			#main {
				#primary {
					table.order-history-item-header {
						width: 100%;
						table-layout: initial;
						.order-number {
							@include respond(extra-small) {
								width: 33.5%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
						.order-date {
							@include respond(extra-small) {
								width: 25.5%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
						.order-status-th {
							@include respond(extra-small) {
								width: 41%;
								box-sizing: border-box;
								.value,
								.label {
									font-size: 14px;
								}
							}
						}
					}
					&.account-content {
						.no_orders {
							margin-top: 24px;
						}
					}
				}
			}
		}
		&.pt_wish-list.pt_wish-list_kr {
			#main {
				#primary {
					@include respond(largeUp) {
						width: calc(90% - 210px);
					}
					.item-list {
						tr {
							.item-dashboard {
								.edit-link {
									top: 4px;
									& ~ .pipe {
										position: relative;
										top: -1px;
									}
								}
							}
						}
					}
				}
			}
		}
    }
    #wrapper #main #secondary nav .secondary-navigation {
        ul li {
            font-size: 16px;
            line-height: 45px;
            border-bottom: none;
            display: flex;
            img {
                margin: -7px 0 0 13px;
            }
        }
        @include respond(small) {
            display: none;
        }
    }

    #wrapper.pt_account #primary #RegistrationForm.registered-form > div .profile-col-title,
    #wrapper.pt_account #primary .registered-form > div .profile-col-title,
    #wrapper #main #secondary nav .secondary-navigation h2 {
        border-bottom: none;
        font-family: $neuzeit;
        font-size: rem(24px);
        font-weight: 700;
        letter-spacing: normal;
        text-transform: none;
        margin-bottom: 6px;
    }

    // placeholder until global button requirements TODO
    #wrapper.pt_account #primary #RegistrationForm .form-row.form-row-button button:not(.button-text),
    #wrapper.pt_account #primary .registered-form .form-row.form-row-button button:not(.button-text),
    .golfer-preferences button {
        text-decoration: none;
        border-radius: 3px;
        background-color: $black;
        padding: 1px 15px;
        font-size: 14px;
        font-weight: 700;
        font-family: $neuzeit;
        color: $white;
        &:hover {
            color: $white;
        }
    }
}

// Tooltip
#wrapper.pt_account,
#wrapper.pt_order,
#wrapper.pt_wish-list {
    .golfer-profile-wrapper {
        .golfer-profile-hdr {
            .progress-links {
                .account-tooltip {
                    display: inline-block;
                    height: 12px;
                    margin: 0 5px 0 -5px;
                    position: relative;
                    top: 1px;

                    @media screen and (min-width: 880px) {
                        display: inline-block;
                    }

                    .info-icon {
                        height: 12px;
                    }
                }
            }
        }
    }
}

.tooltip-account-dialog {
    .acc-tooltip-modal {
        box-sizing: border-box;
        width: 100%;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .col-6 {
        width: 100%;

        @media screen and (min-width: 880px) {
            width: 50%;
        }
    }

    .acc-tooltip {
        span {
            display: inline-block;
            line-height: 16px;
        }

        &.checked {
            span {
                background: url("../images/icons/check_circle.svg") right top no-repeat;
                background-size: 17px;
                display: inline-block;
                padding-right: 20px;
            }
        }
    }

    ul {
        margin: 5px 0;
        padding: 0 15px;

        li {
            list-style: none;
            margin: 0 0 5px;
            font-size: 1rem;
        }
    }
}



.fj_account #wrapper.pt_account {
	&.tab-myfittings {
		#primary {
			&.account-content {
				padding-top: 19px !important;
				max-width: 100% !important;
				width: 100% !important;
			}

			.fitting-title {
				margin: 0;
				border-bottom: none;
				font-family: $neuzeit;
				font-size: rem(28px);
				line-height: 33.6px;
				font-weight: 700;
				letter-spacing: normal;
				text-transform: none;
				text-align: left;
				padding: 0 0 40px 0;
				@include respond(small) {
					text-align: center;
					padding: 0 0 40px 0;
				}
			}

			p {
				&.recommendation-tile-care {
					padding-bottom: 25px;
					font-size: 16px;
					line-height: 19.2px;
					font-family: $neuzeit;
					color: $black;
				}

				&.recommendation-tile-recomend {
					padding-bottom: 0;
					text-transform: uppercase;
					font-size: 10px;
					line-height: 12px;
					font-family: $neuzeit;
					color: $black;
					letter-spacing: -0.1px;
				}

				&.recommendation-tile-name {
					font-size: 24px;
					line-height: 28.8px;
					font-family: $neuzeit;
					color: $black;
					font-weight: 700;
					padding-bottom: 0;
				}

				&.recommendation-tile-learn-more {
					padding: 54px 0 0 0;
					margin-top: auto;
					display: flex;

					a {
						font-size: 16px;
						line-height: 19.2px;
						font-family: $neuzeit;
						color: $black;
						font-weight: 700;
					}
				}
			}

			.global-fitting-container {
				width: 100%;
				border-radius: 4px;
				border: 1px solid $alto;
			}

			.top-fitting-container {
				display: flex;
				width: 100%;
				border-bottom: 1px solid $alto;
				padding: 15px 0;
				box-sizing: border-box;
				flex-direction: row;

				@include respond(small) {
					padding: 0;
					flex-direction: column;
				}
			}

			.fitting-information {
				width: 25%;
				border-right: 1px solid $alto;
				padding: 0 10px 0 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include respond(small) {
					width: calc(100% - 20px);
					padding: 10px;
					box-sizing: border-box;
					border-right: 0;
					border-bottom: 1px solid $alto;
					margin: 0 10px;
				}
			}

			.fitting-location {
				width: 28%;
				border-right: 1px solid $alto;
				padding: 0 10px 0 30px;

				@include respond(small) {
					width: 100%;
					padding: 20px;
					box-sizing: border-box;
					border-right: 0;
				}
			}

			.fitting-data {
				flex: 3 1 auto;
				padding: 0 30px;
				@include respond(small) {
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
				}
			}

			.fitting-data-child {
				display: flex;
				justify-content: space-between;
				height: 100%;

				@include respond(small) {
					flex-direction: column;
				}
			}

			.ffitter-title,
			.ffitter-name {
				padding: 0;
				font-family: $neuzeit;
				font-size: rem(16px);
				line-height: 19.2px;
			}

			.ffitter-title {
				font-weight: 400;
			}

			.ffitter-name {
				font-weight: 700;
			}

			.p-bottom-0 {
				padding-bottom: 0;
			}

			.footjoy-button {
				display: inline-block;
				border: 1.5px solid $black;
				padding: 13px 20px;
				font-size: rem(14px);
				text-transform: uppercase;
				font-weight: 700;
				color: $black;
				border-radius: 3px;
				font-family: $neuzeit;
				text-decoration: none;
			}

			.ffitter-name-contact {
				height: 44px;
			}

			.fitting-location-title,
			.fitting-location-name,
			.fitting-location-address,
			.fitting-data-title,
			.fitting-data-date {
				padding-bottom: 0;
				font-family: $neuzeit;
			}

			.fitting-location-name,
			.fitting-location-address,
			.fitting-data-date {
				font-size: rem(16px);
				line-height: 19.2px;
			}

			.fitting-location-name,
			.fitting-data-date {
				font-weight: 700;
			}

			.fitting-location-title,
			.fitting-data-title {
				font-size: rem(14px);
				line-height: 16.8px;
				padding-bottom: 10px;
			}

			.fitting-data-right {
				display: flex;
				align-items: center;

				@include respond(small) {
					padding: 50px 0 25px 0;
				}
			}

			.fitting-data-order {
				height: 44px;
				.footjoy-button {
					@include respond(small) {
						width: 100%;
						box-sizing: border-box;
						text-align: center;
					}
					span {
						position: relative;
						padding-right: 28px;
						&::after {
							content: '';
							position: absolute;
							top: -1px;
							right: -9px;
							width: 24px;
							height: 24px;
							background: url('../images/icons/icon-myfittins-order.svg') 0 0 no-repeat;
						}
					}
				}
			}
            .accordion__item__button__wrapper {
                position: relative;
                @media screen and (max-width: 480px) {
                    display: flex;
                    flex-direction: column;
                }
                .fittings-image-container {
                    padding: 0;
                    @media screen and (max-width: 480px) {
                        padding: 10px 20px 17px;
                        order: 2;
                    }
                    .fittings-image {
                        position: absolute;
                        top: 10px;
                        right: 30px;
                        left: auto;
                        z-index: 2;
                        @include respond(small) {
                            right: 20px;
                            width: initial;
                        }
                        @media screen and (max-width: 480px) {
                            position: relative;
                            width: 100%;
                            left: auto;
                            right: auto;
                            top: auto;
                        }
                    }
                }
            }
			.js-accordion__item__container {
                @media screen and (max-width: 480px) {
                    order: 3;
                }
				&.collapsed {
					display: none;
				}
			}

			.js-accordion__item__button {
                @media screen and (max-width: 480px) {
                    order: 1;
                }
				&.collapsed {
					.js-accordion__item__button-action  {
						&::before {
							transform: rotate(180deg);
						}
					}
				}
			}

			.js-accordion__item__button-action {
				width: 100%;
				padding: 0 30px 0 70px;
				line-height: 65px;
				position: relative;
				cursor: pointer;
				font-size: rem(20px);
				font-weight: 700;
				box-sizing: border-box;

				&::before {
					content: '';
					position: absolute;
					top: 20px;
					left: 24px;
					width: 24px;
					height: 24px;
					background: url('../images/icons/icon-myfittins-arrow.svg') 0 0 no-repeat;
				}
			}

			.details-container-inner {
				padding: 15px 20px 0 75px;

				@include respond(small) {
					padding: 0 0 15px 0;
				}

				td,
				th {
					padding: 0;
					line-height: 63px;
					font-size: rem(16px);
					font-family: $neuzeit;

					@include respond(small) {
						padding: 19px 20px 0 20px;
						line-height: 19.2px;
						text-align: center;
					}

					&:first-child {
						padding-left: 15px;
						text-align: left;
					}
				}

				td {
					&:last-child {
						@include respond(small) {
							border-left: 1px solid $alto;
						}
					}
				}

				tbody {
					tr {
						&:first-of-type {
							td {
								border-bottom: 1px solid $alto;

								@include respond(small) {
									border-bottom: 0;
								}

								&:first-child {
									border-bottom: 0;
								}
							}
						}
					}
				}
			}

			.display-desktop {
				display: block;
				@include respond(small) {
					display: none;
				}
			}

			.display-mobile {
				display: none;
				@include respond(small) {
					display: block;
				}
			}

			.fittings-image-container {
				text-align: right;
				padding: 26px 15px 10px 15px;
				box-sizing: border-box;

				.footjoy-button {
					@include respond(small) {
						width: 100%;
						box-sizing: border-box;
						text-align: center;
					}
				}
			}

			.fittings-wrapper {
				display: none;
			}


			.fitting-recommendations {
				padding-top: 45px;
				@include respond(small) {
					padding-top: 50px;
				}
				.fitting-title {
					margin: 0;
					border-bottom: none;
					font-family: $neuzeit;
					font-size: rem(28px);
					line-height: 33.6px;
					font-weight: 700;
					letter-spacing: normal;
					text-transform: none;
					text-align: left;
					padding: 0 0 20px 0;
					@include respond(small) {
						padding: 0 0 20px 0;
						text-align: center;
					}
				}
			}

			.fitting-recommendations-container {
				@include respond(largeUp) {
					display: flex;
					justify-content: space-between;
					width: 100%;
					flex-direction: row;
				}

				.fitting-recommendations-left {
					@include respond(largeUp) {
						width: 31.5%;
						height: inherit;
					}

					p {
						font-size: rem(16px);
						padding: 0;
						font-family: $neuzeit;
						@include respond(small) {
							text-align: center;
						}
					}
				}

				.fitting-recommendations-right-inner {
					.fitting-recommendations-right-inner-text {
						p {
							font-size: rem(16px);
							padding: 0;
							font-family: $neuzeit;
							@include respond(small) {
								text-align: center;
								width: 96%;
								padding: 0 16px;
								box-sizing: border-box;
							}
						}
					}
				}

				.fitting-recommendations-left-inner {
					padding-bottom: 34px;

					@include respond(largeUp) {
						max-width: 314px;
						display: flex;
						flex-direction: column;
						height: 100%;
						padding-bottom: 0;
					}
				}

				.fitting-recommendations-left-content {
					padding: 50px 0;
					text-align: center;
					@include respond(largeUp) {
						padding: 55px 0;
						text-align: left;
					}
					p {
						line-height: 24px;
						font-family: $neuzeit;

						span {
							text-transform: capitalize;
						}
					}
				}
			}

			.footjoy-button-second {
				margin-top: auto;
				display: block;
				border: 1.5px solid $black;
				padding: 13px 20px;
				font-size: rem(14px);
				text-transform: uppercase;
				background: $black;
				font-weight: 700;
				color: $white;
				border-radius: 3px;
				font-family: $neuzeit;
				text-decoration: none;
				max-width: 253px;
				height: 44px;
				@include respond(small) {
					margin: 0 auto;
				}
			}


			.fitting-previous-container {
				.fitting-title {
					padding: 55px 0;
					@include respond(largeUp) {
						padding: 40px 0;
					}
				}

				.previous-fitting-item {
					display: flex;
					width: 100%;
					border: 1px solid $alto;
					padding: 15px 0;
					box-sizing: border-box;
					flex-direction: row;
					border-radius: 4px;
					margin-bottom: 20px;

					@include respond(small) {
						padding: 0;
						flex-direction: column;
					}

					.fitting-location {
						@include respond(largeUp) {
							width: 34%;
						}
					}

					.fitting-data {
						@include respond(small) {
							padding-bottom: 50px;
						}
					}
				}
			}
		}

		.fitting-recommendations-right {
			@include respond(largeUp) {
				width: 68.5%;
				height: inherit;
			}

			@include respond(small) {
				width: calc(104% + 32px);
				position: relative;
				left: calc(-2% - 16px);
			}

			.fitting-recommendations-right-inner {
				@include respond(largeUp) {
					height: 100%;
					display: flex;
					justify-content: end;
				}

				.slick-list {
					@include respond(small) {
						padding-right: 25%;
					}

					.slick-track {
						display: flex;
					}
				}

				.slick-dots {
					li {
						button {
							width: 8px;
							height: 8px;
							border-radius: 50%;
							border: 0;
							background: #c4c4c4;
							margin: 0 4px;
						}

						&.slick-active {
							button {
								background: $black;
							}
						}
					}
				}
			}

			.recommendation-tile {
				border: 1px solid $alto;
				border-radius: 4px;
				text-align: left;
				margin-left: 20px;
				float: none;
				height: inherit;
				@include respond(largeUp) {
					width: calc(32% - 20px);
					max-width: 268px;
					height: 100%;
					display: flex;
					flex-direction: column;
				}

				img {
					width: 100%;
					height: 165px;
					object-fit: contain;
				}

				.recommendation-tile-text {
					padding: 20px;
					display: flex;
					height: inherit;
					flex-direction: column;
				}
			}
		}
	}
}


.ui-dialog {
	&.fittings-dialog {
		width: auto !important;
		.dialog-content {
			width: 100% !important;
			box-sizing: border-box;

			iframe {
				width: 500px;
				height: 300px;

				@include respond(largeUp) {
					width: 980px;
					height: 700px;
				}
			}
		}

		.ui-widget-header {
			.ui-button {
				position: relative;
				z-index: 100;

				.ui-button-icon-primary {
					display: block;
					height: 24px;
					margin-left: 16px !important;
					width: 23px;
					position: absolute;
					top: 15px;
				}
			}
		}
	}
}

.fittings-dialog {
	border-radius: 4px;
	.dialog-content {
		text-align: center;
	}

	.ui-dialog-titlebar-close {
		right: 3px !important;
		top: 14px;
		margin: 0;
		padding: 0;
		height: 48px;
		width: 48px;
		opacity: 1;
		background: $white;
	}
}

