.insider-cart--cart {
    @include respond(largeUp) {
        float: left;
        width: 70%;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 20px;
        border-bottom: 2px solid #e6e6e6;
    }
}

.insider-cart {
    margin-top: 20px;
}

.insider-cart__logo {
    margin-bottom: 20px;
}

.insider-cart .insider-cart__description {
    font-family: $neuzeit;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: normal;
}

.insider-cart__buttons {
    display: flex;
    margin-bottom: 20px;
    gap: 15px;
}

.insider-cart__items {
    display: flex;
    justify-content: normal;
    align-items: stretch;
}

.insider-cart__item {
    display: block;
    width: 33.33%;
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    box-sizing: border-box;
}

.insider-cart__item__icon {
    margin: 0;
}

.insider-cart .insider-cart__item__title {
    margin-top: -15px;
    font-family: $neuzeit;
    font-weight: 700;
    font-style: normal;
    font-size: 14.4px;
    text-transform: uppercase;
    line-height: normal;
}

.insider-cart .insider-cart__item__description {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $neuzeit;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
