.pt_product-search-result {
    .product-tile {
        .product-name,
        .product-category {
            @include respond(largeUp) {
                padding-right: 60px;
            }
        }
    }
}
