#wrapper.pt_cart {
	#main {
		#primary {
			.name-container {
				.cart-name {
					h1 {
						font-family: $din-condensed;
					}
				}
			}
		}
	}
}
#wrapper.pt_cart{
	#main{
		#primary{
			#cart-items-form{
				.cart-footer{
					.action-carousel{
						.cart-recommendations{
							ul#featured-carousel,
							ul#horizontal-carousel{
								li{
									.product-tile{
										.product-pricing{
											font-family: $din-condensed;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.banner-message {
	.banner-text1 {
		font-family: $din-condensed;
	}
	.banner-text2 {
		font-family: $din-condensed;
	}
	.banner-text3 {
		font-family: $din-condensed;
	}
}

.home-bottom-slots {
	.promoSlotFull {
		&#home-full-1 {
			.itemContent {
				a {
					&:first-child {
						.headers-wrapper {
							div {
								font-family: $din-condensed;
							}
						}
						h3 {
							font-family: $din-condensed;
						}
					}
				}
			}
		}
		&#home-full-2 {
			h2 {
				font-family: $din-condensed;
			}
		}
		&#home-full-bestSellerCarousel {
			h2 {
				font-family: $din-condensed;
			}
		}
		&#home-full-4 {
			.bannerContent {
				h3 {
					font-family: $din-condensed;
				}
			}
		}
		&#home-full-5{
			font-family: $din-condensed;
		}
		&#home-full-6 {
			div,
			article {
				h3,
				h4 {
					font-family: $din-condensed;
				}
			}
		}
		&#home-full-7 {
			h4 {
				font-family: $din-condensed;
			}
		}
		.tabSelector {
			a {
				font-family: $din-condensed;
			}
		}
		.bannerContent {
			h1 {
				font-family: $din-condensed;
			}
		}
	}
}
.pdp-secondaryContent {
	.slide-caption {
		font-family: $din-condensed;
	}
}
.players-container {
	h3 {
		font-family: $din-condensed;
	}
}
.features-container {
	.features-and-benefits {
		h3 {
			font-family: $din-condensed;
		}
	}
}
#wrapper {
	#main {
		#secondary {
			nav {
				.secondary-navigation {
					.content-asset {
						span.toggle {
							font-family: $din-condensed;
						}
					}
				}
			}
		}
	}
}
h1,h2,h3,h4,h5{
	font-family: $din-condensed;
}
.bonus-discount-container {
	.ui-dialog-title {
		font-family: $din-condensed;
	}
}
#wrapper.pt_categorylanding {
	#main {
		#secondary {
			&.sidebar-menu {
				h5 {
					font-family: $din-condensed;
				}
			}
		}
		.primary-content {
			.top-banner {
				.title {
					font-family: $din-condensed;
				}
			}
		}
	}
}
#wrapper.pt_customer-service {
	#primary {
		h1 {
		    font-family: $din-condensed;
		}
	}
	#secondary {
		.customer-service-side-nav {
			h3 {
				font-family: $din-condensed;
			}
		}
	}
}

.mini-cart-header {
	font-family: $neuzeit;
}

@mixin countries-box {
	.location {
    	ul {
      		li {
        		&.title {
					  font-family: $din-condensed;
				}
			}
		}
	}
}
#wrapper.pt_order,
#wrapper.pt_order-confirmation {
	#main {
		.content-header {
			font-family: $din-condensed;
		}
	}
}

.pdp-main {
	.product-name,
	.product-price,
	.overview-container,
	.overview-container h2,
	.overview-container h3 {
		font-family: $neuzeit;
	}
}

.product-set-container {
	.product-set-list {
		.product-set-details {
			.product-price {
				.price-sales {
					font-family: $neuzeit;
				}
			}
		}
	}
}
.ui-dialog.just-added-to-cart {
	.added-to-cart-content {
		.added-to-cart-header {
			font-family: $din-condensed;
		}
	}
}
.product-tile {
	.product-pricing {
		font-family: $font-small;
	}
}
.pt_product-search-noresult {
	.primary-content {
		.no-hits-search {
			.no-hits-label {
				font-family: $neuzeit;
			}
		}
		.search-noresults-header {
			h1 {
				font-family: $neuzeit;
			}
		}
		.section-header {
			font-family: $neuzeit;
		}
	}
}
.no-hits-contactus {
	h3 {
		font-family: $neuzeit;
	}
	.content-asset ul li .contactus-black {
		font-family: $neuzeit;
	}
}
.refinement-header {
	font-family: $neuzeit;
}
.store-locator-search-container {
	.store-locator-header {
		h1 {
			font-family: $din-condensed;
		}
	}
}
.level-2 ul > li > a {
	font-family: $din-condensed !important;
}

.product-tile {
	font-family: $neuzeit;
	font-weight: normal;
	.product-category {
		@include font-small;
	}
}
.refinement {
	font-family: $neuzeit;

	h3,
	.fa {
		font-family: $neuzeit;
	}
}

