body.fixedPosition {
    position: fixed;
}
#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 10rem;
    display: none;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gray-light;
        padding-right: 5px;
    }
}

#wrapper.pt_product-details {
    #main {
        margin-top: 104px !important;
        @include respond(largeUp) {
            margin-top: 106px !important;
        }
        &.full-width {
            padding: 0;
            .innerWidth {
                max-width: 100%;
            }
        }
        .innerWidth {
            & > .breadcrumb {
                @include respond(small) {
                    display: none;
                }
            }
        }
    }
}

.pt_product-details {
    .breadcrumb {
        position: absolute;
        top: 32px;
        left: 0;
        margin: 0;
        z-index: 1;
        @include respond(largeUp) {
            margin-left: 32px;
        }
        @include respond(small) {
            top: 46px;
        }
        .breadcrumb-element {
            font-family: $neuzeit;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: .025rem;
            color: $grey-crumbs;
            &::after {
                margin-left: 0;
                color: $grey-crumbs;
            }

            a {
                color: $grey-crumbs;
            }
        }
    }
    .product-col-1 {
        .product-image-container {
            padding: 0;
        }
    }
    .product-primary-image {
        float: none;
        @include respond(small) {
            display: block;
            width: 100%;
        }

        .regular.slider {
            overflow: hidden;
        }

        .spritespin {
            position: absolute !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .slick-arrow {
            top: calc(50% + 8px);
            background: url("../images/carouselBlackArrowLeft.svg") no-repeat center;
            background-size: 10px 16px;
            width: 45px;
            height: 45px;
            @include respond(largeUp) {
                border: 1px solid $gray-outline;
            }
            &.slick-prev {
                transform: translateY(-50%);
            }
            &.slick-next {
                transform: translateY(-50%) rotate(180deg);
            }
            &.slick-disabled {
                opacity: 0;
            }
        }
        .slick-dots {
            position: absolute;
            bottom: -26px;
            height: 10px;
            overflow: hidden;
            li {
                button {
                    width: 18px;
                    height: 4px;
                    margin: 0 2px;
                    border: none;
                    border-radius: 0;
                    background-color: $gray-darker;
                }
                &.slick-active {
                    button {
                        background-color: $black;
                    }
                }
            }

        }
    }
    .product-detail {
        @include respond(largeUp) {
            margin-top: 24px;
        }
    }
    .thumbnails-container {
        position: absolute;
        left: 8px;
        @include respond(largeUp) {
            position: static;
            display: flex;
            justify-content: center;
        }
        .view-360-button-container {
            width: 52px;
            height: 52px;
            @include respond(largeUp) {
                padding-right: 14px;
                border-right: 2px solid $gray-medium;
            }
            .view-360-button {
                display: block;
                width: 100%;
                height: 100%;
                text-align: center;
                @include respond(largeUp) {
                    background-color: $grey-inactive;
                }
                img {
                    max-width: 80%;
                    margin-top: 12px;
                }
            }
        }
        .video-container {
            @include respond(small) {
                display: none;
            }
            .play-text {
                background-color: $grey-inactive;
                border: 0;
                float: none;
                height: rem(52px);
                margin-left: rem(14px);
                padding: 0;
                width: rem(52px);
                img {
                    height: 100%;
                }
            }
        }
        .product-thumbnails {
            margin: 0 28px;
            @include respond(large) {
                margin: 0 21px;
                width: 330px;
           }
        }
    }
}

.pdp-main {
    @extend %clearfix;
    font-family: $neuzeit;
    @include respond(small) {
        position: relative;
        top: 35px;
        margin-bottom: 60px;
    }

    .product-wrapper {
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;
        @include respond(largeUp) {
            padding: 0;
        }
    }
    .product-main-info {
        margin: 0 auto;
        @include respond(small) {
            display: flex;
            flex-direction: column;
        }
        @include respond(standard) {
            margin-bottom: 0;
        }

        .breadcrumb {
            font-family: $neuzeit;
            margin-left: 2.6%;
            position: relative;
            z-index: 1;
            a.breadcrumb-element {
                color: $grey-crumbs;
                    &::after {
                        color: $grey-crumbs;
                        margin-left: 0;
                    }
            }
            span.breadcrumb-element {
                color: $black;
                font-weight: bold;

                a {
                    color: $grey-crumbs;
                }
            }
        }
        .pdp-hero {
            @include respond(small) {
                order: 1;
            }
        }
        .product-top-data {
            .breadcrumbs-mobile {
                display: block;
                @include respond(largeUp) {
                    display: none;
                }
                .breadcrumb {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin: 0;
                    padding: 22px 0 0;
                }
                &.short {
                    .breadcrumb {
                        padding: 0;
                    }
                }
            }
        }
        > .product-top-data {
            padding: 0 10px;
            @include respond(small) {
                order: 2;
                .product-name.product-set-name {
                    text-align: center;
                }
				& ~ .product-top-data {
					display: none;
				}
            }
            > #reviewssnippet {
                margin-bottom: 14px;
                @include respond(small) {
                    margin-top: 15px;
                }
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .hidden {
        display: none !important;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-col-1 {
        float: none;
        width: 100%;
        box-sizing: border-box;
        @include respond(largeUp) {
            float: left;
            width: auto;
            padding: 0;
            flex: 1;
        }
        @include respond(large) {
            width: auto;
        }
        &.product-set {
            @include respond(largeUp) {
                width: 740px;
            }
            @include respond(large) {
                width: 480px;
            }
        }
    }

    .product-primary-image,
    .product-primary-image-mobile {
        width: 100%;
        max-width: 100%;
        text-align: center;
        position: relative;
        @include respond(small) {
            float: none;
            margin: auto;
        }

        .prev-image-button,
        .next-image-button {
            height: 30px;
            width: 18px;
            position: absolute;
            top: 50%;
            cursor: pointer;

            @include respond(largeUp) {
                display: none;
            }
        }

        .prev-image-button {
            background-image: url("../images/lCarouselLite.svg");
            left: -8.5%;
        }

        .next-image-button {
            background-image: url("../images/rCarouselLite.svg");
            right: -8.5%;
        }

        .plus-image-link {
            @include respond(small) {
                display: block;
                width: 34px;
                height: 34px;
                background-image: url("../images/plus-image-link.png");
                position: absolute;
                top: 0;
                left: -8.5%;
                z-index: 1;
            }
            @include respond(large) {
                display: block;
                width: 34px;
                height: 34px;
                background-image: url("../images/plus-image-link.png");
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
        .zoomImg {
            @include respond(large) {
                display: none !important;
            }
        }
    }

    .product-info {
        clear: both;
        margin-top: -5px;
        float: left;
        width: 100%;
        @include respond(small) {
            margin-top: 25px;
            padding: 0 20px;
            box-sizing: border-box;
        }
        &.center-content {
            width: 1200px;
            float: none;
            margin: 0 auto;
            @include respond(large) {
                width: 880px;
            }
            @include respond(small) {
                width: 100%;
            }
            @include respond(1320px) {
                width: 1300px;
            }
        }
        ul {
            font-size: .8rem;
            list-style: square;
            padding: 0 5em;
            @include respond(large) {
                width: 100%;
            }
            @include respond(small) {
                padding: 0em;
            }
            @include respond(largeUp) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
    }
    .product-category {
        color: #999;
        font-size: 16px;
        font-weight: 600;
        @include font-small;
    }
}
#pdpMain {
    .product-detail {
        .product-category {
            @include font-small;
        }
    }
}
.main-image {
    display: block;
}
.primary-image {
    max-width: 80%;
    padding: 0 10%;
    margin: 0 auto;
    @include respond(largeUp) {
        height: calc(var(--globalMaxHeight, 100vh) - var(--globalDecrementHeight, 28vh));
        object-fit: contain;
        opacity: 0;
    }

    @include respond(small) {
        min-height: 80vw;
    }

    &.lazyloaded {
        @include respond(largeUp) {
            opacity: 1;
        }
    }

    .zoom-image-wrapper & {
        max-width: 100%;
        padding: 0;
        max-height: none;
    }
}
.product-primary-image {
    .primary-image:hover {
        @include respond(largeUp) {
            cursor: zoom-in;
        }
    }
}

.product-thumbnails {
    float: left;
    position: relative;
    text-align: center;
    margin: 0 27%;
    width:  344px;
    @include respond(small) {
        display: none;
    }
    &.product-set-thumbnails {
        display: none;
        @include respond(small) {
            display: block;
        }
    }
    .thumb.slick-slide {
        @include respond(large) {
            max-height: 53px;
        }
    }
    ul {
        @extend %clearfix;
    }
    // thumbnail
    .thumb.slick-slide {
        border: 0;
        float: left;
        margin-bottom: 10px;
        margin-right: 0;
        a {
            width: rem(52px);
            float: left;
            display: inline-block;
            height: 100%;
            background-color: $gray-light-bk;
            img {
                width: 100%;
                height: auto;
            }
        }
        &.thumb-360 {
            position: relative;
            img {
                opacity: .3;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $gray-light;
                pointer-events: none;
            }
            &:after {
                content: '';
                display: block;
                width: 80%;
                height: 80%;
                top: 10%;
                left: 10%;
                position: absolute;
                background: url("../images/icons/360-icon.png") no-repeat center;
                background-size: contain;
                pointer-events: none;
            }
        }
        &.selected {
            a {
                background-color: $gray-dark-bk;
            }
        }
        &.is-active {
            .thumbnail-link {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    height: 4px;
                    background-color: $black;
                }
            }
        }
    }
    .product-video.slick-slide {
        margin-bottom: 0;
    }
    h2 {
        margin: 1rem 0 .3rem;
    }

    .carousel {
        margin-bottom: 1px;
        margin-top: 1px;
        height: 63px;
        overflow: visible;
        @include respond(large) {
            height: 340px;
        }

    }

    .slick-arrow {
        background: url("../images/carouselBlackArrowLeft.svg") no-repeat center;
        background-size: 10px 16px;
        height: 30px;
        width: 20px;
        display: inline-block;
        position: absolute;
        top: 22px;
        &.slick-prev {
            left: -24px;
            transform: rotate(0);
        }
        &.slick-next {
            right: -24px;
            transform: rotate(180deg);
        }
        &.slick-disabled {
            opacity: 0;
        }
    }



}

.pdp-main {
    .product-col-2 {
        float: right;

        @include respond(largeUp) {
            width: 428px;
            padding: 0 32px;
        }
        @include respond(large) {
            padding: 0 0.6%;
        }
        @include respond(small) {
            float: none;
            min-width: 100%;
            padding-left: 0;
            width: 100%;
        }
        &.product-detail {
            @include respond(small) {
                padding: 0 rem(8px);
                box-sizing: border-box;
                order: 4;
            }
            &.product-set {
                @include respond(small) {
                    margin-top: 20px;
                    .product-set-list {
                        border: none;
                        .product-set-item {
                            padding-left: 16px;
                            padding-right: 16px;
                        }
                    }
                }
                .product-set-list {
                    .product-set-item {
                        .product-price {
                            text-align: right;
                            .price-sales {
                                padding-right: 0;
                            }
                            .rangeprice {
                                white-space: normal;
                                @media screen and (min-width: 480px) {
                                    white-space: nowrap;
                                }
                            }
                        }
                        .product-set-details-full-view {
                            .my-designs-line {
                                margin-top: 18px !important;
                            }
                            .custom-select-field {
                                .select-header {
                                    margin-top: 11px;
                                    & ~ .select-header {
                                        margin-top: 14px;
                                    }
                                }
                            }
                            .block-add-to-cart {
                                padding-top: 14px;
                                .inventory-container {
                                    label {
                                        margin-bottom: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #product-content {
            min-width: rem(421px);
            padding: 0 0 0 0;
            @include respond(largeUp) {
                padding: 0 0 0 0;
            }
            @include respond(small) {
                min-width: 100%;
                width: 100%;
            }
            .pdp-promo-container {
                background-color: $gray-lighter;
                font-family: $neuzeit;
                margin: rem(11px) 0 0 0;
                padding: .01rem rem(19px) rem(39px);
                text-align: center;
                @include respond(small) {
                    margin: 24px -8px;
                }
                h3 {
                    font-family: $neuzeit;
                    font-size: 1rem;
                    margin: rem(20px) 0 rem(8px);
                }
                p,
                .promotion-callout > span {
                    font-family: $neuzeit;
                    font-size: rem(14px);
                    margin: 0 0 12px;
                    padding: 0;
                    width: 100%;
                    color: $black;
                    a {
                        color: $black;
                        font-weight: bold;
                        padding-left: .5rem;
                        text-decoration: underline;
                    }
                }
            }
            .product-top-data {
                padding: 0 32px;
            }
            .view-full-details {
                padding: 0 32px;
            }
        }
        .pdpForm.bundle {
            .product-set-list {
                .product-set-item {
                    .product-set-details {
                        .product-set-bundle-variations {
                            .product-variations {
                                .must-select {
                                    &.swatches {
                                        li {
                                            display: none;
                                            &.selected {
                                                display: block;
                                            }
                                        }
                                    }
                                    & > .content {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-variations {
            position: relative;
            float: left;
            width: 100%;
            .custom-select-field {
                position: relative;
                margin-top: 0;
                @include respond(small) {
                    margin-bottom: 0;
                }
                div.size-chart-link,
                div.size-chart-lnk {
                    position: absolute;
                    top: -20px;
                    width: auto;
                    right: 22%;
                    @include respond(largeUp) {
                        right: 2%;
                    }
                    @include respond(large) {
                        right: 2%;
                    }
                    @include respond(small) {
                        right: rem(7px);
                    }
                    @include respond(iphone) {
                        right: rem(10px);
                    }
                }
            }
            .custom-select-field {
                .container {
                    .select-header {
                        font-family: $neuzeit;
                        margin-top: 2px;
                        margin-bottom: 5px;
                        &.error {
                            color: $red-sale !important;
                            font-weight: bold !important;
                            text-transform: capitalize !important;
                        }
                    }
                }
            }
            &.start-with {
                .my-designs-line {
                    .select-header {
                        padding-bottom: 20px;
                    }
                }
            }
        }
        .price-standard {
            border-right: 0;
            padding-right: .5rem;
        }
        .promotion {
            padding: 20px 0 1px 0;
            margin: 0;
            display: block;
            border: 0;
            overflow: visible;
            @include respond(large) {
                width: rem(360px);
            }
            .promotion-title {
                display: none;
            }
            .promotion-callout {
                color: $red-sale;
                font-size: 11px;
                font-family: $neuzeit;
                line-height: normal;
                span {
                    padding: 0;
                    margin: 0 0 5px 0;
                    display: block;
                    width: 100%;
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }
    .product-name {
        margin: 1rem 0 10px;
        font-size: rem(28px);
        font-weight: 700;
        line-height: 1.1em;
        letter-spacing: -.005em;
        color: $black;
        @include respond(small) {
            margin-left: 0;
        }
        @include respond(small) {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;
            margin-top: 0;
        }
        @include respond(large) {
            margin-top: 0rem;
        }
    }
    .product-number {
        display: none;
    }
    .product-price {
        display: flex;
        float: left;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 10px;
        font-family: $neuzeit;
        font-size: rem(22px);
        font-weight: 400;
        letter-spacing: -.01em;
        color: $black;
        @include respond(small) {
            margin-left: 0;
        }

        .price-sales {
            padding-right: .5rem;
            font-weight: bold;
            color: $black;
        }
        .price-standard {
            color: $black;
            font-weight: bold;
            text-decoration: line-through;
            & + .price-sales {
                color: $red-sale;
            }
        }

        .price-tiered {
            color: $red;
            font-size: .6em;
            line-height: 1.5em;
        }
    }
    .promotion {
        padding-bottom: 1px;
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: .5rem;
    }
    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }
}

.rating {
    color: $green;
    .product-info & {
        font-size: 2em;
    }
}
.product-variations {
    ul {
        width: 100%;
        float: left;
        margin-bottom: 0;
        &.warning-box {
            border: 1px solid $white;
            max-width: 100%;
            width: auto;
            &.error {
                background-color: $white;
                border: 1px solid $red-sale;
                color: $black;
            }
        }
        li#design-your-own-swatch,
        li[id^="design-your-blank-swatch"] {
            padding: 0;
            position: relative;
            height: 58px;

            .text-own {
                color: $black;
                font-family: $neuzeit;
                margin: 0 auto;
                padding: rem(19px) 0px;
                img {
                    bottom: 0;
                    height: auto;
                    position: absolute;
                    width: rem(58px);

                }
            }

        }
    }
}

.product-variations,
.product-options {
    width: 100%;
    margin-left: .5em;
    .attribute {
        margin: .175rem 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        h3,
        .label {
            float: left;
            margin-bottom: .3rem;
            margin-right: .35rem;
            font-family: $neuzeit;
            font-size: rem(14px);
            font-weight: bold;
            letter-spacing: -.018em;
            text-transform: capitalize;
            color: $grey-label;
        }
        .value {
            width: 100%;
        }
        ul {
        }
        .selected-value {
            border: 0 none;
            color: $black;
            font-weight: bold;
            font-size: rem(14px);
            letter-spacing: -.018em;
            text-transform: capitalize;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 120%;
            text-align: right;
            width: auto;
            @include respond(small) {
                position: absolute;
                width: auto;
            }
            &.start-design {
                max-width: 250px;
                left: auto;
            }
        }
    }
    .swatches li {
        float: left;
        width: rem(58px);
        margin: .08rem;
        @include respond(small) {
            width: rem(57px);
        }
        @include respond(iphone) {
            height: rem(58px);
        }

        &.unselectable {
            a {
                color: $gray-light;
                opacity: .8;
            }
            &:hover {
                a {
                    border-color: $gray-light;
                }
            }
        }
        a {
            //	border-radius: 1.25rem;
            //	border: 2px solid $black;
            display: block;
            line-height: 3.5;
            min-height: 2.625rem;
            min-width: 2.625rem;
            margin: 0 .5rem .5rem 0;
            padding: 1px;
            text-align: center;
            white-space: nowrap;
            @include respond(largeUp) {
                line-height: 2.7;
                min-height: 2rem;
                min-width: 2rem;
            }
            @include respond(large) {
                line-height: 2.7;
                min-height: 34px;
                min-width: 34px;
            }
            @include respond(small) {
                line-height: 2.7;
                width: auto;
                height: auto;
                min-height: 2rem;
                min-width: 2rem;
            }
        }
    }
    .color li {
        a {
            display: table-cell;
            vertical-align: middle;
            line-height: 0;
            padding: 0;
            //	color: $white;
            @include respond(small) {
                width: 75px;
                height: 50px;
            }

            img {
                width: 100%;
                border-radius: 0;
                margin: 0px;
                display: block;
                @include respond(small) {
                    height: rem(52.5px);
                    width: rem(52.5px);
                    border-radius: 0;
                }
            }
        }
    }
    select {
        background-color: $white;
        border: 1px solid $gray-light;
        border-radius: 0;
        height: 42px;
        width: 100%;
        margin-left: 0;
        @include respond(largeUp) {
            height: 2rem;
        }
    }
}
.pdpPlayers {
    .slick-slider {
        height: auto;
    }
}
.custom-select-field {
    .spacerbar {
        background-color: $gray-outline;
        display: inline-block;
        height: 1px;
        margin: 0 0px 4px 3px;
        width: 94%;
        @include respond(small) {
            width: 97%;
        }
    }
    &.has-error {
        .must-select {
            margin-top: 16px;
            border-radius:0;
            @include respond(small) {
                margin-top: 0px;
            }
        }
    }
    .must-select {
        position: relative;
        &.spacer {
            @include respond(small) {
                margin-bottom: 0px;
            }
        }
        &.not-selected {
            margin-top: 16px;
            border-radius:0;
            .required-error {
                display: block;
            }
        }
        .content {
            padding: 0;
        }
    }
    .required-error {
        display: none;
        color: $red;
        position: absolute;
        top: -20px;
    }
    .select-header {
        color: $grey-label;
        margin-left: .15rem;
        font-family: $neuzeit;
        font-size: rem(14px);
        letter-spacing: -.018em;
        text-transform: capitalize;
        &.error {
            color: $red-sale !important;
            font-weight: bold !important;
            text-transform: capitalize !important;
        }
    }
    .info-icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
}

.tooltip-variant-modal {
    display: none;
}

.ui-dialog.tooltip-variant-dialog {
    .tooltip-variant-modal {
        display: block;
    }
    .ui-dialog-titlebar-close {
        top: 25px;
        right: 0;
        margin: 0 0 -1px 3px;
    }
}

#pdpMain {
    .product-detail,
    .product-set-details {
        .custom-select-field {
            width: 100%;
            margin-bottom: 0;
            margin-top: rem(8px);
            @include respond(small) {
                margin-bottom: 0;
            }
            .container {
                position: relative;
                float: left;
                width: auto;
                margin: 0;
                @include respond(small) {
                    max-width: 100%;
                }
            }
            .select-size,
            .select-sizeW,
            .select-sizeJ,
            .select-sizeJG,
            .select-width,
            .select-length,
            .select-hand,
            .select-waist,
            .select-gloveHand,
            .select-fit-value {
                width: 100%;
                padding: rem(3px) 0;
                border: 0;
                background: none;
                .header {
                    margin-left: 6px;
                    &:focus {
                        color: #666;
                    }
                }
                li {
                    border: 1px solid $gray-outline;
                    border-radius: 0;
                    height: rem(42px);
                    line-height: rem(42px);
                    padding: 0;
                    width: rem(52.5px);
                    margin: .18rem;
                    font-size: rem(14px);
                    font-family: $neuzeit;
                    @include respond(small) {
                        width: rem(51.5px);
                    }
                    &.wider {
                        margin: .18rem .17rem;
                        min-width: rem(83px);
                        width: auto;
                        @include respond(small) {
                            min-width: rem(81.8px);
                        }
                        @include respond(iphone) {
                            min-width: rem(92.7px);
                        }
                    }
                    &.selected {
                        background: $black;
                        border-color: $black;
                        color: $white;
                    }
                    &.inactive {
                        background: $grey-inactive;
                        border-color: $gray-outline;
                        color: $gray-outline;
                    }
                }
            }
        }
    }
    .product-set-details {
        .custom-select-field {
            .container {
                width: 100%;
            }
        }
    }
    .product-col-2.product-detail.giftcard-detail {
        .product-add-to-cart {
            display: inline-block;
        }
    }
    .product-detail {
        #reviewssnippet {
            margin-bottom: 10px;
        }
        .product-variations {
            width: 100%;
            padding: 0 32px;
            box-sizing: border-box;
            @include respond(small) {
                margin-left: 0;
                padding: 0;
                width: 100%;
            }
        }
        .hide-variations {
            .custom-select-field {
                display: none;
            }
        }
        .custom-select-field {
            margin: 8px -4px 0;
            .container {
                width: 100%;
                &:nth-child(odd) {
                    margin-right: 2%;
                }
            }
        }
        .pdpForm {
            float: left;
            width: 100%;
			.next-button-message {
				padding: 20px 0 0;
				color: #d32300;
				font-size: 14px;
				text-align: right;
				@include respond(largeUp) {
                    padding: 10px 32px 6px;
                }
                .preorder-message {
                    text-align: left;
                }
			}
            .pdp-addtocart-message {
                font-size: 14px;
                padding-left: 32px;
                padding-right: 32px;
                padding-top: 17px;
                text-align: center;
                @include respond(largeUp) {
                    padding-top: 10px;
                }
            }
            .product-add-to-cart {
                display: flex;
                align-items: baseline;
                padding: 0;
                @include respond(largeUp) {
                    padding: 0 32px;
                }
                .cart-buttons-container {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    .cart-button {
                        flex: 1 1 auto;
                        margin-left: 4px;
                    }
                    .apple-pay-button {
                        flex: 1 1 auto;
                        margin-left: 4px;
                    }
                }
                .gift-card-data {
                    label {
                        &:not(.error) {
                            font-family: $neuzeit;
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: normal;
                        }
                        + .field-wrapper {
                            float: left;
                        }
                        &.error {
                            font-size: 12px;
                            line-height: normal;
                            padding: 0;
                            margin: 3px 0 1px;
                        }
                    }

                    input[type='text'],
                    input[type='number'],
                    input[type='phone'],
                    input[type='email'],
                    input[type='date'],
                    select {
                        height: 33px;
                        border-color: $field-border-color;
                        border-radius: 0;
                        font-family: $neuzeit;
                        font-size: 14px;
                        @include placeholder-color($gray-mediumer);
                    }
                    textarea {
                        border-color: $field-border-color;
                        border-radius: 0;
                        font-family: $neuzeit;
                        font-size: 14px;
                        @include placeholder-color($gray-mediumer);
                    }
                    select {
                        font-family: $neuzeit;
                        font-size: 11px;
                        padding: 0 26px 0 12px;
                        background-position: right 10px center;
                        text-transform: uppercase;
                        &.error {
                            background-color: $gray-white;
                        }
                    }
                    .form-row {
                        &:not(.amount) {
                            label:not(.error) {
                                float: left;
                                width: -webkit-fill-available;
                                margin-top: 8px;
                                min-width: rem(300px);
                            }
                            input[type='text'],
                            input[type='number'],
                            input[type='phone'],
                            input[type='email'],
                            input[type='date'],
                            select,
                            textarea {
                                width: 100%;
                                padding: 0 6px;
                                border: $field-border-narrow;
                                background: transparent;
                            }
                            textarea {
                                resize: vertical;
                            }
                        }
                        .or-divider {
                            float: left;
                            margin: 0 10px;
                            font-family: $din-condensed;
                            font-size: 14px;
                            line-height: 33px;
                            text-transform: uppercase;
                        }
                        .amount-select {
                            float: left;
                            width: 134px;
                        }
                        .amount-input {
                            float: left;
                            width: auto;
                            position: relative;
                            .field-text {
                                font-family: $neuzeit;
                                font-size: 24px;
                                line-height: 31px;
                                vertical-align: top;
                                color: $gray-warm;
                                letter-spacing: normal;
                            }
                            input {
                                width: 44px;
                                padding: 0 8px;
                                margin: 0 4px;
                                text-align: center;
                                + .error {
                                    position: absolute;
                                    width: 160px;
                                    top: 40px;
                                    left: 20px;
                                }
                            }
                        }
                        .date-msg {
                            padding: 0 0 20px;
                            font-size: 14px;
                        }
                        .gift-later {
                            margin: -10px 0 -8px;
                        }
                        input[type="radio"] + label {
                            padding-left: 30px;
                        }
                        .datepicker-toggle {
                            display: inline-block;
                            position: relative;
                        }
                        .datepicker-toggle-button {
                            position: absolute;
                            right: 5px;
                            top: 3px;
                            background: url(../images/icons/ico_calendar.png) no-repeat;
                            width: 27px;
                            height: 28px;
                            pointer-events: none;
                            z-index: 9;
                        }
                        .datepicker-overlay {
                            position: absolute;
                            background-color: $white;
                            width: 97%;
                            left: 9px;
                            top: 2px;
                            padding: 9px 0;
                        }
                        .datepicker-placeholder,
                        .datepicker-overlay {
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: none;
                        }
                        input[type="radio"] {
                            top: auto;
                            left: -9999px;
                        }
                    }
                    .expected-date {
                        display: none;
                        &.later-date {
                            display: block;
                        }
                    }
                }

                .inventory-container {
                    font-family: $neuzeit;
                    margin-bottom: 6px;
                    width: auto;

                    @include respond(small) {
                        margin-bottom: 0;
                        margin-top: 4px;
                        padding-top: 0;
                    }
                    .inventory {
                        select {
                            padding-left: 19px;
                            width: rem(78px);
                            height: rem(58px);
                            border-radius:0;
                            float: left;
                            clear: left;
                            color: $black;
                            background-color: $white;
                            font-size: rem(14px);
                            font-weight: bold;
                        }
                    }
                    .availability-web {
                        margin-left: rem(20px);
                        @include respond(small) {
                            float: left;
                        }
                        label, div {
                            font-size: 11px;
                            letter-spacing: 0.01em;
                        }
                    }
                }
                .secondary-actions {
                    a {
                        margin-top: 21px;
                        text-transform: uppercase;
                        font-size: 10px;
                    }
                    @include respond(small) {
                        text-align: center;
                        padding-top: 14px;
                        a, span {
                            float: none;
                            display: inline;
                            margin-right: 5px;
                            margin-top: 0;
                        }
                        span {
                            line-height: 90%;
                        }
                        a {
                            line-height: 18px;
                        }
                    }
                }
                #design-your-own {
                    font-size:20px;
                }
                @include respond(large) {
                    #add-to-cart {
                        font-size: rem(15px);
                        padding-left: 20px;
                        padding-right: 20px;
                        width: 100%;
                    }
                }
            }
        }
        &.giftcard-detail {
            box-sizing: border-box;
            letter-spacing: normal;
            #product-content {
                min-width: auto;
            }
            .giftcard-top-content {
                @include respond(largeUp) {
                    padding: 0 32px;
                }
            }
            .product-name {
                font-size: rem(28px);
                letter-spacing: normal;
                @include respond(largeUp) {
                    margin-bottom: 2px;
                }
            }
            .product-category {
                margin-bottom: 4px;
            }
            .product-number {
                margin-bottom: 6px;
            }
            .product-price {
                font-size: 24px;
                letter-spacing: normal;
            }
            .gc-description,
            .egc-example {
                margin-bottom: 18px;
                font-family: $neuzeit;
                font-size: 14px;
                p {
                    display: inline-block;
                    line-height: normal;
                }
                a {
                    font-family: $din-condensed;
                    font-weight: bold;
                    line-height: normal;
                    text-transform: uppercase;
                    text-decoration: underline;
                    color: $black;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .gc-description,
            .egc-example {
                @include respond(largeUp) {
                    padding: 0 32px;
                }
            }
            #reviewssnippet {
                display: none;
            }
            .product-variations {
                margin-bottom: 18px;
                @include respond(largeUp) {
                    margin-bottom: 0;
                }

                .attribute {
                    .giftcardtheme {
                        .selected-value {
                            left: 110px;
                        }
                    }
                    .selected-value {
                        top: 0;
                        left: 87px;
                        text-align: left;
                    }
                }
            }
            .form-row {
                margin-bottom: 0;
            }
            .required-label {
                display: block;
                font-family: $neuzeit;
                font-size: 14px;
                margin: 2px 0 14px;
                @include respond(small) {
                    margin: 10px 0 0;
                }
            }
        }
    }
    .product-set-details {
        .product-variations {
            padding: 0;
        }
    }
}

.product-set .product-add-to-cart {
    display: block;
}

@include respond (large) {
    .product-add-to-cart {
        padding: 0;
        .add-to-cart {
            padding: 0px 0px;
            width: 8.25rem;
            font-size: .75rem;
            font-weight:200;
        }
    }
}

.pdp-main .product-add-to-cart {
    .secondary-actions {
        float: left;
        width: 100%;
        @include respond(small) {
            margin: auto;
            margin-left: 0;
        }
    }
    margin: 0;
    padding: 0em .5em;
    @include respond (large) {
        padding: 0;
    }
    overflow: hidden;
    .secondary {
        color: black;
        float: left;
        margin-right: .625rem;
        margin-top: 1rem;
    }
    .secondary-spacer {
        float: left;
        margin-top: 1rem;
        margin-right: .625rem;
        @include respond(small) {
            //display: none;
        }
    }
    @include respond(small) {
        padding: 0;
    }
    .inventory-container {
        width: 100%;
        float: left;
        margin: 7px 0;
        @include respond (small) {
            height: auto;
        }
        .quantity {
            label {
                margin-right: .5rem;
                text-transform: capitalize;
            }
        }
        .select-pdp {
            border-radius: 1rem;
            border: 1px solid $gray-outline;
            background-color: $white;
            color: $gray-warm;
            padding: 0rem 1rem 0rem 1rem;
            background-image: url("../images/down-arrow.png");
            height: 2.25rem;
            background-repeat: no-repeat;
            background-position: 76% 22px;
            font-family: $neuzeit;
            @include respond(small) {
                padding: 0rem 0rem 0rem 1rem;
            }
            &:focus {
                border-color: #999;
            }
        }
        .availability-web {
            @include respond(small) {
                float: right;
                margin-left: 0;
                width: 55%;
            }
        }
        .inventory {
            float: left;
            white-space: nowrap;
            margin-bottom: 0;
            label {
                display: inline;
                float: left;
                font-size: rem(16px);
                text-align: right;
                text-transform: capitalize;
                font-family: $neuzeit;
                color: $grey-label;
                margin-bottom: 0;
            }
            input {
                font-size: 1.5em;
                height: 50px;
                width: 50px;
                margin-right: .4em;
                text-align: center;
                @include respond(largeUp) {
                    height: 37px;
                    width: 37px;
                }
            }
        }
        .low-inventory-msg {
            clear: both;
            margin-bottom: .5rem;
            color: $red;
        }
    }
    .dw-apple-pay-button {
        padding: 0 36px!important;
        height: 58px!important;
        float: none!important;
        width: 100%!important;
        border-radius:0!important;
        border: 2px solid $black !important;
        margin: 0 !important;
        min-height:10px!important;
        min-width:49%!important;
        background-size:45% 45%!important;
        background-color:$white!important;
        background-image:-webkit-named-image(apple-pay-logo-black)!important;
        background-repeat:no-repeat!important;
        background-position:50% 50%!important;
    }
    .add-to-cart {
        background: $black;
        border: 2px solid $black;
        color: white;
        float: left;
        font-family: $neuzeit;
        font-size: rem(15px);
        line-height: 1.32;
        font-weight: bold;
        height: rem(58px);
        opacity: 1;
        padding: 0 14px;
        position: relative;
        width: 100%;
        @include respond(small) {
            font-size: rem(15px);
            margin: 0;
            padding: rem(2px) rem(8px);
        }
        @include respond(large) {
            width: 49%;
            margin-right: 2%;
            font-size: rem(20px);
        }
        @include respond(iphone) {
            font-size: rem(12px);
            padding: rem(2px) rem(5px);
            width: rem(104px);
        }
        &.disabled {
            background: $gray-outline;
            border: 1px solid $gray-outline;
            width: 100%;
            @include respond(small) {
                padding: 0 rem(35px);
            }
        }
        &.locked {
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1.35;
			width: 100%;
            &::before {
                background: url(../images/icons/lock-white.svg) no-repeat center;
                content: "";
                display: block;
                position: relative;
				margin-right: 11px;
				height: 100%;
                width: 21px;
            }
			.member-only-btn-text {
				max-width: 190px;
				white-space: normal;
				@media screen and (min-width: 440px){
					max-width: unset;
				}
				@media screen and (min-width: 880px){
					max-width: 190px;
				}
			}
        }
        &:hover, &:focus {
            color: $black;
            background-color: $white;
            border: 2px solid $black;
        }
    }
    .design-your-own {
        font-weight:200;
        + .dw-apple-pay-button {
            @include respond(largeUp) {
                margin-top: 3px !important;
            }
        }
    }
}

.pdp-main {
    .product-actions {
        @extend %clearfix;
        //padding: 1em .5em;
        .socialsharing {
            float: none;
            margin-right: 1rem;
            padding-top: 1em;
            @include respond(small) {
                display: none;
            }
        }
        a {
            margin-right: .1rem;
            &.share-icon {
                color: $gray-warm;
                &:hover {
                    color: $red;
                }
                i {
                    font-size: 3rem;
                    @include respond(largeUp) {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
    .recommendations {
        clear: left;
        padding: 2em 0;
        h2 {
            font-size: 2em;
            font-style: italic;
            font-weight: 400;
            margin-left: 14px;
        }
        #carousel-recommendations {
            margin: auto;
            position: relative;
            overflow: hidden;
            width: 750px;
        }
        ul {
            list-style-type: none;
            position: relative;
            width: 2000em;
        }
        li {
            float: left;
            width: 250px;
        }
        .product-tile {
            padding: 0 2em;
        }
        .product-name {
            font-size: 1em;
            margin: .2em 0;
            width: 100%;
        }
        .product-price {
            font-size: 1em;
        }
        .product-tile:hover .recommendation-tooltip {
            display: block !important;
        }
    }
}

.js .pdp-main .recommendations .product-tile .recommendation-tooltip {
    display: none !important;
}

.pdp-main {
    .recommendations .recommendation-tooltip {
        background: none repeat scroll 0 0 $white;
        border: 1px solid;
        left: 50%;
        position: absolute;
        text-align: left;
        top: 50%;
        width: 300px;
        z-index: 100;
    }
    .promotion {
        border-top: 1px solid #ccc;
        margin: 1em 0;
        overflow: hidden;
        padding: 1.5em 1.5em .7em 1.5em;
        .promotion-callout {
            color: $red-sale;
        }
    }
    .product-set-list {
        border-top: 1px solid $gray-light;
        .product-set-item {
            border-bottom: 2px solid $gray-light;
            padding: 20px 0 36px;
            @include respond(largeUp) {
                padding: 40px 0 24px;
            }
            .product-thumbnail {
                background-color: transparent;
                transition: background-color 0.35s;
            }
            .product-set-details-main-info {
                .product-set-details-info {
                    display: flex;
                    flex-direction: column;
                }
                .product-price {
                    margin-top: 0;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0;
                    padding-left: 14px;
                    @include respond(largeUp) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                    .price-sales {
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 22px;
                        letter-spacing: 0;
                        padding-left: 14px;
                        @include respond(largeUp) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
                .product-set-item-name {
                    font-family: $neuzeit;
                    letter-spacing: 0;
                    text-transform: none;
                    color: #000000;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    margin-top: 0;
                    margin-bottom: 3px;
                    @include respond(largeUp) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
                .product-set-item-category,
                .product-set-item-color-variants-qty {
                    color: #000000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    @include respond(largeUp) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
                .product-color-variants-qty
                .product-price {
                    float: none;
                    margin-top: 0;
                    .price-sales {
                        margin-top: 0;
                    }
                }
            }
            .product-set-details-full-view {
                max-height: 0;
                transition: max-height 0.25s;
                overflow: hidden;
                .product-set-bundle-promotions {
                    padding-left: 116px;
                    @include respond(largeUp) {
                        padding-left: 188px;
                    }
                }
                .custom-select-field {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    .select-header {
                        margin-left: 0;
                    }
                    .size-chart-lnk {
                        right: 0;
                    }
                    .must-select,
                    .select-waist,
                    .select-width,
                    .select-size {
                        li {
                            margin-left: 0 !important;
                            margin-right: 5px !important;
                        }
                    }
                }
                .productset-promo-box-section {
                    padding: 16px 24px;
                    font-size: 16px;
                    line-height: 18.4px;
                    color: #333333;
                    background-color: $gray-lighter;
                    font-family: $neuzeit;
                    text-align: center;
                    .promotion {
                        width: 100%;
                        @include respond(large) {
                            width: 100%;
                        }
                    }
                    h3 {
                        font-family: $neuzeit;
                        font-size: 1rem;
                        margin: rem(20px) 0 rem(8px);
                    }
                    p,
                    .promotion-callout > span {
                        font-family: $neuzeit;
                        font-size: rem(14px);
                        margin: 0 0 12px;
                        padding: 0;
                        width: 100%;
                        color: $black;
                    }
                    a {
                        color: #333333;
                        text-decoration: underline;
                        font-weight: 700;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .product-set-details-actions {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                a,
                span {
                    color: #000000;
                    text-decoration: underline;
                    font-size: 14px;
                    line-height: 16.8px;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .item-view-full-details {
                    font-weight: 400;
                }
                .shop-this-item {
                    font-weight: 700;
                    position: relative;
                    padding-right: 33px;
                    cursor: pointer;
                    @include respond(small) {
                        padding-right: 23px;
                    }
                    &:after {
                        content: "";
                        width: 17px;
                        height: 10px;
                        position: absolute;
                        top: 2px;
                        background: url('../images/down-arrow.png') no-repeat right;
                        background-size: 13px 8px;
                        transform: rotate(0deg);
                        right: 0;
                        display: inline-block;
                    }
                }
            }
            .item-view-product-details-wrapper {
                display: none;
            }
            .product-set-details-main-info-wrapper {
                display: flex;
                justify-content: space-between;
            }
            .product-set-item-wrapper {
                .product-set-item-details {
                    margin-bottom: 26px;
                    display: inline-block;
                    width: 100%;
                }
                .product-set-image {
                    width: 104px;
                    @include respond(largeUp) {
                        width: 116px;
                    }
                }
                .product-set-details {
                    padding-left: 20px;
                    box-sizing: border-box;
                    width: calc(100% - 104px);
                    margin: 0;
                    float: right;
                    @include respond(largeUp) {
                        margin: 0;
                        padding-left: 40px;
                        width: calc(100% - 116px);
                    }
                }
                .product-thumbnail {
                    @include respond(largeUp) {
                        width: 100%;
                    }
                }
            }
            &.active {
                padding-bottom: 30px;
                @include respond(largeUp) {
                    padding-bottom: 24px;
                    padding-top: 32px;
                }
                .product-set-item-details {
                    margin-bottom: 0;
                }
                .item-view-product-details-wrapper {
                    display: block;
                    margin-top: 11px;
                    .item-view-product-details {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                        text-decoration: underline;
                        color: #000000;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .product-set-details-actions {
                    display: none;
                }
                .product-set-details-full-view {
                    max-height: 2000px;
                    transition: max-height 0.25s;
                    .block-add-to-cart {
                        .product-add-to-cart {
                            margin-bottom: 0;
                            .availability-web {
                                display: none;
                            }
                        }
                    }
                    .productset-promo-box-section {
                        margin-top: 24px;
                    }
                    .product-set-bundle-promotions {
                        padding-left: 122px;
                        @include respond(largeUp) {
                            padding-left: 190px;
                        }
                    }
                }
                .product-set-item-wrapper {
                    .product-set-image {
                        width: 104px;
                        @include respond(largeUp) {
                            width: 150px;
                        }
                    }
                    .product-set-details {
                        padding-left: 18px;
                        width: calc(100% - 104px);
                        @include respond(largeUp) {
                            padding-left: 40px;
                            width: calc(100% - 150px);
                        }
                    }
                    .product-thumbnail {
                        background-color: #ECECEC;
                    }
                }
            }
        }
        .product-set-image {
            height: auto;
            background-color: $grey-inactive;
            float: left;
            width: 22%;
            img {
                display: block;
                width: 100%;
            }
        }
        .product-set-details {
            float: left;
            width: 78%;
        }
        .attribute {
            h3, .label {
                width: 25%;
            }
        }
        .product-name {
            float: none;
            font-size: 1.3em;
            margin: 0;
            width: auto;
        }
        .block-add-to-cart {
            background: none repeat scroll 0 0 $gray-white;
            clear: both;
            overflow: hidden;
            padding: .5em 0;
            .inventory-container {
                float: none;
                width: auto;
                margin: 0;
            }
            .inventory {
                float: left;
                margin-left: 22%;
                width: 20%;
            }
            label {
                padding: 0;
                text-align: left;
                width: 40%;
            }
            .input-text {
                width: 35px;
            }
            .product-price {
                float: left;
                font-size: 1.6em;
                margin: 0;
                padding: 6px 0 0;
                text-align: right;
                width: 35%;
            }
            button {
                float: right;
                margin: 3px 5px 0 0;
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }
}

.product-add-to-cart {
    .inventory-container {
        .availability-web {
            label {
                @include respond(small) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .low-inventory-msg {
        color: $red-sale;
        font-size: 14px;
        position: absolute;
        right: 0;
        top: rem(-22px);
        @include respond(small) {
            right: 0;
            top: rem(-20px);
        }
        @include respond(iphone) {
            right: 0;
            top: rem(7px);
        }
    }
}

.availability-msg {
    @include respond(small) {
        width: auto;
    }
    .not-available-msg {
        color: $red;
    }
}

.js button.add-all-to-cart {
    display: inherit;
}

.top-border-pdp {
    display: none;
    width: 100%;
    height: 2px;
    float: left;
    margin-bottom: 1rem;
    margin-top: 0rem;
}

.swatches {
    .selected-value {
        float: left;
    }
    &.color {
        clear: both;
        width: 100%;
        @include respond(iphone) {
            width: rem(320px);
        }
        .selected-value {
            @include respond(small) {
                width: rem(52.5px);
                height: inherit;
                right: 0;
                width: auto;
            }
            @include respond(iphone) {
                right: rem(6px);
            }

        }
    }
    &.color.not-selected {
        border: 2px $red solid;
        border-radius: 3em;
        clear: left;
        width: auto;
        padding: 0 1em;
    }
    .selectable .swatchanchor {
        position: relative;
        border: 0;
        background: $gray-light-bk;
        box-sizing: inherit;
        border-radius: 0;
        &:hover {
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $black;
            }
        }
    }
    .selected {
        float: left;
        width: 100%;
        .swatchanchor {
            color: $white;
            background: $gray-dark-bk;
            border: 0;
            margin: 0;
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $black;
            }
            img {
                border-radius: 0;
                width: 100%;
                @include respond(small) {
                    height: rem(52.5px);
                    width: rem(52.5px);
                }
            }
        }
    }
    .selectable {
        float: left;
        width: 100%;
        &.selected {
            pointer-events: none;
        }
    }
    .members-only {
        position: relative;
        &::after {
            background: url("../images/icons/swatch-locked.svg") no-repeat center;
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            height: 13px;
            width: 10px;
        }
    }
}

.overview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin: rem(10px) auto rem(50px);
    font-size: rem(16px);
    order: 4;

    @include respond(largeUp) {
        max-width: 1200px;
        width: 80%;
    }

    @include respond(1320px) {
        max-width: 1300px;
        width: calc(100% - 492px);
        min-width: 700px;
        padding-top: rem(200px);
        margin-left: 0;
        margin-right: 0;
        position: relative;
        left: var(--overviewLeft);
    }


    ul {
        list-style: none;

        li {
            padding: rem(5px) 0 rem(5px) rem(22px);
            position: relative;
            width: 100%;
            box-sizing: border-box;
			&:not(.pdp-spec-list-item) {
				&::after {
					position: absolute;
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					top: 11px;
					left: 0;
					background-color: $black;
				}
            }
        }
    }

    h2 {
        margin: 0;
    }

    h3 {
        margin: rem(10px) 0;
        color: $black;
        letter-spacing: 0;
    }

    h2 {
        font-size: rem(36px);
        text-transform: none;
        letter-spacing: normal;

        @include respond(largeUp) {
            font-size: rem(60px);
        }
    }

    .description-body {
        font-size: rem(24px);
    }

    .long-description {
        margin: rem(30px) 0;
        @include respond(largeUp) {
            margin-botton: rem(60px);
        }
    }

    .tlt-overview {
        font-size: rem(28px);
    }

    .pdp-description,
    .pdp-overview {
        padding: 0 rem(30px);
        @include respond(largeUp) {
            flex: 0;
            min-width: 33%;
            &:first-of-type {
                flex: 1;
                padding: 0 10% 0 0;
                min-width: auto;
            }
        }
    }
}

h3.tlt-asset-grid {
    font-family: $neuzeit;
    font-weight: bold;
    font-size: rem(28px);
    color: $black;
    letter-spacing: normal;
    margin-bottom: rem(30px);
    text-align: left;

    @include respond(largeUp) {
        margin-bottom: rem(50px);
    }

    @media (max-width:330px) {
        margin: auto;
        padding: 4% 0;
        max-width: 93%;

        &.tlt-details {
            margin-top: rem(15px);
        }
    }
}

.pdp-expand-wrapper {
    border-bottom: solid rem(2px) $gray-light;
}
.pdp-expand {
    height: 87px;
    overflow: hidden;
    border-top: solid rem(2px) $gray-light;

    h3.tlt-asset-grid {
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            width: 17px;
            height: 10px;
            position: absolute;
            top: 12px;
            background: url('../images/down-arrow.png') no-repeat right;
            transform: rotate(0deg);
            right: rem(20px);
            @media (max-width:330px) {
                right: rem(-5px);
                top: rem(22px);
            }
        }
    }

    .pdp-asset-grid {
        visibility: hidden;
    }

    &.expanded {
        transition: all;
        height: auto;

        h3 {
            &::after {
                transform: rotate(180deg);
            }
        }
        .pdp-asset-grid {
            visibility: visible;
        }
    }
}

.pdp-asset-grid,
.explore-2x {
    display: flex;
    flex-wrap: wrap;

    .explore-img-right {
        @include respond(small) {
            order: 2;
        }
    }
}

.pdp-asset-grid {
    .pdp-asset {
        font-size: rem(16px);
        width: 46.5%;
        margin-right: 6%;
        margin-bottom: rem(20px);
        margin-top: rem(10px);
        @include respond(small) {
            margin-bottom: 0;
            margin-top: 0;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @include respond(largeUp) {
            width: 23%;
            margin-right: 2.5%;

            &:nth-child(4n) {
                margin-right: 0;
            }
        }

        b {
            font-size: rem(18px);
            display: inline-block;
            padding-bottom: rem(18px);
            line-height: rem(18px);

            @include respond(largeUp) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }
    }
}

.pdp-spec-val, .pdp-best p, .pdp-secondaryContent p, .piu-item h3 {
    font-size: rem(16px);
}

.pdp-spec-tlt, .best-tlt {
    font-size: rem(18px);
    font-weight: bold;
}

.pdp-spec-val {
    text-align: right;
    padding-left: 20px;
    box-sizing: border-box;
    @include respond(small) {
        max-width: 65%;
        text-align: right;
    }
}

.pdp-specs {
    @include respond(large) {
        margin-right: 50px;
    }

    .pdp-spec-line {
        padding: rem(10px) 0;
        border-bottom: 1px solid #dedede;
        display: flex;
        line-height: 20px;
        justify-content: space-between;
    }
}

.pdp-specs-wrap {
    @include respond(largeUp) {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 50px;
    }
}

.spec-btn {
    display: block;
    button {
        font-family: $neuzeit;
        font-weight: bold;
        font-size: rem(16px);
    }
    @include respond(small) {
        margin-top: 25px;
    }
}

.pdp-specs, .pdp-best, .explore-item {
    @include respond(largeUp) {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        margin-bottom: 0;
    }
}

.explore-item {
    justify-content: center;
}

.explore-item-p {
    padding: 0 rem(20px);
}

.pdp-best {
    margin-top: 25px;

    @include respond(largeUp) {
        margin-top: 10px;
        margin-left: 50px;
    }
}

.explore-tlt {
    font-size: rem(24px);
    font-family: $neuzeit;
    font-weight: bold;
    padding: 25px 0 5px;
    top: auto;
}

.pdp-hero img , .pdp-explore img, .piu-image {
    width: 100%;
}

.pdp-explore, h3.pdp-piu-tlt {
    text-align: center;
}

.pdp-piu-wrap {
    float: none;
    clear: both;
}

.pdp-piu {
    .piu-item {
        margin: 0 rem(8px);

        h3 {
            font-weight: bold;
            font-family: $neuzeit;
            margin-bottom: rem(5px);
            color: $black;
        }

        h4 {
            font-size: 14px;
            font-family: $neuzeit;
            margin-top: 0;
            font-weight: normal;
        }
    }
}

.pdp-piu {
    .piu-image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: rem(-9999px);
            right: rem(-9999px);
            bottom: rem(-9999px);
            left: rem(-9999px);
            min-width: 100%;
            min-height: 100%;
            margin: auto;
            width: auto;
            @supports (object-fit: cover) {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }
    .slick-list {
        @media screen and (max-width: 767px) {
             padding: 0px 33px!important;
        }
    }
}

.pdp-piu .slick-next {
  background: $white url('../images/carouselBlackArrowRight.svg') center/9px 13px no-repeat;
    right: 0;
    top: 50%;
    transform: translateY(calc(-100% - 9px));
    box-shadow: 1px 1px 5px 0px rgba(0,0,0, 0.35);
    &:hover,
    &:focus {
        background-color: #fff !important;
    }
    @media screen and (min-width: 768px) {
        right: 12.5%;
    }
    @include respond(largeUp) {
        right: 12.8%;
    }
    @media screen and (min-width: 1024px) {
        right: 13%;
    }
    @media screen and (min-width: 1100px) {
        right: 13.2%;
    }
    @media screen and (min-width: 1200px) {
        right: 13.5%;
    }
    @media screen and (min-width: 1400px) {
        right: 13.65%;
    }
    @media screen and (min-width: 1600px) {
        right: 13.85%;
    }
    @media screen and (min-width: 1800px) {
        right: 14%;
    }
}

.pdp-piu .slick-prev {
  background: $white url('../images/carouselBlackArrowLeft.svg') center/9px 13px no-repeat;
    left: 0;
    top: 50%;
    transform: translateY(calc(-100% - 9px));
    box-shadow: -1px -1px 5px 0px rgba(0,0,0, 0.35);
    &:hover,
    &:focus {
        background-color: #fff !important;
    }
    @media screen and (min-width: 768px) {
        left: 12.5%;
    }
    @include respond(largeUp) {
        left: 12.8%;
    }
    @media screen and (min-width: 1024px) {
        left: 13%;
    }
    @media screen and (min-width: 1100px) {
        left: 13.2%;
    }
    @media screen and (min-width: 1200px) {
        left: 13.5%;
    }
    @media screen and (min-width: 1400px) {
        left: 13.65%;
    }
    @media screen and (min-width: 1600px) {
        left: 13.85%;
    }
    @media screen and (min-width: 1800px) {
        left: 14%;
    }
}

.pdp-piu .slick-arrow {
    height: rem(40px);
    width: rem(40px);
    opacity: 1;
    background-color: $white !important;

    &:hover {
        background-color: $white !important;
    }
}

.pdp-recommendations .product-listing-1x4 .search-result-items .slick-arrow {
    height: rem(40px);
    width: rem(40px);
    opacity: 1;
    background-color: transparent !important;
    @media screen and (min-width: 768px) {
        background-color: $white !important;
    }
    &:hover,
    &:focus {
        background-color: transparent !important;
        @media screen and (min-width: 768px) {
            background-color: $white !important;
        }
    }
}

.pdp-asset-grid, .pdp-specs-wrap, .pdp-secondaryContent, .explore-1x {
    margin-bottom: rem(35px);
}

.explore-1x {
    picture {
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}


.description-container {
    width: 100%;
    float: left;
    margin-bottom: 2rem;
    margin-top: 2rem;
    @include respond(large) {
        margin-top: 0;
    }
    @include respond(small) {
        margin-top: 0;
    }
    .pdp-description {
        width: 60%;
        @include respond(large) {
            width: 80%;
            margin-top: 0;
        }
        @include respond(small) {
            width: 100%;
        }

        margin: auto;
        span {
            font-size: 16px;
            color: $gray-warm;
            @include respond(small) {
                font-size: 14px;
            }
        }
        .description-body {
            h3 {
                text-align: center;
                width: 100%;
                float: left;
                font-size: 30px;
                text-transform: uppercase;
                font-family: $oswald;
                font-weight: 700;
                letter-spacing: 2.5px;
                margin-bottom: 25px;
                @include respond(small) {
                    font-size: 20px;
                    margin-bottom: 1rem;
                }

            }
            p {
                text-align: left;
                font-size: 1rem;
                font-family: $titillium;
                color: $gray-warm;
                letter-spacing: .5px;
                margin-bottom: 3rem;
            }
        }
    }
}

.variant-dropdown {
    width: 50%;
    float: left;
    @include respond(small) {
        width: 100%;
    }
    value {
        width: 94%;
    }
    .variation-select {
        border-radius: 0;
        border: 2px solid #ccc;
        background: #f5f5f5;
        color: #666;
        text-transform: uppercase;
        height: 2.25rem;
        background-image: url("../images/down-arrow.png");
        background-repeat: no-repeat;
        background-position: 94% 50%;
        display: none;
    }
}

// start size-chart styles
.size-chart-link,
.size-chart-lnk {
    a {
        border-color: $white !important;
        color: $black;
        font-family: $neuzeit;
        font-size: rem(14px);
        font-weight: bold;
        letter-spacing: -.018em;
        padding: 0;
        text-decoration: underline;
        text-transform: capitalize;
    }
}

#size-guide-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 999;
    transform: translateX(102%);
    transition: all .5s;
    @include respond (small) {
        width: 100%;
    }
    #close-size {
        height: 36px;
        width: 36px;
        float: right;
        margin-top: 45px;
        background: url("../images/icons/size-close.svg") no-repeat;
        cursor: pointer;
    }
    .size-chart {
        font-family: $neuzeit;
        font-size: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px 0px 11px 2px rgba(51,51,51,0.5);
        padding: 0 20px;
        background-color: $white;
        height: 100%;
        .loading {
            background: url("../images/loading.gif") no-repeat center center;
            width: 100%;
            height: 70%;
            position: absolute;
        }
        @include respond (largeUp) {
            width: 890px;
            padding: 0 35px;
        }
        .size-inner {
            padding-bottom: 40px;
        }

        hr {
            border: none;
            border-bottom: solid 2px #d6d6d6;
            margin: 65px 0 25px;
            @include respond (largeUp) {
                margin: 45px 0;
            }
            &.mobile-inner {
                @include respond (small) {
                    margin-top: 10px;
                }
            }
        }
        .relative {
            position: relative;
        }
        .flex-lg {
            @include respond (largeUp) {
                display: flex;
            }
        }
        .full-image {
            width: calc(100% + 70px);
            margin: 0 -35px;
            @include respond (small) {
                width: 96%;
                margin: 0 2%;
            }
        }
        .full-mobile {
            @include respond (small) {
                margin: 0 -20px;
            }
        }
        a {
            color: $black;
            text-decoration: underline;
        }
        h2,
        h3,
        h4 {
            font-family: $neuzeit;
            font-weight: bold;
            text-transform: none;
            color: $black;
        }
        h2 {
            font-size: rem(36px);
            margin: 40px 0 50px;
            &.size-tlt {
                margin-bottom: rem(40px);
                @include respond (largeUp) {
                    margin-bottom: rem(60px);
                }
            }
        }
        h3 {
            font-size: rem(24px);
            margin-top: 50px;
            &.size-find-tlt {
                margin-bottom: rem(-20px);
            }
        }
        .size-table {
            display: table;
            width:100%;
            @include respond (small) {
                display: flex;
                width: auto;
            }
        }
        .size-row {
            display: table-row;
            @include respond (small) {
                display: block;
                width: 50%;
            }
        }
        .size-label {
            font-size: rem(18px);
        }
        .size-conversion {
            display: flex;
            justify-content: space-between;
            margin: -10px 0 20px;
            .btn-toggle {
                display: flex;
                border: solid 1px $black;
                [class^="btn-"] {
                    text-align: center;
                    padding: 10px 0;
                    min-width: 44px;
                    cursor: pointer;
                }
                &.rule-in {
                    .btn-in {
                        background-color: $black;
                        color: $white;
                    }
                    .btn-cm {
                        background-color: $white;
                        color: $black;
                    }
                }
            }
        }
        .data-row .size-column,
        .size-col .size-col-item {
            &:nth-child(even):not(.fit-col-hover) {
                background-color: #e7e7e7;
            }
            &:hover {
                background-color: #2a6685;
                color: $white;
            }
        }
        .data-row {
            @include respond (small) {
                .size-column {
                    border-right: solid 1px $black;
                }
                .row-hdr {
                    border-right: none;
                }
            }
        }
        .size-column-header,
        .size-column,
        .size-col-item {
            text-align: center;
            padding: 16px;
        }
        .size-column-header,
        .size-column {
        display: table-cell;
        vertical-align: middle;
        @include respond (small) {
            display: block;
            &:first-child {
                border-bottom: solid 2px $black;
            }
            &:nth-child(even):not(.fit-col-hover) {
                background-color: #e7e7e7;
            }
        }
        }
        .size-col .size-col-hdr,
        .header-row .size-column-header,
        .no-wrap {
            white-space: nowrap;
        }
        .size-apparel .header-row {
            @include respond (small) {
                order: 1;
                .size-column-header {
                    border-bottom: none;
                    &.mob-border-bot {
                        border-bottom: solid 2px $black;
                    }
                }
            }
        }
        .size-hdr-border {
            border-bottom: solid 2px $black;
            font-weight: bold;
        }
        .no-right {
            border-right: none;
        }
        .border-right {
            border-right: solid 1px $black;
        }
        .size-column {
            font-size: rem(16px);
        }
        .content-left {
            padding: 2% 6%;
        }
        .image-right {
            width: 100%;
            @include respond (largeUp) {
                width: auto;
                order: 1;
            }
        }
        .row-hdr,
        .size-column-header,
        .size-col-hdr {
            font-weight: 700;
        }
        .row-hdr {
            @include respond (largeUp) {
                border-right: solid 1px $black;
            }
        }
        .size-table-flex {
            display: flex;
            .size-col {
                flex-grow: 1;
                &:hover {
                    background-color: #68a1b8;
                    color: $white;
                    .size-col-item {
                        background-color: #68a1b8;
                        &:hover {
                            background-color: #2a6685;
                        }
                    }
                }
                .size-col-item {
                    @include respond(small) {
                        display: flex;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        padding: 0;
                        height: 50px;
                    }
                }
            }
            .header-col .size-col-item {
                font-weight: bold;
                &:not(.size-col-hdr) {
                    border-right: solid 1px $black;
                }
            }
        }
        .footnote {
            margin-top: 25px;
            font-size: rem(14px);
        }
        .size-scroll {
            overflow-x: auto;
            overflow-y: hidden;
            width: auto;
            table {
                margin-bottom: 30px;
            }
        }
        .gloves-chart {
            @include respond(small) {
                .size-gloves {
                    table-layout: fixed;
                    margin-top: 15px;
                }
                .size-table-lbl {
                    font-size: rem(14px);
                }
            }
        }
        .size-gloves {
            width: 94%;
            margin-left: 6%;
            @include respond (largeUp) {
                width: 100%;
                margin-left: 0;
            }
        }
        .size-gloves,
        .size-shoes {
            td {
                padding: 0;
                text-align: center;
                vertical-align: middle;
                font-size: rem(13px);
                @include respond (largeUp) {
                    font-size: rem(16px);
                }
                &.cell-partial {
                    vertical-align: bottom;
                }
                &.cell-partial-top {
                    vertical-align: top;
                }
                span {
                    &.cell-split,
                    &.cell-blank {
                        padding: 5% 0;
                        @include respond(extra-small) {
                            padding: 0;
                        }
                        @include respond (largeUp) {
                            padding: 11% 0;
                        }
                    }
                    @include respond (largeUp) {
                        &.cell-full {
                            padding: 30% 0;
                        }
                    }
                    &.cell-blank {
                        background-color: $white;
                    }
                    &:hover {
                        background-color: #2a6685;
                        color: $white;
                    }
                }
                &:hover {
                    background-color: #2a6685;
                    color: $white;
                }
            }
            .hdr-cell {
                font-weight: bold;
                @include respond (largeUp) {
                    padding: 2% 15px;
                }
            }

        }
        .size-gloves .hdr-cell {
            border: 0;
            span {
                display: block;
                font-size: 14px;
                &.chart-desktop {
                    font-weight: normal;
                }
                @include respond (small) {
                    padding: 5px 0;
                    &.chart-desktop {
                        display: none;
                    }
                }
            }
        }
        .size-gloves td {
            border: solid 1px $black;
            span {
                display: block;
            }
        }
        .no-hover{
            border: none;
            &:hover {
                background: none;
                color: $black;
            }
        }
        .tlt-top {
            text-align: center;
            @include respond(small) {
                text-align: left;
                margin-left: 14vw;
            }
        }
        .tlt-rows {
            transform: scale(-1);
            position: absolute;
            left: -6px;
            height: 100%;
            .size-label {
                writing-mode: vertical-rl;
                height: 100%;
            }
            @include respond (largeUp) {
                left: -13px;
                text-align: center;
            }
            @include respond(small) {
                bottom: 0;
            }
        }
        .text-center {
            text-align: center;
        }
        .width-100 {
            width: 100%;
        }
        .cell-light {
            background-color: #69a1b8;
        }
        .cell-dark {
            background-color: #4d728a;
        }
        .cell-light,
        .cell-dark {
            color: $white;
        }
        .index-size {
            margin: 20px 0 40px 22%;
            @include respond (largeUp) {
                margin-left: 13%;
            }
        }
        .index-circle {
            float: left;
            margin-right: 14%;
            font-size: 14px;
            line-height: 22px;
            &:after {
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 50%;
                left: -26px;
                background-color: #69a1b8;
                position: absolute;
            }
        }
        .index-circle.dark {
            &:after {
                background-color: #4d728a;
            }
        }
        .size-gloves .hdr-column,
        .size-shoes .hdr-column,
        .header-row .size-column-header,
        .size-pants .size-col-hdr {
            border-bottom: solid 1px $black;
        }
        .data-cell span:nth-of-type(2) {
            border-top: solid 1px $black;
        }
        .size-box {
            background-color: #ececec;
            padding: 50px 20px;
            margin-top: 20px;
            @include respond (largeUp) {
                padding: 50px;
                margin-top: 50px;
            }
        }
        .size-shoes {
            border-collapse: initial;
            @include respond(small) {
                table-layout: auto;
                td {
                    padding: 20px;
                }
            }
            td {
                &.data-cell {
                    font-size: rem(18px);
                    padding: 20px;
                    @include respond (largeUp) {
                        font-size: rem(16px);
                    }
                }
            }
            .hdr-cell {
                font-size: rem(16px);
                border: initial;
                border-bottom: solid 1px $black;
            }
            .hdr-fixed {
                position: sticky;
                left: 0;
                padding: 10px;
                @include respond (largeUp) {
                    padding: 20px;
                }
                border-right: solid 1px $black;
                border-bottom-color: transparent;
                background-color: $white;
            }
            .hdr-fixed-corner {
                border-bottom-color: $black;
            }
        }
        .size-grey-theme {
            tr {
                td {
                    &.data-cell {
                        border-color: transparent;
                    }
                    &:nth-child(even):not(.hdr-column):not(.fit-col-hover) {
                        background-color: #e7e7e7;
                        &:hover {
                            background-color: #2a6685;
                            color: $white;
                        }
                    }
                }
            }
        }
        .size-lnk {
            font-size: rem(18px);
            font-weight: bold;
        }
        .fit-col-hover,
        .size-gloves .fit-col-hover span:not(.chart-in):not(.chart-cm):not(.chart-desktop):not(.cell-split):not(.cell-full),
        .size-gloves .fit-col-hover span.cell-dark,
        .size-gloves .fit-col-hover span.cell-light,
        .size-grey-theme tr td.fit-col-hover {
            background-color: #68a1b8;
            color: $white;
        }
        .size-gloves .fit-col-hover span.cell-dark,
        .size-gloves .fit-col-hover span.cell-light {
            &:hover {
                background-color: #2a6685;
                color: $white;
            }
        }
        .size-cell-hover,
        .size-grey-theme tr td.size-cell-hover {
            background-color: #2a6685;
            &:hover {
                background-color: #2a6685;
            }
        }

        .chart-in {
            display: inline-block;
        }
        .size-gloves .hdr-cell span.chart-cm,
        .size-shoes .hdr-cell span.chart-cm,
        .size-pants .hdr-cell span.chart-cm,
        .chart-cm {
            display: none;
        }

    }
    &.units-cm {
        .size-chart .size-shoes .hdr-cell span.chart-cm,
        .size-chart .size-pants .hdr-cell span.chart-cm,
        .chart-cm {
            display: inline-block;
        }
        .size-chart .size-gloves .hdr-cell span.chart-cm {
            display: block;
        }
        .size-chart .size-gloves .hdr-cell span.chart-in,
        .chart-in {
            display: none;
        }

        .size-conversion .btn-toggle.rule-in {
            .btn-cm {
                background-color: $black;
                color: $white;
            }
            .btn-in {
                background-color: $white;
                color: $black;
            }
        }
    }
    &.show {
        transform: translateX(0%);
    }
}
#size-bg {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: .3;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    &.show {
        @include respond(largeUp) {
            display: block;
        }
    }
}

//lock viewport from scrolling
html.show-size {
    @include respond(largeUp) {
        overflow: hidden;
    }
}

// end size-chart styles

// IE fix for product thumbs
.productthumbnail {
    width: 55px;
}

.selection-error {
    display: none;
    font-size: 1.2em;
    clear: left;
    @include respond(small) {
        text-align: center;
    }
}

#product-set-list {
    .product-set-bundle-variations {
        .select-length,
        .select-width,
        .select-hand,
        .select-gloveHand,
        .select-size,
        .select-sizeW,
        .select-sizeJ,
        .select-sizeJG {
            border-color: #ccc;
            .header {
                .header-toggle {
                    color: #999;
                }
            }
            &.not-selected {
                border-color: $red;
                .header .header-toggle {
                    color: $red;
                }
            }
        }
    }
}

// Product Set Customizations //
#pdpMain {
    .product-set-container {
        @include respond(largeUp) {
            max-width: 1506px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 70px;
            padding-left: 24px;
            padding-right: 24px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
        }
        .product-col-1 {
            &.product-image-container {
                position:relative;
                @include respond(largeUp) {
                    width: calc(100% - 502px);
                    max-width: 820px;
                    margin-bottom: 14px;
                }
                @include respond(large) {
                    width: calc(100% - 502px);
                }
                .regular.slider {
                    @include respond(largeUp) {
                        margin-bottom: 14px;
                    }
                    .slick-slide {
                        a {
                            display: block;
                            position: relative;
                            height: 0;
                            padding-bottom: 100%;
                            min-height: initial;
                        }
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            padding: 0;
                            object-fit: contain;
                        }
                    }
                }
                .product-set-product-main-images {
                    display: none;
                    @include respond(largeUp) {
                        display: flex;
                        column-gap: 14px;
                        flex-wrap: wrap;
                    }
                    .product-set-product-main-image {
                        width: calc(50% - 7px);
                        background-color: #f0f0f0;
                        margin-bottom: 14px;
                        position: relative;
                        height: 0;
                        padding-bottom: 47.32%;
                        a {
                            display: flex;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            img {
                                object-fit: cover;
                                max-height: 100%;
                                max-width: 100%;
                                padding: 0;
                                width: 100%;
                            }
                        }
                    }
                    .product-set-product-main-description {
                        width: calc(50% - 7px);
                        position: relative;
                        height: 0;
                        padding-bottom: 47.32%;
                        margin-bottom: 14px;
                        @include respond(large) {
                            width: 100%;
                        }
                    }
                    .product-set-product-main-description-wrapper {
                        display: flex;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .product-set-product-main-description-content {
                        padding: 32px 32px 32px 56px;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        letter-spacing: 0;
                        box-sizing: border-box;
                    }
                    .product-set-main-name {
                        font-size: 35px;
                        font-weight: 700;
                        line-height: 26px;
                        margin-top: 4px;
                        @include respond(large) {
                            font-size: 26px;
                        }
                    }
                    .product-set-main-description {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 26px;
                        margin-top: 29px;
                        @include respond(large) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .product-col-2 {
            &.product-set {
                @include respond(largeUp) {
                    padding-right: 0;
                    width: 100%;
                    min-width: 502px;
                    max-width: 502px;
                    padding-left: 24px;
                    box-sizing: border-box;
                    margin-top: -6px;
                }
                @include respond(large) {
                    padding-right: 0;
                    padding-left: 24px;
                }
                #product-content {
                    .product-top-data {
                        padding: 0;
                    }
                }
                .product-set-product-main-description {
                    padding: 0 24px 66px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    justify-content: center;
                    letter-spacing: 0;
                    align-items: center;
                    box-sizing: border-box;
                    position: relative;
                    top: -10px;
                    .product-set-main-name {
                        font-size: 23px;
                        font-weight: 700;
                        line-height: 28px;
                    }
                    .product-set-main-description {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        margin-top: 5px;
                    }
                    &.desktop-hidden {
                        @include respond(largeUp) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
#main {
    &.product-set-page {
        font-family: $neuzeit;
        .breadcrumb {
            display: none;
        }
    }
}
.product-set-container {
    .product-set {
        .product-primary-image {
            @include respond(small) {
                margin-bottom: 24px;
            }
        }
        .regular.slider {
            @include respond(small) {
                margin-bottom: 24px;
            }
            .slick-arrow {
                border: none;
            }
            .slick-dots {
                display: none !important;
            }
        }
        .thumbnails-container {
            left: auto;
            bottom: auto !important;
            position: relative;
            display: flex;
            justify-content: center;
            .product-thumbnails {
                width: 100%;
                float: none;
                max-width: calc(100% - 65px);
                .carousel {
                    height: initial;
                    .slick-arrow {
                        display: none !important;
                    }
                }
                .thumb.slick-slide {
                    margin-right: 4px;
                    a {
                        width: 100%;
                        cursor: pointer;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        .product-primary-image {
            @include respond(small) {
                display: block;
                float: none;
                max-width: none;
                width: 100%;
                margin: 0 0 32px;
            }
            .plus-image-link {
                @include respond(small) {
                    top: 8px;
                    left: 8px;
                }
            }
            .prev-image-button {
                @include respond(small) {
                    left: 8px;
                }
            }
            .next-image-button {
                @include respond(small) {
                    right: 8px;
                }
            }
        }
        .product-set-name {
            font-size: 32px;
        }
        .product-name {
            color: $black;
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
        .product-description {
            margin-bottom: 48px;
            font-family: $neuzeit;
            font-size: 14px;
            letter-spacing: .025rem;
            line-height: normal;
            @include respond(largeUp) {
                margin-bottom: 16px;
                width: 80%;
            }
        }
        .product-price {
            margin-top: -.25rem;
            font-size: 16px;
            letter-spacing: .01rem;
            color: $black;
        }
        .product-add-to-cart button {
            display: block;
            width: 100%;
            height: 58px;
            margin: 28px auto;
            background: black;
            color: white;
            font-family: $neuzeit;
            font-weight: bold;
            &:hover {
                border: 2px solid $black;
                color: $black;
                background-color: $white;
            }
        }
        .find-dealer {
            text-align: center;
            margin-top: 1rem;
            text-transform: uppercase;
            a {
                color: black;
                font-size: .65rem;
            }
        }
        .progress-bar-mobile {
            @include respond(largeUp) {
                display: none;
            }
            .progress {
                margin-top: 12px;
            }
            .ui-slider-handle {
                width: 60px;
                height: 10px;
                background: #959595;
                border-radius: 7px;
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                right: 0;
                cursor: pointer;
                border: none;
                transition: all ease-in-out 0.3s;
                transform: translateX(-50%);
                outline: none;
            }
        }
    }
    .pdp-main {
        .product-name {
            width: 100%;
            margin-top: 0rem;
        }
    }
    .product-set-list {
        border-top: 2px solid #e6e6e6;
        .block-add-to-cart {
            position: relative;
            margin-bottom: -1rem;
            top: -1rem;

            @include respond(largeUp) {
                top: 0;
                margin-bottom: 0;
            }

            button {
                display: block;
                float: none;
                width: 100%;
                padding: 0;
                margin: 0 0 0 4px;
                background: black;
                color: white;
                @include respond(largeUp) {
                    width: calc(100% - 68px);
                }
                &:hover {
                    border: 2px solid $black;
                    color: $black;
                    background-color: $white;
                }
            }
            background: none;
            .availability {
                width: 60%;
                margin-left: 40%;
                .label {
                    float: left;
                }
            }
            label {
                width: 20%;
                float: left;
            }
            .input-text {
                width: 55px;
                float: left;
                @include respond(small) {
                    margin-top: 7px;
                }
            }
            .product-add-to-cart {
                padding: 0;
                display: flex;
                align-items: flex-end;
                margin-bottom: 24px;
            }
            .inventory {
                width: auto;
                margin: 0;
                .select-pdp {
                    float: left;
                    clear: left;
                    width: rem(78px);
                    height: rem(58px);
                    padding: 0 32px 0 20px;
                    border-radius: 0;
                    background-position: calc(100% - 12px) 21px;
                    color: #000000;
                    font-weight: bold;
                }
                .quantity {
                    float: left;
                    width: 50%;
                    margin-left: 19%;
                }
            }
            .availability-web {
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
                margin: 0;
                label {
                    width: auto;
                    padding: 1em 0;
                    font-size: 11px;
                    color: $black;
                    + .value {
                        float: left;
                        padding: 1em 0;
                        font-size: 11px;
                        color: $black;
                    }
                }
                .availability-novariation {
                    padding: 1em 0;
                    font-size: 11px;
                    color: $black;
                }
            }
        }
        .product-set-details {
            float: none;
            width: 100%;
            margin: 0;
            @include respond(largeUp) {
                float: right;
                width: 68%;
                margin-left: 10%;
            }
            &.variation-section {
                float: none;
                width: 100%;
                margin-left: 0;
            }
            .product-number {
                margin-top: 0;
                margin-bottom: 10px;
                float: none;
                width: 100%;
                color: $gray-medium2;
            }
            .product-set-bundle-promotions {
                display: block;
            }
            .product-price {
                .price-sales {
                    font-size: 16px;
                    letter-spacing: .01rem;
                    color: $black;
                    margin-bottom: 1rem;
                    float: none;
                    width: 100%;
                    margin-top: -5px;
                }
                .price-standard {
                    color: $black;
                    font-size: 18px;
                    float: left;
                    margin-left: 6px;
                    & + .price-sales {
                        color: $red-sale;
                        width: auto;
                        margin-top: 0;
                        margin-left: 10px;
                    }
                }
            }

            .item-name {
                color: $gray-dark;
                text-transform: none;
                letter-spacing: -.005rem;
                font-size: 18px;
                font-weight: bold;
            }
            .product-variations {
                float: none;
                margin: 0;
            }
        }
    }
}

.product-detail {
    .promotion {
        display: none;
    }
}

// Product Set and Bundle Variation and Promotion Customizations

#pdpMain {
    .product-set-bundle-variations {
        .custom-select-field {
            margin-bottom: 0;
            .container {
                margin-bottom: 0;
            }
        }
        .attribute {
            margin: 0;
        }
    }
}

.product-set-bundle-promotions {
    .promotion {
        display: none;
    }
}

.set-bundle-cart-actions {
    margin: auto;
    width: 58%;
    @include respond (small) {
        width: 75%;
    }
    @include respond (large) {
        width: 65%;
    }
    margin-top: -1rem;
    margin-bottom: 4rem;
    ul {
        width: 100%;
        margin: auto;
        float: left;
        li {
            width: 44%;
            @include respond(large) {

            }
            float: left;
            text-align: center;
            a {
                color: $black;
            }
        }
        .spacer-li {
            width: 2%;
        }
    }
}

.bundle-attribute {
    float: left;
    margin-left: 7px;
    margin-top: -1rem;
    margin-bottom: 1rem;
    .attribute {
        .label {
            color: $black;
            text-transform: uppercase;
            font-weight: bold;
        }
        .value {
            color: $gray-warm;
        }
    }
}

// Power Reviews

.pdp-power-reviews {
    @include respond (small) {
        margin: 0;
        overflow-x: visible;
        width: 100%;
    }
    float: none;
    width: 100%;
    .reviews-header {
        font-size: 2rem;
        text-align: center;
        color: #333;
    }
}

// PDP Recommendations
.pdp-recommendations {
    float: left;
    width: 100%;

    border-bottom: none;
    padding-bottom: rem(70px);
    margin-bottom: 4rem;
    @include respond (small) {
        margin-top: 0;
        margin-bottom: 0rem;
        padding-bottom: rem(40px);
    }
    @include respond(largeUp) {
        width: 100%;
        margin: 0 auto;
        float: none;
        max-width: rem(1200px);
    }
    @include respond(large) {
        max-width: rem(880px);
    }
    @include respond(1320px) {
        max-width: 1300px;
    }
    .product-info ul {
        font-size: .8rem;
        list-style: square;
        width: 100%;
        padding: 0;
    }
    h3 {
        font-family: $neuzeit;
        font-weight: bold;
        font-size: rem(28px);
        text-transform: none;
        margin-bottom: rem(10px);
        letter-spacing: normal;
        color: $black;
        padding-left: 10px;
        @include respond(largeUp) {
            text-align: left;
            padding-left: 10px;
        }
    }

    .search-result-items {
        border: none;
    }
    .product-listing-1x4 {
        overflow: visible;
        .search-result-items {
            float: none;
            width: 100%;
            box-sizing: border-box;
            left: 0;
            padding-left: 0;
            padding-right: 0;
            @include respond(large) {
                left: 0;
            }
            .slick-list{
                padding: 0;
                margin-left: -10px;
                margin-right: 0;

                @media screen and (min-width: 768px) {
                    padding: 0 10px 0 0;
                    margin-left: 0;
                }
                @media screen and (min-width: 880px) {
                    padding: 0 3px 0 0;
                    margin-left: 0;
                }
            }
            .slick-arrow {
                height: 30px;
                width: 19px;
                top: 50%;
                margin-top: 0;
                padding: rem(20px);
                box-shadow: none;
                transform: translateY(calc(-100% - 9px));

                @media screen and (min-width: 481px) {
                    transform: translateY(calc(-50% - 6px));
                    margin-top: -15px;
                }
                @media screen and (min-width: 768px) {
                    box-shadow: 0 .125rem .25rem rgba($black, 0.3);
                    transform: translateY(calc(-50% - 15px));
                }
                &.slick-prev {
                    background: transparent url('../images/carouselBlackArrowLeft.svg') center/9px 13px no-repeat;
                    left: 0;
                    @media screen and (min-width: 768px) {
                        background: $white url('../images/carouselBlackArrowLeft.svg') center/9px 13px no-repeat;
                    }
                }

                &.slick-next {
                    background: transparent url('../images/carouselBlackArrowRight.svg') center/9px 13px no-repeat;
                    right: 0;
                    @media screen and (min-width: 768px) {
                        background: $white url('../images/carouselBlackArrowRight.svg') center/9px 13px no-repeat;
                    }
                }
                &.slick-disabled {
                    opacity: 0;
                }
            }
        }
        .grid-tile {
            width: 33%;
            padding: 0 10px;
            margin: 0 auto;
            box-sizing: border-box;
            height: fit-content;

            @include respond(small) {
                padding-right: 0;
            }
            @include respond(large) {
                width: 33%;
                margin: auto;
            }

            .product-tile {
                @include respond(largeUp) {
                    padding-bottom: 36px;
                }

				&.members-only {
					.product-tile-details .product-category {
						display: none;
					}
				}
                .product-tile-details {
                    position: relative;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: 8px;
                    @media screen and (min-width: 768px) {
                        padding-left: 0;
                        padding-right: 0;
                        margin-top: 12px;
                    }
					@media screen and (min-width: 880px) {
                        padding-bottom: 33px;
						position: relative;
                    }
					@media screen and (min-width: 1200px) {
						padding-bottom: 45px;
					}
                }
                .product-image {
                    text-align: center;

                    img {
                        width: 100%;
                        display: inline;
                        background-color: #ebebeb;
                    }
                    .product-badge {
                        display: none;
                    }
                }
				.product-promo {
					display: none;
				}
                .name-link {
                    font-size: 1rem;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #333;
                }
                .product-category {
                    text-align: left;
                    font-size: 14px;
                }
                .product-pricing {
                    text-align: right;
                    position: absolute;
                    top: 0;
                    right: 10px;
                    font-size: 14px;
                    color: $black;
                    display: flex;
                    flex-direction: column;
                    @media screen and (min-width: 768px) {
                        right: 0;
                   }
                   .product-sales-price {
                       order: -1;
                       margin-bottom: -5px;
                   }
                }
				.members-only-login {
					font-size: 12px;
					text-align: left;
				}
                .colors-available {
                    text-align: left;
                    font-size: 14px;
                    margin-top: 2px;
					@media screen and (min-width: 880px) {
                        height: 45px;
						position: absolute;
						bottom: -1px;
						left: 0;
						width: 100%;
                    }
                }
                .product-name {
                    line-height: 1rem;
                    font-size: 16px;
                    text-align: left;
                    padding-right: rem(60px);
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 2px;
                    a {
                        font-size: 16px;
                        text-transform: none;
                        line-height: 120%;
                    }
                }
                .promotional-message {
                    display: none;
                }
                .product-swatches {
                    ul {
                        padding: 0;
                        margin: 0;
                        @include respond (large) {
                            width: auto;
                        }
						@media screen and (min-width: 880px) {
							height: 45px;
							position: absolute;
							bottom: -19px;
							left: 0;
							width: 100%;
						}
						@media screen and (min-width: 1200px) {
							bottom: -9px;
						}
                    }
                }
				&:hover {
					.pr-clp-raing,
					.product-swatches,
					.product-category {
						@include respond(largeUp) {
							display: block;
						}
					}
					@include respond(largeUp) {
						.colors-available {
							display: none;
						}
					}
				}
				.product-swatches {
					@include respond(largeUp) {
						display: none;
					}
					ul {
						text-align: left;
						margin: 4px 0;
						li {
							display: block;
							float: left;
							margin: 0 2px 0 0;
							padding: 0;
							width: 20px;
							&.open-close {
								margin-left: 3px;
								line-height: 20px;
								width: auto;
								@include respond(largeUp) {
									margin-left: 8px;
									line-height: 36px;
								}
								@include respond(large) {
									margin-left: 3px;
									line-height: 20px;
								}
								@include respond(extraLarge) {
									margin-left: 3px;
									line-height: 30px;
								}
							}
							@include respond(largeUp) {
								margin: 0 5px 0 0;
								width: 36px;
							}
							@include respond(large) {
								margin: 0 2px 0 0;
								width: calc((100% / 7) - 1px);
							}
							@include respond(extraLarge) {
								margin: 0 2px 0 0;
								width: calc((100% / 7) - 1px);
							}
						}
					}
					.swatch {
						display: block;
						width: 100%;
						height: auto;
						text-align: left;
						background-color: $gray-lighter;
						border-radius: 0;
						border-width: 1px;
						&.selected {
							border-width: 1px;
							img {
								width: 100%;
								height: auto;
								border-radius: 0;
							}
						}
						img {
							width: 100%;
							height: auto;
							border-radius: 0;
						}
					}
				}
				.colors-available {
					display: none;
					@include respond(largeUp) {
						display: block;
						height: 34px;
					}
					@include respond(standard) {
						height: 45px;
					}
				}
                .plp-text-badge {
                    display: block;
                    text-align: left;
                }
            }
        }
    }
}

.progress,
.progressDash {
    background-color: $gray-light;
    background-image: linear-gradient(to right, $gray-medium, $gray-medium);
    background-repeat: no-repeat;
    background-size: 0 100%;
    border-radius: 10px;
    display: block;
    height: 10px;
    margin: 24px auto 0;
    overflow: visible;
    transition: background-size .4s ease-in-out;
    width: 65%;
}

.progress {
    margin: auto;

    .ui-slider-handle {
        background: #959595;
        border-radius: 7px;
        border: none;
        bottom: 0;
        cursor: pointer;
        height: 10px;
        left: 0;
        margin-left: 0;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-50%);
        transition: all ease-in-out 0.3s;
        width: 60px;
    }
}

.ui-dialog.video {
    padding: 0;
    #dialog-container {
        margin-top: -20px !important;
        width: 760px !important;
        @include respond (large) {
            padding: 1.25em 5px !important;
        }
        @include respond (small) {
            margin-top: -10px !important;
            width: 280px !important;
            padding: 15px !important;
        }
    }
}

.applepay-dialog {
    margin: 27% auto;
    position:fixed;
    @include respond(largeUp) {
        width: 470px !important;
        margin-top: 20%;
    }
}
#appleDialog {
    padding-top: 0 !important;
}

#pdpMain {
    &.giftcard-pdp {
        .pdp-power-reviews {
            display: none;
        }
        .product-info {
            display: none;
        }
        .product-add-to-cart {
            .add-to-cart {
                font-size: 18px;
            }
        }
    }
    .product-col-1 {
        &.product-image-container {
            width: calc(100% - 492px);
            position:relative;
            @include respond(large) {
                width: calc(100% - 448px);
            }
            @include respond(small) {
                //display: none;
                width: 100%;
                order: 3;
            }

            &.sticky-image {
                position: fixed;
            }
            &.product-set {
                .product-set-mobile-sticky-bar {
                    &.is-sticky {
                        @include respond(small) {
                            position: fixed;
                            top: 104px;
                            left: 0;
                            right: 0;
                            background-color: $white;
                            padding-bottom: 10px;
                            padding-top: 10px;
                            z-index: 10;
                        }
                    }
                }
                &.sticky-image {
                    position: relative;
                }
            }

            .pdp-badges-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                position: absolute;
                top: 32px;
                right: 0;
                z-index: 1;
                @include respond(small) {
                    top: 30px;
                    width: 100%;
                    flex-direction: row-reverse;
                    align-items: end;
                    justify-content: space-between;
                }
                .pdp-badge-image {
                    margin-right: 32px;
                    @include respond(small) {
                        margin-right: 8px;
                        position: absolute;
                        top: -24px;
                    }

                    img {
                        width: rem(60px);
                        position: static;
                        &[src*="members-only"] {
                            display: None;
                        }

                        &.product-badge--trending,
                        &.product-badge--thermoseries {
                            width: rem(120px);
                        }
                    }
                }
                &.pdp-badges-kr {
                    @include respond(small) {
                        top: 0;
                        .pdp-badge-image {
                            top: 0;
                        }
                    }
                }
                .pdp-badge-text {
                    @include respond(largeUp) {
                        height: max-content;
                        position: relative;
                        right: 0;
                    }
                }
            }

            .product-badge {
                font-weight: bold;
                padding: rem(8px) rem(13px);
                text-transform: uppercase;
                @include respond(largeUp) {
                    transform: rotate(180deg);
                    -ms-writing-mode: bt-rl;
                    writing-mode: vertical-rl;
                }
                @include respond(small) {
                    left: rem(10px);
                    position: absolute;
                    right: auto;
                    writing-mode: horizontal-tb;
                }
            }

            .regular.slider {
                margin-bottom: 34px;
                background-color: $grey-inactive;
                @include respond(largeUp) {
                    height: calc(var(--globalMaxHeight, 100vh) - var(--globalDecrementHeight, 28vh));
                }
                .thumb.desktop-hidden {
                    @include respond(largeUp) {
                        display: none;
                    }
                }
				.slick-slide {
					height: auto;
				}
                &.product-set-initial {
                    pointer-events: none;
                    .slick-arrow {
                        @include respond(largeUp) {
                            display: none !important;
                        }
                    }
                }
            }

            &.product-set {
                .regular.slider {
                    @include respond(largeUp) {
                        height: auto;
                    }

                    .slick-slide img {
                        @include respond(largeUp) {
                            object-fit: cover;
                        }
                    }
                }
            }

            &.giftcard-image {
                .pdp-mobile-slider-zoom-btn {
                    display: none;
                }
            }

        }
        #thumbnails {
            .carousel {
                li.product-video {
                    border: 0;
                    .play-text {
                        position: relative;
                        width: 52px;
                        img {
                            width: 52px;
                        }

                        //play button in black circle:
                        &:before {
                            content: "";
                            display: block;
                            width: 40%;
                            height: 40%;
                            background-color: $black;
                            position: absolute;
                            top: 30%;
                            left: 30%;
                            border-radius: 50%;
                            box-shadow: 0px 0px 10px -2px $white;
                        }
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 0px;
                            height: 0px;
                            top: calc(50% - 5px);
                            left: calc(50% - 2px);
                            border-top: solid transparent 5px;
                            border-right: none;
                            border-bottom: solid transparent 5px;
                            border-left: solid $white 8px;
                        }
                        //end: play button in black circle
                    }
                }
            }
        }
        .desktop-hide {
            display: none;
            @include respond(small) {
                display: block;
                .product-video {
                    border-top: 2px solid $clp-border;
                    border-bottom: 2px solid $clp-border;
                    width: 100%;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 10px;
                    img {
                        display: inline;
                        height: 16px;
                    }
                    & > span {
                        display: block;
                        padding: 5px 0;

                        span {
                            font-size: 12px;
                            text-transform: uppercase;
                            line-height: 16px;
                            font-weight: bold;
                            padding-left: 20px;
                            background: url("../images/icons/video-play-icon.png") no-repeat left -6px center;
                            background-size: 32px 32px;
                        }
                    }
                }
            }
        }
    }
    .search-result-items .grid-tile .product-image img {
        width: 100%;
    }
}

.variant-dropdown{
    .label{
        display: none;
    }
}
li#design-your-own-swatch,
li[id^="design-your-blank-swatch"] {
    background-color: $gray-light-bk;
    cursor: pointer;
    line-height: 0;
    padding: 0;
    width: rem(58px);
    height: rem(58px);
    img {
        bottom: 0;
        display: block;
        margin:0 auto !important;
        width:rem(58px) !important;
        height: auto !important;
        position: absolute  !important;
    }

    .text-own {
        color: $black;
        display: block;
        font-size: 10px;
        font-weight: 600;
        margin: 1px auto;
        line-height: 1;
        padding: rem(19px) 0;
        text-align: center;
        text-transform: uppercase;
        width: rem(55px);
        padding: 1.1875rem 0px;
    }
}
.ui-dialog .pdp-main .product-add-to-cart button.dw-apple-pay-button  {
    margin-left:10px!important;
    width: 45%!important;
    min-width: 40%!important;
}

//Added to cart dialog styling
.ui-dialog.just-added-to-cart {
    @include respond(large) {
        max-width: 585px;
    }
    .ui-dialog-title {
        min-height: 24px;
    }
    .ui-dialog-titlebar-close {
        top: 35px;
        @include respond(small) {
            top: 20px;
        }
    }
    .dialog-content {
        box-sizing: content-box;
    }
    .added-to-cart-content {
        width: 545px;
        @include respond(small) {
            width: auto;
        }
        .added-to-cart-header {
            font-family: $neuzeit;
            font-size: 30px;
            line-height: 32px;
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-dark;
            margin-bottom: 16px;
            width: 91%;
            margin-left: 8%;
            @include respond(small) {
                margin-left: 0;
                width: 100%;
            }
            &::before {
                content: url("../images/GreenCheck.png");
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-right: 4px;
                vertical-align: bottom;
            }
        }
        .product-info {
            position: relative;
            .cart-totals {
                position: absolute;
                width: 25%;
                bottom: 0;
                right: 0;
                padding-left: 8px;
                text-align: right;
                box-sizing: border-box;
                @include respond(small) {
                    display: none;
                }
            }
        }
        .added-product {
            width: 75%;
            padding-right: 16px;
            border-right: 1px solid $gray-light;
            box-sizing: border-box;
            @include respond(small) {
                width: 100%;
                padding-right: 0;
                border: none;
            }
            .mini-cart-product {
                padding: 0;
            }
            .mini-cart-image {
                width: 175px;
                min-height: 175px;
                height: auto;
                border: 1px solid #ebebeb;
                padding: 5%;
                box-sizing: border-box;
                @include respond(small) {
                    width: 48%;
                }
                img {
                    max-width: none;
                    width: 100%;
                }
            }
            .mini-cart-name,
            .mini-cart-attributes,
            .mini-cart-pricing {
                float: left;
                width: 192px;
                margin-left: 24px;
                @include respond(small) {
                    width: 46%;
                    margin-left: 6%;
                }
            }
            .mini-cart-name-value {
                font-family: $titillium;
                font-weight: 600;
                font-size: 18px !important;
                line-height: 1em !important;
            }
            .mini-cart-attributes {
                font-size: 12px;
            }
            .mini-cart-pricing {
                .mini-cart-price {
                    display: block;
                    float: none;
                    margin-top: 20px;
                }
            }
        }
        .buttons-group {
            clear: both;
            margin-top: 40px;
            @include respond(small) {
                margin-top: 25px;
            }
            .just-added-button {
                display: inline-block;
                width: 48%;
                @include respond(small) {
                    display: block;
                    width: 100%;
                    margin-bottom: 8px;
                }
                &.continue-shopping {
                    margin-right: 2%;
                    @include respond(small) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .bonus-item-promo {
        .bonus-item-promo-header {
            font-size: 30px;
            letter-spacing: .6px;
            color: $gray-dark;
            margin: 0;
        }
        .bonus-item-details {
            color: $red;
            font-size: 14px;
            font-weight: 700;
            margin: 8px 0 16px;
        }
        .bonus-item-buttons {
            .button {
                width: 48%;
                @include respond(small) {
                    width: 100%;
                    display: block;
                    margin-bottom: 8px;
                }
                &:first-of-type {
                    @include respond(largeUp) {
                        margin-right: 2%;
                    }
                }
            }
        }
    }
    .just-added-recommendations {
        width: 545px;
        @include respond(small) {
            display: none;
        }
        ul#featured-carousel {
            padding-left: 0;
            li.slick-slide {
                margin: 0 10px;
            }
        }
    }
}

.pdp-main {
    .product-add-to-cart {
        #design-your-own {
            font-size:20px;
            font-weight:200;
        }
    }
}

.ui-dialog.zoom-image-dialog {
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
    padding: 0 !important;
    left: 0 !important;
    top: 0 !important;
    .ui-dialog-titlebar {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 16px;
        height: 16px;
    }
   .ui-dialog-titlebar-close {
       &:hover {
           background: transparent;
       }
    }
    .zoom-content {
        padding: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        text-align: center;
        .zoom-image-wrapper {
            background-color: $grey-inactive;
            margin-bottom: 6px;
            img {
                width: 100%;
            }
        }
    }
}

.members-only-modal {
    display: none;
}

.members-only-dialog.ui-dialog {
    padding: 0 !important;
    @include respond(extra-small) {
        left: 2% !important;
        width: 96% !important;
    }
    @include respond(small) {
        z-index:  98 !important;
        top: 8px;
        .ui-dialog-titlebar-close {
            top: 10px;
            height: 30px;
            .ui-button-icon-primary {
                margin: 6px 0 0;
            }
        }
        .account-login.ui-accordion-content-active {
            height: 285px !important;
        }
    }
    @include respond(largeUp) {
        width: 775px !important;
    }
    .ui-dialog-titlebar {
        height: 0;
        padding: 0;
    }
    .ui-dialog-title {
        display: none;
    }
    .ui-dialog-titlebar-close {
        top: rem(28px);
        left: rem(20px);
        right: auto;
        @include respond(small) {
            top: rem(14px);
            left: auto;
            right: -2px !important;
        }
    }
    .members-only-modal {
        padding: 0 !important;
        width: 100% !important;
        @include respond(largeUp) {
            display: flex !important;
            justify-content: space-between;
        }
    }
}

.members-only-content,
.members-only-account {
    box-sizing: border-box;
    @include respond(largeUp) {
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }
}

.members-only-content {
    font-family: $neuzeit;
    text-align: center;
    padding: rem(24px) rem(30px) rem(28px);
    @include respond(largeUp) {
        text-align: left;
        padding: rem(81px) rem(48px) rem(126px) rem(59px);
        width: 49%;
    }
    .logo {
        height: rem(40px);
    }
    .member-access {
        @include font-size(14px);
        display: block;
        color: #6ba4b8;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: rem(36px);
        @include respond(largeUp) {
            margin-top: rem(51px);
        }
    }
    .members-only-title {
        @include font-size(36px);
        @include line-height(36px);
        font-weight: bold;
        margin: rem(16px) 0 rem(44px);
        @include respond(largeUp) {
            margin-bottom: rem(38px);
        }
    }
    ul {
        @include font-size(16px);
        @include line-height(18px);
        text-align: left;
        padding: 0 0 0 rem(16px);
        li {
            padding-left: rem(16px);
            + li {
                margin-top: rem(20px);
            }
        }
    }
}

.members-only-account {
    background: #f6f6f6;
    padding: 0;
    @include respond(largeUp) {
        float: right;
    }
    .account-header {
        @include font-size(24px);
        @include line-height(24px);
        background: none;
        border: none;
        color: $black;
        margin: 0;
        padding: rem(24px);
        font-family: $neuzeit;
        font-weight: bold;
        letter-spacing: normal;
        text-align: left;
        text-transform: none;
        height: auto;
        width: 100%;
        @include respond(largeUp) {
            padding: rem(26px) rem(32px);
        }
        &:hover {
            background: none;
            color: $black;
        }
        &:not(:first-of-type) {
            border-top: 1px solid #dedede;
        }
        &::after {
            font-size: 30px;
            content: "+";
            position: absolute;
            top: 50%;
            right: rem(24px);
            transform: translateY(-50%);
            @include respond(largeUp) {
                right: rem(32px);
            }
        }
        &.ui-accordion-header-active {
            &::after {
                content: "-";
                right: rem(37px);
            }
        }
    }
    .ui-accordion-content {
        padding: 0 rem(24px) rem(30px);
        @include respond(largeUp) {
            padding: 0 rem(32px) rem(76px);
        }
        input {
            @include font-size(16px);
            @include line-height(52px);
            min-height: rem(52px);
            border-radius: 0;
            border-color: #9d9d9d;
            color: #6e6e6e;
            font-family: $neuzeit;
            padding: 0 rem(16px);
            margin-bottom: rem(15px);
            &:first-of-type {
                margin-top: rem(4px);
            }
            &[type="checkbox"] {
                + label {
                    display: flex;
                    @include line-height(18px);
                    background: none;
                    > .styled-checkbox {
                        position: relative;
                        display: inline-block;
                        width: rem(16px);
                        height: rem(16px);
                        margin-right: rem(8px);
                        border: 1px solid $blue-dark;
                        vertical-align: middle;
                        box-sizing: content-box;
                    }
                }
            }
        }
        input[type="checkbox"]:checked + label > .styled-checkbox,
        label.checked .styled-checkbox {
            background: $blue-dark;
            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 3px;
                background: #fff;
                display: block;
            }
            &::before {
                width: 8px;
                bottom: 5px;
                transform: rotate(45deg);
            }
            &::after {
                width: 14px;
                right: -1px;
                bottom: 7px;
                transform: rotate(-45deg);
            }
        }
        label {
            @include font-size(11px);
            font-family: $neuzeit;
            font-weight: normal;
            margin: rem(11px) 0 rem(27px);
            padding: 0;
            &[for$="customer_addtoemaillist_header"] {
                margin: rem(2px) 0 rem(30px);
            }
        }
        button {
            @include font-size(15px);
            font-family: $neuzeit;
            font-weight: bold;
            height: rem(60px);
            width: 100%;
        }
        .account-link {
            @include font-size(11px);
            @include line-height(13px);
            display: inline-block;
            font-family: $neuzeit;
            vertical-align: middle;
            width: calc(100% - 30px);
            a {
                color: #2e485d;
                font-weight: bold;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
        .password-link {
            margin-top: rem(18px);
        }
    }
}

.customizable-product-pdp {
	.product-thumbnails .thumb.slick-slide.is-active .thumbnail-link::after {
		height: 2px;
	}

	.swatches .selected .swatchanchor {
		background: $gray-lighter;

		&::after {
			height: 2px;
		}
	}

	.product-variations .color .selected-value {
		font-weight: normal;
		font-size: 13px;
	}

	.product-variations .attribute .label,
	.custom-select-field .select-header,
	&.pdp-main .product-add-to-cart .inventory-container .inventory label {
		color: $black;
		padding-bottom: 8px;
		font-size: 13px;
	}

	.custom-select-field .spacerbar {
		width: 100%;
		margin: 16px 0px 16px 3px;
	}

	.product-variations ul li#design-your-own-swatch, li[id^="design-your-blank-swatch"] {
		max-height: none;

		&.new-design .text-own {
			font-size: 11px;
			padding-top: 12px;
			padding-bottom: 13px;
		}
	}

	&#pdpMain .product-detail .pdpForm .product-add-to-cart {
		align-items: flex-end;

		.cart-buttons-container .cart-button {
			margin-left: 0;
		}

		.cart-buttons-container {
			margin-top: 12px;
			flex-wrap: wrap;
		}

		.inventory-container {
			margin-right: 4px;
			margin-bottom: 0;
		}

		.add-to-cart {
			border-width: 2px;
		}

		.low-inventory-msg {
			position: static;
			text-align: right;
			padding-bottom: 5px;
			margin-top: -10px;
			width: 100%;
		}
	}

	.product-primary-image .slick-dots li button {
		height: 7px;
	}

	.swatches.color li:not(.selected-value)  {
		width: 3.625rem;;
		height: 3.625rem;

		a {
			width: 3.625rem;;
			height: 3.625rem;
		}
	}

	.pdp-my-designs {
        &.manage-active .select-header {
            .custom-designs-save {
                display: block;
            }

            .custom-designs-manage {
                display: none;
            }
        }

        &.manage-active .delete-candidate-message {
            display: block;
        }

		.select-header {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.custom-designs-manage,
            .custom-designs-save {
				color: $black;
				font-weight: normal;
				text-decoration: underline;
			}

            .custom-designs-save {
                display: none;
            }
		}

		.swatches li {
			width: 3.625rem;
			height: 3.625rem;
			background: $gray-lighter;
            cursor: pointer;

            .custom-design-swatch-img {
                width: 3.625rem;
                height: 3.625rem;
            }

			&.delete-candidate {
				position: relative;

				&:after {
					content: '';
					position: absolute;
                    left: 0;
					width: 100%;
					height: 100%;
					border: 1px solid $red-sale;
					box-sizing: border-box;
				}

                &:before {
                    background: url("../images/icons/red-cross.png") no-repeat;
                    top: 4px;
					content: '';
					position: absolute;
                    right: 4px;
                    width: 12px;
                    height: 12px;
                    background-size: 12px;
                }
			}
		}

		.spacerbar {
			background-color: $gray-outline;
			display: block;
			height: 1px;
			margin: 16px 0;
			width: 100%;
		}

		.delete-candidate-message {
			color: $red-sale;
			padding-top: 8px;
            display: none;
		}
	}

    .my-designs-line {
        padding-bottom: 20px;
        border-bottom: solid 1px #b5b5b5;
        margin-bottom: 15px;
        .select-header {
            text-transform: none;
        }
    }

	.customizer-different-size-button {
		padding-bottom: 10px;
		position: relative;

		@include respond(largeUp) {
			padding: 10px 32px 0;
		}

		input[type="checkbox"] {
			& + label {
				display: flex;
				width: auto;
				flex-direction: row;
				cursor: pointer;
				line-height: 18px;
				font-weight: normal;
				text-transform: none;
				position: relative;
				background: none;
				padding: 0 0 0 25px;

				& > .styled-checkbox {
					position: absolute;
					display: inline-block;
					width: 18px;
					height: 18px;
					left: 0;
					background: $white;
					border: solid 2px $black;
					border-radius: 2px;
					box-sizing: border-box;
				}
			}

			&:checked + label > .styled-checkbox {
				background: $black;
				&::before,
				&::after {
					content: "";
					position: absolute;
					height: 3px;
					background: $white;
					display: block;
				}

				&::before {
					width: 8px;
					bottom: 4px;
					left: -1px;
					transform: rotate(45deg);
				}

				&::after {
					width: 14px;
					right: -2px;
					bottom: 6px;
					transform: rotate(-45deg);
				}
			}
		}
	}
}

html.overflow-hidden {
    overflow: hidden;
}

.product-image-container:not(.product-set):not(.quickview-image-container) {
    @include respond(small) {
        height: calc(80vw + 34px);
        overflow: hidden;
    }
    .regular.slider {
        overflow: hidden;
        @include respond(small) {
            height: 80vw;
            overflow: visible;
        }
    }

    .thumbnails-container {
        @include respond(largeUp) {
            height: 56px;
            overflow: hidden;
        }
    }

    .primary-image {
        @include respond(small) {
            opacity: 0;
            padding-bottom: 34px;
        }

        &.lazyloaded {
            @include respond(small) {
                opacity: 1;
            }
        }
    }
}

@import "notify_me";
@import "shop_the_look";
