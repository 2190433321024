.pt_storefront.home {
    .menu-toggle, .mobile-hamburger-toggle {
        margin-top: 0;
    }
}

.level-1 > li {
    .country-container {
		display: none;
	}
}

.nav-mobile-only {
	// Countries Container
	.countries-container {
		padding: 18px 20px 16px;
		border-top: 2px solid $gray-darker;
		& > a {
			background: url("../images/countries-icons.gif") 140px center no-repeat;
			padding-left: 20px;
			text-transform: uppercase;
			color: $black;
			border-top: none;
			font-size: 18px;
			line-height: 22px;
			padding: 0%;
			padding-right: 30px;
			&::after {
				right: 4px;
			}
			&.gb {
				background: url("../images/uk.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.de{
				background: url("../images/germany.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.fr{
				background: url("../images/france.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.es {
				background: url("../images/spain.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.ie{
				background: url("../images/ireland.gif") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.be {
				background: url("../images/belgium.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.dk {
				background: url("../images/denmark.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.fi {
				background: url("../images/finland.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.it {
				background: url("../images/italy.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.se {
				background: url("../images/sweeden.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.at {
				background: url("../images/austria.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.lu {
				background: url("../images/luxembourg.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.nl {
				background: url("../images/netherlands.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.sk {
				background: url("../images/slovakia.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.si {
				background: url("../images/slovenia.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.pt {
				background: url("../images/portugal.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.au {
				background: url("../images/australia.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
			&.nz {
				background: url("../images/newzealand.jpg") left center no-repeat;
				background-size: 18px 14px;
    			background-position: 24px 3px;
			}
		}
		.country-container{
			position: absolute;
			width: 100%;
			top: 1px;
			right: 0;
			overflow: scroll;
			background-color: $white;
			border-bottom: 0;
			box-sizing: border-box;
			display: none;
			z-index:53;
			padding-bottom: 10%;
			.home-button {
				display: block;
			}
			h2{
				float: left;
				width: 100%;
				padding: 10px 10px 10px 0px;
				box-sizing: border-box;
				color: $gray-dark;
				line-height: 23px;
				margin: 0;
				text-align: center;
				font-size: 20px;
				a.close-countries{
					background: url("../images/interface/close.jpg") left top no-repeat;
					height: 23px;
					width: 24px;
					float: right;
					opacity: 0.8;
					&:hover{
						opacity: 1;
					}
				}
			}
			.country-selector, .content-asset {
				position: relative;
				width: 100%;
				display: flex;
				margin: 0 auto;
				align-items: center;
				flex-direction: column;
				.location {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					padding-left: 10%;
					@include respond(small){
						border: 0;
					}

					h2 {
						border: none;
						text-align: left;
						padding-top: 20px;
						font-size: 18px;
					}
					
					span{
						float: left;
						width: fit-content;
						box-sizing: border-box;
						text-align: left;
						padding: 1% 0;
						padding-left: 20px;
						font-size: 16px;
						a{
							color: $gray-dark;
							font-size: 16px;
							line-height: 18px;
							text-decoration: underline;
							display: inline-block;
							background: url("../images/countries-icons.gif") left center no-repeat;
							padding-left: 40px;
							border-top: none;
							padding-top: 0;
							padding-bottom: 0;
							text-transform: none;

							&:hover{
								text-decoration: underline;
							}
							img{
								float: left;
								margin: 5px 20px 0 10px;
							}
							&::after {
								content: none;
							}
						}
						&.austr{
							a{
								background-position: 10px 1px;
							}
						}
						&.fr_FR{
							position: relative;
							a {
								background: url("../images/france.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 8px 2px;
								position: relative;
							}
							@include basket-icon-mobile;
						}
						&.en_FR {
							position: relative;
							display: none;
							a {
								background: url("../images/france.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
								position: relative;
							}
							@include basket-icon-mobile;
						}
						&.en_ES {
							position: relative;
							a {
								background: url("../images/spain.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_DE {
							position: relative;
							display: none;
							a {
								background: url("../images/germany.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.de_DE {
							position: relative;
							a {
								background: url("../images/germany.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_IE{
							position: relative;
							a{
								background: url("../images/ireland.gif") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_BE {
							position: relative;
							a{
								background: url("../images/belgium.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_DK {
							position: relative;
							a{
								background: url("../images/denmark.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_FI {
							position: relative;
							a{
								background: url("../images/finland.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_IT {
							position: relative;
							a{
								background: url("../images/italy.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_SE {
							position: relative;
							display: none;
							a {
								background: url("../images/sweeden.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_AT {
							position: relative;
							a {
								background: url("../images/austria.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_LU {
							position: relative;
							a{
								background: url("../images/luxembourg.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_NL {
							position: relative;
							a{
								background: url("../images/netherlands.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_SK {
							position: relative;
							a{
								background: url("../images/slovakia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_SI {
							position: relative;
							a{
								background: url("../images/slovenia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_PT {
							position: relative;
							a{
								background: url("../images/portugal.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_AU{
							position: relative;
							a{
								background: url("../images/australia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8x 2px;
							}
							@include basket-icon-mobile;
						}
						&.sv_SE {
							position: relative;
							a {
								background: url("../images/sweeden.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en_GB {
							position: relative;
							a {
								background: url("../images/uk.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
							@include basket-icon-mobile;
						}
						&.en {
							display: none;
							a {
								background: url("../images/uk.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 8px 2px;
							}
						}
						&.malas{
							a{
								background: url("../images/malaysia.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.singapore{
							a{
								background: url("../images/singapore.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.usa{
							position: relative;
							a{
								background: url("../images/us.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
							@include basket-icon-mobile;
						}
						&.southafrica {
							a{
								background: url("../images/southafrica.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.thai{
							a{
								background: url("../images/thailand.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.mexico{
							a{
								background: url("../images/latin.gif") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.australia{
							a{
								background: url("../images/australia.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.japan{
							a{
								background: url("../images/japan.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.korea{
							a{
								background: url("../images/korea.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.china{
							a{
								background: url("../images/china.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
						}
						&.canada{
							position: relative;
							a{
								background: url("../images/canada.jpg") left center no-repeat;
								background-size: 24px 16px;
 								background-position: 8px 4px;
							}
							@include basket-icon-mobile;
						}
					}
				}
			}
		}
	}
}

.data-content-asset {
	display: contents;
}
