///////////////////////
/// Styles for Checkout Header
///////////////////////
#wrapper.pt_order-confirmation,
#wrapper.pt_checkout {
	header {
		.top-banner{
			.menu-utility-user{
				@include respond(small) {
					margin-top:20px;
				}
				li{
					&#mini-cart{
						.mini-cart-content{
							top: 81px;
							&.cart-show {
								@include respond(small) {
									display:none!important;
								}
							}
						}
						a {
							&.mini-cart-link {
								@include respond(small) {
									position:relative;
									padding:0;
									top:0;
								}
								@include respond(largeUp) {
									height: 20px;
									padding-left:0;
								}
							}
						}
					}
				}
			}
		}
	}
	footer {
        background: none;
        .footer-container {
            .footer-bottom {
				@include respond(largeUp) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
                .left-checkout-footer {
					@include respond(small) {
						padding-left: 27px;
						padding-right: 27px;
						box-sizing: border-box;
					}
					@include respond(largeUp) {
						float: none;
						margin-left: auto;
						margin-right: auto;
						.content-asset ul li {
							padding: 0 18px;
						}
					}
                }
				.banner-security {
					@include respond(small) {
						padding-left: 27px;
						padding-right: 27px;
						box-sizing: border-box;
						& > span {
							width: 33%!important;
						}
					}
					@include respond(largeUp) {
						float: none;
						display: flex;
						justify-content: space-between;
						max-width: 635px;
						width: 100%;
						margin-left: auto;
						margin-right: auto;
                    }
                    .data-content-asset {
                        & > span {
                            font-weight: bold;
                            text-transform: uppercase;
                            color: $gray-dark;
                            width: initial;
                            display: inline-block;
                            vertical-align: middle;
                            padding-left: 29px;
                            line-height: 120%;
                            text-align: left;
                            float: left;
                            box-sizing: border-box;
                            background: url('../images/security-icons.gif') no-repeat left 2px;
                            @include respond(small) {
                                width: 33%;
                                padding: 30px 0 0;
                                float: left;
                                font-size: 11px;
                                text-align: center;
                            }
                            @include respond(large) {
                                padding-left: 28px;
                            }

                            &.secure {
                                @include respond(small) {
                                    background: url('../images/secure-tr-icon.gif') no-repeat center top;
                                }
                            }

                            &.returns {
                                background-position: left center;
                                line-height: 30px;
                                @include respond(small) {
                                    background: url('../images/return-center-icon.gif') no-repeat center top;
                                    line-height: 120%;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }

                            &.phone {
                                background-position: left -55px;

                                span {
                                    color: $gray-warm;
                                    font-size: 12px;
                                    text-transform: none;
                                    font-weight: normal;
                                }

                                @include respond(small) {
                                    line-height: 120%;
                                    background-position: center -57px;
                                    span {
                                        padding: 0;
                                        width: 100%;
                                        float: none;
                                        font-weight: normal;
                                        color: $gray-warm;
                                    }
                                }
                            }
                        }
                    }
				}
            }
        }
    }
	.footer-copy {
		@include respond(largeUp) {
			padding: 25px 65px 60px;
		}
	}
}
