.breadcrumb-element {
    a {
        color: #999;
    }
}

#wrapper.pt_product-search-result {
    #main {
        margin-top: rem(165px);
        @include respond(small) {
            margin-top: rem(90px);
        }
        #secondary {
            @include respond(small) {
                padding: 0 20px;
            }
            @include respond(largeUp) {
                padding-top: 0;
            }
            .mobile-refinements-active & {
                @include respond(small) {
                    padding-bottom: 92px;
                    margin-top: 25px;
                }
            }
        }
        &::after {
            .mobile-refinements-active & {
                @include respond(small) {
                    content: '';
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    z-index: 60;
                    background-color: white;
                }
            }
        }
    }
}
.pt_product-search-result {
    .slot-grid-header {
        @include respond(small) {
            margin: 0 0 24px 0;
        }
        .category-top-banner {
            @include respond(small) {
                margin-top: -16px;
            }

            &.one-section-banner {
                .banner-section-wrapper {
                    width: 100%;
                    .banner-section-text {
                        @include respond(largeUp) {
                            width: 50%;
                        }
                    }
                }
            }
            &.two-section-banner {
                .banner-section-wrapper {
                    display: block;
                    float: left;
                    &:nth-of-type(1) {
                        @include respond(largeUp) {
                            width: 50%;
                        }
                    }
                    &:nth-of-type(2) {
                        display: none;
                        @include respond(largeUp) {
                            display: block;
                            width: 50%;
                        }
                        .banner-section-text {
                            padding-bottom: 20px;
                            h2 {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            &.three-section-banner {
                .banner-section-wrapper {
                    float: left;
                    &:nth-of-type(1) {
                        @include respond(largeUp) {
                            width: 50%;
                        }
                    }
                    &:nth-of-type(2) {
                        display: none;
                        @include respond(largeUp) {
                            display: block;
                            width: 25%;
                        }
                        .banner-section-text {
                            padding-bottom: 20px;
                            h2 {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    &:nth-of-type(3) {
                        display: none;
                        @include respond(largeUp) {
                            display: block;
                            width: 25%;
                        }
                        .banner-section-text {
                            padding-bottom: 20px;
                            h2 {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            .banner-section-wrapper {
                position: relative;
                width: 100%;
                min-height: 140px;
                overflow: hidden;
                box-sizing: border-box;
                @include respond(largeUp) {
                    min-height: 265px;
                }
                @include respond(xlUp) {
                    min-height: 18vw;
                }
                .background-wrapper {
                    @include respond(small) {
                        position: relative;
                        width: 100%;
                        height: calc(37.5vw);
                    }
                    .banner-section-background {
                        position: absolute;
                        max-width: none;
                        width: auto;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .banner-section-text {
                    width: 100%;
                    box-sizing: border-box;
                    font-family: $neuzeit;
                    padding: 0 20px;
                    @include respond(small) {
                        padding: 0 24px;
                        position: relative;
                        margin-top: -93px;
                        margin-bottom: 0;
                    }
                    @include respond(largeUp) {
                        position: absolute;
                        bottom: 0;
                        padding: 30px;
                    }
                    &.light-text {
                        h1, h2, h3, h4, h5, h6, p, span, a {
                            color: $white;
                        }
                        #main-banner-title {
                            &::after {
                                background: url("../images/interface/icon-arrow-down-white.svg") no-repeat center;
                            }
                        }
                        .cta-button {
                            &:hover {
                                &::after {
                                    background-color: $white;
                                }
                            }
                        }
                    }
                    &.expanded {
                        @include respond(small) {
                            h2 {
                                margin-bottom: 47px;
                            }
                            #main-banner-title {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            .main-banner-description {
                                display: block;
                            }
                        }

                    }
                    #main-banner-title {
                        position: relative;
                        &::after {
                            @include respond(small) {
                                content: '';
                                background: url("../images/interface/icon-arrow-down-black.svg") no-repeat center;
                                display: inline-block;
                                width: 12px;
                                height: 7px;
                                position: absolute;
                                right: 0;
                                top: 18px;
                            }
                        }
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: normal;
                        letter-spacing: .05rem;
                        @include respond(small) {
                            margin-bottom: 20px;
                            padding-right: 12px;
                            max-height: 42px;
                        }
                        @include respond(largeUp) {
                            margin: 0 0 12px;
                        }
                    }
                    h3 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: .01rem;
                    }
                    .cta-button {
                        display: inline-block;
                        color: black;
                        text-transform: uppercase;
                        font-family: $din-condensed;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        position: relative;
                        padding-bottom: 4px;
                        &:hover {
                            &::after {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 3px;
                                background-color: $black;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                    }
                    .main-banner-description {
                        @include respond(small) {
                            display: none;
                            margin-bottom: 20px;
                        }
                        p {
                            font-size: 16px;
                            @include respond(small) {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-refinements-active {
    @include respond(small) {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}

.plp-actions {
    background-color: $white;
    margin: 0 20px 20px;
    position: relative;
    box-sizing: border-box;
    border-top: 1px solid $gray-darker;
    border-bottom: 1px solid $gray-darker;
    font-family: $neuzeit;

    @include respond(largeUp) {
        margin: 0 0 28px;
    }
    .mobile-refinements-active & {
        @include respond(small) {
            position: fixed;
            width: 100%;
            top: 26px;
            left: 0;
            z-index: 99999;
            padding: 0 20px;
            margin: 0;
            border: none;
        }
    }

    .plp-actions-button {
        box-sizing: border-box;
        @include respond(small) {
            margin: 8px 0;
        }

        body:not(.mobile-refinements-active) & {
            @include respond(small) {
                float: left;
                width: 50%;
                text-align: center;
            }
        }
        &.refinements-toggle-button {
            border-right: 1px solid $gray-darker;
            @include respond(largeUp) {
                float: left;
                border: none;
            }

            .mobile-refinements-active & {
                @include respond(small) {
                    border: none;
                    border-bottom: 1px solid $gray-darker;
                    margin-bottom: 0;
                    padding-bottom: 8px;
                }
            }
        }
        &.sort-by-button {
            .mobile-refinements-active & {
                @include respond(small) {
                    display: none;
                }
            }
        }
        .plp-actions-toggle-refine {
            display: inline-block;
            color: $black;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 47px;
            letter-spacing: 0;
            background: url(../images/icons/icon-filter.svg) no-repeat left center;
            background-size: 16px 16px;
            padding-left: 24px;
            box-sizing: border-box;
            .mobile-refinements-active & {
                @include respond(small) {
                    display: block;
                    padding-right: 52px;
                    line-height: 26px;
                    background: url(../images/icons/icon-filter.svg) no-repeat left center, url(../images/icons/icon-remove-refinement.svg) no-repeat right center;
                    background-size: 16px, 16px;
                }
            }
            @include respond(largeUp) {
                display: block;
                width: 240px;
                padding-left: 48px;
                line-height: 58px;
                margin: 8px 0;
                background-position: 25px center;
                font-size: 14px;
            }

            .results-count {
                display: none;
                color: $gray-warm;
                @include respond(largeUp) {
                    margin-left: 12px;
                    display: inline-block;
                }
                &::before {
                    @include respond(largeUp) {
                        content: '('
                    }
                }
                &::after {
                    @include respond(largeUp) {
                        content: ')'
                    }
                }
                .mobile-refinements-active & {
                    @include respond(small) {
                        display: block;
                        float: right;
                    }
                }
            }
        }
        .sort-by {
            float: none;
            height: auto;
            margin: 4px auto 0;
            @include respond(small) {
                min-width: auto;
                max-width: none;
            }
            @include respond(largeUp) {
                width: 364px;
                float: right;
                padding: 11px 0 11px 30px;
                margin-right: 18px;
                margin-top: 8px;
                box-sizing: border-box;
            }
			@include respond(880px, 1024px) {
				width: 319px;
			}
            label {
                display: none;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0;
				margin-right: 22px;
                text-transform: uppercase;
                @include respond(largeUp) {
                    display: inline-block;
					margin-right: 50px;
                    font-size: 14px;
                }
				@include respond(880px, 1024px) {
					margin-right: 22px;
				}
            }
            select {
                width: auto;
                @include respond(small) {
                    font-size: 16px;
                    letter-spacing: 0;
                    border: none;
                    background: transparent url(../images/selectArrowBlack.svg) no-repeat right 8px center;
                    padding-left: 8px;
                    padding-right: 32px;
                    max-width: 100%;
                }
                @include respond(largeUp) {
                    width: 216px;
                    font-size: 14px;
                    padding-right: 32px;
                }
            }
        }
    }
}

.plp-refinement-mobile-cta {
    display: none;
    position: fixed;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    z-index: 100;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0 -3px 10px rgba(0,0,0, .3);
    box-sizing: border-box;
    .mobile-refinements-active & {
        @include respond(small) {
            display: block;
        }
    }
    button,
    .button {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 46px;
        height: 48px;
        width: 48%;
        margin-top: 11px;
        &.button-primary {
            background-color: $black;
            color: $white;
        }
        &.button-secondary {
            float: right;
        }
    }
}

.search-result-content {
    max-width: none;
    &.phrase-search {
        border-bottom: none;
    }
}

.search-result-items {
    width: 100%;
    border-top: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 40px;

    .grid-tile {
        @include respond(largeUp) {
            padding: 0 0 0 22px;
            flex: 0 0 25%;
            margin-bottom: 0;
        }
		@include respond(small) {
			&.wide-tile-1x,
			&.wide-tile-2x {
				flex: 0 0 100%;
				margin-right: 0;
			}
		}

        &.wide-tile-2x {
            width: 100%;
            flex-basis: 100%;
            @include respond(largeUp) {
                flex: 0 0 50%;
            }
        }
        &.wide-tile-3x {
            width: 100%;
            flex-basis: 100%;
        }

    }
}

.wide-tile-1x,
.wide-tile-2x {
	&.hidden {
		display: none;
	}
	.product-image,
	.thumb-link,
	img	{
		height: 100%;
	}
}

.p-w-r .pr-snippet.pr-category-snippet .pr-category-snippet__item.pr-category-snippet__total {
    display: table-cell !important;
}

#refined-by-attributes {
    display: none;
    padding-top: 24px;
    padding-bottom: 16px;
    border-bottom: none;
    font-family: $neuzeit;
    @include respond(largeUp) {
        width: calc(100% - 622px);
        min-height: 58px;
        padding: 14px 92px 6px 20px;
        margin: 8px 0;
        display: block;
        float: left;
        box-sizing: border-box;
        border-left: 1px solid $gray-darker;
        border-right: 1px solid $gray-darker;
    }
	@include respond(880px, 1024px) {
		width: calc(100% - 577px);
	}

    &.not-refined {
        .mobile-refinements-active & {
            @include respond(small) {
                display: none;
            }
        }
    }

    .mobile-refinements-active & {
        @include respond(small) {
            display: block;
            border-bottom: 1px solid $gray-darker;
        }
    }
}

.selected-refinement {
    display: inline-block;
    width: auto;
    border: 1px solid $gray-darker;
    border-radius: 3px;
    padding: 6px 10px;
    margin-right: 4px;
    margin-bottom: 8px;
    .mobile-refinements-active & {
        @include respond(small) {
            padding: 6px 10px;
            margin-bottom: 8px;
        }
    }
    .selected-refinement-value {
        font-size: 14px;
        margin-right: 5px;
    }
    a {
        margin: 0;
        background: url(../images/icons/icon-remove-refinement.svg) no-repeat center;
        background-size: 12px 12px;
    }
}

span.clear-all-refinements {
    display: none;
    @include respond(largeUp) {
        display: block;
        position: absolute;
		right: 407px;
        top: 28px;
    }
	@include respond(880px, 1024px) {
		right: 362px;
	}

    a {
        padding-top: 0;
        font-family: $font-neuzeit;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
            @include respond(largeUp) {
                text-decoration: underline;
            }
        }
    }
}

#wrapper.pt_product-search-result {
    .primary-content {
        box-sizing: border-box;
    }
}

#wrapper.pt_product-search-result {
    #secondary {
        display: none;
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 48px;
        @include respond(largeUp) {
            display: block;
            width: 240px;
        }
        .mobile-refinements-active & {
            @include respond(small) {
                display: block;
                position: fixed;
                width: 100%;
                height: 100vh;
                left: 0;
                background-color: $white;
                overflow: scroll;
                z-index: 99;
            }
        }
        .hide-refinements & {
            @include respond(largeUp) {
                display: none
            }
        }
    }
}
