.store-locator {
    #stores {
        &.no-stores {
            .stores-header {
                &.no-stores {
                    display: block;
                }
            }
        }
    }
}

#store-search-form {
    select,
    input {
        &.error {
            background-color: $gray-white;
        }
    }

    div {
        &.zip-error,
        &.country-error {
            display: none;

            &.not-valid {
                display: block;
            }
        }
    }
}
