#wrapper.pt_content #primary .content-asset {
    .footer__links__title {
        margin: 0;
        padding: 20px 0;
        font-family: $neuzeit;
        font-weight: 700;
        font-size: 22px;
        line-height: normal;
        letter-spacing: normal;
        color: $dark;
        text-transform: none;
    }

    .footer__links__title__collapse-button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        padding: 0 35px 0 0;
        line-height: normal;

        &:hover {
            background: none;
            border: none;
            padding: 0;

            @include respond(small) {
                &::after {
                    top: 50%;
                }
            }
        }

        @include respond(small) {
            &::after {
                content: '—';
                display: block;
                position: absolute;
                left: initial;
                bottom: initial;
                right: 0;
                top: 50%;
                background: none;
                transform: translateY(-50%);
                font-size: 40px;
                font-weight: 700;
                line-height: 1;
            }
        }

        &.collapsed {
            @include respond(small) {
                &::after {
                    content: '+';
                }
            }
        }
    }

    .footer__links {
        margin-bottom: 40px;
    }

    .footer__links__title__collapse-button {
        display: none;
    }

    .footer__help__description {
        margin-top: 0;
        margin-bottom: 20px;
        padding: 0;
        color: $dark;
        font-family: $neuzeit;
        font-size: 16px;
        line-height: 22px;
    }

    .footer__help__number {
        font-family: $neuzeit;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
        color: $dark;
    }

    .footer__help__time {
        margin-top: 0;
        margin-bottom: 20px;
        padding: 0;
        font-family: $neuzeit;
        color: $dark;
        font-size: 16px;
        line-height: 22px;
    }

    .footer__help__link {
        font-family: $neuzeit;
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
        color: $dark;
    }

    .countries-container {
        display: none;
    }
}
