.shop-the-look {
    border-bottom: 1px solid #D8D8D8;
    padding: 0 0 30px 0;

    img {
        width: 100%;
    }

    h2 {
        font-family: $font-neuzeit;
        font-size: rem(32px);
        font-weight: 700;
        letter-spacing: normal;
        margin: 30px auto 0;
        text-align: center;
        text-transform: none;

        @include respond(largeUp) {
            font-size: rem(40px);
            margin: 50px auto 0;
            text-align: left;
        }
    }

    .progress {
        display: none;
    }

    .stl-container {
        margin: 30px auto 0;

        @include respond(largeUp) {
            padding: 0 98px 100px;
            margin: 60px auto 0;
        }


        &.stl-slider {
            .slick-arrow {
                @include respond(small) {
                    display: none !important;
                }

                @include respond(largeUp) {
                    background: url("../images/carouselBlackArrowLeft.svg") no-repeat center;
                    background-size: 12px;
                    display: block;
                    height: 88px;
                    width: 50px;
                    position: absolute;
                    top: calc(50% - 148px);

                    &.slick-prev {
                        left: 32px;
                        transform: rotate(0);
                    }

                    &.slick-next {
                        right: 32px;
                        transform: rotate(180deg);
                    }

                    &.slick-disabled {
                        opacity: 0;
                        cursor: default;
                    }
                }
            }
        }

        .stl-grid-container {
            margin: auto;

            &.grid-container-3 {
                @include respond(largeUp) {
                    max-width: 75%;
                }
            }

            &.grid-container-4 {
                @include respond(largeUp) {
                    max-width: 50%;
                }
            }

            &.grid-container-6 {
                @include respond(largeUp) {
                    max-width: 75%;
                }
            }
        }

        .stl-grid {
            display: grid;
            gap: 9px;
            margin: auto;

            &.grid-3 {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);

                @include respond(largeUp) {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }

                .enlarge {
                    grid-column: span 2 / span 2;
                    grid-row: span 2 / span 2;

                    @include respond(largeUp) {
                        grid-column: span 2 / span 2;
                        grid-row: span 2 / span 2;
                    }
                }
            }

            &.grid-4 {
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            &.grid-5 {

                @include respond(largeUp) {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }

                .enlarge {
                    grid-column: span 2 / span 2;
                    grid-row: span 2 / span 2;
                }
            }

            &.grid-6 {
                @include respond(small) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }

                @include respond(largeUp) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &.grid-7 {
                @include respond(small) {
                    grid-template-rows: repeat(4, 1fr);
                }

                @include respond(largeUp) {
                    grid-template-columns: repeat(5, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }

                .enlarge {
                    grid-column: span 2 / span 2;
                    grid-row: span 2 / span 2;
                }
            }

            &.grid-8 {
                @include respond(small) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-template-rows: repeat(4, 1fr);
                }

                @include respond(largeUp) {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                }
            }

            .stl-product {
                background-color: $gray-lighter;
                display: block;
                height: 100%;
                width: 100%;
                order: 2;
                display: flex;
                flex-direction: column;
                justify-content: end;
                overflow: hidden;

                &:not(.enlarge):hover() {
                    .stl-product-details {
                        top: auto;
                        bottom: 0;
                    }
                }

                &.active-product {
                    order: 1;
                }

                &.enlarge {
                    @include respond(small) {
                        grid-column: span 2 / span 2;
                    }

                    @include respond(largeUp) {
                        grid-row: span 2 / span 2;
                    }
                }
            }
        }

        .explore-set {
            color: $black;
            display: block;
            font-family: $font-neuzeit;
            font-size: rem(16px);
            font-weight: 400;
            letter-spacing: normal;
            margin: 30px auto;
            text-align: center;
            text-decoration: underline;

            @include respond(largeUp) {
                font-size: rem(24px);
                margin: 37px 20px 0;
                text-align: right;

                &::after {
                    content: ' ->';
                }
            }
        }

        .stl-product-details {
            background-color: $white;
            font-family: $font-neuzeit;
            display: flex;
            flex-direction: column;

            @include respond(largeUp) {
                background-color: #ffffffd9;
                border: 1px solid $gray-lighter;
                bottom: -100%;
                top: auto;
                position: absolute;
                transition: bottom .5s ease-out;
                height: 84px;
                width: calc(100% - 2px);
            }

            button,
            .button {
                border-width: 1px;
                font-family: $font-neuzeit;
                font-size: rem(14px);
                font-weight: 400;
                height: 30px;
                letter-spacing: normal;
                line-height: 30px;
                margin: auto;
                max-width: 238px;
                padding: 0;
                text-transform: capitalize;
                white-space: nowrap;
                width: 95%;

                @include respond(largeUp) {
                    background-color: $black;
                    color: $white;
                    font-size: rem(18px);
                    height: 36px;
                    line-height: 36px;

                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }
            }

            #quickviewbutton {
                border-color: #D8D8D8;
                font-size: rem(16px);
                font-weight: 400;
                height: 30px;
                position: relative;
                margin: auto;

                @include respond(largeUp) {
                    border: none;
                    display: flex;
                    height: 36px;
                    font-size: rem(16px);

                    &::before {
                        background: url("../images/icons/quick-shop-white.png") no-repeat center center;
                        background-size: 100%;
                    }

                    &:hover {
                        background-color: $black;
                        color: $white;
                    }
                }
            }

            .product-name-price {
                display: flex;
                font-size: rem(15px);
                gap: 20px;
                justify-content: space-between;
                line-height: 20.4px;
                margin: 0;
                padding: 13px 8px;
                text-align: left;

                @include respond(largeUp) {
                    font-size: rem(17px);
                    padding: 8px;
                }

                .name-link {
                    color: $black;
                    font-size: rem(15px);
                    font-weight: 400;
                    letter-spacing: normal;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 60%;

                    @include respond(largeUp) {
                        font-size: rem(17px);
                        max-width: 70%;
                    }
                }
            }

            .product-sale-price,
            .product-sales-price {
                color: $black;
                font-weight: 700;
                width: 40%;
                text-align: right;
                white-space: nowrap;

                @include respond(largeUp) {
                    width: 30%;
                }
            }

            .product-sales-price {
                color: #D00000;
            }

            .product-standard-price {
                display: none;
            }
        }

        .active-product {
            &.enlarge {
                @include respond(largeUp) {
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    justify-content: end;
                }

                img {
                    @include respond(largeUp) {
                        max-width: 70%;
                        margin: auto;
                    }
                }

                .button {
                    @include respond(largeUp) {
                        height: 45px;
                        line-height: 45px;
                        max-width: 253px;
                    }
                }

                .stl-product-details {
                    @include respond(largeUp) {
                        background-color: transparent;
                        border: none;
                        bottom: 20px;
                        height: auto;
                        max-width: 65%;
                        margin: 20px auto;
                        position: relative;
                    }
                }
            }
        }
    }
}