/////////////////////////
// Checkout Shipping Step
/////////////////////////
#wrapper.pt_checkout{
	#main{
		select.error{
			background-color: $gray-white;
			@include font-small;
		}
		.checkout-name{
			font-size: 30px;
			line-height: 36px;
			font-weight: bold;
			margin: 25px 0;
			text-transform: uppercase;
			@include respond(small) {
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 17px;
			}
		}
		#primary{
			width: 66.7%;
			@include respond(small) {
				width: 100%;
			}
			&.fullbleed{
				width: 100%;
			}
			/*Error message on last step of checkout*/
			#cart-table{
				th{
					background: $gray-white;
					padding: 10px 5px;
					&:first-child{
						padding-left: 20px;
					}
					&:last-child{
						padding-right: 20px;
					}
				}
				td{
					padding: 20px 5px 13px;
					@include respond(small) {
						padding: 17px 5px 0;
					}
					.shipment-label{
						@include respond(small) {
							margin-top: 0;
							padding-bottom: 10px;
						}
					}
					&.item-image{
						padding-left: 0;
						img{
							width: 135px;
							margin-right: 35px;
							max-width: 100%!important;
							@include respond(small) {
								width: 100px;
								margin-right: 20px;
							}
						}
					}
					&.item-details{
						.product-list-item{
							div.name,
							a{
								font-style: normal;
								color: $gray-dark;
								font-weight: bold;
								line-height: 100%;
								font-size: 14px;
								display: block;
								@include respond(small) {
									margin-bottom: 3px;
								}
							}
							.label{
								font-size: 11px;
								color: $gray-dark;
								text-transform: uppercase;
								font-weight: bold;
							}
							.attribute{
								display: block;
								width: 100%;
								line-height: 148%;
							}
							.value{
								font-size: 11px;
								color: $gray-warm;
								text-transform: uppercase;
								font-weight: normal;
							}
							.sku{
								padding-bottom: 10px;
								span{
									font-size: 11px;
									color: $gray-warm;
									text-transform: none;
									font-weight: normal;
								}
							}
							&.myjoy-item{
								.attribute{
									padding-bottom: 10px;
									display: block;

									.label,
									.value{
										color: $gray-warm;
										font-weight: normal;

									}
									&.name{
										padding-bottom: 0;
										span.value{
											font-weight: bold;
											color: $gray-dark;
											padding-bottom: 0;
											display: block;
										}
									}
								}
							}
						}
					}
					&.item-quantity{
						color: $gray-warm;
						font-size: 12px;
						font-weight: normal;
						@include respond(small) {
							float: right;
							width: 60%;
							text-align: left;
							padding-top: 0;
						}
					}
					&.item-total{
						padding-right: 20px;
						& > span{
							padding-top: 9px;
							display: block;
							width: 100%;
							@include respond(small) {
								font-size:12px;
							}
							&.price-adjusted-total,
							&.price-unadjusted{

								@include respond(small) {
								clear: both;
								padding-top: 3px;
								  span{
								  		font-size:12px;
									}
								}
							}
						}
						div.promo-adjustment{
							width: 100%;
							@include respond(small) {
								font-size: 12px;
							}
						}

						.mobile-title{
							display: none;
							@include respond(small) {
								float: left;
								display: block;
								font-weight: bold;
								font-size: 12px;
								color: $gray-dark;
								text-transform: uppercase;
								text-align: left;
								margin: 0 5px 0 0;
							}
						}
						@include respond(small) {
							float: right;
							width: 60%;
							text-align: left;
							padding-top: 10px;
							padding-bottom: 25px;
						}
						.price-total{
							color: $gray-dark;
							font-weight: normal;
							@include respond(small) {
								float: left;
								font-size: 12px;
								padding-top: 0;
								width: auto;
							}
						}
					}
				}
				.cart-promo{
					background: none;
					font-style: normal;
					border-top: 1px solid $gray-light;
					td{
						padding: 0;
					}
					span{
						font-size: 14px;
					}
					@include respond(small) {
						padding-bottom: 17px;
						.bonus-item-promo{
							border: 0;
						}
					}
				}
			}
			.order-summary-footer{
				.place-order-totals{
					float: right;
					width: 40%;
					@include respond(small) {
						width: 100%;
					}
				}
				.place-order-totals{
					margin-top: 20px;
					@include respond(small) {
						margin-top: 11px;
					}
					.order-totals-table{
						float: right;
						width: 100%;
						tr{
							td{
								color: $gray-dark;
								text-transform: uppercase;
								padding: 3px 0 1px;
								&:first-child{
									font-weight: bold;
									text-align: left;
								}
							}
							&.order-discount{
								td{
									color: $red;
								}
							}
							&.order-sales-tax{
								td{
									padding-bottom: 15px;
								}
							}
							&.order-total{
								td{
									font-size: 14px;
									border-top: 1px solid $gray-light;
									padding: 13px 0 0;
								}
							}
						}
					}
				}
				.submit-order{
					a.back-to-cart{
						color: $gray-dark;
					}
				}
			}
			h2{
				padding-top: 10px;
				border-top: 2px solid $gray-light;
				font-size: 16px;
				letter-spacing: 0.085em;
				@include respond(small) {
					font-size: 14px;
					padding-top: 16px;
				}
				.dialog-required{
					float: right;
					color: $gray-warm;
					font-size: 11px;
					text-transform: none;
					font-style: normal;
					line-height: 20px;
					.required-indicator{
						color: $gray-warm;
						em{
							font-style: normal;
							@include font-small;
						}
					}

				}
			}
			.checkout-progress-indicator{
				padding-bottom: 19px;
				@include respond(small) {
					padding-bottom: 0;
				}
				div{
					font-size: 14px;
					font-weight: bold;
					text-transform: uppercase;
					color: $gray-warm;

					i{
						padding: 0;
						display: none;
					}
					@include respond(small) {
						display: block;
						width: 25%;
						text-align: center;
						float: left;
						padding: 0;
						margin: 0;

						&.step-2{
							div.name{
								box-sizing: border-box;
								padding: 0 10px;
							}
						}
					}

					a,
					div.name{
						@include respond(small) {
							display: block;
							width: 100%;
							font-size: 11px;
							color: $gray-warm;
						}
					}
					span{
						color: $gray-warm;
						width: 24px;
						height: 24px;
						line-height: 24px;
						font-size: 12px;
						background: $gray-cirlce;
						border-radius: 12px;
						display: inline-block;
						text-align: center;
						margin-right: 6px;
						@include respond(small) {
							margin-right: 0;
							margin-bottom: 5px;
						}
					}
					&.active{
						color: $gray-dark;
						.name{
							color: $gray-dark;
						}
						span{
							color: $white;
							background: $gray-dark;
						}
					}
				}
			}
			.shipping-method-list {
				.html-slot-container {
					margin-bottom: rem(14px);
				}
			}
			#shipping-method-list {
				.html-slot-container {
					margin-bottom: rem(20px);
				}
			}
			/////////////////
			/////Styles for MultiShipping step of Checkout
			////////////////
			#multi-shipment-shipping-method-list{
				margin-bottom: rem(20px);
				h2{
					@include respond(small) {
						margin-bottom: 16px;
					}
				}
				.shipping-method-list {
					padding-bottom: 10px;
					@include respond(small) {
						border-bottom: 1px solid $gray-light;
						padding-bottom: 10px;
					}

					h2 {
						display: none;
					}
					p {
						padding-bottom: rem(20px);
						margin: 0;
						color: $black;
						font-size: rem(13px);
						font-weight: 600;
					}
					.form-row.form-indent {
						margin-left: 0;
						margin-top: 0;
						margin-bottom: 23px;
						.form-caption{
							margin: 0;
							font-size: 12px;
							line-height: 180%;
						}
						ul.shipping-promos{
							line-height: 180%;
						}
						ul.shipping-promos,
						li{
							font-size: 12px;
							color: $gray-warm;
							padding: 0;
							margin: 0;
						}
						.form-caption,
						ul.shipping-promos{
							box-sizing: border-box;
							margin-left: 20px;
							font-size: 12px;
						}
						label{
							width: 100%;
							font-size: 12px;
							color:  $gray-dark;
							text-transform: uppercase;
							font-weight: bold;
							margin-bottom: 0;
							box-sizing: border-box;

							span{
								&.standard-shipping{
									text-decoration: line-through;
								}
								&.discount-shipping {
									color: $red;
									&.loggedin {
										color: $green-dark;
									}
								}
							}
							a{
								font-size: 12px;
								color:  $gray-dark;
								text-transform: none;
								text-decoration: underline;
								font-weight:  normal;
								&:hover{
									text-decoration: none;
								}
							}
						}
					}
				}
				.shipment-items{
					.item-list{
						tr{
							th{
								padding: 10px 20px;
								background: $gray-white;
								@include respond(small) {
									display: none;
								}
							}
							th:last-child,
							td:last-child{
								width:25px;
								padding: 10px 20px;
								text-align: center;
							}
							td{
								&.item-quantity{
									.mobile-visible{
										display: none;
										@include respond(small) {
											display:inline-block;
											font-size: 11px;
											font-weight: bold;
											color: $gray-dark;
											text-transform: uppercase;
										}
									}
									@include respond(small) {
										width: 58%;
										float: right;
										padding: 0 0 30px 0;
										text-align: left;
										color: $gray-warm;
									}
								}
								&.item-details{
									@include respond(small) {
										width: 100%;
										float: left;
										box-sizing: border-box;
										padding-left: 0;
										padding-right: 0;
									}
									.product-detail{
										float: left;
										@include respond(small) {
											width: 58%;
											float: right;
										}
									}
									.item-image{
										float: left;
										width: 135px;
										margin-right: 35px;
										@include respond(small) {
											width: 100px;
											margin-right: 20px;
										}
										img{
											width: 100%;
											max-width: 100%!important;
										}
									}
									.product-list-item{

										div.name,
										a{
											font-size: 14px;
											color: $gray-dark;
											font-weight: bold;
											font-style: normal;
											line-height: 120%;
											padding-top: 5px;
										}
										.value{
											font-weight: normal;
										}
										.sku{
											padding-bottom: 10px;
											span{
												font-size: 11px;
												color: $gray-warm;
											}
										}
										.attribute{
											span{
												font-size: 11px;
												text-transform: uppercase;
												color: $gray-warm;
												&.label{
													color: $gray-dark;
													font-weight: bold;
												}
											}
										}
										& + div{
											a{
												color: $gray-dark;
												margin-top: 10px;
												font-size: 12px;
												text-decoration: underline;
												display: inline-block;
												&:hover{
													text-decoration: none;
												}
												@include respond(small) {
													display: none;
												}
											}
											&.item-edit-details{
												a{
													margin-top: 0;
													display: inline;
												}
											}
										}
									}
									.item-edit-details{
										padding-top: 10px;
										button,
										a{
											color: $gray-dark;
											font-size: 12px;
											text-decoration: underline;
											text-transform: none;

											&:hover{
												text-decoration: none;
											}
										}
										button{
											height: auto;
											line-height: 16px;
											padding: 0;
											font-weight: normal;
										}
										@include respond(small) {
											display: none;
										}
									}
								}
							}
							&:last-child{
								border-bottom: 0;
							}
						}
					}
				}
				.checkout-multi-shipment{
					display: inline-block;
    				padding: 20px 0 42px;
    				width: 100%;
					border-top: 2px solid $gray-light;
					@include respond(small) {
						width: 100%;
						padding: 20px 0 0;
					}
					button{
						float: right;
						font-size: 20px;
						background: $black;
						color: $white;
						height: 44px;
						line-height: 40px;
						padding: 0 23px;
						@include respond(small) {
							width: 100%;
						}
						&:hover{
							background: $white;
							color: $black;
							border-color: $black;
						}
					}
				}
			}
			#multi-shipment-shipping-method-list {
				h2.shipping-list {
					margin-bottom: rem(20px);
				}
			}
			/////////////////
			/////END Styles for MultiShipping step of Checkout
			////////////////
			.login-box {
				&.login-account {
					h2 {
						padding-left: 28px;
						font-size: 18px;
						background: url("../images/icons/icon-account-black.svg") no-repeat left 16px;
						background-size: 20px 18px;
						@include respond(largeUp) {
							padding-left: 36px;
							padding-top: 14px;
							background-position: left 14px;
						}
						span.login-now {
							display: inline;
							margin-left: 4px;
							text-decoration: underline;
							color: $blue-medium;
							background: none;
						}
					}
				}
			}

			.checkout-shipping,
			.login-box{
				h2{
					margin-bottom: 10px;
					@include respond(small) {
						margin-bottom: 16px;
					}
					span{
						background:  url("../images/down-arrow.png") no-repeat scroll right 10px center;
						display: block;
						width: 100%;
						cursor: pointer;
					}
					&.close{
						span{
							background:  url("../images/arrow-right.png") no-repeat scroll right 10px center;
						}
						a{
							color: #000;
						}
					}
					a{
						color: #000;
					}
				}
				.login-box-content{
					margin-top: 40px;
					@include respond(small) {
						margin-top: 10px;
					}
					&.close{
						display: none;
					}

					.form-row.form-row-button button {
						font-size: 11px;
						padding: 0 18px;
					}
					.error-form{
						padding: 0;
						margin: 0 0 10px 0;
					}
				}
				.form-row{
					position: relative;
					@include respond(small) {
						margin: 0px;
					}
					&.select-address{
						@include respond(large) {
							label{
								line-height: 130%;
							}
						}
					}
					&.password{
						margin-bottom: 0;
					}
					&.form-row-button{
						margin-top: 0;
						margin-bottom: 10px;
						label{
							line-height: 10px;
						}
						#password-reset{
							color: $gray-dark;
							float: left;
							text-decoration: underline;

							&:hover {
								text-decoration: none;
							}
						}
						button{
							float: right;
							height: 34px;
						    line-height: 30px;
						    padding: 0 13px;
						    margin-top: 25px;
						    font-size:20px;
						    font-weight:200;
						    @include respond(small) {
								width: 100%;
							}
						    &:hover{
						    	border-color: $black;
						    }
						}
						.field-wrapper{
							width: 340px;
							@include respond(small) {
								width: 100%;
							}
						}
					}
					label{
						width: 18%;
						text-align: left;
						float: left;
						font-weight: bold;
						text-transform: uppercase;
						font-size: 12px;
						line-height: 34px;
						margin-top: 0;
						@include respond(small) {
							width: 100%;
						}
						.required-indicator{
							display: none;
						}
						&.radio {
							@include font-small;
						}
					}
					&.required{
						label{
							span{
								padding-right: 5px;
								&::after{
									content: " *";
									color: $gray-dark;
								}
							}
						}
					}
					&.gift-message-text{
						.field-wrapper{
							textarea{
								border-color: $gray-light;
							}
							.char-count{
								padding-top: 5px;
								color: $gray-warm;
								span{
									display: inline-block;
									width: auto;
								}
							}
						}
					}
					.field-wrapper{
						float: left;
						width: 82%;
						padding-left: 0;
						margin-bottom: 5px;
						@include respond(small) {
							width: 100%;
						}
						input[type="password"],
						input[type="text"],
						input[type="email"],
						input[type="tel"],
						input[type="number"]{
							width: 340px;
							border-radius:0;
							@include font-small;
							@include font-small;
							@include respond(small) {
								width: 100%;
							}
						}
						span{
							display: block;
							width: 100%;
						}
						label.radio{
							width: 40px;
							margin-top: 10px;
							@include respond(small) {
								margin-top: 0;
							}
							span{
								color: $gray-warm;
								font-size: 11px;
								text-transform: none;
								font-weight: normal;
								line-height: 150%;
							}
						}
					}
				}
                .form-col {
                    .sms-channel-options {
                        background-color: #f7f7f7;
                        border: 1px solid #e6e6e6;
                        padding: 11px 21px 17px;
                        margin-left: 18%;
                        max-width: 340px;
                        box-sizing: border-box;
                        font-family: neuzeit-grotesk, sans-serif;
                        @media screen and (max-width: 879px) {
                            max-width: 100%;
                            margin-left: 0;
                        }
                        .form-row {
                            &.checkbox-custom {
                                margin-bottom: 0;
                                .field-label {
                                    font-weight: 700;
                                    font-size: 14px;
                                    margin-bottom: 8px;
                                    width: 100%;
                                }
                                label {
                                    width: 100%;
                                    padding-left: 0;
                                    line-height: 16px;
                                    font-weight: 400;
                                    color: #999;
                                    text-transform: none;
                                    display: flex;
                                    align-items: flex-start;
                                }
                                input[type=checkbox]+label>.styled-checkbox {
                                    position: relative;
                                    display: inline-block;
                                    width: 14px;
                                    height: 14px;
                                    margin-right: 8px;
                                    background: $white;
                                    border: 1px solid $black;
                                    vertical-align: top;
                                    min-width: 14px;
                                    &::after,
                                    &::before {
                                        display: none;
                                    }
                                }
                                input[type=checkbox] {
                                    display: none;
                                }
                                input[type=checkbox]:checked+label>.styled-checkbox {
                                    background-color: $black;
                                    &::before,
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        height: 3px;
                                        background: #fff;
                                        display: block;
                                    }
                                    &::before {
                                        width: 6px;
                                        height: 2px;
                                        bottom: 5px;
                                        left: 1px;
                                        transform: rotate(45deg);
                                    }
                                    &::after {
                                        width: 10px;
                                        height: 2px;
                                        right: 0;
                                        bottom: 7px;
                                        transform: rotate(-45deg);
                                    }
                                }
                                & ~ .checkbox-custom {
                                    margin-top: 8px;
                                }
                            }
                        }
                        .sms-channel-checkout-content {
                            color: #999;
                            font-weight: 400;
                            margin-top: 4px;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin: 0;
                                & + p {
                                    margin-top: 12px;
                                }
                            }
                            a {
                                color: #999;
                                font-size: 11px;
                                line-height: 16px;
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
			}
			.checkout-shipping,
			.checkout-billing {
				input:focus,
				select:focus,
				button:focus,
				a:focus {
					outline: 1px dotted #212121;
					outline: 5px auto -webkit-focus-ring-color;
				}
				.form-row {
					.form-field-tooltip {
						a {
							@include font-small;
						}
					}
				}
				input.input-checkbox:focus,
				input.input-radio:focus {
					+ label {
						position: relative;
						&::before {
							content: '';
							width: 12px;
							height: 12px;
							display: block;
							position: absolute;
							left: 0;
							top: 2px;
							background-color: transparent;
							outline: 1px dotted #212121;
							outline: 5px auto -webkit-focus-ring-color;
						}
					}
				}
			}
			.checkout-shipping{
                &.sms-enabled {
                    .form-row[class$="_shippingAddress_addressFields_phone"] {
                        border: 0;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                }
				h2 {
					margin-bottom: 25px;
					@include respond(small) {
						margin-bottom: 11px;
					}
					span{
						background: none;
					}
					&.h2-flex {
						display: flex;
						align-items: center;
						flex-direction: row;
						flex-wrap: wrap;
						.address-tlt {
							flex: 0;
    						white-space: nowrap;
							@include respond(extra-small) {
								flex-basis: 100%;
							}
						}
						.address-pers {
							font-weight: 300;
							text-transform: none;
							letter-spacing: normal;
							font-size: rem(12.5px);
							flex: 1;
						}
					}
				}
				#dwfrm_singleshipping_shippingAddress_addToAddressBook{
					& + label{
						margin-bottom: 15px;
						@include respond(small) {
							margin-bottom: 0;
						}
					}
				}
				select{
					width: 340px;
					height: 34px;
					line-height: 30px;
					border-radius:0;
					@include respond(small) {
						width: 100%;
					}
				}
				&.no-multi-country {
					.selected-country {
						@include font-small;
						line-height: 34px;
					}
					.input-select.country:not(.dropdown-list) {
						border: none;
						background: $white;
						padding-left: 0;
						box-shadow: none;
						border-radius:0;
						@include font-small;
					}
				}

				.form-row.label-inline{
					margin-left: 18%;
					margin-top: 0;
					margin-bottom: 0;
					@include respond(small) {
						margin: 13px 0 0 0;
					}
					.field-wrapper{
						width: auto;
						margin-bottom: 0;
					}
					label{
						width: 100%;
						padding-left: 20px;
						span{
							font-size: 12px;
							color: $gray-warm;
							text-transform: none;
							float: left;
							line-height: 120%;
							margin-left: 0;
							@include font-small;
							font-weight: normal !important;
						}
					}
				}
				.form-field-tooltip{
					left: 350px;
				    position: absolute;
				    top: 0;
				    margin: 7px 0 0 18%;
				    padding-top: 0;
				    @include font-small;
				    @include respond(large) {
				    	margin-top: 0;
				    }
				    @include respond(small) {
						left: 0;
					    position: relative;
					    top: 0;
					    text-align: right;
					    padding-left: 0;
					    width: 100%;
					    text-align: right;
					    margin: 0;
					}
					a{
						color: $gray-warm;
						font-size: 11px;
						@include font-small;
					}
				}
				.form-caption{
					margin: 7px 0 0 18%;
					color: $gray-warm;
					font-size: 11px;
					@include font-small;
					@include respond(small) {
						margin: 0;
					}
					&.address1{
						left: 350px;
					    position: absolute;
					    top: 2px;
					    @include respond(small) {
							left: 0;
						    position: relative;
						    top: 0;
						    text-align: right;
						}
					}
					a{
						color: $gray-dark;
						text-decoration: underline;
						&.privacy-policy{
							display: inline-block;
						    font-size: 12px;
						    margin-left: 23px;
						    margin-top: -10px;
						    @include respond(small) {
								margin-top: 0;
							}
						}
						&:hover{
							text-decoration: none;
						}
					}
				}
				& > .form-caption{
					margin-bottom: 35px;
					@include respond(small) {
						margin-bottom: 25px;
					}
				}
				.email-fiels{
					margin-bottom: 23px;
					@include respond(small) {
						margin-bottom: 15px;
					}
					.form-row{
						margin-bottom: 0;

					}
					.field-wrapper{
						margin-bottom: 0;
					}
				}
			}
			#shipping-method-list{
				margin-top: 24px;
				display: block;
				margin-bottom: 35px;
				h2 {
					margin-bottom: rem(20px);
				}
				p {
					padding-bottom: rem(25px);
					color: $black;
					font-size: rem(13px);
					font-weight: 600;
				}
				.form-row.form-indent{
					margin-left: 0;
					margin-bottom: 23px;
					ul.shipping-promos{
						padding:0 0 0 21px;
						margin: 0;
						font-size: 12px;
						color: $gray-warm;
					}
					label{
						cursor: pointer;
						span{
							font-size: 12px;
							color: $gray-dark;
							text-transform: uppercase;
							font-weight: bold;
							margin-right: 3px;
							&.tool-info{
								font-weight: normal;
							}
							&.standard-shipping{
								text-decoration: line-through;
							}
							&.discount-shipping, &.free-shipping-color {
								color: $red;
								&.loggedin {
									color: $green-dark;
								}
							}
						}
						a{
							font-size: 12px;
							color: $gray-dark;
							font-weight: normal;
							text-transform: none;
							text-decoration: underline;
							line-height: 120%;
							&:hover{
								text-decoration: none;
							}
						}
					}
					.form-caption{
						display: inline-block;
					    margin-left: 23px;
					    margin-top: 4px;
						font-size: 12px;
					}
				}
			}
			.continue-btn{
				float: left;
				width: 100%;
				border-top: 2px solid $gray-light;
				margin-bottom: 40px;
				@include respond(small) {
					margin-bottom: 0;
				}
				@include respond(largeUp) {
					padding-bottom: 65px;
   					position: relative;
				}

				.form-row.form-row-button{
					margin: 0;
					width: 100%;
					@include respond(largeUp) {
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						position: absolute;
						text-align: center;
						width: 149.9%;
					}

					button{
						background: $black;
						color: $white;
						height: 44px;
						line-height: 40px;
						cursor: pointer;
						margin-top: 21px;
						padding: 0 29px;
						font-size:20px;
						font-weight:200;
						@include respond(small) {
							width: 100%;
						}
						&:hover{
							border-color:  $black;
							color: $black;
							background: $white;
						}
					}
				}
			}
		}
		#secondary {
			.checkout-mini-cartout {
				.mini-cart-name .mini-cart-name-value.mini-cart-name-value-myjoys {
					font-size:14.4px;
					font-weight:400;
					font-family:$din-condensed;
					letter-spacing:.6px;
				}
			}
		}
	}
}

//////// Preferred Shop Typeahead /////////////////////////////////////
.typeahead,
.tt-query,
.tt-hint {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 100%;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}
.ui-helper-hidden-accessible {
	@include font-small;
}
// this section will hide/show the placeholder on focus/unfocus
::-webkit-input-placeholder { opacity: 1; -webkit-transition: opacity .5s; transition: opacity .5s; }  /* Chrome <=56, Safari < 10 */
:-moz-placeholder { opacity: 1; -moz-transition: opacity .5s; transition: opacity .5s; } /* FF 4-18 */
::-moz-placeholder { opacity: 1; -moz-transition: opacity .5s; transition: opacity .5s; } /* FF 19-51 */
:-ms-input-placeholder { opacity: 1; -ms-transition: opacity .5s; transition: opacity .5s; } /* IE 10+ */
::placeholder { opacity: 1; transition: opacity .5s; } /* Modern Browsers */

*:focus::-webkit-input-placeholder { opacity: 0; } /* Chrome <=56, Safari < 10 */
*:focus:-moz-placeholder { opacity: 0; } /* FF 4-18 */
*:focus::-moz-placeholder { opacity: 0; } /* FF 19-50 */
*:focus:-ms-input-placeholder { opacity: 0; } /* IE 10+ */
*:focus::placeholder { opacity: 0; } /* Modern Browsers */
