.fj_account #wrapper.pt_account {
	#main {
        font-family: $neuzeit;

        &.hide-acctMenu {
            margin: rem(60px) 0 rem(60px);
            #primary h1 {
                display: block;
            }
        }

        .profile-col-title {
            font-family: $neuzeit;
            text-transform: capitalize;
        }

        .mobile-visible {
            display: none;
            @include respond(small) {
                display: block;
            }
        }

        .col-1 {
            margin-top: 90px;
            @include respond(small) {
                width: 100%;
            }
        }

        .col-2 {
            margin-top: 120px;
        }

        .account-toggle-wrapper {
            padding: 30px;

            #RegistrationForm .form-row {
                .field-wrapper {
                    width: 100%;
                    padding-left: 0;
                }
                input,
                select {
                    height: 50px;
                    border-radius: 0;
                }
            }

            &.register {
                border-top: 1px solid $gray-mediumer;
            }

            &.active {
                .account-toggle-content {
                    display: block;
                }
            }

            input:not([type=checkbox]) {
                border: 1px solid $gray-border;
                border-radius: 0;
                @include font-small;
                font-size: 16px;
                letter-spacing: -0.32px;
                font-weight: 400;
                padding: 4px 18px;
                text-transform: none;

                + input,
                + span:empty + input,
                + button {
                    margin-top: 15px;
                }
            }

            .username label,
            .password label,
            .form-caption {
                display: none;
            }

            .form-row {
                margin: rem(15px) 0 0;
            }

            label.lbl-check {
                padding-left: 0;
                margin: 20px 0 27px;
                font-weight: 400;
                font-size: rem(14px);
            }
			.signup-lable-text {
				display: inline-block;
    			width: calc(100% - 35px);
			}

            .password-link {
                font-size: 14px;
                font-weight: normal;
                text-transform: capitalize;
                padding: 28px 0;
                background: none;
                letter-spacing: normal;

                a {
                    text-decoration: underline;
                    color: $black;
                    font-weight: bold;
                    padding-left: 9px;

                    &.privacy-policy {
                        font-weight: normal;
                        padding-left: 3px;
                        @include respond(extra-small) {
                            display: block;
                            margin-left: 32px;
                        }
                    }
                }

                span {
                    text-transform: none;
                }
            }

            .password-link {
                font-size: 12px;
                padding: 13px 0;
            }

            button {
                background-color: #000;
                border-radius: 0;
                color: #FFF;
                font-family: neuzeit-grotesk,sans-serif,serif;
                font-size: 15px;
                font-weight: bold;
                width: 100%;
                height:60px;
            }

            input[type=checkbox]+label>.styled-checkbox {
                position: relative;
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 15px;
                float: left;
                background: $white;
                border: 1px solid $black;
                vertical-align: top;

                &::after,
                &::before {
                    display: none;
                }
            }

            input[type=checkbox] {
                display: none;
            }

            input[type=checkbox]:checked+label>.styled-checkbox {
                background-color: $black;
                box-sizing: border-box;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    height: 3px;
                    background: #fff;
                    display: block;
                }

                &::before {
                    width: 8px;
                    bottom: 5px;
                    transform: rotate(45deg);
                }

                &::after {
                    width: 14px;
                    right: -1px;
                    bottom: 7px;
                    transform: rotate(-45deg);
                }
            }
            &.active {
                .account-toggle-trigger {
                    &::after {
                        content: "-";
                    }
                }
            }
            .account-toggle-trigger {
                color: #000;
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                position: relative;
                display: block;
                text-transform: capitalize;
                text-decoration: none;

                &::after {
                    font-size: 30px;
                    content: "+";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }

            .account-toggle-content {
                display: none;
                padding-top: 10px;
                span.error, div.error, div.error-message {
                    font-family: $font-small;
                }
            }
        }
        .insider-login {
            background: $gray-lighter;
            padding: 32px 26px;
            width: 337px;
            float: right;
            box-sizing: border-box;
            @include respond(small) {
                margin: 0 auto;
                float: none;
                width: 90%;
            }

            .insider-image {
                width: 151px;
                height: 32px;
                margin: 0 79px 33px 0;
                background: url('../images/fj_insider_lg.png') no-repeat;
            }

            h4 {
                font-family: $neuzeit;
                font-size: 24px;
                line-height: 1;
                letter-spacing: -0.48px;
                color: #243e37;
                margin: 0 0 20px;
                text-transform: none;

                &.fj-account-login-insider-headline  {
                    margin: 0 auto;
                    font-size: 16px;
                    line-height: 1;
                    letter-spacing: -0.08px;
                    text-align: center;
                    color: $black;
                    clear: both;
                    max-width: 83px;
                }
            }

            p {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.5;
                letter-spacing: 0.3px;
                color: $black;
                padding: 0;
                width: 100%;
            }

            .fj-account-login-insider-container {
                display: flex;
                justify-content: space-between;
            }

            .fj-account-login-insider {
                flex: 1;
                text-align: center;
                margin-right: 25px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            .fj-account-login-insider-image img {
                height: 60px;
                margin: 14px auto 0;
            }

        }
    }
}

.fj_account #wrapper.pt_account_login #main {
    max-width: rem(900px);

    #primary {
        h1.visible {
            display: block;
            padding-bottom: 20px;
            margin-top: rem(80px);
        }

        #NewPasswordForm .form-caption {
            @include respond(largeUp) {
                margin-left: rem(197px);
            }
        }

        #NewPasswordForm>fieldset {
            position: initial !important;
        }
    }
}

.fj_account #wrapper {

    .pt_order #main {
        &.hide-acctMenu {
            margin: rem(105px) auto rem(60px);
        }
    }

    .consent-non-registered {

        .lbl-check {
            width: auto !important;
            margin: 0 !important;
            font-size: 0.875rem !important;

            .styled-checkbox {
                clear: both;
            }

            &::after {
                background: none;
                content: none;
            }
        }
        .signup-lable-text {
            display: inline-block;
            width: calc(100% - 35px);
            color: $black;
            font-weight: 400;
            font-size: 0.875rem;
            white-space: nowrap;
        }
    }

    #consent-required-detail {
        position: relative;
        left: 30px;
        p {
            width: 90%;
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}
#wrapper.pt_account #primary #custom-registration  {
    border-top: none;
    label.required {
        text-transform: none;
        font-size: 1rem;
        margin-top: 10px;
    }

    .col-1 {
        width: 70%;
        @include respond(small) {
            width: 100%;
        }
        label {
            width: auto;
        }
        .account-toggle-trigger::after {
            content: " ";
        }
    }
    .col-2 {
        width: 35%;
        @include respond(small) {
            display: none;
        }
        .account-toggle-wrapper {
            padding-top: 0;
        }
        .account-toggle-trigger::after {
            content: " ";
        }
    }
    #RegistrationForm {
        input[type="text"],
        input[type="number"],
        input[type="password"],
        input[type="date"],
        input[type="tel"],
        input[type="email"],
        textarea,
        select {
            border-radius: 0;
            text-transform: none;
            font-size: 1rem;
        }

        select {
            margin-bottom: 8px;
            display: block;
        }

        .signup-lable-text {
            font-weight: normal;
			.special-character {
				display: inline-block;
				position: relative;
				top: -2px;
			}
        }

        .form-row {

            label {
                width: 100%;
                font-size: 1.1rem;
            }

            .input-radio {
                display: none;
            }

            &.birthday-type {
                margin-top: 15px;
            }

            &.address-type {
                margin-top: 15px;
                label::after {
                    content: " ";
                }
            }

            &.not-required {
                label::after {
                    content: " ";
                }
                label span {
                    position: relative;
                    bottom: 7px;
                }
				input[name="frequency"] {
					& ~ label {
						span {
							bottom: 4px;
						}
					}
				}
            }

            &.select-dob{
                    float: left;
                    width: 30%;
                    padding-right: 7px;
                select {
                    float: left;
                    margin-right: 15px;
                    @include respond(small) {
                        margin-right: 5px;
                        padding: 0 10px;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            .checkbox-row {
                width: 100%;
                clear: both;
                label {
                    line-height: normal;
                    span {
                        @include respond(small) {
                            left: 20px;
                            position: relative;
                        }
                    }
                }
            }
            .lbl-check {
                margin-top: 20px;
            }
        }

        .input_text.small {
            width: 65%;
            float: left;
            clear: both;
            @include respond(small) {
                width: 49%;
            }
        }
        button.lookup {
            width: auto;
            float: left;
            height: 50px;
            line-height: 50px;
            margin-left: 10px;
            margin-top: 0;
            @include respond(small) {
                margin-left: 7px;
            }
        }
		.consent-privacy-required {
			margin-bottom: 17px;
		}
        .consent-detail-expanded {
            height: 250px;
            overflow-y: scroll;
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }

            .bold-larger {
                font-weight: bold;
                font-size: 1rem;
                color: $black;
            }
			.kr-registration-detail-title {
				display: inline-block;
				margin-bottom: 8px;
				margin-top: 4px;
			}
			.kr-registration-detail-list {
				list-style: circle;
    			padding-left: 16px;
			}
        }
		.consent-non-registered {
			margin-bottom: 16px;
		}
        .radio-container {
            display: inline-block;
            float: left;
            width: 90px;
            margin-top: 10px;
            label span {
                position: relative;
                bottom: 5px;
            }
        }
    }

}
.dob-wrapper {
    margin-top: 15px;

    label {
        font-size: 1.1rem;
    }

    &::after {
        clear: both;
    }
    .dob-selects {
        display: flex;
    }
}

.kr-register-bold {
    margin: 25px 0;
    span {
        font-weight: 700;
        font-size: 14px;
    }
}

.kr-register-btn {
    width: 180px !important;
    float: right;
    @include respond(small) {
        width: 145px !important;
    }
}

.kr-consent-doublecheck {
    float: left;
}

.kr-lable-text {
    display: inline-block;
    color: $black;
    font-weight: 700;
    @include respond(small) {
        font-size: 13px;
    }
}

.fj_account #wrapper.pt_account.pt_account-kr #main .account-toggle-wrapper {
    @media screen and (max-width: 600px) {
		padding-left: 0;
		padding-right: 0;
	}
}

#wrapper.pt_account.pt_account-kr {
	#primary #custom-registration {
		#RegistrationForm {
			.form-row.select-dob {
				@media screen and (max-width: 600px) {
					width: 32%;
				}
			}
			.radio-container label span {
				@media screen and (max-width: 600px) {
					bottom: 2px;
				}
			}
		}
		.col-1 {
			.account-toggle-wrapper {
				.account-toggle-trigger {
					&::after {
						content: "+";
						right: -2px;
					}
				}
				&.active {
					.account-toggle-trigger {
						&::after {
							content: "-";
							right: 0;
						}
					}
				}
			}
		}
	}
	.consent-non-registered .signup-lable-text {
		@media screen and (max-width: 600px) {
			white-space: normal;
		}
	}
}


#wrapper.pt_account #primary #RegistrationForm .form-row {
	&.kr-postal-wrap {
		display: flex;
		align-items: flex-start;
		.form-row {
			label {
				display: none;
			}
			.field-wrapper {
				span.error {
					& + span.error {
						display: none !important;
					}
				}
			}
		}
		.postcode-lookup {
			white-space: nowrap;
		}
		&.postcode-wrapper {
			.form-field-tooltip.kr-tooltip {
				margin-top: 4px;
                @include respond(small) {
                    min-width: 130px;
                    flex-grow: 1;
                }
                @include respond(largeUp) {
                    margin-top: 6px;
                }
				a {
					box-sizing: border-box;
					display: inline-block;
					padding: 17px 12px 16px;
                    text-align: center;
                    @include respond(largeUp) {
                        padding: 17px 30px 16px;
                    }
				}
			}
		}
	}
	&.kr-address-wrap {
		label {
			display: none;
		}
		.field-wrapper {
			span.error {
				& + span.error {
					display: none !important;
				}
			}
		}
	}
}

#privacy-required {
    .ck-validate {
        display: none;
        color: #b2595c;
    }
    &.error {
        .ck-validate {
            display: block;
        }
        .styled-checkbox {
            border-color: #b2595c !important;
        }
    }
}

#wrapper.pt_account #primary #RegistrationForm .form-row.postcode-wrapper .form-field-tooltip.kr-tooltip {
    margin-top: 0;
    padding-top: 0;
    @include respond(small) {
        margin-top: 6px;
    }
}

.kr-ck-spacer {
	@include respond(small) {
		white-space: nowrap;
		margin-left: 20px;
		bottom: 0;
	}
}

#wrapper.pt_account #primary #custom-registration #RegistrationForm .form-row.not-required label span {
	&.kr-ck-spacer {
		@include respond(small) {
			white-space: nowrap;
			margin-left: 20px;
			bottom: 0;
		}
	}
}

.opt-in-address {
    font-family: $neuzeit;
}

#wrapper.pt_account #primary .opt-in-address {
    margin-top: 20px;
}
