@import "nav";
@import "menu_utility";
//@import "country_selector";


// Header Banner - Promo and Help Menu

.header-banner {
	// Countries Container
	.countries-container {
		position: relative;
		padding-right: 120px;
		@include respond(small){
			display: none;
		}
		& > a {
			background: url("../images/countries-icons.gif") 140px center no-repeat;
			text-transform: uppercase;
			color: #000;
			font-size: 16px;
			line-height: 24px;
			& > i {
				border: solid black;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 4px;
				margin-left: 30px;
				margin-bottom: 4px;
				&.down {
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					transition-duration: 500ms;
				}
				&.revert {
					transform: rotate(-135deg);
					-webkit-transform: rotate(-135deg);
					transition-duration: 500ms;
					margin-bottom: -3px;
				}
			}
			&.gb {
				background: url("../images/uk.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.de{
				background: url("../images/germany.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.fr{
				background: url("../images/france.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.es {
				background: url("../images/spain.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.ie{
				background: url("../images/ireland.gif") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.be {
				background: url("../images/belgium.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.dk {
				background: url("../images/denmark.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.fi {
				background: url("../images/finland.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.it {
				background: url("../images/italy.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.se {
				background: url("../images/sweeden.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.at {
				background: url("../images/austria.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.lu {
				background: url("../images/luxembourg.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.nl {
				background: url("../images/netherlands.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.sk {
				background: url("../images/slovakia.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.si {
				background: url("../images/slovenia.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.pt {
				background: url("../images/portugal.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.au {
				background: url("../images/australia.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
			&.nz {
				background: url("../images/newzealand.jpg") left center no-repeat;
				background-size: 18px 11px;
				background-position: 20px 3px;
			}
            &.sg {
                background: url("../images/singapore.jpg") left center no-repeat;
                background-size: 18px 11px;
                background-position: 20px 3px;
            }
            &.my {
                background: url("../images/malaysia.jpg") left center no-repeat;
                background-size: 18px 11px;
                background-position: 20px 3px;
            }
            &.th {
                background: url("../images/thailand.jpg") left center no-repeat;
                background-size: 18px 11px;
                background-position: 20px 3px;
            }
		}
		.country-container{
			position: fixed;
			float: left;
			width: 500px;
			right: 0;
			top: 50px;
			height: 100vw;
			background-color: $white;
			border: 2px solid $clp-border;
			border-bottom: 0;
			box-sizing: border-box;
			display: none;
			z-index:53;
			@include respond(small){
				bottom: 34px;
				width: 320px;
			}
			h2{
				float: left;
				width: 100%;
				padding: 10px;
				box-sizing: border-box;
				color: $gray-dark;
				line-height: 23px;
				margin: 0;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				a.close-countries{
					background: url("../images/interface/close.jpg") left top no-repeat;
					height: 23px;
					width: 24px;
					float: right;
					opacity: 0.8;
					&:hover{
						opacity: 1;
					}
				}
			}
			.country-selector{
				position: relative;
				right: auto;
				top: auto;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				flex-wrap: wrap;
				.location {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					@include respond(small){
						border: 0;
					}

					h2 {
						padding-top: 30px;
						border: none;
						text-align: left;
						padding-left: 0;
						font-size: 18px;
					}

					span{
						float: left;
						width: fit-content;
						box-sizing: border-box;
						text-align: left;
						line-height: 26px;
						min-height: 26px;
						a{
							color: $gray-dark;
							font-size: 16px;
							line-height: 18px;
							text-decoration: underline;
							display: inline-block;
							text-transform: capitalize;
							background: url("../images/countries-icons.gif") left center no-repeat;
							padding-left: 60px;

							&:hover{
								text-decoration: underline;
							}
							img{
								float: left;
								margin: 5px 20px 0 10px;
							}
						}
						&.ecommerce {
							@include basket-icon-desktop;
						}
						&.austr{
							a{
								background-position: 10px 1px;
							}
						}
						&.fr_FR{
							position: relative;
							a {
								background: url("../images/france.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
								position: relative;
							}
						}
						&.en_FR {
							position: relative;
							display: none;
							a {
								background: url("../images/france.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
								position: relative;
							}
						}
						&.en_ES {
							position: relative;
							a {
								background: url("../images/spain.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_DE {
							position: relative;
							display: none;
							a {
								background: url("../images/germany.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.de_DE {
							position: relative;
							a {
								background: url("../images/germany.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_IE{
							position: relative;
							a{
								background: url("../images/ireland.gif") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_BE {
							position: relative;
							a{
								background: url("../images/belgium.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_DK {
							position: relative;
							a{
								background: url("../images/denmark.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_FI {
							position: relative;
							a{
								background: url("../images/finland.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_IT {
							position: relative;
							a{
								background: url("../images/italy.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_SE {
							position: relative;
							display: none;
							a {
								background: url("../images/sweeden.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_AT {
							position: relative;
							a {
								background: url("../images/austria.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_LU {
							position: relative;
							a{
								background: url("../images/luxembourg.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_NL {
							position: relative;
							a{
								background: url("../images/netherlands.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_SK {
							position: relative;
							a{
								background: url("../images/slovakia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_SI {
							position: relative;
							a{
								background: url("../images/slovenia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_PT {
							position: relative;
							a{
								background: url("../images/portugal.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_AU{
							position: relative;
							a{
								background: url("../images/australia.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.sv_SE {
							position: relative;
							a {
								background: url("../images/sweeden.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en_GB {
							position: relative;
							a {
								background: url("../images/uk.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.en {
							display: none;
							a {
								background: url("../images/uk.jpg") left center no-repeat;
								background-size: 24px 16px;
    							background-position: 28px 2px;
							}
						}
						&.malas{
							a{
								background: url("../images/malaysia.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.singapore{
							a{
								background: url("../images/singapore.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.usa{
							position: relative;
							a{
								background: url("../images/us.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.southafrica {
							a{
								background: url("../images/southafrica.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.thai{
							a{
								background: url("../images/thailand.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.mexico{
							a{
								background: url("../images/latin.gif") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.australia{
							a{
								background: url("../images/australia.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.newzealand{
							a{
								background: url("../images/newzealand.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.japan{
							a{
								background: url("../images/japan.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.korea{
							a{
								background: url("../images/korea.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.china{
							a{
								background: url("../images/china.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
						&.canada{
							position: relative;
							a{
								background: url("../images/canada.jpg") left center no-repeat;
								background-size: 24px 16px;
								background-position: 28px 2px;
							}
						}
					}
				}
			}
		}
	}
}
