///////////////////////
/////Order Confirmation
///////////////////////
#wrapper.pt_order-confirmation {
	#primary {
		@include respond(largeUp) {
			position: relative;
		}
	}
}

#wrapper.pt_order,
#wrapper.pt_order-confirmation{
	#main{
        font-family: $neuzeit;
        address {
            font-style: normal;
            div {
                padding: 4px 0;
            }
        }
        h1 {
            font-size: rem(40px);
            font-family: $neuzeit;
            font-weight: 700;
            text-transform: uppercase;
            color: $black;
            letter-spacing: normal;
            margin-bottom: 15px;
            padding: 0;
            @include respond(small) {
                border: none;
                padding-bottom: 2px;
            }
        }
		.confirmation-message {
            font-size: 14px;
			color: $gray-warm;
			padding: 0;
			margin: 0 0 20px 0;
			border: 0;
			letter-spacing: normal;
		}
        .details-box {
            @include respond(largeUp) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            @include respond(small) {
                margin-bottom: 30px;
            }
            .order-shipping {
                .order-shipment-address {
                    display:block;
                }
                .order-shipment-address ~ .order-shipment-address {
                    display: none;
                }

            }
            .flex-details {
                flex: 1;
                border: solid 1px #e6e6e6;
                padding: 20px 25px;
                @include respond(largeUp) {
                    margin-right: 30px;
                    .arrow-tog {
                        display: none;
                    }
                }
                @include respond(small) {
                    padding-bottom: 25px;
                    position: relative;
                    margin-bottom: 20px;
                    overflow: hidden;
                    max-height: 30px;
                    transition: all 0.15s ease-out;
                    .arrow-tog {
                        transition: all 0.15s ease-out;
                        transform: rotate(0deg);
                        position: absolute;
                        right: 20px;
                        top: 32px;
                        width: 17px;
                        height: 10px;
                    }
                    &.opened {
                        max-height: 800px;
                        transition: all 0.25s ease-in;
                        .arrow-tog {
                            transition: all 0.25s ease-in;
                            transform: rotate(180deg);
                        }
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .order-title {
                    margin-bottom: 25px;
                }
                .payment-summary {
                    margin-top: 20px;
                    .payment-amount {
                        margin-bottom: 10px;
                    }
                }
            }
            .order-payment-summary {
                background-color: #f7f7f7;
                .total-rows {
                    display: flex;
                    margin: 5px 0;
                    .label {
                        flex: 1;
                    }
                    .value {
                        text-align: right;
                        font-weight: 700;
                    }
                    &.order-discount {
                        .label {
                            font-weight: 700;
                        }
                    }
                    &.no-discount {
                        display: none;
                    }
                    .ship-free {
                        font-weight: 700;
                        color: $green-dark;
                        text-transform: uppercase;
                    }
                    .guest-free {
                        color: $red;
                    }
                }
                .grand-total {
                    border-top: solid 1px #e6e6e6;
                    padding: 15px 0;
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: 15px;
                }
                .order-totals-table {
                    tr td {
                        &:first-child {
                            text-align: left;
                        }
                        &:nth-child(even) {
                            white-space: nowrap;
                        }
                    }
                }
            }
            .billing-address-details,
            .payment-details,
            .order-reg {
                div {
                    margin: 5px 0;
                }
            }
        }
		button.print-page {
			position: absolute;
			top: 48px;
			right: 40px;
			font-size: 12px;
			color: $gray-dark;
			line-height: 120%;
			text-decoration: underline;
			padding: 0;
			margin: 0;
			border: 0;
			background: none;
			text-transform: none;
			font-weight: bold;
			letter-spacing: normal;
			&:hover {
				text-decoration: none;
			}
			@include respond(small) {
				display: none;
			}
			@include respond(print) {
				display: none;
			}
		}
        .button-cancels {
            @include respond(small) {
                padding: 0 10px 20px;
            }
        }
		.order-information {
			div {
				display: inline-block;
				width: 100%;
				margin-bottom: 8px;
				span {
					font-size: 16px;
                    text-transform: none;
					&.label {
						color: $black;
						font-weight: bold;
					}
					&.value {
						color: $black;
					}
				}
			}
		}
		.order-information,
		.confirmationregister {
			display: inline-block;
			border-bottom: none;
			padding-bottom: 34px;
			width: 100%;
			@include respond(small) {
				padding: 27px 0 0 !important;
			}
			h2{
				margin: 34px 0 12px !important;
				@include respond(small) {
					line-height: 18px;
   					margin: 15px 0 18px !important;
				}
			}
			.login-box-content {
				.hidden {
					visibility: hidden;
					height: 0px;
					.form-row {
						padding: 0;
						margin: 0;
						height: 0px;
						display: inline-block;
					}
				}
				.confirm-login-btn {
					margin-left: 12%;
					width: 340px;
					@include respond(small) {
						width: 100%;
						margin-left: 0;
					}
					button {
						height: 44px;
						line-height: 40px;
						font-size: 20px;
						padding: 0 10px;
						letter-spacing: 0;
						float: right;
						&:hover{
							border-color: $black;
						}
						@include respond(small) {
							float: left;
						}
					}
				}
				.form-row {
					position: relative;

					label {
						width: 12%;
						text-align: left;
						float: left;
						font-weight: bold;
						text-transform: uppercase;
						line-height: 34px;
						margin-top: 0;
						@include respond(small) {
							width: 100%;
							line-height: 120%;
						}
						.required-indicator{
							display: none;
						}
					}
					&.email-line {
						margin-bottom: 0;
						.field-wrapper {
							margin-bottom: 1px;
						}
						.email {
							margin-top: 8px;
							@include respond(small) {
								margin-top: 0;
							}
						}
						label {
							margin-bottom: 2px;
						}
					}
					&.required {
						label {
							span {
								padding-right: 5px;
								&::after {
									content: " *";
									color: $gray-dark;
								}
							}
						}
					}
					.form-caption {
						margin: 5px 0 0 12%;
						color: $gray-warm;
						@include respond(small) {
							margin-left: 0;
							display: none;
						}
					}

					.field-wrapper {
						float: left;
						width: 82%;
						padding-left: 0;
						margin-bottom: 5px;
						@include respond(small) {
							width: 100%;
						}
						input[type="password"],
						input[type="text"]{
							width: 340px;
							@include respond(small) {
								width: 100%;
							}
						}
						span{
							display: block;
							width: 100%;
						}
						.email{
							color: $gray-warm;
							text-transform: uppercase;
						}
					}
				}
			}
		}
        .order-information {
            padding-bottom: 21px;
        }
		.content-header {
			font-size: 16px;
			margin: 0px 0 16px 0!important;
			font-style: normal;
			font-weight: bold;
			text-transform: uppercase;
			font-family: $oswald;
			font-weight: bold;
			background: none;
			padding: 0;
			@include respond(small) {
				margin: 20px 0!important;
			}
		}
		.confirmationregister {
			padding: 20px 0;
			@include respond(small) {
				padding-bottom: 30px;
			}
		}
        .shiptrack-header {
            padding: 20px 30px 10px;
            border: solid 1px #e8e8e8;
            border-bottom: none;
            margin-top: 25px;
            @include respond(largeUp) {
                padding: 25px;
                margin-top: 45px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .button {
                padding: 1px 30px;
                text-align: center;
                @include respond(small) {
                    line-height: 40px;
                    height: 40px;
                    display: block;
                    margin: 20px 0;
                }
            }
            .ordersom-info {
                h2 {
                    margin: 0 0 10px;
                    @include respond(small) {
                        margin-bottom: 7px !important;
                    }
                }
                .order-date {
                    margin-bottom: 5px;
                    span {
                        font-size: 14px;
                    }
                    .label {
                        font-weight: 700;
                    }
                }
                @include respond(small) {
                    padding-bottom: 12px;
                }
            }
            .ordersom-info-nonship {
                h2 {
                    margin-bottom: 0;
                }
            }
        }
		.order-confirmation-details {
            margin: 45px 0;
            border-top: solid 1px #e8e8e8;
            border-right: solid 1px #e8e8e8;
            border-left: solid 1px #e8e8e8;
			letter-spacing: normal;
            &.confirmation-details-shipping {
                margin-top: 0;
            }
            &.details-noship {
                margin-top: 0;
            }
            @include respond(small) {
                margin: 20px 0;
            }
			h2{
				margin-top: 30px !important;
				margin-bottom: 16px !important;
				letter-spacing: normal;
				@include respond(small) {
					font-size: 16px;
				}
			}
			div.content-header {
				@include respond(small) {
					margin: 16px 0 0 !important;
				}
			}
			table.item-list {
				border-bottom: 2px solid $gray-light;
				&.gc-list {
					tr {
						td {
							&.order-billing {
								@include respond(small) {
									width: 50% !important;
								}
							}
							&.order-payment-instruments {
								@include respond(small) {
									width: 50% !important;
								}
							}
						}
					}
				}

				tr {
					border: 0;
					.section-header{
						padding: 10px 0;
						color: $gray-dark;
						background: none;
						@include respond(small) {
							display: none;
						}
					}
					td {
						color: $gray-warm;
						font-style: normal;
						line-height: 22px;
						padding: 0 10px 0 0;

						&.order-payment-instruments{
							.payment-amount,
							.remaining-balance {
								display: block;
								width: 190px;
								.value {
									float: right;
								}
							}
							.orderpaymentinstrumentsgc {
								margin-bottom: 10px;
							}
							.amount {
								+ .orderpaymentinstrumentsgc {
									margin-top: 10px;
								}
							}
						}
						.mobile-visible{
							display: none;
							@include respond(small) {
								display: block;
								padding: 10px 0;
								font-size: 16px!important;
								line-height: 100%;
								font-family: $din-condensed;
							}
						}
						@include respond(small) {
							width: 100% !important;
							float: left;
						}
						address{
							font-style: normal;
						}
						.payment-type {
							font-weight: normal;
							margin-bottom: 5px;
						}
						.payment-type-border {
							font-weight: bold;
							padding-top: 13px;
							margin-top: 13px;
							border-top: 1px solid $gray-light;
							width: 50%;
						}
						&.order-information{
							display: none;
						}
						&.order-payment-summary{
							@include respond(small) {
								padding-bottom: 10px;
								.section-header.mobile-visible{
									font-size: 16px;
									padding-bottom: 15px;
								}

							}
							.order-detail-summary {
								.order-totals-table {
									tr {
										border: 0;
										td {
											color: $gray-dark;
											text-transform: uppercase;
											padding: 0 0 11px 0;
											line-height: 100%;
											letter-spacing: .036rem;
											&:first-child{
												font-weight: bold;
												text-align: left;
												font-family: $din-condensed;
											}
											@include respond(small) {
												width: auto !important;
												float: none;
											}
											&:last-child {
												@include font-small;
												font-weight: normal;
											}
										}
										&.order-shipping-discount,
										&.order-discount {
											td {
												color: $red;
											}
										}
										&.order-sales-tax {
											td {
												padding-bottom: 19px;
											}
										}
										&.order-total-split {
											td {
												padding-top: 13px;
												border-top: 1px solid $gray-light;
												@include respond(small) {
													padding-bottom: 17px;
												}
											}
										}
										&.order-total {
											td {

												border-top: 1px solid $gray-light;
											}
										}
									}
								}
							}
						}
					}
					&:last-child {
						td {
							padding-bottom: 20px;
							@include respond(small) {
								padding-bottom: 0;
								padding-right: 0;
							}
						}
					}
				}
			}

			table.order-shipment-table {
                border-collapse: revert;
                font-size: 16px;
				&.table-2,
				&.table-3 {
					tr {
						&.desktop-visible {
							display: none;
						}
						td{
							border-bottom: 0;
						}
					}
				}
				tr {
					&.desktop-visible {
						@include respond(small) {
							display: none;
						}
					}
                    &.row-cancel {
                        position:relative;
                    }
					th {
                        font-size: 16px !important;
						font-family: $neuzeit;
						text-transform: uppercase;
						padding: 10px 20px;
						background: $gray-white;
                        padding: 20px 20px !important;
                        &.hdr-qty {
                            text-align: center;
                        }
						&:last-child {
							background: #f7f7f7;
                            padding: 14px 30px 14px 0 !important;
                            vertical-align: middle;
							text-align: right;
							&.section-header {
								border-color: transparent;
							}
						}
						@include respond(small) {
							display: none;
						}
                        @include respond(largeUp) {
                            &:first-of-type {
                                width: 34%;
                            }
                            &.hdr-status {
                                width: 24%;
                                text-align: center;
                            }
                            &.hdr-shippingm {
                                width: 18%;
                            }
                            &.hdr-priceqty {
                                width: 8%;
                            }
                        }
					}
					td {
						padding: 30px 20px;
						color: $black;
                        font-size: 16px;
						@include respond(small) {
                            display: none;
                            &.cancel-item-select {
                                display: block;
                            }
						}
                        .track-btn {
                            display: inline-block;
                            height: 40px;
                            line-height: 40px;
                            color: $white !important;
                            text-transform: uppercase !important;
							margin-top: 15px;
                            @include respond(small) {
                                font-size: 12px !important;
                            }
							&:hover {
								color: $black !important;
							}
                        }
                        &.line-item-details {
                            @include respond(small) {
                                display: flex;
                                .track-status {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-top: 20px;
                                    a {
                                        line-height: 37px;
                                    }
                                }
                            }
                        }

						.mobile-on{
							display: none;
							@include respond(small) {
								display: block;
							}
						}
						&.order-shipment-gift-message-header{
							padding: 30px 0 0 0;
							text-transform: uppercase;
							border-bottom: 0;
							@include respond(small) {
								width: 100%
							}
						}
						&.order-shipment-gift-message{
							color: $gray-warm;
							padding: 0 0 30px 0;
							border-bottom: 0;
							@include respond(small) {
								width: 100%
							}
						}
						&.line-item-details{
							width: auto;
							@include respond(small) {
								width: 100%;
								padding: 10px 0 0 0;
							}

                            @include respond(largeUp) {
                                padding-bottom: 55px;
                            }

                            .item-actions {
                                a {
                                    text-decoration: underline;
                                    color: $black !important;
                                    font-weight: 400 !important;
                                    font-size: 16px;
                                    text-transform: capitalize;
                                }
                                &.mobile-visible {
                                    select {
                                        width: 170px;
                                    }
                                    label {
                                        text-transform: capitalize;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }
                            }
							.product-list-item{
								&.myjoy-item{
									.attribute{
										display: block;
    									padding-bottom: 5px;
										span.label{
											color: $gray-warm;
											font-weight: normal;
										}
										&.name{
											padding-bottom: 0;
											span{
												color: $gray-dark;
											}
										}
									}
								}
                                @include respond(largeUp) {
                                    float: left;
                                    width: 53%;
                                }
                                @media (min-width: 1200px) and (max-width: 1592px) {
									width:38%;
								}
								@include respond(small) {
                                    float: none;
                                    width: 100%;
                                    padding: 12px 25px;
								}
								@include respond(print) {
									float: left;
									width: 100%;
								}
								div.name,
								a{
									font-size: 16px;
									color: $gray-dark;
									font-weight: bold;
									text-transform: none;
								}
								.sku{
									padding-bottom: 10px;
									span{
										color: $gray-warm;
                                        font-size: 14px;
									}
								}
								.gc-to,
								.gc-from,
								.gc-message {
									margin: 4px 0;
								}
								.gc-to-email {
									.label {
										display: none;
									}
								}
								.attribute {
									span {
										text-transform: none;
										color: $gray-warm;
										&.label {
											font-weight: 700;
										}
										&.label,
                                        &.value {
                                            font-size: 16px;
											color: $black;
										}
									}
								}
                                .som-myjoy-attr {
                                    .name span {
                                        font-size: 16px;
                                        color: $black;
                                        margin: 20px 0 10px;
                                        display: block;
                                    }
                                    .color span {
                                        margin-top: 0;
                                    }
                                }
								&.myjoy-item{
									.attribute{
										padding-bottom: 10px;
										display: block;

										.label,
										.value{
											color: $gray-warm;
											font-weight: normal;

										}
										&.name{
											padding-bottom: 0;
											span.value{
												font-weight: bold;
												color: $gray-dark;
												padding-bottom: 0;
												display: block;
											}
										}
									}
								}
							}
						}
						&:nth-child(2){
							@include respond(small) {
								color: $gray-warm;
								padding-top: 10px;
							}
						}
						&:nth-child(3){
							padding: 30px 20px;
							@include respond(small) {
								padding: 10px 0 0 0;
							}
						}
						&:nth-child(4){
							padding-right: 20px;
							@include respond(small) {
								padding-right: 0;
								padding-top: 10px;
							}
						}
                        &:last-child {
                            text-align: right;
                            padding-right: 30px;
                            font-weight: 700;
                        }
						&.line-item-details{
							.item-image{
								@include respond(small) {
									float: left;
									position: absolute;
                                    position: relative;
                                    width: 230px;
                                    margin-right: 5px;
                                    padding-left: 15px;
								}
								img{
									margin-right: 26px;
    								width: 135px;
    								float: left;
    								@include respond(large) {
										width: 90px;
										margin-right: 10px;
									}
    								@include respond(small) {
										width: 100%;
										margin-right: 0;
									}
								}
							}
						}
						&.order-shipment-details{
							width: 30%;
							border-right: 0;
							border-bottom: 0;
							padding-top: 0;

							.order-shipment-address{
								margin-top: 20px;
								position: relative;
								@include respond(small) {
									margin-top: 0;
								}
							}
							&.last{
								display: none;
							}
							@include respond(small) {
								&.first{
									display: none;
								}
								&.last{
									display: block;
									width: 100%;
									box-sizing: border-box;
									border-bottom: 2px solid $gray-light;
									padding-bottom: 30px;

									.mobile-visible{
										font-family: $din-condensed;
										font-size: 16px;
										font-weight: bold;
										text-transform: uppercase;
										margin: 15px 0 5px 0;

										&.gift-mobile{
											display:block;
											.order-shipment-gift-message{
												color: $gray-warm;
    											text-transform: none;
    											font-weight: normal;
    											border-bottom: 1px solid #e6e6e6;
    											padding-bottom: 20px;
											}
										}
									}

								}
							}
							.order-shipment-address{
								.name{
									color: $gray-warm;
									line-height: 22px;
									text-transform: none;
									.phone{
										display: none;
									}
								}
							}
							.shipping-status,
							.shipping-method,
							.trackingnumber {
								.value,
								.label{

									color: $gray-dark;
									line-height: 22px;
								}
								.value{
									color: $gray-warm;
								}
								a {

									color: $gray-warm;
								}
								a:hover {
									text-decoration: underline;
								}
							}
							.trackingnumber {
								a {
									color: $red;
								}
								a:hover {
									text-decoration: none;
								}
							}
						}
					}
                    .line-item-quantity {
						position: relative;
                        text-align: center;
					}
					td, th {
                        border-bottom: solid 1px #e8e8e8 !important;
						&.header-divider {
							width: 25px;
							background: #f7f7f7;
							border: 0;
							@include respond(small) {
								display: none;
							}
						}
                        &.cancel-item-select {
                            @include respond(small) {
                                border: none !important;
                            }
                        }
                        &:nth-child(4){
							text-align: left;
						}
					}
					td.header-divider{
						@include respond(small) {
						    display: block;
						    float: left;
						    width: 100%;
						}
					}
					.product-availability-list {
						display: block;
						text-align: left;
						@include respond(largeUp) {
							position: absolute;
							left: 34px;
							top: 43px;
							width: 251px;
						}
						li.instock-date {
							line-height: 1.25;
						}
					}
				}
				.egc-to-email {
					display: block;

					color: $gray-warm;
					@include respond(largeUp) {
						margin-top: -14px;
					}
				}
                .product-list-item .line-item-attributes {
                    margin-bottom: 4px;
                }
                &.tbl-cancel {
                    .line-item-details {
                        @include respond(small) {
                            flex-wrap: wrap;
                            .item-image,
                            .product-list-item {
                                flex-basis: 40%;
                                .mobile-on {
                                    margin-top: 18px;
                                }
                            }
                            .item-actions {
                                flex: 0 0 100%;
                                padding: 0 0 20px 25px;
                                &.mobile-select {
                                    padding-bottom: 68px;
                                }
                            }
                        }
                    }
                }
			}
            &.order-cancel-items {
                margin-bottom: 0;
            }
		}
        .line-item-attributes {
            .label,
            .value {
                text-transform: capitalize;
            }
            .label {
                font-weight: 700;
            }
        }
        .returns {
            margin-top: 20px;
        }
		.actions{
			padding: 20px 0;
			border-top: 2px solid $gray-light;
			width: 100%;
			display: inline-block;
			@include respond(small) {
				border-top: 0;
				padding: 0 0 20px;
			}
			.button{
				float: right;
				padding: 0 27px;
				@include respond(small) {
					float: left;
					width: 100%;
					height: 44px;
					line-height: 40px;
					font-size: 20px;
				}
				@include respond(large) {
					height: 44px;
					line-height: 40px;
					font-size: 20px;
				}
			}
		}
		.free-shipping-color {
			color: $red;
			&.loggedin {
				color: $green-dark;
			}
		}
        .sms-channel-section {
            max-width: 544px;
            .content-header {
                text-transform: none;
                font-family: neuzeit-grotesk, sans-serif;
            }
            .form-row {
                .lbl-check {
                    font-weight: 400;
                    padding-left: 0;
                    margin-left: 0;
                    display: flex;
                }
                .field-wrapper {
                    span.error ~ span.error {
                        display: none !important;
                    }
                }
                &.smschannelphone {
                    max-width: 464px;
                    .field-wrapper {
                        margin-bottom: 0;
                    }
                }
                &.checkbox-custom {
                    margin-bottom: 0;
                    padding-left: 4px;
                    input[type=checkbox]+label>.styled-checkbox {
                        position: relative;
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        margin-right: 14px;
                        background: $white;
                        border: 2px solid $black;
                        vertical-align: top;
                        min-width: 14px;
                        border-radius: 3px;
                        &::after,
                        &::before {
                            display: none;
                        }
                    }
                    input[type=checkbox] {
                        display: none;
                    }
                    input[type=checkbox]:checked+label>.styled-checkbox {
                        background-color: $black;
                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            height: 3px;
                            background: #fff;
                            display: block;
                        }
                        &::before {
                            width: 6px;
                            height: 2px;
                            bottom: 5px;
                            left: 1px;
                            transform: rotate(45deg);
                        }
                        &::after {
                            width: 10px;
                            height: 2px;
                            right: 0;
                            bottom: 7px;
                            transform: rotate(-45deg);
                        }
                    }
                }
                &.registration-sms-channel-notification,
                &.sms-notification {
                    color: #999;
                    font-weight: 400;
                    padding-left: 4px;
                    p {
                        font-size: 11px;
                        line-height: 16px;
                        margin: 0;
                        & + p {
                            margin-top: 12px;
                        }
                    }
                    a {
                        color: #999;
                        font-size: 11px;
                        line-height: 16px;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .sms-channel-orderconfirmation-subscribed-message {
                .sms-channel-orderconfirmation-subscribed-text {
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 10px;
                    }
                }
                .sms-channel-orderconfirmation-subscribed-actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .button {
                        margin-right: 20px;
                    }
                    .link {
                        text-decoration: underline;
                        cursor: pointer;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .sms-channel-additional-options {
                width: 100%;
                margin-top: 0;
                h2, h3, h4, h5, p, a {
                    color: $black;
                    font-family: $neuzeit;
                    text-transform: none;
                    letter-spacing: normal;
                }
                h2,
                h3,
                h4 {
                    font-weight: 700;
                }
                h2 {
                    font-size: 24px;
                    margin-bottom: 15px;
                }
                h3 {
                    font-size: 20px;
                    padding: 10px 0;
                }
                p,
                h4 {
                    font-size: 16px;
                    width: 100%;
                }
                & > h4 {
                    margin-top: 0;
                    margin-bottom: 8px;
                }
                input[type="checkbox"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: initial;
                    height: initial;
                    overflow: hidden;
                    z-index: 2;
                    min-height: initial;
                    margin: 0;
                }
                input[type="radio"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    right: 0;
                    bottom: 0;
                    margin: 0;
                    min-height: initial;
                    z-index: 2;
                }
                input[type="radio"] + label {
                    background: url('../images/radio_unchecked_pref.svg') left 1px no-repeat;
                    background-size: 16px 16px;
                    span {
                        font-weight: 400;
                    }
                }
                input[type="radio"]:checked + label {
                    background:url('../images/radio_checked_pref.svg') left 1px no-repeat;
                    background-size: 16px 16px;
                }
                .radio-row {
                    label {
                        padding-left: 29px;
                        color: $black;
                        font-size: 11px;
                        line-height: 18px;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
                .check-row {
                    #FIInterests {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .check-btn {
                        opacity: 0;
                    }
                    .option-item {
                        display: inline-block;
                        margin: 0 5px 5px 0;
                        max-width: 75px;
                        width: 100%;
                        @include respond(largeUp) {
                            margin: 0 10px 10px 0;
                            max-width: 106px;
                        }
                    }
                    label {
                        display: inline-block;
                        margin: 0;
                        position: relative;
                        width: 100%;
                    }
                    input.check-btn + span {
                        padding: 4px 6px;
                        color: $gray-dark;
                        border: solid 1px $gray-light;
                        border-radius: 2px;
                        background-color: $white;
                        margin: 0;
                        width: 100%;
                        line-height: 1;
                        min-height: 44px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        @include respond(largeUp) {
                            min-height: 46px;
                        }
                    }
                    input.check-btn:checked + span {
                        color: $white;
                        border-color: #a2c3b8;
                        background-color: $green-light;
                    }
                }
                .form-section {
                    .form-row {
                        .field-wrapper {
                            width: 100%;
                        }
                    }
                    &.style-options {
                        display: flex;
                        justify-content: flex-start;
                        margin-bottom: 13px;
                        .radio-row {
                            width: initial;
                            padding-bottom: 0;
                            position: relative;
                            margin-right: 32px;
                        }
                    }
                    &.birthday-options {
                        label {
                            font-size: 16px;
                            width: 100%;
                            font-weight: 700;
                            color: #000;
                            text-transform: none;
                            letter-spacing: normal;
                            margin-bottom: 11px;
                            margin-top: 15px;
                            padding: 0;
                            @include respond(largeUp) {
                                margin-bottom: 19px;
                                margin-top: 21px;
                            }
                        }
                        .birthday-fields {
                            display: flex;
                            .form-row {
                                margin-right: 10px;
                                max-width: 84px;
                                margin-top: 0;
                                margin-bottom: 0;
                                @include respond(largeUp) {
                                    max-width: 90px;
                                }
                                .field-wrapper {
                                    margin-bottom: 0;
                                }
                                label {
                                    display: none;
                                }
                                select {
                                    padding: 0 28px 0 8px;
                                    background-position: right 7px center;
                                    background-color: white;
                                    background-size: 15px 9px;
                                    font-size: 14px;
                                    height: 38px;
                                    background-image: url("../images/interface/icon-select-arrow-black.svg");
                                    @include respond(largeUp) {
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }
                    &.category-options {
                        margin-bottom: 16px;
                        h4 {
                            margin: 16px 0 10px;
                            @include respond(largeUp) {
                                margin: 22px 0 10px;
                            }
                        }
                    }
                }
            }
            &.sms-channel-section-oc-register {
                .form-row {
                    &.smschannelphone {
                        label {
                            width: 100%;
                        }
                    }
                    &.checkbox-custom {
                        .lbl-check {
                            display: flex;
                            line-height: 16px;
                            font-weight: 400;
                            text-transform: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
	}
    a.button {
        display: inline-block;
        line-height: 44px;
        border-width: 1px;
        text-transform: uppercase;
        border: solid 1px $black;
    }
    .order-cancel-items {
        .cancel-item-quantity {
            text-align: center !important;
            select {
                width: 105px;
            }
            &.cancel-item-select {
                @include respond(small) {
                    position: absolute;
                    bottom: 19px;
                    select {
                        width: 143px;
                        position: absolute;
                        bottom: 0;
                        left: 26px;
                    }
                }
            }
        }
        .total-cancel-price {
            text-align: right !important;
        }
    }
}

#wrapper.pt_order {
	#main .order-confirmation-details table.order-shipment-table tr .product-availability-list {
		@include respond(largeUp) {
			width: 202px;
		}
	}
}

#wrapper.pt_order-confirmation #main {
    margin-top: 135px;
    .order-confirmation-details {
        border: none;
        h2.content-header {
            font-family: $neuzeit;
            font-size: 24px;
        }
        .details-box {
            margin-bottom: 30px;
            .order-payment-instruments {
                margin-top: 20px;
            }
        }
        .order-shipment-table {
            border: solid 1px #e8e8e8;
            border-bottom: none;
        }
        &.order-conf-page {
            table.order-shipment-table {
                border: solid 1px #e8e8e8;
                border-bottom: none;
                tr {
                    td,
                    th {
                        &:nth-child(4) {
                            text-align: right;
                        }
                    }
                }
            }
            .details-box .flex-details {
                max-height: none;
            }
        }
    }
    .continue {
        text-align: center;
        line-height: 44px !important;
        width: auto !important;
    }
}

#wrapper {
    &.pt_account,
    &.pt_order {
        .desktop-visible {
            display: block;
            @include respond(small) {
                display: none;
            }
        }
    }
}
