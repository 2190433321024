#wrapper.pt_product-details {
	.pdp-spredfast {
		background-color: $gray-white;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		@include respond(large) {
			max-width: 768px;
		}
		h2 {
			font-size: 40px;
			padding: 20px 0;
			margin: 0;
			text-align: center;
			@include respond(small) {
				font-size: 30px;
				padding: 15px 0;
			}
		}
	}
}

#pdpMain {
	.product-detail {
		.pdpForm {
			.product-add-to-cart {
				@include respond(large) {
					#design-your-own {
						width: 49%;
						font-size: 12px;
					}
				}
				@include respond(small) {
					#design-your-own {
						width: 100%;
						margin-top: 10px;
					}
				}
			}
		}
		.product-category {
			color: #999;
			font-size: 16px;
			font-weight: 600;
			font-family: "Oswald", sans-serif;
		}
	}
}

#pdpMain {
    .product-col-1 {
        #thumbnails {
            .carousel {
                li.product-video {
                    border: 2px solid $gray-light;
                    .play-text {
                        position: relative;
                        width: 61px;
                        border: 0 none;
                        img {
                            width: 61px;
                        }

                        //play button in black circle:
                        &:before {
                            content: "";
                            display: block;
                            width: 40%;
                            height: 40%;
                            background-color: $black;
                            position: absolute;
                            top: 30%;
                            left: 30%;
                            border-radius: 50%;
                            box-shadow: 0px 0px 10px -2px $white;
                        }
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 0px;
                            height: 0px;
                            top: calc(50% - 5px);
                            left: calc(50% - 2px);
                            border-top: solid transparent 5px;
                            border-right: none;
                            border-bottom: solid transparent 5px;
                            border-left: solid $white 8px;
                        }
                        //end: play button in black circle
                    }
                }
            }
        }
    }
	.search-result-items .grid-tile .product-image img {
		width: 195px;
	}
}

.product-set .product-add-to-cart {
	display: block;
}

@include respond (large) {
	.product-add-to-cart {
		padding: 0;
		.add-to-cart {
			padding: 0px 0px;
			width: 8.25rem;
			font-size: .75rem;
		}
		.design-your-own {
			width: 8.75rem;
			padding: 1px;
			font-size: .75rem;
		}
	}
}

.pdp-main .product-add-to-cart {
	#design-your-own {
		font-size: 14px;
		height: 42px;
		width: 49%;
	}
}

.pdp-global-slot {
	width: 100%;
	background-color: $gray-light;
	height: 2rem;
	font-family: $titillium;
	text-align: center;
	padding-top: 1rem;
	color: $gray-warm;
	margin-bottom: 1rem;
}

.pdp-content-assets {
	float: left;
	width: 100%;
	margin-bottom: 2rem;
	@include respond(small) {
		margin-bottom: 0;
	}
	.content-container {
		margin-bottom: 10px;
	}
	.pdp-content-header,
	.content-asset {
		div, span, a, p, h1, h2, h3, h4, h5, h6 {
			@extend %color-by-class;
		}
		width: 60%;
		margin: auto;
		@include respond(small) {
			width: 100%;
		}
		@include respond(large) {
			width: 80%;
		}
		h3,
		h2 {
			font-size: 20px;
			margin-bottom: 2rem;
			@include respond(small) {
				margin: 0 0 10px 0;
			}
		}
		p {
			font-size: 14px;
			color: $gray-warm;
			@extend %color-by-class;
			padding-bottom: 2rem;
			margin: 0;

			b {
				color: $gray-dark;
				@extend %color-by-class;
			}
		}
	}
}

.pdp-content-header {
	margin-bottom: 20px;
}

.pdp-featuresContent {
	.features-container {
		.features-and-benefits {
			width: 100%;
			.technology-copy {
				margin: 0 0 20px 20px;
				@include respond(large) {
					width: 46%;
					margin-left: 4%;
				}
				@include respond(small) {
					margin-left: 0;
				}
				h4 {
					padding: 0;
					margin: 0;
					text-transform: uppercase;
				}
				p {
					height: auto;
					margin: 0;
					padding: 0 0 40px 0;
				}
			}
			img {
				margin-bottom: 40px;
				clear: both;
				@include respond(large) {
					width: 50%;
					float: left;
				}
				@include respond(small) {
					max-width: 100%
				}
			}
		}
	}
}

.pdp-secondaryContent {
	.content-asset {
		width: 100%;
	}
	.content-container {
		width: 100%;
		float: left;
		margin-bottom: 5rem;
		@include respond(large) {
			margin-bottom: 0;
		}
		@include respond(small) {
			margin-bottom: 18px;
		}
		.content {
			display: block !important;
		}
	}
	.pdpLeftCarousel {
		width: 50%;
		ul.slick-slider {
			padding: 0; //override default PDP ul styles
			li {
				position: relative;
				width: 100%;
			}
		}
		img {
			width: 100%;
		}
	}
	.slide-caption {
		font-size: 30px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-family: $oswald;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: .1em;
		position: absolute;
		bottom: 2.1em;
		margin-left: .075em;
		color: $white;
		@extend %color-by-class;
		width: 100%;
		text-align: center;
		@include respond(large) {
			font-size: 24px;
			line-height: 24px;
			bottom: 1.5em;
		}
		@include respond(small) {
			font-size: 20px;
			line-height: 20px;
		}
	}
	.slide-cta-button {
		position: absolute;
		bottom: 50%;

		//center button...
		left: 50%;
		button {
			margin-left: -50%;
		}
	}
	.content-1 {
		width: 50%;
		float: left;
		height: 600px;
		@include respond(large) {
			//overflow: hidden;
			//height: 420px;
			height: auto;
		}
		@include respond(small) {
			width: 100%;
			height: auto;
			float: left;
			//background-size: cover;
			margin-bottom: 1rem;
		}
	}
	.content-2 {
		width: 50%;
		float: left;
		height: 600px;
		@include respond(large) {
			width: 50%;
			float: left;
			// height: 420px;
			height: 384px;
			background-size: contain;
		}
		@include respond(small) {
			width: 100%;
			float: left;
			background-size: 100%;
			height: 18rem;
			margin-bottom: 8.5rem;
			background-repeat: no-repeat;
		}
		h3 {
			font-size: 30px;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-family: $oswald;
			font-weight: 700;
			margin: 0;
			padding-top: 42px;
			padding-bottom: 28px;
			text-align: center;
			position: relative;
			text-align: center;
			top: 417px;
			@include respond(small) {
				top: 260px;
				font-size: 20px;
				padding-left: 20px;
				padding-right: 20px;
			}
			@include respond(large) {
				top: 242px;
				font-size: 24px;
			}
		}
		button {
			position: relative;
			top: 400px;
			margin: auto;
			width: auto;
			left: 210px;
			@include respond (small) {
				left: calc(50% - 70px);
				top: 240px;
			}
			@include respond (large) {
				left: 90px;
				width: 12rem;
				top: 230px;
			}
		}
	}
	.content-3 {
		width: 100%;
		float: left;
		height: 480px;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
		@include respond(large) {
			height: 307px; //need to manually set height, since image is on background and has no block dimension
		}
		@include respond(small) {
			width: 100%;
			float: left;
			background-size: 100%;
			height: auto;
			margin-bottom: 1rem;
			background-repeat: no-repeat;
		}
		.copy {
			margin-top: 8%;
			margin-left: 6.7%;
			width: 34%;
			@include respond(large) {
				margin-top: 5%;
			}
			@include respond(small) {
				margin-top: 7rem;
				margin-left: 0;
				padding: 0;
			}
			&.right {
				margin-left: 59.3%;
			}
			h3 {
				font-size: 30px;
				margin: 0;
				padding: .467em 0;
				text-transform: uppercase;
				font-family: $oswald;
				letter-spacing: 0.033em;
				@include respond(large) {
					font-size: 25px;
				}
				@include respond(small) {
					font-size: 1.25rem;
					text-align: center;
				}
			}
			p {
				font-size: .875rem;
				font-family: $titillium;
				color: $gray-warm;
				@extend %color-by-class;
				letter-spacing: .035em;
				margin-bottom: 2em;
				padding: 0;
				line-height: 1.45em;
				@include respond(small) {
					font-size: .72rem;
					margin: 0;
				}
				@include respond(large) {
					font-size: .72rem;
				}
			}
			button {
				margin: auto;
				@include respond(small) {
					width: 60%;
					margin-left: 20%;
				}
			}
		}
	}
}

.players-container {
	width: 100%;
	float: left;
	background: #f5f5f5;
	padding: 7px 0 0 20px;
	z-index: 10000;
	margin-bottom: 10px;
	box-sizing: border-box;
	@include respond(large) {
		margin-bottom: 0;
	}
	@include respond(small) {
		padding: 0rem;
		margin-bottom: 0;
	}
	h3 {
		font-size: 2rem;
		text-transform: uppercase;
		font-family: $oswald;
		font-weight: 700;
		float: left;
		width: 20%;
		padding: 1rem;
		text-align: center;
		padding-top: 1rem;
		letter-spacing: 2px;
		box-sizing: border-box;
		margin: 45px 0;
		@include respond(large) {
			font-size: 1.75rem;
			padding-top: 2rem;
			padding-left: 0;
			padding-right: 0;
		}
		@include respond(small) {
			font-size: 1.25rem;
			float: left;
			width: 100%;
			text-align: center;
			padding: 0;
			margin: 17px 0;
		}
	}

	ul.slick-slider {
		width: 72%;
		float: right;
		position: relative;

		@include respond(large) {
			width: 75%;
		}
		@include respond(small) {
			width: 100%;
			padding: 0 10px;
			margin: auto;
			box-sizing: border-box;
			height: auto;
			text-align: center;
			.slick-track {
				min-width: 100%;
			}
		}
		.slick-prev {
			left: 20px;
			width: 31px;
			@include respond(small) {
				left: -5px;
				top: 35px;
			}
		}
		.slick-next {
			right: 20px;
			width: 31px;
			@include respond(small) {
				right: -5px;
				top: 35px;
			}
		}
		li {
			width: 15%;
			list-style: none;
			float: left;
			text-align: center;
			a {
				display: inline;
			}
			@include respond(small) {
				width: 100%;
				margin: 0 4px 10px 4px;
				padding: 0rem;
				box-sizing: border-box;
				float: none;
				display: inline-block;
			}
			img {
				width: 80%;
				display: inline;
				@include respond(small) {
					width: 100%;
				}
			}
			.player-name {
				float: left;
				font-size: .75rem;
				font-family: $titillium;
				color: $gray-warm;
				letter-spacing: .5px;
				text-align: center;
				width: 100%;
				@include respond(small) {
					width: 100%;
				}
			}
		}
	}
}

.design-your-own {
	background: $black;
	border: 2px solid $black;
	color: $white;
	float: right;
	width: 12rem;
	opacity: 1;
	padding: 1px;
	height: 52px;
	&:hover {
		color: $black;
		background-color: $white;
		border-color: $black;
	}
	@include respond(small) {
		height: 52px;
		width: 100%;
		margin-top: 1rem;
	}
}
.product-variations {
	ul {
		li {
			&#design-your-own-swatch,
			[id^="design-your-blank-swatch"] {
				padding: 4px;
				cursor: pointer;

				img {
					display: block;
					margin:0 auto;
					width:23px;
					height: 23px;
					margin-top: 5px;
				}

				.text-own {
					color: $gray-warm;
					display: block;
					text-transform: uppercase;
					text-align: center;
					font-size: 10px;
					font-weight: 600;
					margin: 2px 0 0 0;
					line-height: 1;
				}
			}
		}
	}
}
.features-container {
	width: 100%;
	float: left;
	.features-and-benefits {
		width: 60%;
		@include respond(small) {
			width: 90%;
		}
		margin: auto;
		h3 {
			font-size: 20px;
			text-transform: uppercase;
			font-family: $oswald;
			font-weight: 700;
			margin-top: 2rem;
			@include respond(small) {
				font-size: 1.25rem;
			}
		}
		h4 {
			font-size: .875rem;
			font-family: $titillium;
			color: $black;
			letter-spacing: .5px;
			margin-bottom: -.75rem;
		}
		p {
			font-size: .875rem;
			font-family: $titillium;
			color: $gray-warm;
			letter-spacing: .5px;
			margin-bottom: 2rem;
		}
		img {
			float: left;
		}
		.technology-copy {
			float: left;
			width: 50%;
			margin-left: 1rem;
			margin-bottom: 5rem;
			margin-top: -1rem;
			@include respond(large) {
				float: left;
				width: 80%;
				margin-left: 0rem;
				margin-bottom: 0rem;
				margin-top: 0rem;
			}
			@include respond(small) {
				width: 100%;
				margin-bottom: 1rem;
				margin-top: 0;
				margin-left: 0;
			}
		}
	}
}

.play-text {
	border: 2px solid #ddd;
	float: left;
	width: 55px;
	height: 55px;
	img {
		height: 55px;
	}
}

.pdpPlayers {
	.slick-slider {
		height: 11.65rem;
		width: 96%;
	}
	.slick-prev {
		background-image: url("../images/player-arrow-prev.png");
		left: 280px;
		@include respond(large) {
			left: 183px;
		}
		@include respond(extra-small) {
			left: 20px;
		}
		top: 70px;
		background-repeat: no-repeat;
		display: inline-block !important;
	}
	.slick-next {
		background-image: url("../images/player-arrow-next.png");
		right: 30px;
		@include respond(large) {
			right: 20px;
		}
		@include respond(extra-small) {
			right: 0px;
		}
		top: 70px;
		background-repeat: no-repeat;
		display: inline-block !important;
	}
}

.custom-select-field {
	margin-bottom: 1rem;
	float: left;
	width: 100%;
	margin-top: -1rem;
	.container {
		position: relative;
		width: 49%;
		float: left;
		margin-right: 2%;
		@include respond(small) {
			width: 100%;
			margin-bottom: 0rem;
		}
		&:last-child {
			margin-right: 0;
		}
	}

	&.has-error {
		.must-select {
			margin-top: 16px;
			@include respond(small) {
				margin-top: 0px;
			}
		}
	}
	.must-select {
		position: relative;

		&.not-selected {
			margin-top: 16px;

			.required-error {
				display: block;
			}
		}
	}
	li {
		cursor: pointer;
	}
	.select-header {
		width: 43%;
		font-weight: bold;
		font-size: .75rem;
		text-transform: uppercase;
		@include respond(small) {
			width: 100%;
			float: left;
		}
	}

	.select-length,
	.select-waist,
	.select-hand,
	.select-gloveHand,
	.select-size,
	.select-sizeW,
	.select-sizeJ,
	.select-sizeJG {
		border: 2px solid #ccc;
		border-radius: 1.25rem;
		padding: 7px;
		background-color: #f5f5f5;
		float: left;
		width: 90%;
		box-sizing: border-box;
		@include respond(small) {
			width: 100%;
			margin-bottom: 1rem;
		}
		@include respond(large) {
			width: 97%;
		}
		.header .header-toggle {
			color: #999;
			letter-spacing: 0;
		}
		&.not-selected {
			border-color: $red;

			.header .header-toggle {
				color: $red;
			}
		}
		.content {
			//	display: none;
			padding: 5px;
			@include respond(large) {
				padding: 5px 0;
			}
		}
		li {
			min-width: 22px;
			background-color: white;
			border-radius: 1.25rem;
			border: 2px solid #ededed;
			float: left;
			height: 1.9rem;
			font-weight: bold;
			margin: .15rem;
			text-align: center;
			line-height: 30px;
			padding-left: 5px;
			padding-right: 5px;
			letter-spacing: 0;
			@include respond(large) {
				margin: 1px;
			}
			&:hover {
				border-color: $black;
			}
			.selected {
				border: 2px solid black;
			}
		}
		.selected {
			border: 2px solid black;
		}
		.header {
			cursor: pointer;
			background-image: url("../images/down-arrow.png");
			background-repeat: no-repeat;
			background-position: 97% 0.35rem;
			margin-left: 11px;
			color: #999;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: .5px;
		}
	}
	.select-gloveHand {
		li {
			width: auto;
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	.select-width {
		border: 2px solid #ccc;
		border-radius: 1.25rem;
		padding: 7px;
		background-color: #f5f5f5;
		float: left;
		width: 90%;
		box-sizing: border-box;
		@include respond(large) {
			width: 95%;
		}
		@include respond(small) {
			width: 100%;
			margin-bottom: 1rem;
		}
		&.not-selected {
			border-color: $red;
			.header .header-toggle {
				color: $red;
			}
		}
		.header .header-toggle {
			color: #999;
			letter-spacing: 0;
		}
		.content {
			//	display: none;
			padding: 5px;
			@include respond(large) {
				padding: 5px 0;
			}
		}
		li {
			min-width: 3.5rem;
			background-color: white;
			border-radius: 1.25rem;
			border: 2px solid #ededed;
			float: left;
			height: 2rem;
			font-weight: bold;
			margin: .15rem;
			text-align: center;
			line-height: 2rem;
			padding-left: 5px;
			padding-right: 5px;
			@include respond(large) {
				min-width: 3rem;
			}
			&:hover {
				border-color: $black;
			}
		}
		.selected {
			border: 2px solid black;
		}
		.header {
			cursor: pointer;
			background-image: url("../images/down-arrow.png");
			background-repeat: no-repeat;
			background-position: 97% 0.35rem;
			margin-left: 11px;
			color: #999;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: .5px;
		}
	}
}

.product-set-bundle-variations {
	.custom-select-field {
		margin-bottom: 1rem;
		@include respond(small) {
			width: 10.5rem;
		}
		margin-top: -.5rem;
		.select-size, .select-width, .select-length, .select-waist, .select-hand, .select-gloveHand {
			width: 96%;
			@include respond(large) {
				width: 80%;
			}
		}
		.container {
			width: 98%;
			margin-bottom: 1rem;
			@include respond (small) {
				margin-bottom: 0rem;
			}
			.content {
				//	display: none;
			}
		}
	}
}

.pdp-spredfast {
	@include respond(small) {
		//	display: none;
	}
}

.klarna-placement--wrapper {
	@include respond(largeUp) {
		padding: 0 32px;
		margin-bottom: 30px;
	}
}

#QuickViewDialog {
	.klarna-placement--wrapper {
		@include respond(largeUp) {
			padding: 0;
		}
	}
}

.klarna-placement--cart {
	padding-bottom: 15px;
	border-bottom: 1px solid #e6e6e6;
}
